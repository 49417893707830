import { handleActions } from 'redux-actions';

import { types, inputFieldNames } from '../constants';
import { colors } from '../constants';
import accommodateForBulletPoints from '../filters/accommodateForBulletPoints';
import { convertArrayToString } from '../filters/accommodateForBulletPoints';

const INITIAL_STATE = {
  game: {
    isLoading: false,
    errorMessage: '',
    item: { attachmentFiles: [] },
    validationErrors: {},
    formIsValid: true,
    updatedItem: {
      updatedVariations: [],
      createdGameVariations: [],
      howToPlayArray: [],
      thingsToSayArray: [],
      whatYouNeedArray: [],
      tipsArray: [],
      attachmentFiles: []
    },
    isUpdating: false
  },
  dbVersionOfVariationForTheGame: {
    isLoading: false,
    errorMessage: '',
    item: {}
  },
  //This is only for tracking the request of changing variation id
  changingVariationId: {
    isSaving: false,
    errorMessage: ''
  },
  removeGame: {
    open: false,
    isSaving: false,
    gameClassroomVariation: null,
    errorMessage: ''
  },
  openPrintingDialogBox: false,
  //To fix the bug of printing all games dialog opening when coming back from Children page
  openPrintingDialogBoxInstructionFired: false,
  rateGame: {
    item: null,
    isSaving: false,
    isLoading: false,
    errorMessage: ''
  },
  createGame: {
    dialogOpen: false
  }
};

const clearUpdatedItem = state => {
  return {
    ...state,
    game: {
      ...state.game,
      updatedItem: {
        ...state.game.updatedItem,
        createdGameVariations: [],
        updatedVariations: [],
        howToPlayArray: [],
        thingsToSayArray: [],
        whatYouNeedArray: [],
        tipsArray: [],
        attachmentFiles: []
      }
    }
  };
};

const startUpdateGameRating = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isSaving: true
    }
  };
};
const updateGameRatingSuccess = (state, { updatedRating }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      item: updatedRating
    }
  };
};
const updateGameRatingFailure = (state, { errorMessage }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      errorMessage
    }
  };
};
const updateGameRatingFinished = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isSaving: false
    }
  };
};

function startLoadGame(state) {
  return {
    ...state,
    game: {
      ...state.game,
      isLoading: true
    }
  };
}

const removeGameVariationsWithEmptyDescription = gameVariations => {
  return gameVariations.filter(gv => gv.description.trim() !== '');
};

function loadGameSuccess(state, { game }) {
  if (game.gameVariations) {
    game.gameVariations = removeGameVariationsWithEmptyDescription(game.gameVariations);
  }
  if (game.developmentTypeId === 1) {
    game.color = colors.GREEN;
  } else if (game.developmentTypeId === 2) {
    game.color = colors.ORANGE;
  } else if (game.developmentTypeId === 3) {
    game.color = colors.RED;
  }

  //Adding array forms for Editing a game in EditGamePage.
  if (game.howToPlay) {
    game.howToPlayArray = accommodateForBulletPoints(game.howToPlay, '•');
  } else {
    game.howToPlayArray = [];
  }

  if (game.whatYouNeed) {
    game.whatYouNeedArray = accommodateForBulletPoints(game.whatYouNeed, '•');
  } else {
    game.whatYouNeedArray = [];
  }

  if (game.thingsToSay) {
    game.thingsToSayArray = accommodateForBulletPoints(game.thingsToSay, '•');
  } else {
    game.thingsToSayArray = [];
  }

  if (game.tips) {
    game.tipsArray = accommodateForBulletPoints(game.tips, '•');
  } else {
    game.tipsArray = [];
  }
  //////////////////////////////////////////

  return {
    ...state,
    game: {
      ...state.game,
      item: game
    }
  };
}

function loadGameFailure(state, { errorMessage }) {
  return {
    ...state,
    game: {
      ...state.game,
      errorMessage
    }
  };
}

function loadGameFinished(state) {
  return {
    ...state,
    game: {
      ...state.game,
      isLoading: false
    }
  };
}

function startLoadVariationForGame(state) {
  return {
    ...state,
    dbVersionOfVariationForTheGame: {
      ...state.dbVersionOfVariationForTheGame,
      isLoading: true
    }
  };
}

function loadVariationForGameSuccess(state, { variations }) {
  return {
    ...state,
    dbVersionOfVariationForTheGame: {
      ...state.dbVersionOfVariationForTheGame,
      item: variations
    }
  };
}

function loadVariationForGameFailure(state, { errorMessage }) {
  return {
    ...state,
    dbVersionOfVariationForTheGame: {
      ...state.dbVersionOfVariationForTheGame,
      errorMessage
    }
  };
}

function loadVariationForGameFinished(state) {
  return {
    ...state,
    dbVersionOfVariationForTheGame: {
      ...state.dbVersionOfVariationForTheGame,
      isLoading: false
    }
  };
}

function startUpdateGameVariationId(state) {
  return {
    ...state,
    changingVariationId: {
      ...state.changingVariationId,
      isSaving: true
    }
  };
}

function updateGameVariationIdSuccess(state, { updatedDbVersionOfVariationForTheGame }) {
  return {
    ...state,
    dbVersionOfVariationForTheGame: {
      ...state.dbVersionOfVariationForTheGame,
      item: updatedDbVersionOfVariationForTheGame
    }
  };
}

function updateGameVariationIdFailure(state, { errorMessage }) {
  return {
    ...state,
    changingVariationId: {
      ...state.changingVariationId,
      errorMessage
    }
  };
}

function updateGameVariationIdFinished(state) {
  return {
    ...state,
    changingVariationId: {
      ...state.changingVariationId,
      isSaving: false
    }
  };
}

function showRemoveGameDialog(state, { open }) {
  return {
    ...state,
    removeGame: {
      ...state.removeGame,
      open,
      errorMessage: null
    }
  };
}

function startRemoveGame(state) {
  return {
    ...state,
    removeGame: {
      ...state.removeGame,
      isSaving: true
    }
  };
}

function removeGameSuccess(state, { gameClassroomVariationId }) {
  return {
    ...state,
    removeGame: {
      ...state.removeGame,
      gameClassroomVariation: gameClassroomVariationId
    }
  };
}

function removeGameFailure(state, { errorMessage }) {
  return {
    ...state,
    removeGame: {
      ...state.removeGame,
      errorMessage
    }
  };
}

function removeGameFinished(state) {
  return {
    ...state,
    removeGame: {
      ...state.removeGame,
      isSaving: false
    }
  };
}

const fireOpenPrintingDialogBoxInstruction = (state, { status }) => {
  return {
    ...state,
    openPrintingDialogBoxInstructionFired: status
  };
};

const openPrintingDialog = (state, { open }) => {
  return {
    ...state,
    openPrintingDialogBox: open
  };
};

const clearExistingGameRating = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      item: null
    }
  };
};

const startSubmitRating = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isSaving: true
    }
  };
};

const submitRatingSuccess = (state, { createdRating }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      item: createdRating
    }
  };
};

const submitRatingFailure = (state, { errorMessage }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      errorMessage
    }
  };
};

const submitRatingFinished = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isSaving: false
    }
  };
};

const startGetGameRating = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isLoading: true
    }
  };
};

const getGameRatingSuccess = (state, { createdRating }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      item: createdRating
    }
  };
};

const getGameRatingFailure = (state, { errorMessage }) => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      errorMessage
    }
  };
};

const getGameRatingFinished = state => {
  return {
    ...state,
    rateGame: {
      ...state.rateGame,
      isLoading: false
    }
  };
};

const openCreateGameDialog = (state, { open }) => {
  return {
    ...state,
    createGame: {
      ...state.createGame,
      openDialog: open
    }
  };
};

const loadAttachmentsForGagme = (state, { images }) => {
  var filteredAttachments = images.filter(i => i.container === 2);
  var attachmentCustomObjets = [];

  for (let index = 0; index < filteredAttachments.length; index++) {
    const element = {
      newlyAdded: false,
      removed: false,
      fileData: null,
      name: filteredAttachments[index].mediaFileName,
      mediaURL: ''
    };
    attachmentCustomObjets.push(element);
  }

  return {
    ...state,
    game: {
      ...state.game,
      item: {
        ...state.game.item,
        attachmentFiles: attachmentCustomObjets
      },
      updatedItem: {
        ...state.game.updatedItem,
        attachmentFiles: attachmentCustomObjets
      }
    }
  };
};

//Adding nessessary/missing fields.
const createObjectWithNecessaryFields = (updatedGame, updatedItem) => {
  updatedItem.id = updatedGame.id;
  updatedItem.name = updatedGame.name;
  updatedItem.developmentTypeId = updatedGame.developmentTypeId;
  return updatedItem;
};

const uniqueArray = mixedArray => {
  let newArray = [];
  let uniqueObject = {};
  for (let i in mixedArray) {
    let objTitle = mixedArray[i]['name'];
    uniqueObject[objTitle] = mixedArray[i];
  }
  for (let i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }
  return newArray;
};

const updateTextFieldForGame = (state, { value, inputFieldName }) => {
  if (typeof value === 'string' || value instanceof String) value = value.trim();

  let updatedGame = state.game.item;
  let updatedItem = createObjectWithNecessaryFields(updatedGame, state.game.updatedItem);
  let validationErrors = state.game.validationErrors;
  let formIsValid = state.game.formIsValid;

  switch (inputFieldName) {
    case inputFieldNames.GAME_TITLE:
      if (typeof value === 'undefined' || value === '') {
        validationErrors.gameTitle = 'Game name cannot be empty';
        formIsValid = false;
      } else {
        formIsValid = true;
        delete validationErrors.gameTitle;
      }
      updatedGame.name = value;
      updatedItem.name = value;
      break;
    case inputFieldNames.GAME_DESCRIPTION:
      if (value === '') {
        validationErrors.description = 'Game description cannot be empty';
        formIsValid = false;
      } else {
        formIsValid = true;
        delete validationErrors.description;
      }
      updatedGame.description = value;
      updatedItem.description = value;
      break;

    case inputFieldNames.GAME_TYPE:
      updatedGame.developmentTypeId = value;
      updatedItem.developmentTypeId = value;
      if (value === 1) {
        updatedGame.color = colors.GREEN;
        updatedItem.color = colors.GREEN;
      } else if (value === 2) {
        updatedGame.color = colors.ORANGE;
        updatedItem.color = colors.ORANGE;
      } else if (value === 3) {
        updatedGame.color = colors.RED;
        updatedItem.color = colors.RED;
      }
      break;
    case inputFieldNames.GAME_TIPS:
      updatedGame.tips = value;
      updatedItem.tips = value;
      break;
    case inputFieldNames.GAME_VIDEO:
      updatedGame.video = value;
      updatedItem.video = value;
      break;
    case inputFieldNames.GAME_ATTACHMENT:
      if (!updatedGame.attachmentFiles) {
        updatedGame.attachmentFiles = [];
      }

      updatedGame.attachmentFiles.push(value);
      updatedItem.attachmentFiles.push(value);
      var filteredArray = uniqueArray(updatedGame.attachmentFiles);
      updatedGame.attachmentFiles = filteredArray;
      updatedItem.attachmentFiles = filteredArray;
      break;
    default:
      break;
  }
  return {
    ...state,
    game: {
      ...state.game,
      item: updatedGame,
      updatedItem,
      validationErrors,
      formIsValid
    }
  };
};

const removeAttachment = (state, { attachment }) => {
  let updatedGame = state.game.item;
  let updatedItem = state.game.updatedItem;
  let remainingAttachements = updatedGame.attachmentFiles.filter(item => item.name !== attachment.name);

  updatedGame.attachmentFiles = remainingAttachements;
  updatedItem.attachmentFiles = remainingAttachements;

  return {
    ...state,
    game: {
      ...state.game,
      item: updatedGame,
      updatedItem
    }
  };
};

const updateInstructionForGame = (state, { value, inputFieldName, index }) => {
  let updatedGame = state.game.item;

  let updatedItem = createObjectWithNecessaryFields(updatedGame, state.game.updatedItem);

  if (inputFieldName === inputFieldNames.HOW_TO_PLAY) {
    updatedGame.howToPlayArray[index] = value;
    updatedItem.howToPlayArray = updatedGame.howToPlayArray;
    //This is done for print preview
    updatedGame.howToPlay = convertArrayToString(updatedGame.howToPlayArray);
  } else if (inputFieldName === inputFieldNames.WHAT_YOU_NEED) {
    updatedGame.whatYouNeedArray[index] = value;
    updatedItem.whatYouNeedArray = updatedGame.whatYouNeedArray;
    //This is done for print preview
    updatedGame.whatYouNeed = convertArrayToString(updatedGame.whatYouNeedArray);
  } else if (inputFieldName === inputFieldNames.THINGS_TO_SAY) {
    updatedGame.thingsToSayArray[index] = value;
    updatedItem.thingsToSayArray = updatedGame.thingsToSayArray;
    //This is done for print preview
    updatedGame.thingsToSay = convertArrayToString(updatedGame.thingsToSayArray);
  } else if (inputFieldName === inputFieldNames.TIPS) {
    updatedGame.tipsArray[index] = value;
    updatedItem.tipsArray = updatedGame.tipsArray;
    //This is done for print preview
    updatedGame.tips = convertArrayToString(updatedGame.tipsArray);
  }

  return {
    ...state,
    game: {
      ...state.game,
      item: updatedGame
    }
  };
};

const updateVariationForGame = (state, { value, variationName, id }) => {
  let game = state.game.item;
  //Select the variations out of the list of variations
  let selectedVariation = game.gameVariations.filter(gv => gv.id === id)[0];
  if (selectedVariation) {
    selectedVariation.description = value;
  }

  let updatedVariations = state.game.updatedItem.updatedVariations;
  //Check if selected variation is already present in updatedVariations list.
  let selectedVariationInUpdatedVariations = updatedVariations.filter(v => v.id === selectedVariation.id)[0];

  if (!selectedVariationInUpdatedVariations) {
    updatedVariations = [...updatedVariations, selectedVariation];
  }
  //If the variation already exists in updatedVariations array, then its description
  //  will automatically get updated due to selectedVariation and selectedVariationInUpdatedVariations
  //  pointing to the same object

  return {
    ...state,
    game: {
      ...state.game,
      updatedItem: {
        ...state.game.updatedItem,
        updatedVariations
      },
      item: game
    }
  };
};

const addNewGameVariation = (state, { newGameVariation }) => {
  let createdGameVariations = state.game.updatedItem.createdGameVariations;
  const gameVariationInList = createdGameVariations.filter(
    v => v.levelNumber === newGameVariation.levelNumber && v.developmentTypeId === newGameVariation.developmentTypeId
  )[0];
  if (gameVariationInList) {
    gameVariationInList.description = newGameVariation.description;
  } else {
    createdGameVariations = [...createdGameVariations, newGameVariation];
  }

  return {
    ...state,
    game: {
      ...state.game,
      updatedItem: {
        ...state.game.updatedItem,
        createdGameVariations
      }
    }
  };
};

const startUpdateGame = state => {
  return {
    ...state,
    game: {
      ...state.game,
      isUpdating: true
    }
  };
};

const updateGameSuccess = (state, { game }) => {
  //Adding array forms for Editing a game in EditGamePage.
  if (game.howToPlay) {
    game.howToPlayArray = accommodateForBulletPoints(game.howToPlay, '•');
  } else {
    game.howToPlayArray = [];
  }

  if (game.whatYouNeed) {
    game.whatYouNeedArray = accommodateForBulletPoints(game.whatYouNeed, '•');
  } else {
    game.whatYouNeedArray = [];
  }

  if (game.thingsToSay) {
    game.thingsToSayArray = accommodateForBulletPoints(game.thingsToSay, '•');
  } else {
    game.thingsToSayArray = [];
  }

  if (game.tips) {
    game.tipsArray = accommodateForBulletPoints(game.tips, '•');
  } else {
    game.tipsArray = [];
  }

  if (game.developmentTypeId === 1) {
    game.color = colors.GREEN;
  } else if (game.developmentTypeId === 2) {
    game.color = colors.ORANGE;
  } else if (game.developmentTypeId === 3) {
    game.color = colors.RED;
  }
  //////////////////////////////////////////

  return {
    ...state,
    game: {
      ...state.game,
      item: game
    }
  };
};

const updateGameFailure = (state, { errorMessage }) => {
  return {
    ...state,
    game: {
      ...state.game,
      errorMessage
    }
  };
};

const updateGameFinished = state => {
  return {
    ...state,
    game: {
      ...state.game,
      isUpdating: false
    }
  };
};

const clearSingleGame = state => {
  return {
    ...state,
    game: {
      ...state.game,
      item: {}
    }
  };
};

const changeDefaultVraitionIdForGame = (state, { defaultVariationId }) => {
  return {
    ...state,
    game: {
      ...state.game,
      item: {
        ...state.game.item,
        defaultVariationId
      }
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.START_LOAD_GAME]: startLoadGame,
    [types.LOAD_GAME_SUCCESS]: loadGameSuccess,
    [types.LOAD_GAME_FAILURE]: loadGameFailure,
    [types.LOAD_GAME_FINISHED]: loadGameFinished,

    [types.START_LOAD_VARIATIONS_FOR_GAME]: startLoadVariationForGame,
    [types.LOAD_VARIATIONS_FOR_GAME_SUCCESS]: loadVariationForGameSuccess,
    [types.LOAD_VARIATIONS_FOR_GAME_FAILURE]: loadVariationForGameFailure,
    [types.LOAD_VARIATIONS_FOR_GAME_FINISHED]: loadVariationForGameFinished,

    [types.START_UPDATE_GAME_VARIATION_ID]: startUpdateGameVariationId,
    [types.UPDATE_GAME_VARIATION_ID_SUCCESS]: updateGameVariationIdSuccess,
    [types.UPDATE_GAME_VARIATION_ID_FAILURE]: updateGameVariationIdFailure,
    [types.UPDATE_GAME_VARIATION_ID_FINISHED]: updateGameVariationIdFinished,

    [types.SHOW_REMOVE_GAME_DIALOG]: showRemoveGameDialog,

    [types.START_REMOVE_GAME]: startRemoveGame,
    [types.REMOVE_GAME_SUCCESS]: removeGameSuccess,
    [types.REMOVE_GAME_FAILURE]: removeGameFailure,
    [types.REMOVE_GAME_FINISHED]: removeGameFinished,

    [types.FIRE_OPEN_PRITING_DIALOG_BOX_INSTRUCTION]: fireOpenPrintingDialogBoxInstruction,
    [types.OPEN_PRINTING_DIALOG]: openPrintingDialog,

    [types.CLEAR_EXISTING_GAME_RATING]: clearExistingGameRating,

    [types.LOAD_ATTACHMENTS_FOR_GAME]: loadAttachmentsForGagme,

    [types.REMOVE_GAME_ATTACHMENT]: removeAttachment,

    [types.START_SUBMIT_RATING]: startSubmitRating,
    [types.SUBMIT_RATING_SUCCESS]: submitRatingSuccess,
    [types.SUBMIT_RATING_FAILURE]: submitRatingFailure,
    [types.SUBMIT_RATING_FINISHED]: submitRatingFinished,

    [types.START_GET_GAME_RATING]: startGetGameRating,
    [types.GET_GAME_RATING_SUCCESS]: getGameRatingSuccess,
    [types.GET_GAME_RATING_FAILURE]: getGameRatingFailure,
    [types.GET_GAME_RATING_FINISHED]: getGameRatingFinished,

    [types.START_UPDATE_GAME_RATING]: startUpdateGameRating,
    [types.UPDATE_GAME_RATING_SUCCESS]: updateGameRatingSuccess,
    [types.UPDATE_GAME_RATING_FAILURE]: updateGameRatingFailure,
    [types.UPDATE_GAME_RATING_FINISHED]: updateGameRatingFinished,

    [types.OPEN_CREATE_GAME_DIALOG]: openCreateGameDialog,

    [types.UPDATE_TEXTFIELD_FOR_GAME]: updateTextFieldForGame,
    [types.UPDATE_INSTRUCTION_FOR_GAME]: updateInstructionForGame,
    [types.UPDATE_VARIATION_FOR_GAME]: updateVariationForGame,

    [types.START_UPDATE_GAME]: startUpdateGame,
    [types.UPDATE_GAME_SUCCESS]: updateGameSuccess,
    [types.UPDATE_GAME_FAILURE]: updateGameFailure,
    [types.UPDATE_GAME_FAILURE]: updateGameFinished,
    [types.CLEAR_SINGLE_GAME]: clearSingleGame,
    [types.ADD_NEW_GAME_VARIATION]: addNewGameVariation,

    [types.CLEAR_UPDATED_ITEM]: clearUpdatedItem,
    [types.CHANGE_DEFAULT_VARIATION_ID_FOR_GAME]: changeDefaultVraitionIdForGame
  },
  INITIAL_STATE
);
