import { StyleSheet, Font } from '@react-pdf/renderer';
import Roboto_Bold from './../../../Font/Roboto_Bold.ttf';
import Roboto_Normal from './../../../Font/Roboto-Light.ttf';

Font.register({
  family: 'Roboto_Bold',
  src: Roboto_Bold
});

Font.register({
  family: 'Roboto_Regular',
  src: Roboto_Normal
});

const styles = StyleSheet.create({
  page: {
    height: '100%'
  },
  pageContainer: {},
  pageContent: {
    border: '1px solid red',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    height: '92vh'
  },
  sheetOne: {
    flex: 1,
    borderRadius: 10
  },

  sheetOneContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    height: '100%'
  },
  sheetOneTitleContainer: {},
  gameTitle: {
    textAlign: 'center',
    fontSize: 32,
    fontFamily: 'Roboto_Bold'
  },
  sheetOneImageContainer: {
    marginTop: 12,
    marginBottom: 15
  },
  gameImage: {
    display: 'inline-block'
  },
  sheetOneDescriptionContainer: {
    flexGrow: 1,
    fontSize: 12
  },
  gameDescription: {
    textAlign: 'left',
    fontSize: 16,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    fontFamily: 'Roboto_Regular'
  },
  footerContainer: {
    marginTop: 16
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  gameTypeContainer: {},
  gameType: {
    padding: '3px 10px',
    color: 'white',
    borderRadius: 10,
    fontSize: 14
  },
  logoContainer: { paddingRight: 5 },
  appLogo: {
    width: 90
  }
});

export default styles;
