// ChangePasswordDialog/index.js
import { connect } from 'react-redux';
import LogoutDialog from '../../components/LogoutDialog';

import { openLogOutDialog, logout } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    open: state.classroom.logout && state.classroom.logout.dialogOpen,
    isSaving: state.classroom.logout && state.classroom.logout.isSaving
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onShowLogoutDialog: open => {
      dispatch(openLogOutDialog(open));
    },
    onSubmitLogoutRequest: () => {
      dispatch(logout());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutDialog);
