import React from 'react';
import { Toolbar, AppBar } from '@material-ui/core';

import './style.css';

const GenericHeader = ({ children, className }) => {
  return (
    <AppBar position="static" className={className}>
      <Toolbar className={'toolbar'}>{children}</Toolbar>
    </AppBar>
  );
};

export default GenericHeader;
