import { call, put, takeLatest } from 'redux-saga/effects';

import { types } from '../constants';
import * as classroomsApi from '../api/classrooms';
import { showMessage } from '../actions';

function* watchLoadClassroomDetailsByEmailFromDb({ email, token }) {
  try {
    yield put({ type: types.START_LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB });
    const { jsonData } = yield call(classroomsApi.getclassroomDetailsByEmail, email, token);
    yield put({ type: types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_SUCCESS, classroom: jsonData });
  } catch (e) {
    yield resolveError(types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FINISHED });
  }
}

function* watchChangePassword({ classroomName, token }) {
  try {
    yield put({ type: types.START_CHANGE_PASSWORD });
    const { jsonData } = yield call(classroomsApi.chnagePassword, classroomName, token);
    yield put({ type: types.CHANGE_PASSWORD_SUCCESS, success: jsonData });
    yield put({ type: types.OPEN_CHANGE_PASSWORD_DIALOG, open: false });
    yield put(showMessage(`A password change email has been sent to ${classroomName}.`));
  } catch (error) {
    yield resolveError(types.CHANGE_PASSWORD_FAILURE, error);
  } finally {
    yield put({ type: types.CHANGE_PASSWORD_FINISHED });
  }
}

function* watchLoadVariationDetailsForAllGames({ classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING });
    const { jsonData } = yield call(classroomsApi.loadVariationDetailsForAllGames, classroomId, token);
    yield put({
      type: types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_SUCCESS,
      classroomVariationDetailsForPrinting: jsonData
    });
  } catch (error) {
    yield resolveError(types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FINISHED });
  }
}

function* watchLoadNotifications({ classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_NITIFICATIONS });
    const { jsonData } = yield call(classroomsApi.loadNotifications, classroomId, token);
    yield put({ type: types.LOAD_NITIFICATIONS_SUCCESS, showNotification: jsonData });
  } catch (error) {
    yield resolveError(types.LOAD_NITIFICATIONS_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_NITIFICATIONS_FINISHED });
  }
}

function* watchLoadRolesForUser({ auth0UserId, token }) {
  try {
    yield put({ type: types.START_LOAD_ROLES_FOR_USER });
    const { jsonData } = yield call(classroomsApi.loadRolesForUser, auth0UserId, token);
    yield put({ type: types.LOAD_ROLES_FOR_USER_SUCCESS, userRoles: JSON.parse(jsonData) });
  } catch (error) {
    yield resolveError(types.LOAD_ROLES_FOR_USER_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_ROLES_FOR_USER_FINISHED });
  }
}

//This is used to catch all the errors in case of request unsuccessful, and will extract the actual error message.
function* resolveError(type, error) {
  let errorMessageCaptured = false;
  let completeErrorMessage = {};
  if (!error.ok) {
    try {
      const clone = error.clone();
      yield clone.text().then(text => {
        completeErrorMessage = JSON.parse(text);
        errorMessageCaptured = true;
      });
    } catch (err) {
      errorMessageCaptured = true;
      completeErrorMessage = err;
    }

    if (errorMessageCaptured) {
      const errorMessage = extractErrorMessage(completeErrorMessage);
      yield put({ type, errorMessage });
    }
  }
}

function extractErrorMessage(completeErrorMessage) {
  let errorMessage = '';

  try {
    const message = completeErrorMessage.exceptionMessage
      ? completeErrorMessage.exceptionMessage.split('Content:')[1]
      : completeErrorMessage.messageDetail;

    errorMessage = JSON.parse(message).errorMessage;
    if (!errorMessage) {
      errorMessage = 'An error occured';
    }
  } catch (error) {
    errorMessage = 'An error occured';
  }

  return errorMessage;
}

export default function* gamesWatcher() {
  yield takeLatest(types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB, watchLoadClassroomDetailsByEmailFromDb);
  yield takeLatest(types.CHANGE_PASSWORD, watchChangePassword);

  yield takeLatest(
    types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING,
    watchLoadVariationDetailsForAllGames
  );

  yield takeLatest(types.LOAD_NITIFICATIONS, watchLoadNotifications);

  yield takeLatest(types.LOAD_ROLES_FOR_USER, watchLoadRolesForUser);
}
