import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  para: {
    fontSize: 20,
    color: '#8c8b8b',
    textAlign: 'center'
  }
}));

const EmptySearchResultsMessage = props => {
  const classes = useStyles();
  const { message = 'No games match your search', textSize = 'large' } = props;

  return (
    <React.Fragment>
      <p className={classes.para} style={{ fontSize: textSize === 'large' ? 20 : 16 }}>
        {message}
      </p>
    </React.Fragment>
  );
};

export default EmptySearchResultsMessage;
