import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

import { useAuth0 } from '../../Auth';
import ChildFeedbackDialog from '../../../containers/ChildFeedbackDialog';
import ReportCharts from './../ReportCharts';
import ChildReportActionButtons from './../ChildReportActionButtons';

const useStyles = makeStyles(theme => ({}));

const ReportsTab = props => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  const {
    child,
    handleCompleteChildReportButtonClick,
    isLoadingReportForChart,
    reportForChart,
    onGetReportButtonsStatus,
    reportButtonsStatus
  } = props;

  async function fetchToken() {
    return await getTokenSilently();
  }

  useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      const { child, onLoadChildReports } = props;
      if (child) {
        onLoadChildReports(child.id, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child]);

  return (
    <React.Fragment>
      <ChildFeedbackDialog child={child} />
      <div className={classes.variationsTabContainer}>
        <div className={classes.content}>
          {isLoadingReportForChart && <LinearProgress />}
          <ReportCharts reportForChart={reportForChart} />

          <ChildReportActionButtons
            handleCompleteChildReportButtonClick={handleCompleteChildReportButtonClick}
            child={child}
            onGetReportButtonsStatus={onGetReportButtonsStatus}
            reportButtonsStatus={reportButtonsStatus}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsTab;
