import { types } from '../constants';

export const clearSingleImage = () => ({
  type: types.CLEAR_SINGLE_IMAGE
});

export const setGameImageDimensions = (imageDimensions, gameId) => ({
  type: types.SET_GAME_IMAGE_DIMENSIONS,
  imageDimensions,
  gameId
});
