// ChildrenList/index.js
import React from 'react';
import { List, ListItem, ListItemText, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';

import { useAuth0 } from '../Auth';
import calculatePreferredName from '../../filters/calculatePreferredName';
import EmptySearchResultsMessage from '../EmptySearchResultsMessage';

import Highlight from '../../utils/highlight';

const useStyles = makeStyles(theme => ({
  childListItem: {
    cursor: 'pointer'
  },
  childrenList: {
    height: '100%',
    overflowY: 'auto',
    margin: 0,
    padding: 0
  },
  selectedListItem: {
    background: 'rgba(9, 163, 74, 0.2) !important'
  },
  heading: {
    fontSize: '16px'
  },
  emptySearchResultMessageContainer: {
    height: 'calc(100vh - 165px)',
    position: 'relative'
  },
  emptySearchResultMessageContent: {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  dot: {
    color: 'red',
    fontSize: 'small'
  }
}));

const ChildrenList = props => {
  const classes = useStyles();
  const { children, onChildClick, isLoadingChildren, getAllChildrenForClassroom, searchTerm = '' } = props;
  const { getTokenSilently } = useAuth0();

  //=ComponendDidMount()
  React.useEffect(() => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      const { classroomId } = props;
      if (classroomId) {
        getAllChildrenForClassroom(classroomId, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classroomId]);

  if (searchTerm !== '' && children.length === 0) {
    return (
      <div className={classes.emptySearchResultMessageContainer}>
        <div className={classes.emptySearchResultMessageContent}>
          <EmptySearchResultsMessage message="No children match your search" textSize="small" />
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      {isLoadingChildren && <LinearProgress />}
      <List className={classes.childrenList}>
        {children &&
          children.map(child => {
            return (
              <React.Fragment key={child.id}>
                <ListItem
                  className={classes.childListItem}
                  onClick={() => onChildClick(child.id)}
                  selected={child.isSelected}
                  classes={{ selected: classes.selectedListItem }}
                  id={`${child.firstName}_${child.lastName}`}
                >
                  <ListItemText className={classes.heading}>
                    <Highlight text={calculatePreferredName(child)} highlight={searchTerm} />
                  </ListItemText>
                  {child.areAllReportsFilled === false ? <FiberManualRecordIcon className={classes.dot} /> : ''}
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
      </List>
    </React.Fragment>
  );
};

ChildrenList.propTypes = {
  // describe the component's prop types here
};

ChildrenList.defaultProps = {
  // assign default props to the component here
};

export default ChildrenList;
