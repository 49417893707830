import { types } from '../constants';

export const getClassroomDetailsByEmailFromDb = (email, token) => ({
  type: types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB,
  email,
  token
});

export const changeTabValueInHeader = tabValue => ({
  type: types.CHANGE_TAB_VALUE_IN_HEADER,
  tabValue
});

export const openLogOutDialog = open => ({
  type: types.LOGOUT_DIALOG,
  open
});

export const logout = () => ({
  type: types.HANDLE_LOGOUT
});

export const loadUserRoles = (auth0UserId, token) => ({
  type: types.LOAD_ROLES_FOR_USER,
  auth0UserId,
  token
});
