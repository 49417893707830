import React from 'react';
import { Button, Toolbar, AppBar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  toolbar: {
    margin: '0 auto',
    padding: '0 !important',
    width: 1200,
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: '#000000',
    fontSize: 16,
    marginTop: 5,
    fontWeight: 600
  },
  titleContainer: {
    display: 'flex'
  },
  arrowButton: {
    marginLeft: -20
  }
}));

const HeaderWithBackButton = ({ children, className, position = 'static', title, onBackArrowClick }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position={position} className={className}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            <Button color="primary" className={classes.arrowButton} onClick={onBackArrowClick} id="backButton">
              <ArrowBackIcon />
            </Button>

            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </div>{' '}
          <div className={classes.content}>{children}</div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default HeaderWithBackButton;
