// Header/index.js
import React from 'react';
import { Tabs, Tab, IconButton, Typography, LinearProgress, MenuItem, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingIcon from '@material-ui/icons/Settings';

import { useAuth0 } from '../Auth';
import EngageLogo from '../../res/Engage Play Tools_green.svg';
import ChangePasswordDialog from '../../containers/ChangePasswordDialog';
import LogoutDialog from '../../containers/LogOutDialog';
import NotificationIcon from './NotificationIcon';
import GenericHeader from '../GenericHeader';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    '@media print': {
      display: 'none'
    }
  },
  tabsContainer: {
    flexGrow: 1,
    height: 64,
    marginLeft: 20
  },
  tabsRoot: {
    height: 64
  },
  tabButton: {
    height: 64,
    fontWeight: 600
  },
  logo: {
    width: 103,
    marginTop: 12
  },
  headerClass: {
    background: '#fff'
  },
  toolbar: {
    padding: 0,
    width: 1200,
    margin: '0 auto'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    color: '#000'
  },
  userName: {
    display: 'none',
    color: '#000',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 560,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    margin: 0
  },
  headerButtonsContainer: {},
  highlightTab: {
    background: theme.palette.primary.main,
    color: '#fff !important'
  }
}));

function Header(props) {
  const classes = useStyles();

  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const {
    isLoadingClassroom,
    isLoadingMedia,
    isLoadingImages,
    isLoadingClassroomVariationDetailsForPrinting,
    isLoadingNotification,
    classroomName,
    visibleTab,
    onChangePassword,
    classroomId,
    onLoadNotifications,
    showNotificationDot,
    isLoadingGameDetailsForMultipleGames,
    onLoadUserRoles,
    onLogin
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  async function fetchToken() {
    return await getTokenSilently();
  }

  //=ComponentDidMount
  React.useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      if (user) {
        const { classroomName } = props;
        if (!classroomName) {
          //Send request only if the object is empty.
          onLogin(user.email, token);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(
    () => {
      if (user) {
        fetchToken().then(token => {
          onLoadUserRoles(user.sub, token);
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const handleChange = (event, newValue) => {
    props.onChangeTabValueInHeader(newValue);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  /* Currently disabling the notification click TODO: need to umcomment 'onNotificationClicked' props when we need it back */
  const handleNotificationClicked = () => {
    const { onNotificationClicked } = props;
    // onNotificationClicked(true);
  };

  const handleLogoutClick = () => {
    setAnchorEl(null);
    props.onShowLogoutDialog(true);
  };

  const handleChangePasswordClick = () => {
    setAnchorEl(null);
    onChangePassword();
  };

  const fetchNotifications = () => {
    fetchToken().then(token => {
      onLoadNotifications(classroomId, token);
    });
  };

  return (
    <div className={`${classes.headerContainer} ${classes.grow1}`}>
      <ChangePasswordDialog />

      <LogoutDialog />
      <GenericHeader className={classes.headerClass}>
        <Typography className={classes.title} variant="h6" color="primary">
          <img src={EngageLogo} alt="engage logo" className={classes.logo} />
        </Typography>
        <div className={classes.tabsContainer}>
          <Tabs
            value={visibleTab}
            aria-label="disabled tabs example"
            textColor="primary"
            onChange={handleChange}
            className={classes.tabsRoot}
            indicatorColor="primary"
          >
            <Tab
              label="My Games"
              id="gamesTab"
              className={`${classes.tabButton} ${visibleTab === 0 ? classes.highlightTab : ''}`}
            />
            <Tab
              label="Children"
              id="childrenTab"
              className={`${classes.tabButton} ${visibleTab === 1 ? classes.highlightTab : ''}`}
            />
          </Tabs>
        </div>
        <div className={classes.userName}>
          <h5 className={classes.userName}>{classroomName}</h5>

          {/* {isLoading || isLoadingClassroomVariationDetailsForPrinting ? <LinearProgress variant="indeterminate" /> : ''} */}
        </div>
        {/* Currently disabling the notification setting the showNotificationDot to always false */}
        <div className={classes.headerButtonsContainer}>
          <NotificationIcon
            handleNotificationClicked={handleNotificationClicked}
            fetchNotifications={fetchNotifications}
            showNotificationDot={false}
          />

          <React.Fragment>
            <IconButton aria-label="show 4 new mails" color="inherit" onClick={handleMenu} id="settings-button">
              <SettingIcon color="primary" />
            </IconButton>
            {isAuthenticated ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleChangePasswordClick} id="logout-menu-item">
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogoutClick} id="logout-menu-item">
                  Logout
                </MenuItem>
              </Menu>
            ) : null}
          </React.Fragment>
        </div>
      </GenericHeader>
      {isLoadingClassroom ||
      isLoadingMedia ||
      isLoadingImages ||
      isLoadingClassroomVariationDetailsForPrinting ||
      isLoadingNotification ||
      isLoadingGameDetailsForMultipleGames ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        ''
      )}
    </div>
  );
}

Header.propTypes = {
  // describe the component's prop types here
};

Header.defaultProps = {
  // assign default props to the component here
};

export default Header;
