export const HOME_PAGE = '/home';
export const LANDING = '/landing-page';
export const ALL_GAMES = '/games';
export const GAME_DETAIL = `${ALL_GAMES}/:gameId`;
export const GAME_DETAILS_FROM_ALL_GAMES = `${ALL_GAMES}/:gameId/view-details`;
export const CHILDREN = '/children';

export default {
  HOME_PAGE,
  LANDING,
  ALL_GAMES,
  GAME_DETAIL,
  GAME_DETAILS_FROM_ALL_GAMES,
  CHILDREN
};
