import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import GamesCategoryColumn from '../GamesCategoryColumn';
import { useAuth0 } from '../Auth';
import PrintVariations from '../../components/PrintVariations';
import EmptyClassroomDashboard from './EmptyClassroomDashboard';
import EmptySearchResultsMessage from '../EmptySearchResultsMessage';

const useStyles = makeStyles(theme => ({
  printingVariations: {
    display: 'none',
    '@media print': {
      display: 'block'
    }
  },

  content: {
    height: 'calc(100% - 75px)',
    overflow: 'hidden',
    '@media print': {
      display: 'none'
    }
  },
  gridRoot: {
    height: '100%'
  },
  gridItem: {
    height: '100%'
  },

  emptyClassroomDashboard: {
    height: '100%'
  },
  emptySearchResultMessageContainer: {
    height: 'calc(100vh - 165px)',
    // height: 'calc(100% - 75px)',
    position: 'relative'
  },
  emptySearchResultMessageContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const ClassroomDashboard = props => {
  const classes = useStyles();

  const { getTokenSilently } = useAuth0();

  const {
    behaviouralGames,
    cognitiveGames,
    emotionalGames,
    behaviouralGamesForPrinting,
    cognitiveGamesForPrinting,
    emotionalGamesForPrinting,
    searchTerm,
    isLoadingImages,
    classroomId,
    onLoadVariationDetailsForAllGames
  } = props;

  //=ComponendDidMount()
  React.useEffect(() => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      const { classroomId } = props;
      if (classroomId) {
        props.onGetGamesForClassroom(classroomId, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classroomId]);

  const handleGameClick = gameId => {
    const { onGameClick } = props;
    onGameClick(gameId);
  };

  if (
    searchTerm !== '' &&
    behaviouralGames.length === 0 &&
    cognitiveGames.length === 0 &&
    emotionalGames.length === 0
  ) {
    return (
      <div className={classes.emptySearchResultMessageContainer}>
        <div className={classes.emptySearchResultMessageContent}>
          <EmptySearchResultsMessage />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* This component is used only for printing */}
      <div className={classes.printingVariations}>
        <PrintVariations
          behaviouralGames={behaviouralGames}
          cognitiveGames={cognitiveGames}
          emotionalGames={emotionalGames}
          classroomId={classroomId}
          onLoadVariationDetailsForAllGames={onLoadVariationDetailsForAllGames}
          behaviouralGamesForPrinting={behaviouralGamesForPrinting}
          cognitiveGamesForPrinting={cognitiveGamesForPrinting}
          emotionalGamesForPrinting={emotionalGamesForPrinting}
        />
      </div>
      <div className={classes.content}>
        {behaviouralGames.length > 0 || cognitiveGames.length > 0 || emotionalGames.length > 0 ? (
          <Grid container spacing={4} className={classes.gridRoot}>
            <Grid item xs={4} className={classes.gridItem}>
              <GamesCategoryColumn
                gamesCollection={emotionalGames}
                handleGameClick={handleGameClick}
                searchTerm={searchTerm}
                isLoadingImages={isLoadingImages}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <GamesCategoryColumn
                gamesCollection={cognitiveGames}
                handleGameClick={handleGameClick}
                searchTerm={searchTerm}
                isLoadingImages={isLoadingImages}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <GamesCategoryColumn
                gamesCollection={behaviouralGames}
                handleGameClick={handleGameClick}
                searchTerm={searchTerm}
                isLoadingImages={isLoadingImages}
              />
            </Grid>
          </Grid>
        ) : (
          <EmptyClassroomDashboard className={classes.emptyClassroomDashboard} />
        )}
      </div>
    </React.Fragment>
  );
};

export default ClassroomDashboard;
