// HomePage/index.js
import { connect } from 'react-redux';

import HomePage from '../../components/HomePage';
import { splashScreenShowFinished } from '../../actions';

function mapStateToProps(state, ownProps) {
  const { classroom } = state.classroom;

  return {
    classroomName: classroom && classroom.item && classroom.item.name,
    isLoading: classroom && classroom.isLoading,
    isLoadingClassroom: state.games.gamesForClassroom.isLoading,
    isLoadingMedia: state.medias.gameMedias.isLoading,
    isLoadingImages: state.medias.actualImages.isLoading,
    splashScreenShownOnce: state.general.splashScreenShownOnce,
    visibleTab: state.general.visibleTab
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSplashScreenShowFinished: () => {
      dispatch(splashScreenShowFinished());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
