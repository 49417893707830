import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import MomentUtils from '@date-io/moment';

const styles = makeStyles(theme => {
  return {
    datePickerContainer: {
      background: theme.palette.backgroundGray.default
    },
    root: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    input: {
      minWidth: 120,
      width: '100%',
      borderRadius: 5,

      '& input': {
        marginLeft: 10,
        marginBottom: theme.spacing(1)
      },
      '& label': {
        marginLeft: 10
      }
    }
  };
});

const DatePicker = props => {
  const classes = styles();

  const { handleDateChange, selectedDate, htmlId } = props;
  return (
    <div className={classes.datePickerContainer}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          id={htmlId}
          value={selectedDate}
          label="Date of Birth"
          onChange={date => handleDateChange(date)}
          minDate="1/1/1970"
          maxDate={new Date()}
          format="DD/MM/YYYY"
          variant="contained"
          className={classes.input}
          KeyboardButtonProps={{ className: classes.root }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
