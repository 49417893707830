import { types } from '../constants';

export const loadAllChildren = (classroomId, token) => ({
  type: types.LOAD_ALL_CHILDREN,
  classroomId,
  token
});

export const changeTabValueInChildViewPanel = tabValue => ({
  type: types.CHANGE_TAB_VALUE_IN_CHILDVIEWPANEL,
  tabValue
});

export const openAddNewChildDialog = open => ({
  type: types.OPEN_ADD_NEW_CHILD_DIALOG,
  open
});

export const changeNewChildInputField = (value, fieldName) => ({
  type: types.CHANGE_ADD_NEW_CHILD_INPUT_FIELD,
  value,
  fieldName
});

export const changeUpdateChildInputField = (value, fieldName) => ({
  type: types.CHANGE_UPDATE_CHILD_INPUT_FIELD,
  value,
  fieldName
});

export const updateChild = (child, token) => ({
  type: types.UPDATE_CHILD,
  child,
  token
});

export const submitNewChild = (child, token) => ({
  type: types.CREATE_NEW_CHILD,
  child,
  token
});

export const setChild = child => ({
  type: types.SET_CHILD,
  child
});

export const openEditChildDialogBox = open => ({
  type: types.OPEN_EDIT_CHILD_DIALOG,
  open
});

export const openChildReportDialog = (open, reportTypeId) => ({
  type: types.OPEN_CHILD_REPORT_DIALOG,
  open,
  reportTypeId
});

export const clearAllReportStatuses = () => ({
  type: types.CLEAR_ALL_REPORT_STATUSES
});

export const getQuestionsForTheChild = (childReportTemplateType, token) => ({
  type: types.GET_QUESTIONS_FOR_CHILD,
  childReportTemplateType,
  token
});

export const answerChange = (selectedAnswerId, questionId) => ({
  type: types.CHANGE_SELECTED_ANSWER_FOR_ANSWER,
  selectedAnswerId,
  questionId
});

export const submitAnswer = (response, token) => ({
  type: types.SUBMIT_CHILD_REPORT_ANSWERS,
  response,
  token
});

export const searchTermChangeForChildren = searchTerm => ({
  type: types.CHANGE_SEARCH_TERM_ALL_CHILDREN,
  searchTerm
});
export const loadChildReports = (childId, token) => ({
  type: types.LOAD_CHILD_REPORT,
  childId,
  token
});

export const openTransferChildDialog = open => ({
  type: types.OPEN_TRANSFER_CHILD_DIALOG,
  open
});

export const changeTransferChildEmailInputField = value => ({
  type: types.CHANGE_TRANSFER_CHILD_EMAIL_INPUT_FIELD,
  value
});

export const transferChild = (payload, token) => ({
  type: types.TRANSFER_CHILD,
  payload,
  token
});

export const showDeactivateChildDialogStateChanged = open => ({
  type: types.SHOW_DEACTIVATE_CHILD_DIALOG,
  open
});

export const deactiveChild = (payload, token) => ({
  type: types.DEACTIVATE_CHILD,
  payload,
  token
});

export const setActiveStep = activeStep => ({
  type: types.SET_ACTIVE_STEP,
  activeStep
});

export const setCompletedForIndex = index => ({
  type: types.SET_COMPLETED_FOR_INDEX,
  index
});
