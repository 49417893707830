import React from 'react';
import { Text, Font } from '@react-pdf/renderer';

import styles from '../styles';

// Register hyphenation callback.
// In this example, we enable words to break in half
Font.registerHyphenationCallback(word => {
  return [word];
});

const GameDescription = props => {
  const { description } = props;
  return (
    <Text wrap={true} style={styles.gameDescription}>
      {description}
    </Text>
  );
};

export default GameDescription;
