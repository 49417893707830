const mapToAddGamesPayload = (checkedGames, classroomId) => {
  var games = [];

  //TODO
  if (!classroomId) {
    classroomId = 15;
  }

  checkedGames.forEach(game => {
    const payload = {
      gameId: game.id,
      classroomId: classroomId,
      gameVariationId: game.defaultVariationId
    };

    games = [...games, payload];
  });

  return games;
};

export default mapToAddGamesPayload;
