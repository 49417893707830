import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import PaperTile from '../../PaperTile';
import { colors } from '../../../constants';
import CustomChip from '../../CustomChip';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
  titleContainer: {},
  title: {
    fontSize: 10,
    fontWeight: 600
  },
  variationTitle: {
    fontSize: 16,
    fontWeight: 600
  },
  variationsContainer: {},
  variationsContent: {
    marginTop: 10
  },
  variationListContainer: {
    marginTop: 10
  },
  singleVariation: {
    display: 'flex',
    padding: '3px 0'
  },
  prefix: {
    marginRight: 6,
    fontSize: 12,
    fontWeight: 600
  },
  description: {
    fontSize: 10
  }
}));

const VariationsList = props => {
  const classes = useStyles();
  const { variationTitle, color, variationPrefix, gameVariations } = props;

  if (!gameVariations) {
    return '';
  }

  gameVariations.sort((a, b) => (a.levelNumber > b.levelNumber ? 1 : -1));

  return (
    <div className={`variationsContent ${classes.variationsContent}`}>
      <CustomChip chipLabel={variationTitle.toUpperCase()} color={color} />
      <div className={`variationListContainer ${classes.variationListContainer}`}>
        {gameVariations.map(variation => {
          return (
            <div className={classes.singleVariation} key={variation.id}>
              <span className={classes.prefix} style={{ color: color }}>
                {`${variationPrefix}${variation.levelNumber}`}
              </span>
              <span className={`description ${classes.description}`}>{variation.description}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const VariationsPaperTile = props => {
  const classes = useStyles();
  const { game, gameColor } = props;

  const allGameVariations = game && game.gameVariations;
  const behaviouralGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 1);
  const cognitiveGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 2);
  const emotionalGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 3);

  const allList = [
    { variations: behaviouralGames, gameDevId: 1, title: 'Behavioural', variationPrefix: 'B', color: colors.GREEN },
    { variations: cognitiveGames, gameDevId: 2, title: 'Cognitive', variationPrefix: 'C', color: colors.ORANGE },
    { variations: emotionalGames, gameDevId: 3, title: 'Emotional', variationPrefix: 'E', color: colors.RED }
  ];
  const index = game && allList.findIndex(g => g.gameDevId === game.developmentTypeId);

  if (index > -1) {
    const toaddObj = allList[index];
    allList.splice(index, 1);
    allList.unshift(toaddObj);
  }

  return (
    <PaperTile>
      <div className={`container ${classes.container}`}>
        <div className={`titleContainer ${classes.titleContainer}`}>
          <Typography variant="h4" gutterBottom className={classes.title} style={{ color: gameColor }}>
            VARIATIONS
          </Typography>
        </div>

        <div className={`variationsContainer ${classes.variationsContainer}`}>
          {allList && allList[0].variations && (
            <VariationsList
              variationTitle={allList[0].title}
              color={allList[0].color}
              variationPrefix={allList[0].variationPrefix}
              gameVariations={allList[0].variations}
            />
          )}
          {allList && allList[1].variations && (
            <VariationsList
              variationTitle={allList[1].title}
              color={allList[1].color}
              variationPrefix={allList[1].variationPrefix}
              gameVariations={allList[1].variations}
            />
          )}
          {allList && allList[2].variations && (
            <VariationsList
              variationTitle={allList[2].title}
              color={allList[2].color}
              variationPrefix={allList[2].variationPrefix}
              gameVariations={allList[2].variations}
            />
          )}
        </div>
      </div>
    </PaperTile>
  );
};

export default VariationsPaperTile;
