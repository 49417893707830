import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';

import SingleVariationDetailInChildViewPanel from '../SingleVariationDetailInChildViewPanel';
import { useAuth0 } from '../../Auth';
import GamesFilter from '../GamesFilter';

const useStyles = makeStyles(theme => ({
  variationsTabContainer: {},
  divider: {},
  content: {
    height: 'calc(100vh - 355px)',
    overflow: 'auto'
  }
}));

const VariationsTab = props => {
  const classes = useStyles();

  const {
    allGamesForClassroom,
    onGetGameDetailsForMultipleGames,
    handleChildGameVariationChange,
    childId,
    onGetChildGameVariationsForChild,
    onClearGameDetailsForMultipleGames,
    onClearChildGameVariationsForChild,
    isSavingChildGameVariation,
    isLoadingSelectedVariationsForChild,
    classroomId
  } = props;

  const { getTokenSilently } = useAuth0();
  //IMPORTANT: Using local state because filtering is local to ONLY this component.
  const [gamesForClassroom, setGamesForClassroom] = React.useState(allGamesForClassroom);

  const handleGamesFilterChange = games => {
    setGamesForClassroom(games);
  };

  React.useEffect(() => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      const gameIds = allGamesForClassroom && allGamesForClassroom.map(g => g.id);
      onClearGameDetailsForMultipleGames();
      onClearChildGameVariationsForChild();
      onGetGameDetailsForMultipleGames(gameIds, token);
      onGetChildGameVariationsForChild(childId, token);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId]);

  return (
    <div className={classes.variationsTabContainer}>
      <GamesFilter gamesForClassroom={allGamesForClassroom} handleGamesFilterChange={handleGamesFilterChange} />

      <div className={classes.content}>
        {gamesForClassroom &&
          gamesForClassroom.map(game => {
            return (
              <React.Fragment>
                <SingleVariationDetailInChildViewPanel
                  game={game}
                  key={game.id}
                  handleChildGameVariationChange={handleChildGameVariationChange}
                  isSavingChildGameVariation={isSavingChildGameVariation}
                  isLoadingSelectedVariationsForChild={isLoadingSelectedVariationsForChild}
                  classroomId={classroomId}
                />
                <Divider className={classes.divider} />
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default VariationsTab;
