import { StyleSheet, Font } from '@react-pdf/renderer';

import Roboto_Bold from './../../../Font/Roboto_Bold.ttf';
import Roboto_Normal from './../../../Font/Roboto-Light.ttf';

Font.register({
  family: 'Roboto_Bold',
  src: Roboto_Bold
});

Font.register({
  family: 'Roboto_Regular',
  src: Roboto_Normal
});

const styles = StyleSheet.create({
  sheet: {
    flex: 1,
    paddingLeft: 30
  },
  instructionContainer: {
    marginBottom: 25
  },
  title: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
    padding: 0,
    margin: 0,
    paddingBottom: 10,
    fontFamily: 'Roboto_Bold'
  },
  body: {
    fontSize: 16,
    fontFamily: 'Roboto_Regular'
  },
  chip: {
    height: 20,
    marginBottom: 5,
    fontSize: 12,
    marginRight: 10,
    fontFamily: 'Roboto_Regular'
  },
  list: {},
  singleInstructionContainer: {
    display: 'flex'
  },
  listItemText: {
    fontSize: 10,
    margin: '6px 0px !important',
    color: '#3A3A3A',
    fontWeight: 400,
    fontFamily: 'Roboto_Regular'
  },
  singleDirection: {
    display: 'flex',
    padding: '3px 0',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'Roboto_Regular'
  },
  blueNumbering: {
    fontFamily: 'Roboto_Bold'
  },
  bulletPoints: {
    // display: 'inline-block',
    fontSize: 20,
    marginTop: -5
  },
  instruction: {
    fontSize: 10,
    fontFamily: 'Roboto_Regular',
    marginLeft: 5,
    width: 350
  },
  pg2Headings: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto_Bold',
    marginBottom: 5
  }
});

export default styles;
