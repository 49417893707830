import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';

export default function configureStore(initialState) {
  const persistedState = loadFromLocalStorage();
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  store.subscribe(() => {
    return saveToLocalStorage({
      games: store.getState().games,
      classroom: store.getState().classroom
    });
  });

  return store;
}
