// EditGamePage/index.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid, Paper, Fab, Input } from '@material-ui/core';

import Page from '../../Page';
import FancyDropDown from './../../FancyDropDown';
import { EditGameInstructions, EditGameVariations } from '../EditGameInstructions';
import { inputFieldNames } from '../../../constants';
import ErrorMessage from '../../ErrorMessage';
import mapVariationsToDropDownFriendlyFormat from '../../../filters/mapVariationsToDropDownFriendlyFormat';
import { useAuth0 } from '../../Auth';

import { Attachment as AttachmentIcon } from '@material-ui/icons';

import Attachments from '../Attachments';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '20px 0 60px 0'
  },
  paperContainer: {
    padding: '30px 20px'
  },
  paperContent: {},
  label: {
    fontSize: 16,
    fontWeight: 600
  },
  standardMargin: {
    marginTop: '16px !important',
    marginBottom: '8px !important'
  },
  imageContainer: {
    marginTop: 10,
    height: '300px',
    // width: '323px',
    backgroundPosition: 'center !important',
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important'
  },
  addAttachmentFab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  fab: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    width: 210,
    height: '36px !important',
    borderWidth: 1.3,
    flex: 1
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Body = props => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();

  const {
    game,
    validationErrors,
    gameImage,
    gameTypes,
    handleInputChange,
    handleVariationChange,
    handleAddingNewGameVariation,
    onFetchDefaultVariationForGame,
    handleDefaultVariationChange,
    handleRemoveAttachment,
    gameAttachments
  } = props;

  const hiddenFileInput = React.createRef();

  async function fetchToken() {
    return await getTokenSilently();
  }

  React.useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      onFetchDefaultVariationForGame(game.id, token);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let behaviouralGames, cognitiveGames, emotionalGames;
  if (game && game.gameVariations) {
    behaviouralGames = game.gameVariations.filter(game => game.developmentTypeId === 1);
    cognitiveGames = game.gameVariations.filter(game => game.developmentTypeId === 2);
    emotionalGames = game.gameVariations.filter(game => game.developmentTypeId === 3);
  }

  let mappedVariations;
  if (game.gameVariations && game.gameVariations.length > 0) {
    mappedVariations = mapVariationsToDropDownFriendlyFormat(game.gameVariations);
  }

  if (!game) {
    return '';
  }

  const handleClick = event => {
    hiddenFileInput.current.children[0].click();
  };

  const onAttachmendAdded = event => {
    event.preventDefault();
    const { handleAttahmentChange } = props;
    const file = event.target.files[0];
    const newObj = { newlyAdded: true, removed: false, fileData: file, name: file.name };
    handleAttahmentChange(newObj, inputFieldNames.GAME_ATTACHMENT);
    hiddenFileInput.current.children[0].value = null;
  };

  return (
    <React.Fragment>
      <Page>
        <div className={classes.content}>
          <Paper>
            <div className={classes.paperContainer}>
              <div className={classes.paperContent}>
                <Grid container>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Typography className={classes.label}>Game Details</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      id="gameTitle"
                      label="Title"
                      defaultValue={game.name}
                      className={`${classes.textField} ${classes.gameTitleTextField}`}
                      margin="normal"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      onChange={e => handleInputChange(e, inputFieldNames.GAME_TITLE)}
                    />
                    {validationErrors.gameTitle ? <ErrorMessage message={validationErrors.gameTitle} /> : ''}
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      id="gameDescription"
                      label="Description"
                      defaultValue={game.description}
                      className={`${classes.textField} ${classes.gameDescriptionTextField}`}
                      margin="normal"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      multiline
                      onChange={e => handleInputChange(e, inputFieldNames.GAME_DESCRIPTION)}
                    />
                    {validationErrors.description ? <ErrorMessage message={validationErrors.description} /> : ''}
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    <div className={classes.standardMargin}>
                      <FancyDropDown
                        htmlId="gameTypeDropDown"
                        label="Game type"
                        margin="normal"
                        fullWidth
                        value={game.developmentTypeId}
                        handleChange={e => handleInputChange(e, inputFieldNames.GAME_TYPE)}
                        items={gameTypes}
                        dropDownWidth="100%"
                        className={classes.dropDown}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}></Grid>

                  <Grid item xs={6} className={classes.gridItem}>
                    <div className={classes.standardMargin}>
                      {gameImage && (
                        <div
                          id="gameImage"
                          className={classes.imageContainer}
                          style={{ background: `url(${gameImage})` }}
                        ></div>
                      )}
                      {!gameImage && (
                        <div
                          className={classes.imageContainer}
                          style={{
                            background: '#eeeeee'
                          }}
                        ></div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}></Grid>
                </Grid>
                <br />

                <EditGameInstructions
                  instructions={game.howToPlayArray}
                  title="How to Play"
                  listStyleType="numbering"
                  fieldCount={9}
                  isGrid={false}
                  handleInputChange={handleInputChange}
                />
                <br />

                <EditGameInstructions
                  instructions={game.whatYouNeedArray}
                  title="What you need"
                  fieldCount={6}
                  isGrid={true}
                  handleInputChange={handleInputChange}
                />
                <br />

                <EditGameInstructions
                  instructions={game.thingsToSayArray}
                  title="Things you can say"
                  listStyleType="bullet"
                  fieldCount={6}
                  isGrid={false}
                  handleInputChange={handleInputChange}
                />
                <br />
                <br />

                <Typography className={classes.label}>Variations</Typography>

                <Grid container>
                  <Grid item xs={4}>
                    <FancyDropDown
                      htmlId="gameVariationDropDown"
                      label="Default Variation"
                      margin="normal"
                      fullWidth
                      value={game.defaultVariationId || -1}
                      handleChange={handleDefaultVariationChange}
                      items={mappedVariations && mappedVariations.sort((a, b) => (a.prefix > b.prefix ? 1 : -1))}
                      dropDownWidth="100%"
                      className={classes.dropDown}
                    />
                  </Grid>
                </Grid>

                <EditGameVariations
                  gameVariations={behaviouralGames}
                  fullWidth={true}
                  actionButtonText="Add variation"
                  title="Behavioural"
                  numberingPrefix="B"
                  handleVariationChange={handleVariationChange}
                  listStyleType="numbering"
                  isGrid={false}
                  developmentTypeId={1}
                  gameId={game.id}
                  handleAddingNewGameVariation={handleAddingNewGameVariation}
                />
                <br />

                <EditGameVariations
                  gameVariations={cognitiveGames}
                  fullWidth={true}
                  actionButtonText="Add variation"
                  title="Cognitive"
                  numberingPrefix="C"
                  handleVariationChange={handleVariationChange}
                  listStyleType="numbering"
                  isGrid={false}
                  developmentTypeId={2}
                  gameId={game.id}
                  handleAddingNewGameVariation={handleAddingNewGameVariation}
                />
                <br />

                <EditGameVariations
                  gameVariations={emotionalGames}
                  fullWidth={true}
                  actionButtonText="Add variation"
                  title="Emotional"
                  numberingPrefix="E"
                  handleVariationChange={handleVariationChange}
                  listStyleType="numbering"
                  isGrid={false}
                  developmentTypeId={3}
                  gameId={game.id}
                  handleAddingNewGameVariation={handleAddingNewGameVariation}
                />
                <br />
                <br />

                <Grid container>
                  {/***************** Tips ******************/}
                  <Grid item xs={12} className={classes.gridItem}>
                    <EditGameInstructions
                      instructions={game.tipsArray}
                      title="Tips"
                      listStyleType="bullet"
                      fieldCount={5}
                      isGrid={false}
                      handleInputChange={handleInputChange}
                    />

                    {/* 
                    <Grid item xs={12} className={classes.gridItem}>
                      <Typography className={classes.label}>Tips</Typography>
                    </Grid>
                    <TextField
                      id="gameTips"
                      defaultValue={game.tips}
                      className={`${classes.textField} ${classes.gameTipsTextField}`}
                      margin="normal"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      onChange={e => handleInputChange(e, inputFieldNames.GAME_TIPS)}
                    /> */}
                  </Grid>
                  <br />
                  <br />

                  {/***************** Video ******************/}
                  <Grid item xs={12} className={classes.gridItem}>
                    <Typography className={classes.label}>Video</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      id="gameVideo"
                      defaultValue={game.video}
                      className={`${classes.textField} ${classes.gameVideoTextField}`}
                      margin="normal"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      onChange={e => handleInputChange(e, inputFieldNames.GAME_VIDEO)}
                    />
                  </Grid>
                </Grid>

                {/***************** Attachment ******************/}
                <Grid item xs={12} className={classes.gridItem}>
                  <Typography className={classes.label}>Attachments</Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Fab
                    id="addGamesButton"
                    color="primary"
                    variant="extended"
                    aria-label="add"
                    onClick={handleClick}
                    className={`${classes.addAttachmentFab} ${classes.button}`}
                  >
                    <AttachmentIcon className={classes.extendedIcon} />
                    Add attachment
                    <Input
                      type="file"
                      ref={hiddenFileInput}
                      multiple={false}
                      onChange={onAttachmendAdded}
                      style={{ display: 'none' }}
                    />
                  </Fab>
                </Grid>
                {gameAttachments && (
                  <Grid item xs={12} className={classes.gridItem}>
                    <Attachments gameAttachments={gameAttachments} onRemoveAttachment={handleRemoveAttachment} />
                  </Grid>
                )}
              </div>
            </div>
          </Paper>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default Body;
