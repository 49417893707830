import { StyleSheet, Font } from '@react-pdf/renderer';

import Roboto_Bold from './../../../Font/Roboto_Bold.ttf';
import Roboto_Normal from './../../../Font/Roboto-Light.ttf';

Font.register({
  family: 'Roboto_Bold',
  src: Roboto_Bold
});

Font.register({
  family: 'Roboto_Regular',
  src: Roboto_Normal
});

const styles = StyleSheet.create({
  sheet: {
    flex: 1,
    overflow: 'hidden',
    paddingLeft: 30
  },
  pg2Headings: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'Roboto_Bold'
  },
  variationsContent: {
    marginTop: 10
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  chip: {
    fontSize: 10,
    color: 'white',
    padding: '3px 5px',
    borderRadius: 7
  },
  variationListContainer: {
    marginTop: 10
  },
  singleVariation: {
    display: 'flex',
    padding: '3px 0',
    flexDirection: 'row'
  },
  prefix: {
    marginRight: 6,
    fontSize: 10,
    fontFamily: 'Roboto_Bold'
  },
  description: {
    fontSize: 10,
    width: '90%',
    fontFamily: 'Roboto_Regular'
  }
});

export default styles;
