import React from 'react';
import { makeStyles, Typography, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  instructionContainer: {
    marginBottom: 25,
    // '@media (max-width: 800px)': {
    '@media print': {
      marginTop: 30
    }
  },
  title: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
    padding: 0,
    margin: 0
  },
  body: {
    fontSize: 16
  },
  chip: {
    height: 20,
    marginBottom: 5,
    fontSize: 10,
    marginRight: 10
  },
  list: {
    paddingLeft: 16
  },

  listItemText: {
    fontSize: 10,
    margin: '6px 0px !important',
    color: '#3A3A3A',
    fontWeight: 400
  }
}));

const SingleInstruction = props => {
  const classes = useStyles();
  const { title, body, includeChips, orderedList, color } = props;

  if (!title || !body) {
    return '';
  }

  //Body will be an array in case of What you need.
  if (Array.isArray(body) && body.length === 0) {
    return '';
  }

  const addLineBreaks = string =>
    string.split('*').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  return (
    <div className={`instructionContainer ${classes.instructionContainer}`}>
      <Typography variant="h4" gutterBottom className={classes.title} style={{ color: color }}>
        {title.toUpperCase()}
      </Typography>

      {/* IncludeChips is true in case of What you need */}
      {includeChips ? (
        body.map((item, index) => {
          return <Chip key={index} label={addLineBreaks(item)} className={classes.chip} />;
        })
      ) : (
        // <List dense={true} className={classes.list}>
        <React.Fragment>
          {/* Notes should not have any body */}
          {body && body === ' ' ? (
            ''
          ) : (
            <ol className={classes.list} style={{ listStyleType: orderedList ? 'decimal' : 'disc' }}>
              {body.map((item, index) => {
                return (
                  <li key={index} style={{ color: color, fontSize: 10, fontWeight: 800 }}>
                    <p className={classes.listItemText}>{addLineBreaks(item)}</p>
                  </li>
                );
              })}
            </ol>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default SingleInstruction;
