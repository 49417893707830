import React from 'react';
import { makeStyles } from '@material-ui/core';

import PaperTile from '../../PaperTile';
import SingleInstruction from '../SingleInstruction';
import accommodateForBulletPoints from '../../../filters/accommodateForBulletPoints';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  }
}));

const HowToPlayTile = props => {
  const classes = useStyles();

  const { gameInstructions, gameColor } = props;

  const whatYouNeed = gameInstructions.whatYouNeed && accommodateForBulletPoints(gameInstructions.whatYouNeed, '•');
  return (
    <PaperTile>
      <div className={classes.container}>
        <SingleInstruction
          title="How to play"
          body={gameInstructions.howToPlay && accommodateForBulletPoints(gameInstructions.howToPlay, '•')}
          color={gameColor}
          orderedList={true}
        />
        <SingleInstruction title="What you need" body={whatYouNeed} includeChips={true} color={gameColor} />
        <SingleInstruction
          title="Things you can say"
          body={gameInstructions.thingsToSay && accommodateForBulletPoints(gameInstructions.thingsToSay, '•')}
          color={gameColor}
        />
      </div>
    </PaperTile>
  );
};

export default HowToPlayTile;
