import { types } from '../constants';

export const openNotificationDrawer = open => ({
  type: types.OPEN_NOTIFICATION_DRAWER,
  open
});

export const openConfirmChangePasswordDialog = open => ({
  type: types.OPEN_CHANGE_PASSWORD_DIALOG,
  open
});

export const submitChangePasswordRequest = (classroomName, token) => ({
  type: types.CHANGE_PASSWORD,
  classroomName,
  token
});

export const loadNotifications = (classroomId, token) => ({
  type: types.LOAD_NITIFICATIONS,
  classroomId,
  token
});
