import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LandingPage from '../../components/LandingPage';
import { getAllGames, setLastVisitedPage } from '../../actions';
import { urls, pageNames } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  return {
    gameId: 1
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;

  return {
    onLoginClicked: () => {
      dispatch(setLastVisitedPage(pageNames.HOME_PAGE));
      history.replace(urls.HOME_PAGE);
    },
    handleGetGames: () => {
      dispatch(getAllGames());
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
