import * as client from './baseClient';

export const getclassroomDetailsByEmail = (email, token) => client.get(`/api/classrooms/${email}/`, token);

export const chnagePassword = (classroomName, token) =>
  client.post(`/api/classrooms/change-password/${classroomName}/`, token);

export const loadVariationDetailsForAllGames = (classroomId, token) =>
  client.get(`/api/classrooms/${classroomId}/variation-details`, token);

export const loadNotifications = (classroomId, token) =>
  client.get(`/api/classrooms/${classroomId}/notifications`, token);

export const loadRolesForUser = (auth0UserId, token) => {
  const url = `/api/classrooms/${auth0UserId}/roles`;
  return client.get(url, token);
};
