// AddNewChildDialog/index.js
import { connect } from 'react-redux';

import AddNewChildDialog from '../../components/AddNewChildDialog';
import { openAddNewChildDialog, changeNewChildInputField, submitNewChild } from '../../actions';

function mapStateToProps(state, ownProps) {
  const firstName = state.child.newChild.item.firstName;
  const lastName = state.child.newChild.item.lastName;
  const gender = state.child.newChild.item.gender;
  const dob = state.child.newChild.item.dob;
  const ethnicity = state.child.newChild.item.ethnicity;

  return {
    openDialog: state.child.newChild.showDialog,
    child: state.child.newChild.item,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    isLoading: state.child.newChild.isLoading,
    enableAddButton: firstName !== '' && lastName !== '' && gender !== '' && ethnicity !== '' && dob
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseDialog: () => {
      dispatch(openAddNewChildDialog(false));
    },
    onInputFieldTextChange: (value, inputFieldName) => {
      dispatch(changeNewChildInputField(value, inputFieldName));
    },
    onSubmitNewChild: (child, token) => {
      dispatch(submitNewChild(child, token));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewChildDialog);
