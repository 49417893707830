import React from 'react';
import { Page, View, Document } from '@react-pdf/renderer';

import styles from './styles';
import TitlePdfTile from './TitlePdfTile';
import HowToPlayPdfTile from './HowToPlayPdfTile';
import VariationsPdfTile from './VariationsPdfTile';
import TipsAndNotesPdfTile from './TipsAndNotesPdfTile';

const GameDetailPdf = props => {
  const { data, gameImage, mode, gameImageDimensionRatio } = props;

  if (mode === 'single-page') {
    return (
      <Document>
        <Page orientation="landscape" style={styles.page}>
          <View style={styles.pageContainer}>
            <View style={styles.pageContent}>
              <TipsAndNotesPdfTile gameInstructions={data} gameColor={data.color} />
              <TitlePdfTile
                game={data}
                gameImage={gameImage}
                gameColor={data.color}
                gameImageDimensionRatio={gameImageDimensionRatio}
              />
            </View>
          </View>
        </Page>
        <Page orientation="landscape" style={styles.page}>
          <View style={styles.pageContainer}>
            <View style={styles.pageContent}>
              <HowToPlayPdfTile game={data} gameColor={data.color} />
              <VariationsPdfTile game={data} gameColor={data.color} />
            </View>
          </View>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document>
        {data &&
          data.map(game => {
            return (
              <React.Fragment key={game.id}>
                <Page orientation="landscape" style={styles.page}>
                  <View style={styles.pageContainer}>
                    <View style={styles.pageContent}>
                      <TipsAndNotesPdfTile gameInstructions={game} gameColor={game.color} />
                      <TitlePdfTile
                        game={game}
                        gameImage={game.image}
                        gameColor={game.color}
                        gameImageDimensionRatio={game.dimensionRatio}
                      />
                    </View>
                  </View>
                </Page>
                <Page orientation="landscape" style={styles.page}>
                  <View style={styles.pageContainer}>
                    <View style={styles.pageContent}>
                      <HowToPlayPdfTile game={game} gameColor={game.color} />
                      <VariationsPdfTile game={game} gameColor={game.color} />
                    </View>
                  </View>
                </Page>
              </React.Fragment>
            );
          })}
      </Document>
    );
  }
};

export default GameDetailPdf;
