import { handleActions } from 'redux-actions';

import { types } from '../constants';

const INITIAL_STATE = {
  logout: {
    dialogOpen: false,
    isSaving: false,
    errorMessage: ''
  },
  classroom: {
    item: null,
    isLoading: false,
    eroorMessage: ''
  },
  changePassword: {
    dialogOpen: false,
    isSaving: false,
    errorMessage: ''
  },
  userRoles: {
    items: [],
    isLoading: false,
    errorMessage: ''
  }
};

function startLoadClassroomDetailsByEmail(state) {
  return {
    ...state,
    classroom: {
      ...state.classroom,
      isLoading: true
    }
  };
}
function loadClassroomDetailsByEmailSuccess(state, { classroom }) {
  return {
    ...state,
    classroom: {
      ...state.classroom,
      item: classroom
    }
  };
}
function loadClassroomDetailsByEmailFailure(state, { errorMessage }) {
  return {
    ...state,
    classroom: {
      ...state.classroom,
      errorMessage
    }
  };
}
function loadClassroomDetailsByEmailFinished(state) {
  return {
    ...state,
    classroom: {
      ...state.classroom,
      isLoading: false
    }
  };
}

const openChangePasswordDialog = (state, { open }) => {
  return {
    ...state,
    changePassword: {
      ...state.changePassword,
      dialogOpen: open
    }
  };
};

const openLogOutDialog = (state, { open }) => {
  return {
    ...state,
    logout: {
      ...state.logout,
      dialogOpen: open
    }
  };
};

const startChangePassword = state => {
  return {
    ...state,
    changePassword: {
      ...state.changePassword,
      isSaving: true
    }
  };
};

const changePasswordSuccess = state => {
  return {
    ...state
  };
};

const changePasswordFailure = (state, { errorMessage }) => {
  return {
    ...state,
    changePassword: {
      ...state.changePassword,
      errorMessage
    }
  };
};

const changePasswordFinished = state => {
  return {
    ...state,
    changePassword: {
      ...state.changePassword,
      isSaving: true,
      dialogOpen: false
    }
  };
};

const handleLogout = state => {
  return {
    ...state,
    classroom: {
      item: null,
      isLoading: false,
      eroorMessage: ''
    },
    logout: {
      ...state.logout,
      dialogOpen: false
    }
  };
};

const startLoadRolesForUser = state => {
  return {
    ...state,
    userRoles: {
      ...state.userRoles,
      isLoading: true
    }
  };
};

const loadRolesForUserSuccess = (state, { userRoles }) => {
  return {
    ...state,
    userRoles: {
      ...state.userRoles,
      items: userRoles
    }
  };
};

const loadRolesForUserFailure = (state, { errorMessage }) => {
  return {
    ...state,
    userRoles: {
      ...state.userRoles,
      errorMessage
    }
  };
};

const loadRolesForUserFinished = state => {
  return {
    ...state,
    userRoles: {
      ...state.userRoles,
      isLoading: false
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.START_LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB]: startLoadClassroomDetailsByEmail,
    [types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_SUCCESS]: loadClassroomDetailsByEmailSuccess,
    [types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FAILURE]: loadClassroomDetailsByEmailFailure,
    [types.LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FINISHED]: loadClassroomDetailsByEmailFinished,
    [types.OPEN_CHANGE_PASSWORD_DIALOG]: openChangePasswordDialog,

    [types.START_CHANGE_PASSWORD]: startChangePassword,
    [types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
    [types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
    [types.CHANGE_PASSWORD_FINISHED]: changePasswordFinished,

    [types.LOGOUT_DIALOG]: openLogOutDialog,
    [types.HANDLE_LOGOUT]: handleLogout,

    [types.START_LOAD_ROLES_FOR_USER]: startLoadRolesForUser,
    [types.LOAD_ROLES_FOR_USER_SUCCESS]: loadRolesForUserSuccess,
    [types.LOAD_ROLES_FOR_USER_FAILURE]: loadRolesForUserFailure,
    [types.LOAD_ROLES_FOR_USER_FINISHED]: loadRolesForUserFinished
  },
  INITIAL_STATE
);
