import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingIcon from '@material-ui/icons/Settings';
import { Tabs, Tab, IconButton, Typography, LinearProgress, MenuItem, Menu } from '@material-ui/core';

import { useAuth0 } from '../Auth';
import EngageLogo from '../../res/Landing-page/WhiteLogo.svg';
import LogoutDialog from '../../containers/LogOutDialog';
import GenericHeader from '../GenericHeader';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    '@media print': {
      display: 'none'
    }
  },
  tabsContainer: {
    flexGrow: 1,
    height: 64,
    marginLeft: 20
  },
  tabsRoot: {
    height: 64
  },
  tabButton: {
    height: 64
  },
  logo: {
    width: 103,
    marginTop: 12
  },
  headerClass: {
    // background: '#fff'
  },
  toolbar: {
    padding: 0,
    width: 1200,
    margin: '0 auto'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    color: '#000'
  },
  userName: {
    display: 'none',
    color: '#000',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 560,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    margin: 0
  },
  headerButtonsContainer: {}
}));

const HeaderAdmin = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    isAuthenticated
    // user, getTokenSilently
  } = useAuth0();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoutClick = () => {
    setAnchorEl(null);
    props.onShowLogoutDialog(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { isLoadingGames, isLoadingImages } = props;

  return (
    <div className={`${classes.headerContainer} ${classes.grow1}`}>
      <LogoutDialog />

      <GenericHeader className={classes.headerClass}>
        <Typography className={classes.title} variant="h6" noWrap color="primary">
          <img src={EngageLogo} alt="engage logo" className={classes.logo} />
        </Typography>
        <div className={classes.tabsContainer}>
          <Tabs
            value={0}
            // onChange={handleChange}
            TabIndicatorProps={{ style: { background: 'white' } }}
            className={classes.tabsRoot}
          >
            <Tab label="Games" id="gamesTab" className={classes.tabButton} />
            <Tab label="Classrooms" id="classroomsTab" className={classes.tabButton} />
            <Tab label="Statistics" id="statisticsTab" className={classes.tabButton} />
          </Tabs>
        </div>
        <div className={classes.userName}></div>
        <div className={classes.headerButtonsContainer}>
          <React.Fragment>
            <IconButton aria-label="show 4 new mails" color="inherit" onClick={handleMenu} id="settings-button">
              <SettingIcon />
            </IconButton>
            {isAuthenticated ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogoutClick} id="logout-menu-item">
                  Logout
                </MenuItem>
              </Menu>
            ) : null}
          </React.Fragment>
        </div>
        {/* {isLoadingClassroom ||
          isLoadingMedia ||
          isLoadingImages ||
          isLoadingClassroomVariationDetailsForPrinting ||
          isLoadingNotification ? (
            <LinearProgress variant="indeterminate" />
          ) : (
            ''
          )} */}
      </GenericHeader>
      {isLoadingGames || isLoadingImages ? <LinearProgress /> : ''}
    </div>
  );
};

export default HeaderAdmin;
