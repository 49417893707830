import React from 'react';
import { makeStyles } from '@material-ui/styles';
import GameCard from '../GameCard';

const useStyles = makeStyles(theme => ({
  columnContainer: {
    height: '100%',
    background: '#DDDDDD'
  },
  columnTitleContainer: {
    color: 'white',
    height: 35,
    borderRadius: '5px 5px 0 0',
    display: 'flex'
  },
  heading: {
    paddingLeft: 10,
    // paddingTop: 7,
    marginTop: 7,
    fontSize: 15,
    fontWeight: 400,
    flexGrow: 11,
    display: 'inline-block'
  },
  gamesCount: {
    display: 'inline-block',
    marginTop: 7,
    flexGrow: 1
  },
  gamesContainer: {
    padding: 10,
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 40px)' //40px for top and bottom paddings.
  },
  gamesContent: {
    height: 'calc(100% - 10px)'
  }
}));

const GamesCategoryColumn = props => {
  const classes = useStyles();

  const { gamesCollection, handleGameClick, searchTerm, isLoadingImages } = props;
  return (
    <React.Fragment>
      <div className={classes.columnContainer}>
        <div className={classes.columnTitleContainer} style={{ background: gamesCollection.color }}>
          <h2 className={classes.heading}>{gamesCollection.title}</h2>
          <p className={classes.gamesCount}> {gamesCollection.length} </p>
        </div>
        <div className={classes.gamesContainer}>
          <div className={classes.gamesContent}>
            {gamesCollection &&
              gamesCollection.map((game, index) => {
                return (
                  <GameCard
                    game={game}
                    key={index}
                    // chipColor={gamesCollection.color}
                    handleGameClick={handleGameClick}
                    searchTerm={searchTerm}
                    isLoadingImages={isLoadingImages}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GamesCategoryColumn;
