import React from 'react';
import { Paper, Fab, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  notificationContainer: {
    height: '100%',
    width: '100%',
    cursor: 'pointer'
  },
  paper: {
    height: '100%',
    marginTop: 10
  },
  heading: {
    marginTop: 0,
    marginRight: 40,
    marginLeft: 0,
    marginBottom: 0,
    padding: 10,
    fontWeight: 550
  },
  content: {
    marginTop: 0,
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 2
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    height: '36px !important',
    borderWidth: 1.3,
    flex: 1
  },
  notificationFab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  titleContent: {
    display: 'flex'
  },
  topright: {
    position: 'absolute',
    top: 24,
    right: 24,
    width: 30,
    height: 30,
    background: '#b0b0b0',
    borderRadius: '50%'
  },
  deleteBtn: {
    color: theme.palette.buttonWhite.default,
    width: 30,
    height: 30,
    marginTop: -8
  },
  detail: {
    color: '#7e7e7e',
    marginTop: -8,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 0.5
  }
}));

const handleDeleteClick = (e, id, handleDeleteNotificationClick) => {
  // e.stopPropagation();
  // handleDeleteNotificationClick(id);
};

const NotificationCard = props => {
  const classes = useStyles();
  const { notification, handleNotificationClick, handleNotificationActionClick, handleDeleteNotificationClick } = props;

  const notificationId = `notificationCard${notification.id}`;

  return (
    <div className={classes.notificationContainer} onClick={handleNotificationClick} id={notificationId}>
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <div className={classes.titleContent}>
            <div>
              <h3 className={classes.heading}>{notification.title}</h3>
            </div>
            {notification.showCloseIcon ? (
              <div className={classes.topright}>
                <IconButton
                  aria-label="delete"
                  className={classes.deleteBtn}
                  onClick={e => handleDeleteClick(e, notification.id, handleDeleteNotificationClick)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              ''
            )}
          </div>
          <Typography className={`${classes.content} ${classes.detail}`} variant="h6" gutterBottom>
            {notification.body}
          </Typography>
          {notification.actionBtnName && (
            <Fab
              id="notificationAcionBtn"
              onClick={handleNotificationActionClick}
              color="primary"
              variant="extended"
              aria-label="notificationAction"
              className={`${classes.notificationFab} ${classes.button}`}
            >
              {notification.actionBtnName}
            </Fab>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default NotificationCard;
