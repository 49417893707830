import React from 'react';

import { Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

function MessageDisplay(props) {
  const { text, visible, hideMessage, nextMessage } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      autoHideDuration={8000}
      open={visible}
      onExited={nextMessage}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        hideMessage();
      }}
      disableWindowBlurListener={true}
      ContentProps={{
        'aria-describedby': 'message-snackbar'
      }}
      message={<span id="message-snackbar">{text}</span>}
      action={[
        <IconButton aria-label="Close" key="close" color="inherit" onClick={hideMessage}>
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

export default MessageDisplay;
