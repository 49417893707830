import React from 'react';
import { withStyles, Toolbar, Typography, AppBar, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => {
  return {
    container: {
      height: '100vh'
    },
    gameTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 0
    },
    border: {
      border: '1px solid red'
    },
    gameDescription: {
      zIndex: 100
    },
    appbar: {
      width: '100%',
      marginLeft: 'auto'
    },
    backArrow: {
      marginRight: 10
    },
    leftBarContainer: {
      padding: theme.spacing(2)
    },
    divider: {
      margin: '40px 0'
    },
    arrowButton: {
      marginLeft: -20
    },
    gameNameContainer: {
      minWidth: 100
    }
  };
};

const Header = props => {
  const { classes, onBackArrowClick, game } = props;

  return (
    <AppBar position="static" color="inherit" className={classes.appbar}>
      <Toolbar>
        <Button color="primary" className={classes.arrowButton} onClick={onBackArrowClick} id="backButton">
          <ArrowBackIcon />
        </Button>
        <div className={classes.gameNameContainer}>
          <Typography variant="h6" gutterBottom className={classes.gameTitle}>
            {game && game.name}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
