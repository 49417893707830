const createAnswerResponse = report => {
  let response = {};
  response.formId = report.formId;
  let answers = [];

  report.questions.map(question => {
    const answer = {
      questionId: question.questionId,
      value: question.selectedId
    };
    answers = [...answers, answer];
    return '';
  });

  response.answers = answers;

  response = JSON.stringify(response);

  return response;
};

const MapToAnswersObject = (report, childId, classroomId, templateId) => {
  var answerResponse = createAnswerResponse(report);

  const finalResponse = {
    templateId,
    childId,
    classroomId,
    answerJson: answerResponse
  };

  return finalResponse;
};

export default MapToAnswersObject;
