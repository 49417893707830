import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

const Attachments = props => {
  const classes = useStyles();
  const { gameAttachments, onRemoveAttachment } = props;

  const handleDelete = attachmentToDelete => () => {
    onRemoveAttachment(attachmentToDelete);
  };

  if (!gameAttachments) return;

  return (
    <Paper component="ul" className={classes.root}>
      {gameAttachments.map((data, index) => {
        return (
          <li key={index}>
            <Chip label={data.name} onDelete={handleDelete(data)} className={classes.chip} />
          </li>
        );
      })}
    </Paper>
  );
};

export default Attachments;
