import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 25,
      height: 36,
      background: 'none',
      border: '1px solid',
      borderColor: theme.palette.borderGray.default
    },
    input: {
      margin: theme.spacing(1),
      flex: 1
    }
  };
});

export default function SearchBox(props) {
  const classes = useStyles();
  const { htmlId, handleSearchTermChange, searchTerm, placeholder } = props;

  const handleChange = e => {
    handleSearchTermChange(e.target.value);
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        id={htmlId}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search games' }}
        onChange={handleChange}
        value={searchTerm}
      />
    </Paper>
  );
}
