import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  chip: {
    color: 'white !important',
    paddingTop: 2,
    fontWeight: 600,
    fontSize: 10,
    height: 16
  }
}));

const CustomChip = props => {
  const classes = useStyles();

  const { chipLabel, color } = props;

  return <Chip label={chipLabel} style={{ background: color }} className={classes.chip} />;
};

CustomChip.propTypes = {
  chipLabel: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default CustomChip;
