import React from 'react';
import { Dialog, DialogTitle, Button, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PDFViewer } from '@react-pdf/renderer';
import ClearIcon from '@material-ui/icons/Clear';

import GameDetailPdf from './../GameDetailPdf';

const useStyles = makeStyles(theme => ({
  dialog: {
    border: '1px solid red',
    position: 'relative'
  },
  footer: {
    position: 'absolute',
    right: 0,
    top: 10
  },
  dialogTitle: {
    fontWeight: 400,
    fontSize: 20,
    background: '#F0F0F0'
  }
}));

const PrintingDialogBox = props => {
  const classes = useStyles();
  const { openDialog, onCloseDialog, data, gameImage, mode, onSetGameImageDimensions, gameImageDimensionRatio } = props;

  //Calculating and sending dimensions of single image
  React.useEffect(() => {
    var img = document.createElement('img');
    //Calculating image height and width. Used in printing.
    img.onload = function() {
      const dimensions = {
        height: img.height,
        width: img.width
      };
      onSetGameImageDimensions(dimensions);
    };
    img.src = gameImage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameImage]);

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
      maxWidth={'md'}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        Print Game
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <PDFViewer
          style={{
            textDecoration: 'none',
            padding: '10px',
            color: '#4a4a4a',
            backgroundColor: '#f2f2f2',
            border: '1px solid #4a4a4a',
            minHeight: '70vh',
            width: 600
          }}
        >
          <GameDetailPdf
            data={data}
            gameImage={gameImage}
            mode={mode}
            gameImageDimensionRatio={gameImageDimensionRatio}
          />
        </PDFViewer>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onCloseDialog} color="primary" className={classes.actionButton} id="cancelButton">
          <ClearIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintingDialogBox;
