import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import RaindropWhite from '../../../res/Landing-page/RaindropWhite.svg';
import RaindropBlue from '../../../res/Landing-page/RaindropBlue.svg';

const useStyles = makeStyles(theme => ({
  sizeSmall: {
    height: 53
  },
  sizeMedium: {
    height: 79
  },
  sizeLarge: {
    height: 165
  },
  sizeExtraLarge: {
    height: 200
  }
}));

const RainDrop = props => {
  const classes = useStyles();
  const { color, size } = props;

  return (
    <div className={`${classes.rainDropWhite} ${classes.rainDrop}`}>
      <img
        src={color === 'white' ? RaindropWhite : RaindropBlue}
        alt="rain drop white"
        className={
          size === 'small'
            ? classes.sizeSmall
            : size === 'medium'
            ? classes.sizeMedium
            : size === 'large'
            ? classes.sizeLarge
            : classes.sizeExtraLarge
        }
      />
    </div>
  );
};

export default RainDrop;
