import * as client from './baseClient';

export const getGame = (id, token) => client.get(`/api/games/${id}`, token);
export const getAllGames = token => {
  return client.get('/api/games', token);
};

export const getGamesForClassroom = (classroomId, token) =>
  client.get(`/api/games/games-for-classroom/${classroomId}`, token);

export const getVariationForGame = (gameId, classroomId, token) => {
  const path = `/api/games/${gameId}/variation/classroom/${classroomId}`;
  return client.get(path, token);
};

export const createGameClassroomVariationRecord = (payload, token) => {
  const path = `/api/games/game-classroom-variation`;
  return client.post(path, payload, token);
};

export const updateGameVariationId = (classroomVariationId, token) => {
  const path = `/api/games/game-classroom-variation/${classroomVariationId}`;
  return client.put(path, {}, token);
};

export const submitRating = (payload, token) => client.post(`/api/games/rating`, payload, token);
export const getGameRating = (payload, token) =>
  client.get(`/api/games/rating/${payload.gameId}/classroomId/${payload.classroomId}`, token);

export const updateRating = (payload, token) => client.put(`/api/games/rating`, payload, token);

export const updateGame = (payload, token) => client.put(`/api/games/${payload.id}`, payload, token);
