import React from 'react';
import { withStyles, Divider } from '@material-ui/core';

import VariationButtons from './../VariationButtons';
import GameVariationDropDown from './../GameVariationDropDown';
import SelectButton from './../SelectButton';
import Header from './../Header';
import MessageDisplay from '../../../containers/MessageDisplay';
import StandardDialog from '../../StandardDialog';
import GameRating from './../../GameRating';
import Resources from '../Resources';
import AttachmentLink from '../../../components/AttachmentLink';

const styles = theme => {
  return {
    leftBarContainer: {
      padding: theme.spacing(2)
    },
    divider: {
      margin: '20px 0'
    },
    arrowButton: {
      marginLeft: -20
    },
    gameNameContainer: {
      minWidth: 100
    },
    videoContainer: {
      margin: 0,
      padding: 0,
      height: 200
    },
    atachment: {
      margin: 0,
      padding: 0,
      height: 100
    }
  };
};

const LeftBar = props => {
  const {
    handleButtonClick,
    handleConfirmRemove,
    onBackArrowClick,
    game,
    gameAttachments,
    isLoadingGame,
    isLoadingGameImage,
    classes,
    isLoadingVariationForTheGame,
    selectedVariationId,
    isSavingVariationIdChange,
    open,
    showRemoveGameDialog,
    isSavingRemoveGame,
    errorMessage,
    handleGameVariationChange,
    mappedVariations,
    showOnlySelectStatus,
    gameIsChecked,
    handleSelectGameButtonClick,
    handleRatingClick,
    gameRating,
    getRatingForGame,
    isLoadingOrSavingRateGame
  } = props;

  const isNullOrEmpty = str => {
    return !str || str.length === 0 || /^\s*$/.test(str);
  };

  const hasVideoURL = !isNullOrEmpty(game.video);
  const hasAttachments = gameAttachments !== 'undefined' && gameAttachments.length > 0;

  return (
    <React.Fragment>
      <Header onBackArrowClick={onBackArrowClick} game={game} isLoadingGame={isLoadingGame} />

      <MessageDisplay />

      <div className={classes.leftBarContainer}>
        <div className={classes.content}>
          {/* Confirm Remove dialog box */}
          <StandardDialog
            open={open}
            title="Confirm Remove"
            description="Are you sure you want to remove game"
            actionButtonText="Remove"
            cancelButtonText="Cancel"
            handleClose={() => showRemoveGameDialog(false)}
            handleSubmit={handleConfirmRemove}
            showProgressBar={isSavingRemoveGame}
            errorMessage={errorMessage}
          />

          {showOnlySelectStatus ? (
            <SelectButton
              isLoadingGame={isLoadingGame}
              isLoadingGameImage={isLoadingGameImage}
              gameIsChecked={gameIsChecked}
              handleSelectGameButtonClick={handleSelectGameButtonClick}
              gameId={game.id}
            />
          ) : (
            <React.Fragment>
              <GameVariationDropDown
                isLoadingVariationForTheGame={isLoadingVariationForTheGame}
                mappedVariations={mappedVariations}
                isSavingVariationIdChange={isSavingVariationIdChange}
                selectedVariationId={selectedVariationId}
                handleGameVariationChange={handleGameVariationChange}
              />

              <GameRating
                handleRatingClick={handleRatingClick}
                gameRating={gameRating}
                getRatingForGame={getRatingForGame}
                isLoadingOrSavingRateGame={isLoadingOrSavingRateGame}
              />

              <Divider className={classes.divider} />

              <VariationButtons
                handleButtonClick={handleButtonClick}
                isLoadingGame={isLoadingGame}
                isLoadingGameImage={isLoadingGameImage}
              />
            </React.Fragment>
          )}
        </div>

        <div className={classes.videoContainer}>
          {hasVideoURL && <Divider className={classes.divider} />}

          {hasVideoURL && <Resources URL={game.video} />}
        </div>

        <div className={classes.atachment}>
          {hasAttachments && <Divider className={classes.divider} />}
          {hasAttachments && <AttachmentLink label={'All Attachments'} gameAttachemts={gameAttachments} />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(LeftBar);
