/**
 * Adds description and gameVariations in all gamesForClassroom games.
 * And also adds child game variation (if any exists in the database) into the all gamesForClassroom games.
 */

const ConsolidateGames = (gamesForClassroom, gameDetailsForMultipleGames, childGameVariations, childId) => {
  const consolidatedGames = gamesForClassroom.map(game => {
    //Adding GameDescription and variations.
    const foundGame = gameDetailsForMultipleGames.filter(g => g.id === game.id)[0];
    if (foundGame) {
      game.description = foundGame.description;
      game.gameVariations = foundGame.gameVariations;
    }

    //Adding GameVariation for child saved in the database (to be shown in the drop down list)
    const foundVariation = childGameVariations.filter(g => g.gameId === game.id && g.childId === childId)[0];
    if (foundVariation) {
      game.childGameVariationIdFromDb = foundVariation.gameVariationId;
    } else {
      delete game.childGameVariationIdFromDb;
    }

    return game;
  });

  return consolidatedGames;
};

export default ConsolidateGames;
