const ConsolidateLongListOfChildrenNames = childrenNames => {
  // split the text into separate words
  if (!childrenNames) return;
  var words = childrenNames.split(',');

  //read total number of words
  var totalWordsCount = words.length;
  //maximum number of words per page allowed, set to 10 as example
  var wordsCountPerPage = 12;
  var chunks = [];

  // in each iteration "i" points to the first word in the new page, so it's 0, 11, 21 etc.
  for (var i = 0; i < totalWordsCount; i += wordsCountPerPage) {
    //'slice()' function gets words for the current line, then they're concatenated with 'join()'
    //'Math.min()' function is used to make sure that appropriate number of words are taken for the last page
    chunks.push(words.slice(i, Math.min(i + wordsCountPerPage, totalWordsCount)).join(','));
  }

  return chunks;
};

export default ConsolidateLongListOfChildrenNames;
