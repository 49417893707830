export const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('games');
    if (serializedState === null) {
      return undefined;
    }
    var data = JSON.parse(serializedState);
    return data;
  } catch (error) {
    return undefined;
  }
};

export const saveToLocalStorage = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('games', serializedState);
  } catch (error) {
    console.log(error);
  }
};
