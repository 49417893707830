import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Drawer
  // ListItem, List
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// import NotificationCard from './../NotificationCard';
import NotificationCard from './NotificationCard';

const useStyles = makeStyles(theme => ({
  list: {
    width: 380,
    padding: '0 10px'
  },
  topContent: {
    display: 'inline-block',
    marginTop: 10
  },
  paper: {
    background: '#eeeeee'
  },
  cancelButton: {
    flexGrow: 1,
    display: 'inline-block',
    margin: 'auto'
  },
  barHeading: {
    flexGrow: 1,
    display: 'inline-block'
  },
  noContent: { width: 380, height: '100%', position: 'relative', textAlign: 'center' },
  noContentText: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    color: '#7e7e7e'
  }
}));

function NotificationDrawer(props) {
  const classes = useStyles();
  const {
    showNotificationDrawer,
    showNotification
    // allNotification
  } = props;

  const dummyNotification = {
    id: 1,
    title: 'Time to report your behavioural observations.',
    body: 'Please report observations for all children before the end of the month.',
    actionBtnName: '',
    showCloseIcon: false
  };

  const handleNotificationClicked = () => {
    const { onNotificationClicked } = props;
    onNotificationClicked(false);
  };

  const handleNotificationItemClicked = () => {
    const { onNotificationItemClicked } = props;
    onNotificationItemClicked();
  };

  return (
    <div className={classes.drawer}>
      <Drawer anchor="right" open={showNotificationDrawer} classes={{ paper: classes.paper }}>
        <div className={classes.topContent}>
          <Button className={classes.cancelButton} onClick={handleNotificationClicked} id="crossButton">
            <CloseIcon />
          </Button>
          <h3 className={classes.barHeading}>NOTIFICATIONS</h3>
        </div>
        {/* {allNotification.length ? (
          <div className={classes.list} role="presentation">
            <List>
              {allNotification.map((item, index) => (
                <ListItem button key={item.id}>
                  <NotificationCard notification={item} />
                </ListItem>
              ))}
            </List>
          </div>
        ) */}

        {showNotification ? (
          <div className={classes.list} role="presentation">
            <NotificationCard
              notification={dummyNotification}
              handleNotificationClick={handleNotificationItemClicked}
            />
          </div>
        ) : (
          <div className={classes.noContent} role="presentation">
            <Typography variant="h6" className={classes.noContentText}>
              No Notifications
            </Typography>
          </div>
        )}
      </Drawer>
    </div>
  );
}

NotificationDrawer.propTypes = {
  // describe the component's prop types here
};

NotificationDrawer.defaultProps = {
  // assign default props to the component here
};
export default NotificationDrawer;
