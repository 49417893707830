import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import { Print as PrintIcon, Clear as ClearIcon } from '@material-ui/icons';

import buttonNames from '../../../constants/buttonNames';

const useStyles = makeStyles(theme => ({
  variationButtonsContainer: {
    marginTop: '20px !important'
  },
  actionButton: {
    marginBottom: '20px !important'
  },
  dangerColor: {
    color: theme.palette.danger.default,
    borderColor: theme.palette.danger.default
  }
}));

const VariationButtons = props => {
  const classes = useStyles();

  const { handleButtonClick, isLoadingGame, isLoadingGameImage } = props;

  return (
    <div className={classes.variationButtonsContainer}>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        className={classes.actionButton}
        onClick={() => handleButtonClick(buttonNames.PRINT_GAME)}
        id="printGameButton"
        disabled={isLoadingGame || isLoadingGameImage}
      >
        <PrintIcon />
        &nbsp; {buttonNames.PRINT_GAME}
      </Button>

      <Button
        fullWidth
        variant="outlined"
        className={`${classes.actionButton} ${classes.dangerColor}`}
        onClick={() => handleButtonClick(buttonNames.REMOVE_GAME)}
        id="removeGameButton"
      >
        <ClearIcon />
        &nbsp; {buttonNames.REMOVE_GAME}
      </Button>
    </div>
  );
};

export default VariationButtons;
