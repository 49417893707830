import { call, put, takeLatest } from 'redux-saga/effects';

import { types } from '../constants';
import * as notificationsApi from '../api/classrooms';
// import { showMessage } from '../actions';

function* watchLoadNotifications({ classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_NITIFICATIONS });
    const { jsonData } = yield call(notificationsApi.loadNotifications, classroomId, token);
    yield put({ type: types.LOAD_NITIFICATIONS_SUCCESS, showNotification: jsonData });
  } catch (error) {
    yield resolveError(types.LOAD_NITIFICATIONS_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_NITIFICATIONS_FINISHED });
  }
}

//This is used to catch all the errors in case of request unsuccessful, and will extract the actual error message.
function* resolveError(type, error) {
  let errorMessageCaptured = false;
  let completeErrorMessage = {};
  if (!error.ok) {
    try {
      const clone = error.clone();
      yield clone.text().then(text => {
        completeErrorMessage = JSON.parse(text);
        errorMessageCaptured = true;
      });
    } catch (err) {
      errorMessageCaptured = true;
      completeErrorMessage = err;
    }

    if (errorMessageCaptured) {
      const errorMessage = extractErrorMessage(completeErrorMessage);
      yield put({ type, errorMessage });
    }
  }
}

function extractErrorMessage(completeErrorMessage) {
  let errorMessage = '';

  try {
    const message = completeErrorMessage.exceptionMessage
      ? completeErrorMessage.exceptionMessage.split('Content:')[1]
      : completeErrorMessage.messageDetail;

    errorMessage = JSON.parse(message).errorMessage;
    if (!errorMessage) {
      errorMessage = 'An error occured';
    }
  } catch (error) {
    errorMessage = 'An error occured';
  }

  return errorMessage;
}

export default function* gamesWatcher() {
  yield takeLatest(types.LOAD_NITIFICATIONS, watchLoadNotifications);
}
