// ChildrenViewPanel/index.js
import React, { useEffect } from 'react';
import { Divider, LinearProgress, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useAuth0 } from '../Auth';
import DetailsTab from './DetailsTab';
import ReportsTab from './ReportsTab';
import VariationsTab from './VariationsTab';
import { buttonNames } from '../../constants';
import calculatePreferredName from '../../filters/calculatePreferredName';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflowY: 'hidden'
  },
  childListItem: {
    cursor: 'pointer'
  },
  childrenList: {
    borderRight: `1px solid ${theme.palette.borderGray.default}`
  },
  selectedListItem: {
    background: 'rgba(9, 163, 74, 0.2) !important'
  },
  header: {
    margin: 18
  },
  childName: {
    fontSize: 20,
    fontWeight: 600
    // fontFamily: 'Roboto'
  },
  childViewpanelTabsContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - 290px)'
  }
}));

const ChildrenViewPanel = props => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  const {
    childId,
    child,
    isLoadingChild,
    visibleTab,
    onEditChildClick,
    onCompleteChildReportButtonClick,
    onLoadChildReports,
    isLoadingReportForChart,
    reportForChart,
    onTransferChildClick,
    gamesForClassroom,
    onGetGameDetailsForMultipleGames,
    isLoadingGameDetails,
    onChildGameVariationChange,
    onGetReportButtonsStatus,
    reportButtonsStatus,
    onGetChildGameVariationsForChild,
    onClearGameDetailsForMultipleGames,
    isLoadingSelectedVariationsForChild,
    onDeativateChildClick,
    onClearChildGameVariationsForChild,
    isSavingChildGameVariation,
    classroomId
  } = props;

  const handleTabChange = (event, newValue) => {
    props.onTabValueChangeInChildViewPanel(newValue);
  };

  useEffect(() => {
    const { child, onSetChild } = props;
    if (child) {
      onSetChild(child); //Set child in child.js reducer..
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child]);

  async function fetchToken() {
    return await getTokenSilently();
  }

  const handleChildGameVariationChange = (e, gameId, classroomId, mainGroupVariationId) => {
    const selectedVariationId = e.target.value;

    //Resolving promise
    fetchToken().then(token => {
      const payload = {
        childId: childId,
        gameVariationId: selectedVariationId === mainGroupVariationId ? -1 : selectedVariationId,
        gameId,
        classroomId
      };
      onChildGameVariationChange(payload, token);
    });
  };

  const handleButtonClick = buttonName => {
    switch (buttonName) {
      case buttonNames.EDIT:
        onEditChildClick();
        break;
      case buttonNames.TRANSFER:
        onTransferChildClick();
        break;
      case buttonNames.DEACTIVATE:
        onDeativateChildClick();
        break;
      default:
        break;
    }
  };

  if (childId === -1) {
    return '';
  }

  if (!child) {
    return '';
  }

  return (
    <React.Fragment>
      {isLoadingChild || isLoadingGameDetails || isLoadingSelectedVariationsForChild || isSavingChildGameVariation ? (
        <LinearProgress />
      ) : null}
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Typography variant="h4" className={classes.childName}>
              {child && calculatePreferredName(child)}
            </Typography>
          </div>
        </div>
        <div className={classes.childDetailTabs}>
          <Tabs
            value={visibleTab}
            aria-label="disabled tabs example"
            onChange={handleTabChange}
            className={classes.tabsRoot}
          >
            <Tab label="Variations" id="variationsTab" className={classes.tabButton} />
            {/* <Tab label="Reports" id="reportsTab" className={classes.tabButton} /> */}
            <Tab label="Details" id="detailsTab" className={classes.tabButton} />
          </Tabs>
          <Divider />
          <div className={classes.childViewpanelTabsContainer}>
            {visibleTab === 0 ? (
              <VariationsTab
                allGamesForClassroom={gamesForClassroom}
                onGetGameDetailsForMultipleGames={onGetGameDetailsForMultipleGames}
                isLoadingGameDetails={isLoadingGameDetails}
                handleChildGameVariationChange={handleChildGameVariationChange}
                childId={childId}
                onGetChildGameVariationsForChild={onGetChildGameVariationsForChild}
                onClearGameDetailsForMultipleGames={onClearGameDetailsForMultipleGames}
                onClearChildGameVariationsForChild={onClearChildGameVariationsForChild}
                isSavingChildGameVariation={isSavingChildGameVariation}
                isLoadingSelectedVariationsForChild={isLoadingSelectedVariationsForChild}
                classroomId={classroomId}
              />
            ) : /* : visibleTab === 1 ? (
              <ReportsTab
                child={child}
                handleCompleteChildReportButtonClick={onCompleteChildReportButtonClick}
                onLoadChildReports={onLoadChildReports}
                isLoadingReportForChart={isLoadingReportForChart}
                reportForChart={reportForChart}
                onGetReportButtonsStatus={onGetReportButtonsStatus}
                reportButtonsStatus={reportButtonsStatus}
              />
            )  */
            visibleTab === 1 ? (
              <DetailsTab child={child} handleButtonClick={handleButtonClick} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ChildrenViewPanel.propTypes = {
  // describe the component's prop types here
};

ChildrenViewPanel.defaultProps = {
  // assign default props to the component here
};

export default ChildrenViewPanel;
