import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { InputLabel, MenuItem, FormControl, Select, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  filterContainer: {
    padding: '8px 0'
  },
  button: {
    // border: '1px solid red',
    marginTop: 10
  },
  formControl: {
    // border: '1px solid red',
    width: 200
  }
}));

const GamesFilter = props => {
  const classes = useStyles();
  const { gamesForClassroom, handleGamesFilterChange } = props;
  const [selectedFilter, setSelectedFilter] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const filterValues = [
    { display: 'All Games', value: 1 },
    { display: 'Personal Variations', value: 2 },
    { display: 'Main Group', value: 3 }
  ];

  const handleChange = event => {
    setSelectedFilter(event.target.value);
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case 1:
        handleGamesFilterChange(gamesForClassroom);
        break;
      case 2:
        handleGamesFilterChange(gamesForClassroom.filter(g => g.hasCustomChildVariation === true));
        break;
      case 3:
        handleGamesFilterChange(gamesForClassroom.filter(g => g.hasCustomChildVariation === false));
        break;
      default:
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.filterContainer}>
      <Button className={classes.button} onClick={handleOpen}>
        <FilterListIcon />
      </Button>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label"></InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedFilter}
          onChange={handleChange}
          disableUnderline
        >
          {filterValues.map(item => {
            return <MenuItem value={item.value}>{item.display}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default GamesFilter;
