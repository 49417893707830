import React from 'react';
import { Chip, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import mapDevelopmentTypeIdToVariationTypeAndColor from '../../../filters/mapDevelopmentTypeIdToVariationTypeAndColor';
import ConsolidateChildrenByDevelopmentTypeId from '../../../filters/ConsolidateChildrenByDevelopmentTypeId';
import ConsolidateLongListOfChildrenNames from '../../../filters/ConsolidateLongListOfChildrenNames';

const useStyles = makeStyles(theme => ({
  listContainer: {
    marginTop: 15
  },
  content: {
    borderBottom: `1px solid ${theme.palette.separator}`
  },
  title: {
    marginBottom: 15
  },
  singleGame: {
    display: 'block',
    marginBottom: 10
  },
  gameName: {
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 600
  },
  variationTypeChip: {
    color: 'white',
    height: 24,
    paddingTop: 2,
    fontWeight: 600,
    fontSize: 16
  },
  grayVariationTypeChip: {
    background: theme.palette.backgroundGray.default,
    color: 'black',
    fontWeight: 100,
    marginLeft: 10
  },
  variationChip: {
    display: 'inline-block',
    color: 'white',
    height: 24,
    paddingTop: 2,
    fontWeight: 600,
    fontSize: 16
    // marginLeft: 15,
  },
  avatar: {
    background: 'transparent',
    paddingLeft: 10
  },
  childrenNames: {
    marginTop: -5
  }
}));

const MainGroupGameVariation = props => {
  const classes = useStyles();
  const { game } = props;

  var mappedItem = mapDevelopmentTypeIdToVariationTypeAndColor(game.mainGroupVariation.gameVariation.developmentTypeId);
  const gameVariationWithLevel = `${mappedItem.typePrefix}${game.mainGroupVariation.variationLevelNumber}`;
  const backgroundColor = mappedItem.color;

  return (
    <Chip label={gameVariationWithLevel} className={classes.variationChip} style={{ background: backgroundColor }} />
  );
};

const ChildGameVariationWithChildren = props => {
  const classes = useStyles();
  const { game } = props;

  let chipData;
  if (game && game.childGameVariationsWithChildren && game.childGameVariationsWithChildren.length > 0) {
    chipData = ConsolidateChildrenByDevelopmentTypeId(game.childGameVariationsWithChildren);
  }

  return (
    <React.Fragment>
      {chipData &&
        chipData.map((data, i) => {
          const sortedArray = ConsolidateLongListOfChildrenNames(data.childrenNames);
          return sortedArray.map((names, index) => {
            return (
              <Chip
                key={index}
                size="medium"
                label={index + 1 < sortedArray.length ? `${names},` : names}
                className={`${classes.grayVariationTypeChip} ${classes.variationTypeChip}`}
                avatar={
                  index === 0 && (
                    <Avatar className={classes.avatar} style={{ color: data.color }}>
                      {data.gameVariationCode}
                    </Avatar>
                  )
                }
              />
            );
          });
        })}
    </React.Fragment>
  );
};

const VariationsList = props => {
  const classes = useStyles();

  const { games } = props;

  if (!games) {
    return '';
  }

  return (
    <div className={classes.listContainer}>
      <div className={classes.content}>
        <div className={classes.title}>
          <Chip
            label={games.title.toUpperCase()}
            className={classes.variationTypeChip}
            style={{ background: games.color }}
          />
        </div>
        <div className={classes.gamesList}>
          {games &&
            games.map((game, i) => {
              return (
                <div className={classes.singleGame} key={i}>
                  <Typography variant="body1" gutterBottom className={classes.gameName}>
                    {game.gameName}
                  </Typography>
                  <div>
                    <MainGroupGameVariation game={game} />
                    <ChildGameVariationWithChildren game={game} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default VariationsList;
