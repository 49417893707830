import React from 'react';
import { makeStyles, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const styles = makeStyles(theme => {
  return {
    formControl: {
      minWidth: 120
    }
  };
});

/**
 * Fancy drop down has nice looking background and closer to
 *   material ui select
 */

const FancyDropDown = props => {
  const classes = styles();
  const { handleChange, label, items, value, dropDownWidth = '180px', htmlId, disabled, fullWidth } = props;
  return (
    <React.Fragment>
      <FormControl className={classes.formControl} style={{ width: dropDownWidth }} variant="filled" id={htmlId}>
        <InputLabel id="simple-select-filled-label" shrink={true}>
          {label}
        </InputLabel>
        <Select
          id={htmlId}
          value={value}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: { htmlId }
          }}
          className={classes.select}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          {items &&
            items.map(item => {
              return (
                <MenuItem key={item.value} value={item.value} id={`option${item.value}`}>
                  <span style={{ color: item.color }}>{item.prefix}</span>
                  &nbsp;
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default FancyDropDown;
