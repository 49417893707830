import React from 'react';
import { makeStyles, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const styles = makeStyles(theme => {
  return {
    formControl: {
      // width: 180,
      borderRadius: 100,
      // margin: theme.spacing(1),
      marginBottom: 20
    },
    select: {
      border: '1px solid ' + theme.palette.borderGray.default,
      borderRadius: 100,
      paddingLeft: 15,
      paddingTop: 3,
      height: 36,
      marginTop: '0 !important',
      fontSize: 14
    }
  };
});

const DropDown = props => {
  const classes = styles();
  const {
    handleChange,
    label,
    items,
    value = 1,
    dropDownWidth = '180px',
    htmlId,
    disabled,
    fullWidth,
    widthRestricted
  } = props;
  return (
    <React.Fragment>
      <FormControl className={classes.formControl} style={{ width: dropDownWidth }}>
        <InputLabel htmlFor="age-simple">{label}</InputLabel>
        <Select
          id={htmlId}
          value={value}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: { htmlId }
          }}
          disableUnderline
          className={classes.select}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          {items.map(item => {
            return (
              <MenuItem
                key={item.value}
                value={item.value}
                id={`option${item.value}`}
                style={{ maxWidth: widthRestricted ? 500 : '', whiteSpace: 'normal', lineHeight: '20px' }}
              >
                {item.mainGroup && <span style={{ fontWeight: 400 }}>(Main Group) </span>}
                <span style={{ color: item.color, margin: '0 5px' }}>{item.prefix}</span>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default DropDown;
