import { all } from 'redux-saga/effects';

import games from './games';
import classrooms from './classrooms';
import media from './media';
import children from './children';
import notifications from './notifications';

export default function* rootSaga() {
  yield all([...games(), ...classrooms(), ...media(), ...children(), ...notifications()]);
}
