// LandingPage/index.js
import React from 'react';

import { useAuth0 } from '../Auth';
import Header from './Header';
import SectionBanner from './SectionBanner';
import SectionResearch from './SectionResearch';
import SectionWhatIs from './SectionWhatIs';
import SectionRegister from './SectionRegister';
import Footer from './Footer';

// import PropTypes from 'prop-types';

function LandingPage(props) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const navigateToRegisterSection = e => {
    e.preventDefault();
    var registerSection = document.getElementById('register-container');
    registerSection.scrollIntoView({
      behavior: 'smooth'
    });
  };

  if (isAuthenticated === true) {
    props.onLoginClicked();
  }

  return (
    <React.Fragment>
      <Header
        isAuthenticated={isAuthenticated}
        loginWithRedirect={loginWithRedirect}
        navigateToRegisterSection={navigateToRegisterSection}
      />
      <SectionBanner navigateToRegisterSection={navigateToRegisterSection} />
      <SectionResearch navigateToRegisterSection={navigateToRegisterSection} />
      <SectionWhatIs navigateToRegisterSection={navigateToRegisterSection} />
      <SectionRegister />
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
