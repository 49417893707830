import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Header from './Header';
import VariationsList from './VariationsList';
import { useAuth0 } from '../Auth';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 40
  }
}));

const PrintVariations = props => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  const {
    // behaviouralGames,
    // cognitiveGames,
    // emotionalGames,
    classroomId,
    onLoadVariationDetailsForAllGames,
    behaviouralGamesForPrinting,
    cognitiveGamesForPrinting,
    emotionalGamesForPrinting
  } = props;

  React.useEffect(() => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      if (classroomId) {
        //This is used to fetch all the details about what children are there in which game and other stuff
        //  for printing.
        //Calling it here and not on the click of PrintVariations button because on the click of PrintVariations button
        //  we are giving the command for window.print().
        onLoadVariationDetailsForAllGames(classroomId, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Header />
      </div>
      <div className={classes.variationsContainer}>
        <VariationsList games={behaviouralGamesForPrinting} />
        <VariationsList games={cognitiveGamesForPrinting} />
        <VariationsList games={emotionalGamesForPrinting} />
      </div>
    </div>
  );
};

export default PrintVariations;
