import { handleActions } from 'redux-actions';

import { types } from '../constants';
import { colors } from '../constants';

const INITIAL_STATE = {
  allGames: {
    items: [],
    itemsCopy: [],
    isLoading: false,
    errorMessage: '',
    searchTerm: ''
  },
  gamesForClassroom: {
    items: [],
    itemsCopy: [],
    isLoading: false,
    errorMessage: '',
    searchTerm: ''
  },
  //When pringint all games on home page
  gameDetailsForMultipleGames: {
    items: [],
    isLoading: false,
    errorMessage: ''
  },
  //For tracking the request of adding multiple games.
  submitMultipleGames: {
    isSaving: false,
    errorMessage: '',
    items: []
  },
  filter: {
    byType: null,
    byRelevance: null
  },
  gameTypes: {
    filter: 0,
    items: [
      { value: 0, name: 'All Game Types' },
      { value: 1, name: 'Behavioural' },
      { value: 2, name: 'Cognitive' },
      { value: 3, name: 'Emotional' }
    ]
  },
  popularityFilterTypes: {
    filter: 0,
    items: [
      // { value: 0, name: 'Most Popular' },
      { value: 0, name: 'Recently Added' }
      // { value: 2, name: 'Least Popular' }
    ]
  },
  classroomVariationDetailsForPrinting: {
    item: null,
    isLoading: false,
    errorMessage: ''
  }
};

const filterGamesBasedOnGameType = (allGames, gameTypeId) => {
  let filteredGames;
  if (gameTypeId === 0) {
    filteredGames = allGames;
  } else if (gameTypeId === 1) {
    //Behavioural
    filteredGames = allGames.filter(g => g.developmentTypeId === 1);
  } else if (gameTypeId === 2) {
    //Cognitive
    filteredGames = allGames.filter(g => g.developmentTypeId === 2);
  } else if (gameTypeId === 3) {
    //Emotional
    filteredGames = allGames.filter(g => g.developmentTypeId === 3);
  }

  return filteredGames;
};

const changeSelectedGameTypeFilter = (state, { gameTypeId }) => {
  const filteredGames = filterGamesBasedOnGameType(state.allGames.itemsCopy, gameTypeId);

  return {
    ...state,
    gameTypes: {
      ...state.gameTypes,
      filter: gameTypeId
    },
    allGames: {
      ...state.allGames,
      items: filteredGames
    }
  };
};

const changePopulartyFilter = (state, { popularityFilterId }) => {
  return {
    ...state,
    popularityFilterTypes: {
      ...state.popularityFilterTypes,
      filter: popularityFilterId
    }
  };
};

const changeGameCheckedStatus = (state, { gameId }) => {
  let newGames = state.allGames.items.map(game => {
    if (game.id === gameId) {
      game.checked = !game.checked;
    }

    return game;
  });

  return {
    ...state,
    allGames: {
      ...state.allGames,
      items: newGames
    }
  };
};

const loadAllGamesSuccess = (state, { games, minusAlreadyAddedGames }) => {
  games.map(game => {
    if (game.developmentTypeId === 1) {
      game.color = colors.GREEN;
    } else if (game.developmentTypeId === 2) {
      game.color = colors.ORANGE;
    } else if (game.developmentTypeId === 3) {
      game.color = colors.RED;
    }

    return null;
  });

  const classroomGames = state.gamesForClassroom.items;

  var result;

  function comparer(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          // eslint-disable-next-line
          return other.id == current.id;
          // eslint-disable-next-line
        }).length == 0
      );
    };
  }

  if (minusAlreadyAddedGames) {
    result = games.filter(comparer(classroomGames));
  } else {
    result = games;
  }

  return {
    ...state,
    allGames: {
      ...state.allGames,
      items: result,
      itemsCopy: result
    }
  };
};

const clearAllDropDownFilters = state => {
  return {
    ...state,
    gameTypes: {
      ...state.gameTypes,
      filter: 0
    },
    popularityFilterTypes: {
      ...state.popularityFilterTypes,
      filter: 0,
      items: [
        //Most popular means the games that are added in most classroom.
        // { value: 0, name: 'Most Popular' },
        { value: 0, name: 'Recently Added' }
        // { value: 2, name: 'Least Popular' }
      ]
    }
  };
};

const startLoadAllGames = state => {
  return {
    ...state,
    allGames: {
      ...state.allGames,
      isLoading: true,
      searchTerm: ''
    }
  };
};

const loadAllGamesFailure = (state, { errorMessage }) => {
  return {
    ...state,
    allGames: {
      ...state.allGames,
      errorMessage
    }
  };
};

const loadAllGamesFinished = state => {
  return {
    ...state,
    allGames: {
      ...state.allGames,
      isLoading: false
    }
  };
};

const clearAllGames = state => {
  return {
    ...state,
    allGames: {
      ...state.allGames,
      items: []
    }
  };
};

const clearGamesForClassroom = state => {
  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      items: []
    }
  };
};

const startLoadGamesForClassroom = state => {
  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      isLoading: true,
      searchTerm: ''
    }
  };
};

const loadGamesForClassroomSuccess = (state, { games }) => {
  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      items: games,
      itemsCopy: games
    }
  };
};

const loadGamesForClassroomFailure = (state, { errorMessage }) => {
  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      errorMessage
    }
  };
};
const loadGamesForClassroomFinished = state => {
  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      isLoading: false
    }
  };
};

const startAddGames = state => {
  return {
    ...state,
    submitMultipleGames: {
      ...state.submitMultipleGames,
      isSaving: true
    }
  };
};

const addGamesSuccess = (state, { createdGames }) => {
  let allGames = state.allGames.items;
  const createdGamesIds = createdGames.map(g => g.gameId);

  //Filtering newly created games out of all games list.
  var games = allGames.filter(game => {
    return createdGamesIds.indexOf(game.id) === -1;
  });

  return {
    ...state,
    submitMultipleGames: {
      ...state.submitMultipleGames,
      items: createdGames
    },
    allGames: {
      ...state.allGames,
      items: games
    }
  };
};

const addGamesFailure = (state, errorMessage) => {
  return {
    ...state,
    submitMultipleGames: {
      ...state.submitMultipleGames,
      errorMessage
    }
  };
};

const addGamesFinished = state => {
  return {
    ...state,
    submitMultipleGames: {
      ...state.submitMultipleGames,
      isSaving: false
    }
  };
};

const changeSearchTermAllGames = (state, { searchTerm }) => {
  let games = state.allGames.itemsCopy;
  let filteredGames = games.filter(g => g.name.toLowerCase().includes(searchTerm));

  if (searchTerm === '') {
    filteredGames = state.allGames.itemsCopy;
  }

  return {
    ...state,
    allGames: {
      ...state.allGames,
      searchTerm,
      items: filteredGames
    }
  };
};

const changeSearchTermGamesForClassroom = (state, { searchTerm }) => {
  let games = state.gamesForClassroom.itemsCopy;

  let filteredGames = games.filter(g => g.name.toLowerCase().includes(searchTerm));

  if (searchTerm === '') {
    filteredGames = state.gamesForClassroom.itemsCopy;
  }

  return {
    ...state,
    gamesForClassroom: {
      ...state.gamesForClassroom,
      searchTerm,
      items: filteredGames
    }
  };
};

const changeGameCheckedStatusFromGameDetailsPage = (state, { gameId }) => {
  const updatedAllGames = state.allGames.items.map(game => {
    if (game.id === gameId) {
      game.checked = !game.checked;
    }

    return game;
  });

  return {
    ...state,
    allGames: {
      ...state.allGames,
      items: updatedAllGames,
      itemsCopy: updatedAllGames
    }
  };
};

const startLoadGameDetailsForPrinting = state => {
  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      isLoading: true
    }
  };
};

const addingImagesToFetchedGames = (fetchedGames, existingGames) => {
  const gamesWithImages = fetchedGames.map(game => {
    const existingGame = existingGames.filter(g => g.id === game.id);
    game.image = existingGame[0] && existingGame[0].image;
    return game;
  });

  return gamesWithImages;
};

const loadGameDetailsForPrintingSuccess = (state, { fetchedGames }) => {
  const gamesWithImages = addingImagesToFetchedGames(fetchedGames, state.gamesForClassroom.items);

  gamesWithImages.map(game => {
    if (game.developmentTypeId === 1) {
      game.color = colors.GREEN;
    } else if (game.developmentTypeId === 2) {
      game.color = colors.ORANGE;
    } else if (game.developmentTypeId === 3) {
      game.color = colors.RED;
    }

    return null;
  });

  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      items: gamesWithImages
    }
  };
};

const loadGameDetailsForPrintingFailure = (state, { errorMessage }) => {
  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      errorMessage
    }
  };
};

const loadGameDetailsForPrintingFinished = state => {
  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      isLoading: false
    }
  };
};

const clearGameDetailsForMultipleGames = state => {
  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      items: []
    }
  };
};

const startLoadClassroomVariationDetailsForPrinting = state => {
  return {
    ...state,
    classroomVariationDetailsForPrinting: {
      ...state.classroomVariationDetailsForPrinting,
      isLoading: true
    }
  };
};

const loadClassroomVariationDetailsForPrintingSuccess = (state, { classroomVariationDetailsForPrinting }) => {
  return {
    ...state,
    classroomVariationDetailsForPrinting: {
      ...state.classroomVariationDetailsForPrinting,
      item: classroomVariationDetailsForPrinting
    }
  };
};

const loadClassroomVariationDetailsForPrintingFailure = (state, { errorMessage }) => {
  return {
    ...state,
    classroomVariationDetailsForPrinting: {
      ...state.classroomVariationDetailsForPrinting,
      errorMessage
    }
  };
};

const loadClassroomVariationDetailsForPrintingFinished = state => {
  return {
    ...state,
    classroomVariationDetailsForPrinting: {
      ...state.classroomVariationDetailsForPrinting,
      isLoading: false
    }
  };
};

function calculateAspectRatioFit(srcWidth, srcHeight) {
  var ratio = Math.min(361 / srcWidth, 235 / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

//Adding game dimensions to all games in "gameDetailsForMultipleGames"
const setGameImageDimensions = (state, { imageDimensions, gameId }) => {
  const gameDetails = state.gameDetailsForMultipleGames.items;

  const updatedGameDetails = gameDetails.map(game =>
    game.id === gameId
      ? {
          ...game,
          imageDimensions: imageDimensions,
          dimensionRatio: calculateAspectRatioFit(imageDimensions.width, imageDimensions.height)
        }
      : game
  );

  return {
    ...state,
    gameDetailsForMultipleGames: {
      ...state.gameDetailsForMultipleGames,
      items: updatedGameDetails
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.CHANGE_SELECTED_GAME_TYPE_FILTER]: changeSelectedGameTypeFilter,
    [types.CHANGE_POPULARITY_FILTER]: changePopulartyFilter,
    [types.CHANGE_GAME_CHECKED_STATUS]: changeGameCheckedStatus,

    [types.CLEAR_ALL_DROP_DOWN_FILTERS]: clearAllDropDownFilters,

    [types.START_LOAD_ALL_GAMES]: startLoadAllGames,
    [types.LOAD_ALL_GAMES_SUCCESS]: loadAllGamesSuccess,
    [types.LOAD_ALL_GAMES_FAILURE]: loadAllGamesFailure,
    [types.LOAD_ALL_GAMES_FINISHED]: loadAllGamesFinished,

    [types.START_LOAD_GAMES_FOR_CLASSROOM]: startLoadGamesForClassroom,
    [types.LOAD_GAMES_FOR_CLASSROOM_SUCCESS]: loadGamesForClassroomSuccess,
    [types.LOAD_GAMES_FOR_CLASSROOM_FAILURE]: loadGamesForClassroomFailure,
    [types.LOAD_GAMES_FOR_CLASSROOM_FINISHED]: loadGamesForClassroomFinished,

    [types.CLEAR_ALL_GAMES]: clearAllGames, //Used to clear all games for All games page.
    [types.CLEAR_GAMES_FOR_CLASSROOM]: clearGamesForClassroom, //User to clear games on home page.

    [types.START_ADD_GAMES]: startAddGames,
    [types.ADD_GAMES_SUCCESS]: addGamesSuccess,
    [types.ADD_GAMES_FAILURE]: addGamesFailure,
    [types.ADD_GAMES_FINISHED]: addGamesFinished,

    [types.CHANGE_SEARCH_TERM_ALL_GAMES]: changeSearchTermAllGames,
    [types.CHANGE_SEARCH_TERM_GAMES_FOR_CLASSROOM]: changeSearchTermGamesForClassroom,
    [types.CHANGE_GAME_CHECKED_STATUS_FROM_GAME_DETAILS_PAGE]: changeGameCheckedStatusFromGameDetailsPage,

    [types.START_LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES]: startLoadGameDetailsForPrinting,
    [types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_SUCCESS]: loadGameDetailsForPrintingSuccess,
    [types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FAILURE]: loadGameDetailsForPrintingFailure,
    [types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FINISHED]: loadGameDetailsForPrintingFinished,

    [types.CLEAR_GAMES_DETAILS_FOR_MULTIPLE_PRINTING]: clearGameDetailsForMultipleGames,

    [types.START_LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING]: startLoadClassroomVariationDetailsForPrinting,
    [types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_SUCCESS]: loadClassroomVariationDetailsForPrintingSuccess,
    [types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FAILURE]: loadClassroomVariationDetailsForPrintingFailure,
    [types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FINISHED]: loadClassroomVariationDetailsForPrintingFinished,
    [types.SET_GAME_IMAGE_DIMENSIONS]: setGameImageDimensions
  },
  INITIAL_STATE
);
