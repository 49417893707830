import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GameAttachmentFilter from '../../filters/mapToOtherAttachmentForGame';

import GameDetails from '../../components/GameDetails';
import {
  getGame,
  getVariationForGame,
  updateGameVariationId,
  showRemoveGameDialogStateChanged,
  removeGame,
  changeGameCheckedStatusFromGameDetailsPage,
  setLastVisitedPage,
  openPrintingDialogBox,
  clearExistingGameRating,
  onSubmitRating,
  updateGameRating,
  getGameRatings
} from '../../actions';
import { pageNames } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  const requestComingFromAddNewGamesPage = ownProps.location.pathname.includes('view-details');
  const { gameId } = ownProps.match.params;
  const game = state.game.game.item;
  const dbVersionOfVariationForTheGame = state.game.dbVersionOfVariationForTheGame.item;
  // const gameAttachments = state.media.attachmentFiles.items;
  const existingAttachments = state.media.attachmentFiles.items.filter(i => i.container === 2);
  // eslint-disable-next-line
  const clickedGame = state.games.allGames.items.filter(g => g.id == parseInt(gameId));
  return {
    gameId,
    indexOfSavedVariation: dbVersionOfVariationForTheGame && dbVersionOfVariationForTheGame.gameVariationId,
    isLoadingVariationForTheGame: state.game.dbVersionOfVariationForTheGame.isLoading,
    game,
    gameAttachments: existingAttachments,
    isLoadingGame: state.game.game.isLoading,
    dbVersionOfVariationForTheGame,
    selectedVariationId: dbVersionOfVariationForTheGame && dbVersionOfVariationForTheGame.gameVariationId,
    isSavingVariationIdChange: state.game.changingVariationId.isSaving,
    open: state.game.removeGame.open,
    isSavingRemoveGame: state.game.removeGame.isSaving,
    errorMessage: state.game.removeGame.errorMessage,
    gameImage: state.media.actualImage.item,
    isLoadingGameImage: state.media.actualImage.isLoading,
    showOnlySelectStatus: requestComingFromAddNewGamesPage,
    gameIsChecked: requestComingFromAddNewGamesPage && clickedGame[0] && clickedGame[0].checked,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    gameRating: state.game.rateGame && state.game.rateGame.item,
    isLoadingOrSavingRateGame: state.game.rateGame.isSaving || state.game.rateGame.isLoading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;
  return {
    onGetGameDetails: (gameId, classroomId, token) => {
      dispatch(getGame(gameId, token));
      dispatch(getVariationForGame(gameId, classroomId, token));
    },
    onBackArrowClick: () => {
      dispatch(setLastVisitedPage(pageNames.GAME_DETAIL_PAGE));
      history.goBack();
    },
    onGameVariationChange: (payload, token) => {
      dispatch(updateGameVariationId(payload, token));
    },
    showRemoveGameDialog: open => {
      dispatch(showRemoveGameDialogStateChanged(open));
    },
    onConfirmRemoveGame: (gameClassroomVariationId, token) => {
      dispatch(removeGame(gameClassroomVariationId, token, history));
    },
    handleSelectGameButtonClick: gameId => {
      dispatch(changeGameCheckedStatusFromGameDetailsPage(gameId));
    },
    onOpenPrintingDialogBox: open => {
      dispatch(openPrintingDialogBox(open));
    },
    onClearExistingGameRating: () => {
      dispatch(clearExistingGameRating());
    },
    onSubmitRating: (payload, token) => {
      dispatch(onSubmitRating(payload, token));
    },
    onUpdateGameRating: (payload, token) => {
      dispatch(updateGameRating(payload, token));
    },
    onGetGameRating: (payload, token) => {
      dispatch(getGameRatings(payload, token));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GameDetails));
