import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Button, Typography, AppBar, LinearProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex'
    },
    toolbar: {
      padding: 0,
      width: 1200,
      margin: '0 auto',
      display: 'block',
      marginTop: 10
    },
    leftContent: {
      display: 'inline-block',
      marginTop: 10
    },
    cancelButton: {
      flexGrow: 1
    },
    barHeading: {
      fontSize: theme.typography.htmlFontSize,
      flexGrow: 1,
      display: 'inline-block',
      fontWeight: 600
    },
    actionButton: {
      width: 180,
      float: 'right',
      margin: theme.spacing(2)
    }
  };
});

const ActionBarDoubleRow = props => {
  const classes = useStyles();
  const {
    title,
    actionButtonText,
    enableActionButton,
    handleCrossClick,
    isLoading,
    isLoadingImages,
    isSavingGames,
    handleActionButtonClick
  } = props;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Toolbar className={classes.toolbar}>
            <div className={classes.leftContent}>
              <Button color="secondary" className={classes.cancelButton} onClick={handleCrossClick} id="crossButton">
                <ClearIcon />
              </Button>
              <Typography variant="h1" className={classes.barHeading}>
                {title}
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              className={classes.actionButton}
              disabled={!enableActionButton}
              id="actionBarPrimaryActionButton"
              onClick={handleActionButtonClick}
            >
              {actionButtonText}
            </Button>
            <div>{props.children}</div>
          </Toolbar>
          {isLoading || isSavingGames || isLoadingImages ? <LinearProgress variant="indeterminate" /> : ''}
        </AppBar>
      </div>
    </React.Fragment>
  );
};

export default ActionBarDoubleRow;
