import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    border: '1px solid red',
    padding: '30px 20px'
  },
  label: {
    color: theme.palette.borderGray.default,
    fontSize: 12
  },
  value: {
    fontSize: 16
  },
  gridContainer: {},
  griditem: {}
}));

const LabelValuePair = props => {
  const classes = useStyles();
  const { label, value } = props;

  return (
    <React.Fragment>
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
      <Typography variant="body2" className={classes.value}>
        {value}
      </Typography>
    </React.Fragment>
  );
};

export default LabelValuePair;
