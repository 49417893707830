import React from 'react';
import ReactPlayer from 'react-player';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';

import ColourMoves from '../../../res/Landing-page/ColourMoves.png';
import JackInTheBox from '../../../res/Landing-page/JackInTheBox.png';
import RainDrop from '../RainDrop';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
    top: 330,
    height: '100%',
    background: '#EEEEEE',
    zIndex: 999
  },
  content: {
    width: 1200,
    margin: '0 auto',
    paddingTop: 100
  },
  title: {
    fontSize: 50,
    color: theme.palette.primary.main,
    marginBottom: 20,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 30,
    marginBottom: 30,
    fontWeight: 600
  },
  text: {
    fontSize: 16,
    marginBottom: 20,
    fontWeight: 400
  },
  videoSnapshot: {
    height: 440,
    width: 780,
    margin: '50px auto'
  },
  seeMoreVideosButton: {
    width: 220,
    height: 36,
    fontSize: 12,
    margin: '50px auto',
    display: 'block'
  },
  gridContainer: {
    display: 'relative',
    zIndex: 0
  },
  gridItem: {
    height: 370,
    backgroundRepeat: 'no-repeat'
  },
  leftPanel: {
    backgroundImage: `url(${ColourMoves})`
  },
  rightPanel: {
    backgroundImage: `url(${JackInTheBox})`,
    backgroundPosition: 'right bottom'
  },

  rainDropsContainer: {
    height: 1200,
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: -1,
    overflow: 'hidden',
    backgroundColor: theme.palette.backgroundGray.default
  },

  blueRainDrop: {
    position: 'absolute'
  },

  green: {
    height: 200,
    width: '100%',
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: -170,
    zIndex: -1
  },
  blueRaindropOne: {
    left: '10%',
    top: -80
  },
  blueRaindropTwo: {
    left: '30%',
    top: 100
  },
  blueRaindropThree: {
    left: '40%',
    top: -20
  },
  blueRaindropFour: {
    left: '60%',
    top: 90
  },
  blueRaindropFive: {
    left: '70%',
    top: 75
  },
  blueRaindropSix: {
    left: '90%',
    top: -50
  },
  blueRaindropSeven: {
    left: '5%',
    top: 650
  },
  blueRaindropEight: {
    left: '15%',
    top: 850
  },
  blueRaindropNine: {
    left: '17%',
    top: 500
  },
  blueRaindropTen: {
    left: '20%',
    top: 600
  },
  blueRaindropEleven: {
    left: '60%',
    top: 1100
  },
  blueRaindropTwelve: {
    left: '80%',
    top: 950
  },
  blueRaindropThirteen: {
    left: '82%',
    top: 700
  },
  blueRaindropFourteen: {
    left: '87%',
    top: 450
  },
  blueRaindropFifteen: {
    left: '90%',
    top: 650
  }
}));

const SectionWhatIs = props => {
  const classes = useStyles();

  const URL = 'https://www.youtube.com/watch?v=qdqVHC_rkhw';

  const MoreVideoURL = 'https://www.youtube.com/playlist?list=PLrQUbEo3elOX4n_GHSwKBB0cJ0h9R0R0P';

  const SeeMoreVideoClicked = e => {
    window.open(MoreVideoURL, '_blank');
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            WHAT
          </Typography>
          <Typography variant="h2" className={classes.subTitle}>
            is self-regulation?
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Self-regulation skills help us manage our emotions, thoughts and behaviours – our feeling, thinking and
            doing skills.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Self-regulation skills help children and adults perform important tasks every day, including; remembering
            instructions, ignoring distractions, juggling multiple tasks at once, sticking at difficult or frustrating
            tasks, resisting the temptation to do something that might not be good for us, waiting for rewards, taking
            turns, working well with others, recognising and managing our feelings, dealing with difficult emotions
            (anger, fear, anxiety, frustration etc), and more.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Self-regulation skills can be improved with regular, high-quality practice.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            People with better self-regulation skills make better decisions more often, and are able to function more
            effectively in a wider range of situations – leading to more positive outcomes across their lifespan – as
            demonstrated by the Dunedin Longitudinal Study finding that self-regulation (self-control) skills in early
            life are the most accurate predictor of a broad range of adult lifecourse outcomes including educational,
            physical health, employment, income, relationship, and criminal offending outcomes.
          </Typography>
          <div className={classes.videoSnapshot}>
            <ReactPlayer playing={false} playsInline={true} url={URL} height={440} width={780} controls={true} />
          </div>
          <Button
            id="seeMoreVideosButton"
            className={classes.seeMoreVideosButton}
            color="primary"
            variant="contained"
            onClick={e => SeeMoreVideoClicked(e)}
          >
            SEE MORE VIDEOS
          </Button>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={6} className={`${classes.gridItem} ${classes.leftPanel}`}></Grid>
            <Grid item xs={6} className={`${classes.gridItem} ${classes.rightPanel}`}></Grid>
          </Grid>
        </div>

        <div className={classes.rainDropsContainer}>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropOne}`}>
            <RainDrop color="blue" size="large" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropTwo}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropThree}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropFour}`}>
            <RainDrop color="blue" size="large" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropFive}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropSix}`}>
            <RainDrop color="blue" size="extraLarge" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropSeven}`}>
            <RainDrop color="blue" size="large" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropEight}`}>
            <RainDrop color="blue" size="medium" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropNine}`}>
            <RainDrop color="blue" size="medium" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropTen}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropEleven}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropTwelve}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropThirteen}`}>
            <RainDrop color="blue" size="extraLarge" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropFourteen}`}>
            <RainDrop color="blue" size="small" />
          </div>
          <div className={`${classes.blueRainDrop} ${classes.blueRaindropFifteen}`}>
            <RainDrop color="blue" size="small" />
          </div>
        </div>
        <div className={classes.green}></div>
      </div>
    </React.Fragment>
  );
};
export default SectionWhatIs;
