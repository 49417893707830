import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import './style.css';
import Card from './Card';
import EmptySearchResultsMessage from '../EmptySearchResultsMessage';

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200,
    margin: '20px auto',
    columns: 3,
    columnGap: 10
  },

  box: {
    width: '100%',
    padding: 10,
    // overflow: 'hidden',
    breakInside: 'avoid'
  },
  emptySearchResultMessageContainer: {
    height: 'calc(100vh - 165px)',
    // height: 'calc(100% - 75px)',
    position: 'relative'
  },
  emptySearchResultMessageContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const StaggeredGridLayout = props => {
  const classes = useStyles();
  const { allGames, searchTerm, isLoadingImages, isLoading, handleViewDetailsClick } = props;
  const handleCardClick = gameId => {
    const { onGameCheckStatusChanged } = props;
    onGameCheckStatusChanged(gameId);
  };

  if (allGames.length === 0 && isLoadingImages === false && isLoading === false) {
    return (
      <div className={classes.emptySearchResultMessageContainer}>
        <div className={classes.emptySearchResultMessageContent}>
          <EmptySearchResultsMessage />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        {allGames.map(game => {
          return (
            <div key={game.id} className={classes.box}>
              <Card
                data={game}
                handleCardClick={handleCardClick}
                searchTerm={searchTerm}
                isLoadingImages={isLoadingImages}
                handleViewDetailsClick={handleViewDetailsClick}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

// class StaggeredGridLayout extends React.Component {
//   render() {
//     // const data = getData();
//     const { allGames, searchTerm, isLoadingImages, handleViewDetailsClick } = this.props;
//     const minHeight = 7;

//     let layout = [];

//     for (let i = 0; i < allGames.length; i++) {
//       const index = `key${i + 1}`;
//       if (!allGames[i].description) {
//         allGames[i].description =
//           'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';
//       }
//       const descriptionLength = allGames[i].description.length;
//       const numberOfLineOfDescription = Math.ceil(descriptionLength / 45);
//       const descriptionHeight = Math.ceil(numberOfLineOfDescription / 2);

//       let xValue;
//       if (i % 3 === 0) {
//         xValue = 0;
//       } else if (i % 3 === 1) {
//         xValue = 4;
//       } else if (i % 3 === 2) {
//         xValue = 8;
//       }

//       let key = {
//         i: index,
//         x: xValue,
//         y: 0,
//         w: 4,
//         h: minHeight + descriptionHeight + 1, //+1 for extra height.
//         static: true,
//         index: i
//       };

//       layout = [...layout, key];
//     }

//     const handleCardClick = i => {
//       const { onGameCheckStatusChanged } = this.props;
//       onGameCheckStatusChanged(i);
//     };

//     return (
//       <React.Fragment>
//         <div className="containerForAllCards">
//           <GridLayout layout={layout} cols={12} rowHeight={27} width={1200}>
//             {/* Width:1200 because its the width of Page component */}

//             {layout.map(card => {
//               return (
//                 <div key={card.i}>
//                   <Card
//                     data={allGames[card.index]}
//                     index={card.index}
//                     handleCardClick={handleCardClick}
//                     searchTerm={searchTerm}
//                     isLoadingImages={isLoadingImages}
//                     handleViewDetailsClick={handleViewDetailsClick}
//                   />
//                 </div>
//               );
//             })}
//           </GridLayout>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

export default StaggeredGridLayout;
