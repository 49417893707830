export const HOME_PAGE = 'HOME_PAGE';
export const LOGIN_PAGE = 'LOGIN_PAGE';
export const ADD_NEW_GAMES_PAGE = 'ADD_NEW_GAMES_PAGE';
export const GAME_DETAIL_PAGE = 'GAME_DETAIL_PAGE';

export default {
  HOME_PAGE,
  LOGIN_PAGE,
  ADD_NEW_GAMES_PAGE,
  GAME_DETAIL_PAGE
};
