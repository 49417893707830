// AllGamesList/index.js
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// import PropTypes from 'prop-types';

import Header from './Header';
import StaggeredGridLayout from '../StaggeredGridLayout';
import Page from '../Page';
import { useAuth0 } from '../Auth';
import checkedGamesFilter from '../../filters/checkedGamesFilter';
import mapToAddGamesPayload from '../../filters/mapToAddGamesPayload';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    background: theme.palette.backgroundGray.default
  },
  content: {
    marginTop: '15px !important'
  },
  loadingContainer: {
    marginTop: 100,
    zIndex: 100
  }
}));

const AllGamesList = props => {
  const { getTokenSilently } = useAuth0();

  async function fetchToken() {
    return await getTokenSilently();
  }

  useEffect(() => {
    if (props.reloadAllGames) {
      //Resolving promise
      fetchToken().then(token => {
        //The backend is going to return all the games based on Popularity.
        props.onGetAllGames(token);
      });

      //This is used to clear all the already existing games.
      props.onComponentLoad();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGameTypeFilterChange = event => {
    props.onGameTypesFilterChange(event.target.value);
  };

  const handlePopularityFilterChange = event => {
    props.onPopularityFilterChange(event.target.value);
  };

  const handleAddGamesButtonClick = e => {
    const { allGames, classroomId, onAddMultipleGames } = props;
    const checkedGames = checkedGamesFilter(allGames);
    var games = mapToAddGamesPayload(checkedGames, classroomId);

    fetchToken().then(token => {
      onAddMultipleGames(games, token);
    });
  };

  const handleSearchTermChange = searchTerm => {
    const { onSearchTermChange } = props;
    onSearchTermChange(searchTerm);
  };

  const handleViewDetailsClick = (gameId, isChecked) => {
    props.onHandleViewDetailsClick(gameId, isChecked);
  };

  const {
    gameTypes,
    popularityFilterTypes,
    allGames,
    onGameCheckStatusChanged,
    enableAddGamesButton,
    onHandleCrossClick,
    isLoading,
    isSavingGames,
    searchTerm,
    gameTypeFilter,
    popularityFilter,
    isLoadingImages,
    selectedGamesCount
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Header
          selectedGamesCount={selectedGamesCount}
          gameTypes={gameTypes}
          popularityFilterTypes={popularityFilterTypes}
          handleGameTypeFilterChange={handleGameTypeFilterChange}
          handlePopularityFilterChange={handlePopularityFilterChange}
          enableAddGamesButton={enableAddGamesButton}
          handleCrossClick={() => onHandleCrossClick()}
          isLoading={isLoading}
          isLoadingImages={isLoadingImages}
          isSavingGames={isSavingGames}
          handleActionButtonClick={handleAddGamesButtonClick}
          searchTerm={searchTerm}
          handleSearchTermChange={handleSearchTermChange}
          gameTypeFilter={gameTypeFilter}
          popularityFilter={popularityFilter}
        />

        <Page>
          <div className={classes.content}>
            <StaggeredGridLayout
              allGames={allGames}
              onGameCheckStatusChanged={onGameCheckStatusChanged}
              searchTerm={searchTerm}
              isLoadingImages={isLoadingImages}
              isLoading={isLoading}
              handleViewDetailsClick={handleViewDetailsClick}
            />
          </div>
        </Page>
      </div>
    </React.Fragment>
  );
};

AllGamesList.propTypes = {
  // describe the component's prop types here
};

AllGamesList.defaultProps = {
  // assign default props to the component here
};

export default AllGamesList;
