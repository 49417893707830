/**
 * This adds child report status for the month in children objects.
 */
const AddReportStatusToChildren = (children, reportStatuses) => {
  return children.map(child => {
    const ifExsits = reportStatuses.filter(r => r.childId === child.id)[0];
    if (ifExsits) {
      child.areAllReportsFilled = ifExsits.areAllReportsFilled;
    } else {
      delete child.areAllReportsFilled;
    }

    return child;
  });
};

export default AddReportStatusToChildren;
