import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './styles';
import accommodateForBulletPoints from '../../../filters/accommodateForBulletPoints';

const SingleInstruction = props => {
  const { title, body, includeChips, color } = props;

  if (!title || !body) {
    return <Text></Text>;
  }
  //Body will be an array in case of What you need.
  if (Array.isArray(body) && body.length === 0) {
    return <Text></Text>;
  }

  return (
    <View style={styles.instructionContainer}>
      <Text style={[styles.title, { color: color }]}>{title.toUpperCase()}</Text>

      {/* IncludeChips is true in case of What you need */}
      {includeChips ? (
        body.map((item, index) => {
          return <Text key={index} label={item} style={styles.chip} />;
        })
      ) : (
        <React.Fragment>
          {/* Notes should not have any body */}
          {body && body === ' ' ? (
            <Text>{'  '}</Text>
          ) : (
            <Text style={styles.list}>
              {body.map((item, index) => {
                return (
                  <View style={styles.singleInstructionContainer} key={index}>
                    <Text style={{ color: color }}>•</Text>
                    <Text key={index} style={[styles.listItemText, { fontWeight: 800 }]}>
                      {'   '}
                      {item}
                    </Text>
                  </View>
                );
              })}
            </Text>
          )}
        </React.Fragment>
      )}
    </View>
  );
};

const TipsAndNotesPdfTile = props => {
  const { gameInstructions, gameColor } = props;
  if (!gameInstructions) {
    return <Text></Text>;
  }

  return (
    <React.Fragment>
      <View style={styles.sheet}>
        <Text style={[styles.pg2Headings, { color: gameColor }]}>
          {gameInstructions.tips && gameInstructions.tips.trim() !== '' && gameInstructions.tips.trim() !== '•'
            ? 'Tips'
            : ''}
        </Text>
        <View style={styles.directions}>
          {gameInstructions.tips &&
            accommodateForBulletPoints(gameInstructions.tips, '•').map((instruction, index) => {
              return (
                <View style={styles.singleDirection} key={index}>
                  <Text style={[styles.blueNumbering, styles.bulletPoints, { color: gameColor }]}>{'\u2022'}</Text>
                  <Text style={styles.instruction}>{instruction.trim().replace(/[*]/g, '')}</Text>
                </View>
              );
            })}
        </View>

        <SingleInstruction title="Notes" body=" " color={gameColor} />
      </View>
    </React.Fragment>
  );
};

export default TipsAndNotesPdfTile;
