import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import GreenLogo from '../../../res/Engage Play Tools_green.svg';
import TindalLogo from '../../../res/tindall-logo.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    height: 116,
    width: '100%',
    position: 'relative',
    top: 500
  },
  content: {
    display: 'flex',
    width: 1200,
    margin: '0 auto'
  },
  leftPanel: {
    // height: '100%'
  },
  engageLogoWhite: {
    height: 50,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  rightPanel: {
    color: 'white'
  },
  text: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#292929'
  },
  tindalLogoContainer: {},
  tindalLogo: {
    height: 70,
    marginTop: 15
  }
}));

const Footer = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.content}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid className={`${classes.gridItem} ${classes.leftPanel}`} item xs={2}>
              <img src={GreenLogo} alt="Engage Logo" className={classes.engageLogoWhite} />
            </Grid>
            <Grid className={`${classes.gridItem} ${classes.rightPanel}`} item xs={10}>
              <Typography variant="body1" className={classes.text}>
                &#169; 2020 ENGAGE PLAYTOOLS
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.tindalLogoContainer}>
            <a href="https://tindall.org.nz" target="_blank">
              <img src={TindalLogo} alt="tindal logo" className={classes.tindalLogo} />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
