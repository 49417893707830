import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { inputFieldNames } from '../../../constants';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
    height: '90vh',
    top: 500,
    background: theme.palette.primary.main
  },
  content: {
    width: 1200,
    margin: '0 auto'
  },
  title: {
    fontSize: 50,
    color: 'white',
    marginBottom: 20,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 30,
    fontWeight: 560,
    marginBottom: 50
  },
  gridItem: {
    height: 370,
    backgroundRepeat: 'no-repeat'
  },
  textField: {
    background: 'white',
    margin: 0,
    padding: 0,
    marginBottom: 64
  },
  rightPanel: {},
  map: {
    height: 400,
    width: 380
  },
  sendEmailButton: {
    background: 'none',
    color: 'white',
    border: '1px solid white',
    width: 160,
    fontSize: 12,
    boxShadow: 'none',
    float: 'right'
  },
  Titleaddress: {
    marginTop: 20,
    color: 'white',
    textDecoration: 'none',
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  address: {
    color: 'white',
    textDecoration: 'none',
    fontStyle: 'normal'
  }
}));

const SectionRegister = props => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const classes = useStyles();

  const handleInputChange = (e, inputFieldName) => {
    const value = e.target.value;
    if (inputFieldName === inputFieldNames.FIRST_NAME) {
      setFirstName(value);
    } else if (inputFieldName === inputFieldNames.EMAIL) {
      setEmail(value);
    } else if (inputFieldName === inputFieldNames.MESSAGE) {
      setMessage(value);
    }
  };

  const handleOnSendEmail = () => {
    const text = `mailto:jimmym@mmsouth.org.nz?subject=Engage Enquiry&body=A message from ${firstName}, with email address ${email}. Text: ${message}.`;
    window.open(text);
  };

  return (
    <React.Fragment>
      <div className={classes.container} id="register-container">
        <div className={classes.content}>
          <div className={classes.heading}>
            <Typography variant="h1" className={classes.title}>
              REGISTER
            </Typography>
            <Typography variant="body1" className={classes.subTitle}>
              or get in touch with us to learn more...
            </Typography>
          </div>
          <div className={classes.contact}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12} className={`${classes.gridItem} ${classes.rightPanel}`}>
                <TextField
                  id="firstName"
                  label="First Name"
                  placeholder="Enter first name"
                  className={classes.textField}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  fullWidth
                  onChange={e => handleInputChange(e, inputFieldNames.FIRST_NAME)}
                />
                <TextField
                  id="email"
                  label="Email"
                  placeholder="youremail@example.com"
                  className={classes.textField}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  fullWidth
                  onChange={e => handleInputChange(e, inputFieldNames.EMAIL)}
                />
                <TextField
                  id="message"
                  label="Message"
                  multiline
                  rows="6"
                  className={classes.textField}
                  placeholder="Start typing here"
                  variant="filled"
                  fullWidth
                  onChange={e => handleInputChange(e, inputFieldNames.MESSAGE)}
                />
                <Button className={classes.sendEmailButton} variant="contained" onClick={handleOnSendEmail}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionRegister;
