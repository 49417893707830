import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './styles';
import { colors } from '../../../constants';

const VariationsList = props => {
  const { variationTitle, color, variationPrefix, gameVariations } = props;

  gameVariations.sort((a, b) => (a.levelNumber > b.levelNumber ? 1 : -1));

  return (
    <View style={styles.variationsContent}>
      <View style={styles.chipContainer}>
        <Text style={[styles.chip, { backgroundColor: color }]}>{variationTitle.toUpperCase()}</Text>
      </View>
      <View style={styles.variationListContainer}>
        {gameVariations.map(variation => {
          return (
            <View key={variation.id} style={styles.singleVariation}>
              <Text style={[styles.prefix, { color: color }]}>{`${variationPrefix}${variation.levelNumber}`}</Text>
              <Text style={styles.description}>{variation.description}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const VariationsPdfTile = props => {
  const { game, gameColor } = props;

  const allGameVariations = game && game.gameVariations;
  const behaviouralGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 1);
  const cognitiveGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 2);
  const emotionalGames = allGameVariations && allGameVariations.filter(g => g.developmentTypeId === 3);

  const allList = [
    { variations: behaviouralGames, gameDevId: 1, title: 'Behavioural', variationPrefix: 'B', color: colors.GREEN },
    { variations: cognitiveGames, gameDevId: 2, title: 'Cognitive', variationPrefix: 'C', color: colors.ORANGE },
    { variations: emotionalGames, gameDevId: 3, title: 'Emotional', variationPrefix: 'E', color: colors.RED }
  ];
  const index = game && allList.findIndex(g => g.gameDevId === game.developmentTypeId);

  const removeGameVariationsWithEmptyDescription = gameVariations => {
    return gameVariations.filter(gv => gv.description.trim() !== '');
  };

  if (index > -1) {
    const toaddObj = allList[index];
    allList.splice(index, 1);
    allList.unshift(toaddObj);
  }

  return (
    <React.Fragment>
      <View style={styles.sheet}>
        <Text style={[styles.pg2Headings, { color: gameColor }]}>Variations</Text>

        {allList && allList[0].variations && (
          <VariationsList
            variationTitle={allList[0].title}
            color={allList[0].color}
            variationPrefix={allList[0].variationPrefix}
            gameVariations={removeGameVariationsWithEmptyDescription(allList[0].variations)}
          />
        )}
        {allList && allList[1].variations && (
          <VariationsList
            variationTitle={allList[1].title}
            color={allList[1].color}
            variationPrefix={allList[1].variationPrefix}
            gameVariations={removeGameVariationsWithEmptyDescription(allList[1].variations)}
          />
        )}
        {allList && allList[2].variations && (
          <VariationsList
            variationTitle={allList[2].title}
            color={allList[2].color}
            variationPrefix={allList[2].variationPrefix}
            gameVariations={removeGameVariationsWithEmptyDescription(allList[2].variations)}
          />
        )}
      </View>
    </React.Fragment>
  );
};

export default VariationsPdfTile;
