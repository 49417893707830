const mapToGameClassroomVariationPayload = (changedToVariationId, dbVersionOfVariationForTheGame) => {
  const payload = {
    toBeUpdatedGameClassroomVariationId: dbVersionOfVariationForTheGame.id,
    gameId: dbVersionOfVariationForTheGame.gameId,
    classroomId: dbVersionOfVariationForTheGame.classroomId,
    gameVariationId: changedToVariationId
  };
  return payload;
};

export default mapToGameClassroomVariationPayload;
