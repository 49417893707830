// EditChildDialog/index.js

import React from 'react';
import { DialogTitle, Button, DialogActions, DialogContent, TextField, Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { genders, ethnicities } from '../../constants';
import Dialog from '../Dialog';
import FancyDropDown from './../FancyDropDown';
import MultipleSelectDropDown from './../MultipleSelectDropdown';
import DatePicker from './../DatePicker';

import { inputFieldNames } from '../../constants';
import { useAuth0 } from '../Auth';
import LabelValuePair from './../LabelValuePair';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: '#f0f0f0'
  },
  dialogContent: {
    width: 580,
    paddingTop: theme.spacing(2)
  },
  textField: {
    margin: 0,
    padding: 0
  },
  preferredNameTextField: {
    margin: '20px 0'
  },
  actionButton: {
    width: 180,
    float: 'right',
    margin: theme.spacing(2)
  },
  picker: {
    margin: 0,
    padding: 0
  },
  dropDown: {
    margin: 0,
    padding: 0
  },
  footer: {
    background: '#f0f0f0'
  },
  gridItem: {
    marginBottom: theme.spacing(1),
    display: 'inline-block'
  }
}));

const EditChildDialog = props => {
  const classes = useStyles();

  const { openDialog, onCloseDialog, child, isSaving } = props;

  const { getTokenSilently } = useAuth0();

  const [selectedEthnicities, setEthnicity] = React.useState([]);

  React.useEffect(() => {
    if (openDialog) {
      const hasValue = child && child.ethnicity;
      if (hasValue) {
        setEthnicity(child.ethnicity.split(','));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleInputChange = (event, inputFieldName) => {
    props.onInputFieldTextChange(event.target.value, inputFieldName);
  };

  const handleEthnicityChange = (event, inputFieldName) => {
    const selected = event.target.value;
    props.onInputFieldTextChange(selected.toString(), inputFieldName);
    setEthnicity(selected);
  };

  const handleDateChange = date => {
    props.onInputFieldTextChange(date, inputFieldNames.DOB);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { child, onUpdateChild } = props;

    delete child.createdOn;
    delete child.updatedOn;

    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      onUpdateChild(child, token);
    });
  };

  let genderValue;
  if (child) {
    const allGenders = genders();
    genderValue = allGenders.filter(e => e.value === child.gender)[0].value;
  }

  if (!child) {
    return '';
  }

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      {isSaving ? <LinearProgress /> : ''}
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Edit Child Details'}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12} className={classes.gridItem}>
              <LabelValuePair label="Name" value={child && `${child.firstName} ${child.lastName}`} />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                id="preferredName"
                label="Preferred Name (Optional)"
                defaultValue={child.preferredName}
                className={`${classes.textField} ${classes.preferredNameTextField}`}
                margin="normal"
                variant="filled"
                autoComplete="off"
                fullWidth
                onChange={e => handleInputChange(e, inputFieldNames.PREFERRED_NAME)}
              />
            </Grid>

            <Grid item xs={6} className={classes.gridItem}>
              <DatePicker
                htmlId="dobDatePicker"
                color="primary"
                label="Date of Birth"
                variant="contained"
                className={classes.picker}
                handleDateChange={handleDateChange}
                selectedDate={child && child.dob}
              />
            </Grid>

            <Grid item xs={6} className={classes.gridItem}></Grid>

            <Grid item xs={6} className={classes.gridItem}>
              <FancyDropDown
                htmlId="genderDropDown"
                label="Gender"
                margin="normal"
                fullWidth
                value={genderValue}
                handleChange={e => handleInputChange(e, inputFieldNames.GENDER)}
                items={genders()}
                dropDownWidth="100%"
                className={classes.dropDown}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}></Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <MultipleSelectDropDown
                label="Ethnicity"
                margin="normal"
                fullWidth
                value={selectedEthnicities}
                handleChange={e => handleEthnicityChange(e, inputFieldNames.ETHNICITY)}
                items={ethnicities()}
                htmlId="ethnicityDropDown"
                dropDownWidth="100%"
                className={classes.dropDown}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button
            onClick={onCloseDialog}
            color="primary"
            variant="outlined"
            className={classes.actionButton}
            id="cancelButton"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.actionButton}
            id="saveChildButton"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditChildDialog;
