import React from 'react';
import { Paper, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import mapDevelopmentTypeIdToVariationTypeAndColor from '../../filters/mapDevelopmentTypeIdToVariationTypeAndColor';
import Highlight from '../../utils/highlight';

const useStyles = makeStyles(theme => ({
  gameContainer: {
    height: 220,
    cursor: 'pointer'
  },
  paper: {
    height: '100%',
    marginTop: 10
  },
  content: {
    padding: 10
  },
  heading: {
    margin: 0,
    padding: 0
  },
  chip: {
    color: 'white',
    height: 20,
    marginTop: 5,
    fontWeight: 600,
    fontSize: 12
  },
  gameSnapshot: {
    width: '100%',
    marginTop: 10
  },
  imageContainer: {
    marginTop: 10,
    height: '135px',
    width: '323px',
    backgroundSize: 'contain !important',
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat'
  }
}));

const handleGameCardClick = (e, id, handleGameClick) => {
  e.stopPropagation();
  handleGameClick(id);
};

const GameCard = props => {
  const classes = useStyles();
  const { game, handleGameClick, searchTerm, isLoadingImages } = props;

  let mappedItem, gameVariation, backgroundColor, gameCardId;

  if (game && game.gameVariation) {
    mappedItem = mapDevelopmentTypeIdToVariationTypeAndColor(game.gameVariation.developmentTypeId);
    gameVariation = `VARIATION ${mappedItem.typePrefix}${game.gameVariation.levelNumber}`;
    backgroundColor = mappedItem.color;
    gameCardId = `gameCard${game.id}`;
  } else {
    return <div></div>;
  }
  return (
    <div
      className={classes.gameContainer}
      onClick={e => handleGameCardClick(e, game.id, handleGameClick)}
      id={gameCardId}
    >
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <h3 className={classes.heading}>
            <Highlight text={game.name} highlight={searchTerm} />
          </h3>
          <Chip label={gameVariation} className={classes.chip} style={{ background: backgroundColor }} />
          {!isLoadingImages && game.image && (
            <div
              className={classes.imageContainer}
              style={{
                backgroundImage: `url(${game.image})`
              }}
            ></div>
          )}

          {!game.image && (
            <div
              className={classes.imageContainer}
              style={{
                background: '#eeeeee'
              }}
            ></div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default GameCard;
