export const EMOTIONAL = 'EMOTIONAL';
export const COGNITIVE = 'COGNITIVE';
export const BEHAVIOURAL = 'BEHAVIOURAL';
export const EMOTIONAL_TAG = 'EMOTIONAL | FEELING';
export const COGNITIVE_TAG = 'COGNITIVE | THINKING';
export const BEHAVIOURAL_TAG = 'BEHAVIOURAL | DOING';

export default {
  EMOTIONAL,
  COGNITIVE,
  BEHAVIOURAL,
  EMOTIONAL_TAG,
  COGNITIVE_TAG,
  BEHAVIOURAL_TAG
};
