export const BLUE = '#1e70a4';
export const GREEN = '#64B43C';
export const ORANGE = '#F58C28';
export const RED = '#DC2D37';

export default {
  BLUE,
  GREEN,
  ORANGE,
  RED
};
