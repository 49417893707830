import React from 'react';
import {
  makeStyles,
  List,
  Typography,
  InputLabel,
  Link,
  ListItemIcon,
  ListItem,
  ListItemText
} from '@material-ui/core';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const styles = makeStyles(theme => {
  return {
    wrapIcon: {
      marginBottom: theme.spacing(1)
    }
  };
});

const AttachmentLink = props => {
  const classes = styles();
  const preventDefault = event => event.preventDefault();
  const { label, handleClick, gameAttachemts } = props;

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const getExtentionIcon = mediaFileName => {
    let extention = mediaFileName
      .split('.')
      .pop()
      .toLowerCase();
    switch (extention) {
      case 'pdf':
        return <PictureAsPdfIcon color="primary"></PictureAsPdfIcon>;
      case 'png' || 'jpg':
        return <ImageIcon color="primary"></ImageIcon>;
      case ('ppt', 'pptx'):
        return <SlideshowIcon color="primary"></SlideshowIcon>;
      default:
        return <AttachFileIcon color="primary"></AttachFileIcon>;
    }
  };

  return (
    <React.Fragment>
      <InputLabel htmlFor="link_simple">{label}</InputLabel>
      <List component="nav" aria-label="All attachments">
        {gameAttachemts &&
          gameAttachemts.map(item => {
            return (
              <ListItemLink button href={item.mediaURL} target="_blank">
                <ListItemIcon>{getExtentionIcon(item.mediaFileName)}</ListItemIcon>
                <ListItemText primary={item.mediaFileName} />
              </ListItemLink>
            );
          })}
      </List>
    </React.Fragment>
  );
};

export default AttachmentLink;
