import React from 'react';
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  DialogActions,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Dialog from '../Dialog';

const useStyles = makeStyles(theme => {
  return {
    errorMessage: {
      color: 'red',
      position: 'absolute',
      left: 20
    },
    actionButton: {
      width: 180,
      float: 'right',
      margin: theme.spacing(2)
    }
  };
});

const StandardDialog = props => {
  const classes = useStyles();

  const {
    open,
    handleSubmit,
    handleClose,
    title,
    description,
    actionButtonText,
    cancelButtonText,
    showProgressBar,
    errorMessage,
    isDanger
  } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {showProgressBar && <LinearProgress />}

        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Typography variant="caption" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            id="cancelButton"
            className={classes.actionButton}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            autoFocus
            id="removeButton"
            style={isDanger ? { backgroundColor: 'red' } : { backgroundColor: 'primary' }}
            className={classes.actionButton}
          >
            {actionButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

StandardDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  showProgressBar: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default StandardDialog;
