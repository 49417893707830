import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';

import LabelValuePair from '../../LabelValuePair';
import { buttonNames } from '../../../constants';
import { MapGenderValueToName } from './../../../filters/GenderMapper';
import { MapEthnicityValueToName } from './../../../filters/EthnicityMapper';

import Moment from 'moment';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '30px 20px'
  },
  label: {
    color: theme.palette.borderGray.default,
    fontSize: 12
  },
  value: {
    fontSize: 16
  },
  gridContainer: {},
  griditem: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 50
  },
  button: {
    margin: '0 20px 0 0',
    width: 180
  },
  redButton: {
    border: '1px solid red',
    color: 'red'
  }
}));

const ChildDetails = props => {
  const classes = useStyles();
  const { child } = props;

  if (!child) {
    return '';
  }

  const formattedDate = child.dob ? Moment(child.dob).format('DD/MM/YYYY') : '';

  const prefferedName = child.preferredName || !child.preferredName === 'null' ? child.preferredName : 'None';

  return (
    <Grid container spacing={0} className={classes.gridContainer}>
      <Grid item xs={12} className={classes.griditem}>
        <LabelValuePair label="Name" value={`${child.firstName} ${child.lastName}`} />
      </Grid>
      <Grid item xs={6} className={classes.griditem}>
        <LabelValuePair label="Preferred Name" value={prefferedName} />
      </Grid>
      <Grid item xs={6} className={classes.griditem}>
        <LabelValuePair label="Date of Birth" value={formattedDate} />
      </Grid>
      <Grid item xs={6} className={classes.griditem}>
        <LabelValuePair label="Gender" value={MapGenderValueToName(child.gender)} />
      </Grid>
      <Grid item xs={6} className={classes.griditem}>
        <LabelValuePair label="Ethnicity" value={MapEthnicityValueToName(child.ethnicity)} />
      </Grid>
    </Grid>
  );
};

const ActionButtons = ({ handleButtonClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => handleButtonClick(buttonNames.EDIT)}
        id="editChildButton"
      >
        <EditIcon />
        Edit
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        id="transferChildButton"
        onClick={() => handleButtonClick(buttonNames.TRANSFER)}
      >
        Transfer
      </Button>
      <Button
        variant="outlined"
        className={`${classes.button} ${classes.redButton}`}
        id="deactivateChildButton"
        onClick={() => handleButtonClick(buttonNames.DEACTIVATE)}
      >
        Deactivate
      </Button>
    </div>
  );
};

const DetailsTab = props => {
  const classes = useStyles();
  const { child, handleButtonClick } = props;

  return (
    <div className={classes.variationsTabContainer}>
      <div className={classes.content}>
        <ChildDetails child={child} />
        <ActionButtons handleButtonClick={handleButtonClick} />
      </div>
    </div>
  );
};

export default DetailsTab;
