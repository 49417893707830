import React from 'react';

import StandardDialog from './../StandardDialog';
import { useAuth0 } from '../Auth';

const ChangePasswordDialog = props => {
  const { open, isSaving, onShowChangePasswordDialog, onSubmitChangePasswordRequest, classroomName } = props;
  const { getTokenSilently } = useAuth0();

  const handleSubmit = () => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      onSubmitChangePasswordRequest(classroomName, token);
    });
  };

  return (
    <StandardDialog
      open={open}
      title="Reset Password"
      description="To change your password, a reset link will be sent to your email address."
      actionButtonText="Send Reset Link"
      cancelButtonText="Cancel"
      handleClose={() => onShowChangePasswordDialog(false)}
      handleSubmit={handleSubmit}
      showProgressBar={isSaving}
      errorMessage=""
    />
  );
};

export default ChangePasswordDialog;
