import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './styles';
import accommodateForBulletPoints from '../../../filters/accommodateForBulletPoints';

const HowToPlayPdfTile = props => {
  const { game, gameColor } = props;

  const whatYouNeed = game.whatYouNeed && accommodateForBulletPoints(game.whatYouNeed, '•'); //TODO
  /* 
  let combinedwhatYouNeed = [];
  whatYouNeed &&
    whatYouNeed.forEach(item => {
      combinedwhatYouNeed = [...combinedwhatYouNeed, ...item.split(',')];
    }); */

  return (
    <React.Fragment>
      <View style={styles.sheetTwo}>
        {/* --------------------------------------- */}
        <View style={styles.section}>
          <Text style={[styles.pg2Headings, { color: gameColor }]}>How to play</Text>
          <View style={styles.directions}>
            {game.howToPlay &&
              accommodateForBulletPoints(game.howToPlay, '•').map((direction, index) => {
                return (
                  <View style={styles.singleDirection} key={index}>
                    <Text style={[styles.blueNumbering, { color: gameColor }]}>{index + 1}.</Text>
                    <Text style={styles.instruction}>{direction.trim().replace(/[*]/g, '')}</Text>
                  </View>
                );
              })}
          </View>
        </View>
        <View style={styles.section}>
          <Text style={[styles.pg2Headings, { color: gameColor }]}>What you need</Text>
          <View style={styles.requirementsContainer}>
            {whatYouNeed &&
              whatYouNeed.map((requirement, index) => {
                return (
                  <Text style={styles.requirement} key={index}>
                    {requirement}
                  </Text>
                );
              })}
          </View>
        </View>
        <View style={styles.section}>
          <Text style={[styles.pg2Headings, { color: gameColor }]}>Things you can say</Text>
          <View style={styles.directions}>
            {game.thingsToSay &&
              accommodateForBulletPoints(game.thingsToSay, '•').map((instruction, index) => {
                return (
                  <View style={styles.singleDirection} key={index}>
                    <Text style={[styles.blueNumbering, styles.bulletPoints, { color: gameColor }]}>{'\u2022'}</Text>
                    <Text style={styles.instruction}>{instruction.trim().replace(/[*]/g, '')}</Text>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default HowToPlayPdfTile;
