import { types } from '../constants';

export const showMessage = text => ({
  type: types.MESSAGE_SHOW,
  payload: {
    message: {
      text
    }
  }
});

export const hideMessage = () => ({
  type: types.MESSAGE_HIDE
});

export const nextMessage = () => ({
  type: types.MESSAGE_NEXT
});

export const splashScreenShowFinished = () => ({
  type: types.SPLASH_SCREEN_SHOW_FINISHED
});
