import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  highlight: {
    background: theme.palette.highlight.highlight,
    color: theme.palette.highlight.contrastText
  }
});

function Highlight({ text, highlight, classes }) {
  // Optimization:
  //  This function often gets called with highlight being empty
  //  So we simply shortcut the regular expression splitting and
  //  return the original text as that's what will be returned
  //  anyway.
  if (highlight === '') {
    return text;
  } else {
    // Escape special regular expression characters from inside
    // the highlight string first. Otherwise this causes issues
    // and allows the users to perform a highlight based on
    // regular expression patterns.
    const pattern = '(' + highlight.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + ')';
    const parts = text.split(new RegExp(pattern, 'gi'));
    return (
      <React.Fragment>
        {parts.map((part, index) => (
          <span key={index}>
            {part.toLowerCase() === highlight.toLowerCase() ? <span className={classes.highlight}>{part}</span> : part}
          </span>
        ))}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Highlight);
