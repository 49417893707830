import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid } from '@material-ui/core';
import { inputFieldNames } from '../../../constants';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 16,
    fontWeight: 600
  },
  labelLight: {
    color: '#888888',
    fontWeight: 100
  },
  standardMargin: {
    marginTop: '16px !important',
    marginBottom: '8px !important'
  },
  removeInstructionItemButton: {
    marginTop: 10,
    marginLeft: -10,
    color: theme.palette.danger.default
  },
  addItemButton: {
    color: theme.palette.primary.main,
    marginLeft: 0
  },
  instructionItem: {
    display: 'flex'
  },
  instructionItemNumbering: {
    display: 'inline-block',
    fontWeight: 600,
    marginTop: 30,
    marginRight: 10
  },
  numbering: {
    fontWeight: 800,
    paddingTop: 35,
    paddingRight: 16
  },
  row: {
    display: 'flex'
  },
  textFieldItem: {
    paddingRight: 20
  }
}));

const Title = props => {
  const classes = useStyles();
  const { title, labelLight = false } = props;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <div className={classes.standardMargin}>
          <Typography className={labelLight ? `${classes.labelLight} ${classes.label}` : classes.label}>
            {title}
          </Typography>
        </div>
      </Grid>
    </React.Fragment>
  );
};

const calculateListStyle = (listStyleType, i, numberingPrefix) => {
  let style = '';
  if (listStyleType === 'numbering') {
    style = i + 1;
  } else if (listStyleType === 'bullet') {
    style = '•';
  } else {
    style = '';
  }

  //numberingPrefix will be for variations.
  if (numberingPrefix) {
    style = `${numberingPrefix}${style}`;
  }

  return style;
};

const calculateInputName = title => {
  if (title === 'How to Play') {
    return inputFieldNames.HOW_TO_PLAY;
  } else if (title === 'What you need') {
    return inputFieldNames.WHAT_YOU_NEED;
  } else if (title === 'Things you can say') {
    return inputFieldNames.THINGS_TO_SAY;
  } else if (title === 'Behavioural') {
    return inputFieldNames.BEHAVIOURAL;
  } else if (title === 'Cognitive') {
    return inputFieldNames.COGNITIVE;
  } else if (title === 'Emotional') {
    return inputFieldNames.EMOTIONAL;
  } else if (title === 'Tips') {
    return inputFieldNames.TIPS;
  }
};

const InstructionsInRowForm = ({
  fieldCount,
  title,
  listStyleType,
  inputName,
  instructions,
  isGrid,
  handleInputChange,
  numberingPrefix
}) => {
  const classes = useStyles();
  let rows = [];

  for (let i = 0; i < fieldCount; i++) {
    const row = (
      <div className={classes.row} key={i}>
        <div className={classes.numbering}>{calculateListStyle(listStyleType, i, numberingPrefix)}</div>
        <TextField
          id={`instructionItem${title}${i}`}
          //instructions[i].id will be true in case of Game Variations
          defaultValue={
            // instructions[i] && instructions[i].id ? instructions[i].description.trim() : instructions[i].trim()
            instructions[i] && instructions[i].trim()
          }
          className={`${classes.textField} ${classes.instructionItemTextField}`}
          margin="normal"
          variant="filled"
          autoComplete="off"
          fullWidth={!isGrid}
          onChange={e => handleInputChange(e, inputName, i)}
          multiline={true}
        />
      </div>
    );

    rows = [...rows, row];
  }

  return (
    <React.Fragment>
      <Title title={title} />
      <div className={classes.rowsContainer}>{rows}</div>
    </React.Fragment>
  );
};

const InstructionsInGridForm = ({ inputName, title, instructions, fieldCount, handleInputChange }) => {
  const classes = useStyles();

  let fields = [];

  for (let i = 0; i < fieldCount; i++) {
    const field = (
      <React.Fragment key={i}>
        <Grid item xs={4} className={classes.textFieldItem}>
          <TextField
            id={`instructionItem${title}${i}`}
            // defaultValue={item.id ? item.description.trim() : item.trim()} //item.id will be true in case of Game Variations
            defaultValue={instructions[i]}
            className={`${classes.textField} ${classes.instructionItemTextField}`}
            margin="normal"
            variant="filled"
            autoComplete="off"
            fullWidth={true}
            onChange={e => handleInputChange(e, inputName, i)}
            multiline={true}
          />
        </Grid>
      </React.Fragment>
    );

    fields = [...fields, field];
  }

  return (
    <React.Fragment>
      <Title title={title} />
      <Grid container>{fields}</Grid>
    </React.Fragment>
  );
};

const VariationsInRowForm = ({
  fieldCount,
  title,
  listStyleType,
  inputName,
  instructions,
  isGrid,
  handleVariationChange,
  numberingPrefix,
  developmentTypeId,
  gameId,
  handleAddingNewGameVariation
}) => {
  const classes = useStyles();

  const handleInputChange = (e, inputName, instruction, levelNumber) => {
    if (instruction) {
      handleVariationChange(e, inputName, instruction.id);
    } else {
      //Means new instruction
      const newGameVariation = {
        gameId,
        description: e.target.value,
        developmentTypeId,
        levelNumber
      };

      handleAddingNewGameVariation(newGameVariation);
    }
  };

  let rows = [];

  for (let i = 0; i < fieldCount; i++) {
    const comparer = i + 1;
    const currentInstruction = instructions && instructions.filter(i => i.levelNumber === comparer)[0];

    const row = (
      <div className={classes.row} key={i}>
        <div className={classes.numbering}>{calculateListStyle(listStyleType, i, numberingPrefix)}</div>
        <TextField
          id={`instructionItem${title}${i}`}
          //instructions[i].id will be true in case of Game Variations
          defaultValue={
            // instructions[i] && instructions[i].id ? instructions[i].description.trim() : instructions[i].trim()
            currentInstruction ? currentInstruction.description.trim() : ''
          }
          className={`${classes.textField} ${classes.instructionItemTextField}`}
          margin="normal"
          variant="filled"
          autoComplete="off"
          fullWidth={!isGrid}
          //Null would be passed in case of new variation
          onChange={e => handleInputChange(e, inputName, currentInstruction, i + 1)}
          multiline={true}
        />
      </div>
    );

    rows = [...rows, row];
  }

  return (
    <React.Fragment>
      <div className={classes.rowsContainer}>{rows}</div>
    </React.Fragment>
  );
};

export const EditGameInstructions = props => {
  const { instructions, title, handleInputChange, listStyleType, fieldCount, isGrid } = props;
  const inputName = calculateInputName(title);

  if (!isGrid) {
    return (
      <InstructionsInRowForm
        fieldCount={fieldCount}
        listStyleType={listStyleType}
        instructions={instructions}
        isGrid={isGrid}
        handleInputChange={handleInputChange}
        inputName={inputName}
        title={title}
      />
    );
  } else {
    return (
      <InstructionsInGridForm
        inputName={inputName}
        instructions={instructions}
        handleInputChange={handleInputChange}
        fieldCount={fieldCount}
        title={title}
      />
    );
  }
};

export const EditGameVariations = props => {
  const {
    gameVariations,
    title,
    numberingPrefix,
    handleVariationChange,
    listStyleType,
    isGrid,
    developmentTypeId,
    gameId,
    handleAddingNewGameVariation
  } = props;

  //Sorting all game variations according to levelNumber.
  gameVariations.sort((a, b) => a.levelNumber - b.levelNumber);

  if (!gameVariations) {
    return '';
  }

  return (
    <React.Fragment>
      <Title title={title} labelLight={true} />

      <VariationsInRowForm
        fieldCount={6}
        listStyleType={listStyleType}
        instructions={gameVariations}
        isGrid={isGrid}
        handleVariationChange={handleVariationChange}
        // inputName={inputName}
        title={title}
        numberingPrefix={numberingPrefix}
        developmentTypeId={developmentTypeId}
        gameId={gameId}
        handleAddingNewGameVariation={handleAddingNewGameVariation}
      />
    </React.Fragment>
  );
};
