import mapDevelopmentTypeIdToVariationTypeAndColor from '../mapDevelopmentTypeIdToVariationTypeAndColor';

const AddVariationCodeToChildGameVariations = childGameVariationsWithChildren => {
  return childGameVariationsWithChildren.map(variation => {
    var mappedItem = mapDevelopmentTypeIdToVariationTypeAndColor(variation.developmentTypeId);
    const encodedGameVariation = `${mappedItem.typePrefix}${variation.variationLevelNumber}`;
    variation.gameVariationCode = encodedGameVariation;
    variation.color = mappedItem.color;
    return variation;
  });
};

//This would take all childGameVariations with child details, and return back GameVariationCode, Names, and color
const ConsolidateChildrenByDevelopmentTypeId = childGameVariationsWithChildren => {
  const encodedChildGameVariations = AddVariationCodeToChildGameVariations(childGameVariationsWithChildren);
  const variationDevelopmentCodes = encodedChildGameVariations.map(v => v.gameVariationCode);

  const uniqueVariationDevelopmentCodes = [...new Set(variationDevelopmentCodes)];

  const chipData = uniqueVariationDevelopmentCodes.map(code => {
    //Getting all the variations for the code.
    const gameVariationsByCode = childGameVariationsWithChildren.filter(gv => gv.gameVariationCode === code);

    //Getting all the childNames from the variations.
    const childrenNames = gameVariationsByCode.map(v => v.childName).join(', ');
    const color = gameVariationsByCode.map(v => v.color)[0];

    return {
      gameVariationCode: code,
      childrenNames,
      color
    };
  });

  return chipData;
};

export default ConsolidateChildrenByDevelopmentTypeId;
