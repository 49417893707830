import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AdminDashboard from '../../components/AdminDashboard';
import { clearAllGames, getAllGames, clearExistingMediaAndImages } from '../../actions';
import consolidateGamesWithGameMedia from '../../filters/consolidateGamesWithGameMedia';

function mapStateToProps(state, ownProps) {
  const allGames = state.games.allGames.items;

  const gameMedia = state.medias.gameMedias.items;
  const actualImagesLoaded = gameMedia.some(g => g.image);
  if (actualImagesLoaded) {
    consolidateGamesWithGameMedia(allGames, gameMedia);
  }

  return {
    searchTerm: state.games.allGames.searchTerm,
    allGames,
    isLoadingGames: state.games.allGames.isLoading,
    isLoadingImages: state.medias.actualImages.isLoading
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { history } = ownProps;
  return {
    onGetAllGames: token => {
      dispatch(clearAllGames());
      //True when loading all games for AddGamesPage.
      //False when loading all games on admin page
      const minusAlreadyAddedGames = false;
      dispatch(getAllGames(token, minusAlreadyAddedGames));
      dispatch(clearExistingMediaAndImages());
    },
    onEditGameClick: gameId => {
      history.push(`/admin/game/${gameId}/edit`);
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminDashboard)
);
