// ChangePasswordDialog/index.js
import { connect } from 'react-redux';
import ChangePasswordDialog from '../../components/ChangePasswordDialog';

import { openConfirmChangePasswordDialog, submitChangePasswordRequest } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    open: state.classroom.changePassword && state.classroom.changePassword.dialogOpen,
    isSaving: state.classroom.changePassword && state.classroom.changePassword.isSaving,
    classroomName: state.classroom.classroom.item && state.classroom.classroom.item.name
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onShowChangePasswordDialog: open => {
      dispatch(openConfirmChangePasswordDialog(open));
    },
    onSubmitChangePasswordRequest: (classroomName, token) => {
      dispatch(submitChangePasswordRequest(classroomName, token));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordDialog);
