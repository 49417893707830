/**
 * It is used in Children tab > Select a child > Variations tab.
 * It adds a flag to every game that has a custom child variation
 */

const ChildGameVariationSelector = (finalGamesForClassroom, childGameVariations) => {
  return finalGamesForClassroom.map(game => {
    const hasCustomChildVariation = childGameVariations.filter(variation => variation.gameId === game.id);
    if (hasCustomChildVariation.length > 0) {
      game.hasCustomChildVariation = true;
    } else {
      game.hasCustomChildVariation = false;
    }

    return game;
  });
};

export default ChildGameVariationSelector;
