const gender = () => [
  {
    name: 'Male',
    value: 0
  },
  {
    name: 'Female',
    value: 1
  },
  {
    name: 'Gender Diverse',
    value: 2
  }
];

export default gender;
