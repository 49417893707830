// ActionBar/index.js
import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import SearchBox from '../SearchBox';
import ActionBarSingleRow from '../ActionBarSingleRow';
import AddNewChildDialog from '../../containers/AddNewChildDialog';
import EditChildDialog from '../../containers/EditChildDialog';
import TransferChildDialog from '../../containers/TransferChildDialog';
import DeactiveChildDialog from '../../containers/DeactiveChildDialog';

const useStyles = makeStyles(theme => ({
  actionBarContainer: {
    marginBottom: 20,
    '@media print': {
      display: 'none'
    }
  },
  button: {
    margin: theme.spacing(1),
    width: 180,
    height: '36px !important',
    borderWidth: 1.3,
    flex: 1
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  addChildrenFab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  searchBoxContainer: {
    paddingTop: theme.spacing(1)
  }
}));

function ChildrenDashboardActionBar(props) {
  const classes = useStyles();

  const { searchTerm, onAddNewChildButtonClick } = props;

  const handleSearchTermChange = searchTerm => {
    const { onSearchTermChange } = props;
    onSearchTermChange(searchTerm.toLowerCase());
  };

  return (
    <React.Fragment>
      <AddNewChildDialog />
      <EditChildDialog />
      <TransferChildDialog />

      <DeactiveChildDialog />

      <ActionBarSingleRow>
        <div className={classes.actionBarContainer}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Fab
                id="addGamesButton"
                onClick={onAddNewChildButtonClick}
                color="primary"
                variant="extended"
                aria-label="add"
                className={`${classes.addChildrenFab} ${classes.button}`}
              >
                <AddIcon className={classes.extendedIcon} />
                Add Child
              </Fab>
            </Grid>

            <Grid item xs={10} className={`${classes.borderRed} ${classes.searchBoxContainer}`}>
              <SearchBox
                className={classes.searchBox}
                handleSearchTermChange={handleSearchTermChange}
                searchTerm={searchTerm}
                placeholder="Search Children"
              />
            </Grid>
          </Grid>
        </div>
      </ActionBarSingleRow>
    </React.Fragment>
  );
}

ChildrenDashboardActionBar.propTypes = {
  // describe the component's prop types here
};

ChildrenDashboardActionBar.defaultProps = {
  // assign default props to the component here
};

export default ChildrenDashboardActionBar;
