import { convertArrayToString } from '../../filters/accommodateForBulletPoints';

/**Attaching only the updated variations to the game object
 * And creating text out of instructions arrays.
 */
const MapGameDataForEditing = (game, updatedVariations, createdGameVariations) => {
  let updatedGame = game;
  updatedGame.howToPlay = convertArrayToString(game.howToPlayArray);
  updatedGame.whatYouNeed = convertArrayToString(game.whatYouNeedArray);
  updatedGame.thingsToSay = convertArrayToString(game.thingsToSayArray);
  updatedGame.tips = convertArrayToString(game.tipsArray);

  updatedGame.gameVariations = [...updatedVariations, ...createdGameVariations];
  return updatedGame;
};

export default MapGameDataForEditing;
