import { call, put, takeLatest, all } from 'redux-saga/effects';

import { types } from '../constants';
import * as mediaApi from '../api/media';

//This loads records from common.Media table.
function* watchLoadMedia({ gameIdsString, token }) {
  try {
    yield put({ type: types.START_LOAD_MEDIA });
    const { jsonData } = yield call(mediaApi.getMediaForClassroom, gameIdsString, token);

    yield put({ type: types.LOAD_MEDIA_SUCCESS, gameMedias: jsonData });

    //After media records are successfully loaded, then calling endpoint to get actual images
    const mediaIds = jsonData.map(g => g.mediaId);
    yield put({ type: types.LOAD_ACTUAL_IMAGES, mediaIds, token });
  } catch (e) {
    yield resolveError(types.LOAD_MEDIA_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_MEDIA_FINISHED });
  }
}

function* watchLoadActualImages({ mediaIds, token }) {
  try {
    yield put({ type: types.START_LOAD_ACTUAL_IMAGES });

    let responseData = yield all(
      mediaIds.map(mediaId => {
        return call(mediaApi.getActualImage, mediaId, token);
      })
    );

    const images = consolidateMediaIdsAndImages(responseData, mediaIds);

    yield put({ type: types.LOAD_ACTUAL_IMAGES_SUCCESS, images });
  } catch (error) {
    yield resolveError(types.LOAD_ACTUAL_IMAGES_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_ACTUAL_IMAGES_FINISHED });
  }
}

function* watchLoadSingleActualImages({ mediaIds, token }) {
  try {
    yield put({ type: types.START_LOAD_SINGLE_ACTUAL_IMAGE });

    //Loading the actual image and other attachments for the game
    for (let index = 0; index < mediaIds.length; index++) {
      const jsonData = yield call(mediaApi.getActualImage, mediaIds[index].mediaId, token);
      let mediaId = mediaIds[index].mediaId;
      yield put({ type: types.LOAD_SINGLE_ACTUAL_IMAGE_SUCCESS, image: jsonData, mediaId });
    }

    //const jsonData = yield call(mediaApi.getActualImage, mediaId, token);
    //yield put({ type: types.LOAD_SINGLE_ACTUAL_IMAGE_SUCCESS, image: jsonData, mediaId });
  } catch (error) {
    yield resolveError(types.LOAD_SINGLE_ACTUAL_IMAGE_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_SINGLE_ACTUAL_IMAGE_FINISHED });
  }
}

const consolidateMediaIdsAndImages = (responseData, mediaIds) => {
  let consolidatedData = [];
  for (let i = 0; i < responseData.length; i++) {
    var media = { mediaId: mediaIds[i], image: responseData[i] };
    consolidatedData = [...consolidatedData, media];
  }

  return consolidatedData;
};

//watchDeleteAttachmentsForGame

function* watchLoadAttachmentFilesForGame({ id, token }) {
  try {
    //gameIdsString

    yield put({ type: types.START_LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME });

    const { jsonData } = yield call(mediaApi.getMediaForClassroom, id, token);

    yield put({ type: types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_SUCCESS, images: jsonData });

    yield put({ type: types.LOAD_ATTACHMENTS_FOR_GAME, images: jsonData });
  } catch (error) {
    yield resolveError(types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FINISHED });
  }
}

function* watchDeleteAttachmentsForGame({ mediaIdsArray, token }) {
  try {
    if (typeof mediaIdsArray !== 'undefined' && mediaIdsArray.length > 0) {
      const mediaIdsString = mediaIdsArray.join(';');
      yield call(mediaApi.deleteMedias, mediaIdsString, token);
    }
  } catch (error) {
  } finally {
  }
}

function* watchUploadAttachmentsForGame({ gameId, attachments, token }) {
  try {
    yield call(mediaApi.addAttachmentsForGame, gameId, attachments, token);
  } catch (error) {
  } finally {
  }
}

//This is used to catch all the errors in case of request unsuccessful, and will extract the actual error message.
function* resolveError(type, error) {
  let errorMessageCaptured = false;
  let completeErrorMessage = {};
  if (!error.ok) {
    try {
      const clone = error.clone();
      yield clone.text().then(text => {
        completeErrorMessage = JSON.parse(text);
        errorMessageCaptured = true;
      });
    } catch (err) {
      errorMessageCaptured = true;
      completeErrorMessage = err;
    }

    if (errorMessageCaptured) {
      const errorMessage = extractErrorMessage(completeErrorMessage);
      yield put({ type, errorMessage });
    }
  }
}

function extractErrorMessage(completeErrorMessage) {
  let errorMessage = '';

  try {
    const message = completeErrorMessage.exceptionMessage
      ? completeErrorMessage.exceptionMessage.split('Content:')[1]
      : completeErrorMessage.messageDetail;

    errorMessage = JSON.parse(message).errorMessage;
    if (!errorMessage) {
      errorMessage = 'An error occured';
    }
  } catch (error) {
    errorMessage = 'An error occured';
  }

  return errorMessage;
}

export default function* gamesWatcher() {
  yield takeLatest(types.LOAD_MEDIA, watchLoadMedia);
  yield takeLatest(types.LOAD_ACTUAL_IMAGES, watchLoadActualImages);
  yield takeLatest(types.LOAD_SINGLE_ACTUAL_IMAGE, watchLoadSingleActualImages);
  yield takeLatest(types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME, watchLoadAttachmentFilesForGame);
  yield takeLatest(types.DELETE_ATTACHMENTS_FOR_GAME, watchDeleteAttachmentsForGame);

  yield takeLatest(types.UPLOAD_ATTACHMENTS_FOR_GAME, watchUploadAttachmentsForGame);
}
