import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(themes => ({
  buttonContainer: {
    paddingTop: 20
  }
}));

const ActionBarSingleRow = props => {
  const classes = useStyles();

  return <div className={classes.buttonContainer}>{props.children}</div>;
};

export default ActionBarSingleRow;
