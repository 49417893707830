// ChildrenDashboard/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChildrenDashboard from '../../components/ChildrenDashboard';
// import { setLastVisitedPage } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChildrenDashboard)
);
