// ChildFeedbackDialog/index.js
import { connect } from 'react-redux';

import ChildFeedbackDialog from '../../components/ChildFeedbackDialog';
import {
  openChildReportDialog,
  getQuestionsForTheChild,
  answerChange,
  submitAnswer,
  setActiveStep,
  setCompletedForIndex
} from '../../actions';

function mapStateToProps(state, ownProps) {
  const allQuestions = state.child.childReport.report.item && state.child.childReport.report.item.questions;
  return {
    openDialog: state.child.childReport.showChildReportDialog,
    isLoading: state.child.childReport.report.isLoading,
    report: state.child.childReport.report.item,
    enableCompleteButton: !(allQuestions && allQuestions.some(q => q.selectedId === -1)),
    isSaving: state.child.childReport.response.isSaving,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    currentChildReportTemplateId: state.child.childReport.currentReportTypeId,
    activeStep: state.child.stepper.activeStep,
    completedSteps: state.child.stepper.completedSteps
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseDialog: () => {
      dispatch(openChildReportDialog(false));
    },
    onSubmitClicked: (payload, token) => {
      dispatch(submitAnswer(payload, token));
    },
    onGetQuestionsForTheChild: (childReportTemplateType, token) => {
      dispatch(getQuestionsForTheChild(childReportTemplateType, token));
    },
    onAnswerChange: (selectedAnswerId, questionId) => {
      dispatch(answerChange(selectedAnswerId, questionId));
    },
    setActiveStep: activeStep => {
      dispatch(setActiveStep(activeStep));
    },
    setCompletedForIndex: index => {
      dispatch(setCompletedForIndex(index));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildFeedbackDialog);
