import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';

import HeaderLogo from '../../../res/Engage Play Tools_green.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // position: 'relative',
    // zIndex: 100
  },
  toolbar: {
    width: 1200,
    margin: '0 auto'
  },
  header: {
    background: 'white'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  headerLogo: {
    height: 45,
    marginRight: theme.spacing(2)
  },
  actionButton: {
    width: 160,
    marginLeft: 40,
    fontSize: 12
  }
}));

const Header = props => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, navigateToRegisterSection } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <img src={HeaderLogo} alt="header logo" className={classes.headerLogo} />
          <Typography variant="h6" className={classes.title}></Typography>
          <div>
            {!isAuthenticated && (
              <React.Fragment>
                <Button
                  id="registerButton"
                  className={classes.actionButton}
                  color="primary"
                  variant="contained"
                  onClick={e => navigateToRegisterSection(e)}
                >
                  Enquire
                </Button>
                <Button
                  id="loginButton"
                  className={classes.actionButton}
                  color="primary"
                  variant="outlined"
                  onClick={() => loginWithRedirect({})}
                >
                  Teacher Login
                </Button>
              </React.Fragment>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
