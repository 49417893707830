// HomePage/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NotificationDrawer from '../../components/NotificationDrawer';

import { openNotificationDrawer, changeTabValueInHeader, setLastVisitedPage } from '../../actions';
import { urls, pageNames } from '../../constants';

function mapStateToProps(state, ownProps) {
  return {
    showNotification: state.notifications.notification.show,
    // allNotification: state.notifications.allNotification.items,
    showNotificationDrawer: state.notifications.showNotificationDrawer
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;
  return {
    onNotificationClicked: open => {
      dispatch(openNotificationDrawer(open));
    },
    onNotificationItemClicked: () => {
      dispatch(openNotificationDrawer(false));
      history.push(urls.CHILDREN);
      dispatch(setLastVisitedPage(pageNames.ALL_GAMES));
      dispatch(changeTabValueInHeader(1));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer));
