// ActionBar/index.js
import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';

import SearchBox from '../SearchBox';
import ActionBarSingleRow from '../ActionBarSingleRow';
import PrintingDialogBox from '../../containers/PrintingDialogBox';
import { useAuth0 } from '../Auth';

const useStyles = makeStyles(theme => ({
  actionBarContainer: {
    '@media print': {
      display: 'none'
    }
  },
  fab: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    width: 180,
    height: '36px !important',
    borderWidth: 1.3,
    flex: 1
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  addGamesFab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  allGamesButton: {
    marginLeft: 0
  },

  searchBoxContainer: {
    paddingTop: theme.spacing(1)
  }
}));

function ClassroomDashboardActionBar(props) {
  const classes = useStyles();

  const {
    searchTerm,
    handleSearchTermChange,
    onOpenPrintingDialogBox,
    isLoadingClassroom,
    isLoadingMedia,
    isLoadingImages,
    gamesForClassroom,
    onGetGameDetailsForMultipleGames,
    gameDetailsForMultipleGames,
    onSetGameImageDimensions,
    onFireOpenPrintingDialogBoxInstruction, //To fix the bug of printing all games dialog opening when coming back from Children page
    openPrintingDialogBoxInstructionFired //To fix the bug of printing all games dialog opening when coming back from Children page
  } = props;
  const { getTokenSilently } = useAuth0();

  //Run the code block only once, when all the games are loaded
  React.useEffect(() => {
    if (gameDetailsForMultipleGames && gameDetailsForMultipleGames.length > 0) {
      gameDetailsForMultipleGames.forEach(game => {
        var img = document.createElement('img');
        //Calculating image height and width. Used in printing.
        img.onload = function() {
          const dimensions = {
            height: img.height,
            width: img.width
          };
          onSetGameImageDimensions(dimensions, game.id);
        };
        img.src = game.image;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameDetailsForMultipleGames && gameDetailsForMultipleGames.length]);

  //If all the image dimensions are calculated, then show the dialog box
  if (
    openPrintingDialogBoxInstructionFired && //To fix the bug of printing all games dialog opening when coming back from Children page
    gameDetailsForMultipleGames &&
    gameDetailsForMultipleGames.length > 0 &&
    gameDetailsForMultipleGames.every(g => g.dimensionRatio)
  ) {
    onOpenPrintingDialogBox(true);
  }

  async function fetchToken() {
    return await getTokenSilently();
  }

  const handlePrintAllGamesClick = () => {
    //Resolving promise
    fetchToken().then(token => {
      const allGameIds = gamesForClassroom && gamesForClassroom.map(game => game.id);
      onGetGameDetailsForMultipleGames(allGameIds, token);
    });

    onFireOpenPrintingDialogBoxInstruction(true);
  };

  const handleAddClick = () => {
    props.onAddGamesClick();
  };

  const handleVariationClick = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <PrintingDialogBox mode="multiple-pages" />

      <ActionBarSingleRow>
        <div className={classes.actionBarContainer}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Fab
                id="addGamesButton"
                onClick={handleAddClick}
                color="primary"
                variant="extended"
                aria-label="add"
                className={`${classes.addGamesFab} ${classes.button}`}
              >
                <AddIcon className={classes.extendedIcon} />
                Add Games
              </Fab>
            </Grid>
            <Grid item xs={2}>
              <Button
                id="allGamesPrintButton"
                onClick={handlePrintAllGamesClick}
                variant="outlined"
                aria-label="print"
                className={`${classes.button} ${classes.allGamesButton}`}
                size="medium"
                color="primary"
                disabled={isLoadingClassroom || isLoadingMedia || isLoadingImages}
              >
                <PrintIcon className={classes.extendedIcon} />
                All Games
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                id="variationsPrintButton"
                onClick={handleVariationClick}
                variant="outlined"
                aria-label="print"
                color="primary"
                size="medium"
                className={classes.button}
                disabled={isLoadingClassroom || isLoadingMedia || isLoadingImages}
              >
                <PrintIcon className={classes.extendedIcon} />
                Variations
              </Button>
            </Grid>
            <Grid item xs={6} className={`${classes.borderRed} ${classes.searchBoxContainer}`}>
              <SearchBox
                className={classes.searchBox}
                handleSearchTermChange={handleSearchTermChange}
                searchTerm={searchTerm}
                placeholder="Search Games"
              />
            </Grid>
          </Grid>
        </div>
      </ActionBarSingleRow>
    </React.Fragment>
  );
}

ClassroomDashboardActionBar.propTypes = {
  // describe the component's prop types here
};

ClassroomDashboardActionBar.defaultProps = {
  // assign default props to the component here
};

export default ClassroomDashboardActionBar;
