import * as client from './baseClient';
import axios from 'axios';

export const getMediaForClassroom = (gameIdsString, token) => {
  return client.get(`/api/media/for-classroom/${gameIdsString}`, token);
};

export const getActualImage = (mediaId, token) => {
  return client.getImage(`/api/media/${mediaId}/download`, token);
};

export const deleteMedias = (mediaIdsString, token) => {
  return client.get(`/api/media/deleteAttachments/${mediaIdsString}`, token);
};

export const addAttachmentsForGame = (gameId, attachments, token) => {
  const authAxious = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      Token: token
    }
  });
  for (let i = 0; i < attachments.length; i++) {
    try {
      const formData = new FormData();
      formData.append(attachments[i].name, attachments[i].fileData, attachments[i].name);
      authAxious
        .post(`/api/games/${gameId}/resources`, formData, { 'Content-Type': 'multipart/form-data' })
        .then(resp => {})
        .catch(err => {
          // Handle Error Here
          console.error(err);
        });
    } catch (ex) {}
  }
};
