import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import buttonNames from '../../../constants/buttonNames';

const useStyles = makeStyles(theme => ({
  selectButtonContainer: {
    marginTop: 20
  }
}));

const SelectButton = props => {
  const classes = useStyles();

  const { isLoadingGame, isLoadingGameImage, gameIsChecked, handleSelectGameButtonClick, gameId } = props;

  return (
    <div className={classes.selectButtonContainer}>
      <Button
        color="primary"
        variant={!gameIsChecked ? 'outlined' : 'contained'}
        fullWidth
        className={classes.selectButton}
        onClick={() => handleSelectGameButtonClick(gameId)}
        id="printGameButton"
        disabled={isLoadingGame || isLoadingGameImage}
      >
        <CheckIcon />
        &nbsp;
        {gameIsChecked ? buttonNames.SELECTED : buttonNames.SELECT_GAME}
      </Button>
    </div>
  );
};

export default SelectButton;
