import { handleActions } from 'redux-actions';

import { types } from '../constants';

const INITIAL_STATE = {
  message: {
    queue: [],
    visible: false
  },
  splashScreenShownOnce: false, //Splash screen should be shown only once in the beginning.
  lastVisitedPage: '',
  visibleTab: 0
};

function changeVisibleTabValue(state, { tabValue }) {
  return {
    ...state,
    visibleTab: tabValue
  };
}

function showMessage(
  state,
  {
    payload: {
      message: { text }
    }
  }
) {
  const queueCopy = state.message.queue.slice();
  queueCopy.push(text);

  if (state.message.visible) {
    return {
      ...state,
      message: {
        ...state.message,
        queue: queueCopy,
        visible: false
      }
    };
  } else {
    return {
      ...state,
      message: {
        ...state.message,
        queue: queueCopy,
        visible: true
      }
    };
  }
}

function hideMessage(state) {
  return {
    ...state,
    message: {
      ...state.message,
      visible: false
    }
  };
}

function nextMessage(state) {
  const newQueue = state.message.queue.slice(1);
  return {
    ...state,
    message: {
      ...state.message,
      queue: newQueue,
      visible: newQueue.length > 0
    }
  };
}

const splashScreenShowFinished = state => {
  return {
    ...state,
    splashScreenShownOnce: true
  };
};

const setLastVisitedPage = (state, { pageName }) => {
  return {
    ...state,
    lastVisitedPage: pageName
  };
};

export default handleActions(
  {
    [types.CHANGE_TAB_VALUE_IN_HEADER]: changeVisibleTabValue,

    [types.MESSAGE_SHOW]: showMessage,
    [types.MESSAGE_HIDE]: hideMessage,
    [types.MESSAGE_NEXT]: nextMessage,
    [types.SPLASH_SCREEN_SHOW_FINISHED]: splashScreenShowFinished,
    [types.SET_LAST_VISITED_URL]: setLastVisitedPage
  },
  INITIAL_STATE
);
