import { gameTypes } from '../constants';

const mapDevelopmentTypeIdToGameType = developmentTypeId => {
  switch (developmentTypeId) {
    case 1:
      return gameTypes.BEHAVIOURAL_TAG;
    case 2:
      return gameTypes.COGNITIVE_TAG;
    case 3:
      return gameTypes.EMOTIONAL_TAG;
    default:
      return;
  }
};

export default mapDevelopmentTypeIdToGameType;
