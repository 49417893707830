const ethnicity = () => [
  {
    name: 'NZ Māori',
    value: '0'
  },
  {
    name: 'Tokelauan',
    value: '1'
  },
  {
    name: 'Fijian',
    value: '2'
  },
  {
    name: 'Niuean',
    value: '3'
  },
  {
    name: 'Tongan',
    value: '4'
  },
  {
    name: 'Cook Island Māori',
    value: '5'
  },
  {
    name: 'Samoan',
    value: '6'
  },
  {
    name: 'Other Pacific Peoples',
    value: '7'
  },
  {
    name: 'Southeast Asian',
    value: '8'
  },
  {
    name: 'Indian',
    value: '9'
  },
  {
    name: 'Chinese',
    value: '10'
  },
  {
    name: 'Other Asian',
    value: '11'
  },
  {
    name: 'Middle Eastern',
    value: '12'
  },
  {
    name: 'Latin America',
    value: '13'
  },
  {
    name: 'African',
    value: '14'
  },
  {
    name: 'Other',
    value: '15'
  },
  {
    name: 'Other European',
    value: '16'
  },
  {
    name: 'European/Pākehā',
    value: '17'
  },
  {
    name: 'Not Stated',
    value: '18'
  }
];

export default ethnicity;
