// ChildrenDashboard/index.js
import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ChildrenList from '../../containers/ChildrenList';
import ChildrenViewPanel from '../../containers/ChildrenViewPanel';

const useStyles = makeStyles(theme => {
  return {
    gridRoot: {
      height: 'calc(100vh - 180px)'
    },
    gridItem: {
      height: '100%'
    },
    childrenList: {
      borderRight: `1px solid ${theme.palette.borderGray.default}`
    }
  };
});

const ChildrenDashboard = props => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container className={classes.gridRoot}>
        <Grid item xs={4} className={`${classes.gridItem} ${classes.childrenList}`}>
          <ChildrenList />
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <ChildrenViewPanel />
        </Grid>
      </Grid>
    </Paper>
  );
};

ChildrenDashboard.propTypes = {
  // describe the component's prop types here
};

ChildrenDashboard.defaultProps = {
  // assign default props to the component here
};

export default ChildrenDashboard;
