// ChildrenViewPanel/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChildrenViewPanel from '../../components/ChildrenViewPanel';
import {
  changeTabValueInChildViewPanel,
  setChild,
  openEditChildDialogBox,
  openChildReportDialog,
  loadChildReports,
  openTransferChildDialog,
  getGameDetailsForMultipleGames,
  getReportButtonsStatus,
  changeChildGameVariationForChild,
  getChildGameVariationsForChild,
  clearGameDetailsForMultipleGames,
  clearVariationsForChild,
  showDeactivateChildDialogStateChanged,
  clearAllReportStatuses
} from '../../actions';
import ConsolidateGames from '../../filters/ConsolidateGames';
import ChildGameVariationSelector from '../../utils/ChildGameVariationSelector';

function mapStateToProps(state, ownProps) {
  const childId = ownProps.match.params.childId ? Number(ownProps.match.params.childId) : -1;
  const allChildren = state.children.allChildren.items;

  const selectedChild = allChildren.filter(c => c.id === childId);

  //Consolidating all gamesForClassroom with gameDetailsForMultipleGames
  let gamesForClassroom = state.games.gamesForClassroom.items;
  const gameDetailsForMultipleGames =
    state.games.gameDetailsForMultipleGames && state.games.gameDetailsForMultipleGames.items;
  const childGameVariations = state.child.childGameVariations && state.child.childGameVariations.items;

  let consolidatedGames;
  if (gameDetailsForMultipleGames && gameDetailsForMultipleGames.length > 0 && childGameVariations) {
    consolidatedGames = ConsolidateGames(gamesForClassroom, gameDetailsForMultipleGames, childGameVariations, childId);
  }

  let finalGamesForClassroom = consolidatedGames ? consolidatedGames : gamesForClassroom;

  //Adds a flag to all games that have custom child variations.
  finalGamesForClassroom = ChildGameVariationSelector(finalGamesForClassroom, childGameVariations);

  return {
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    childId,
    child: selectedChild[0],
    isLoadingChild: state.child.child.isLoading,
    visibleTab: state.child.visibleTab,
    isLoadingReportForChart: state.child.childReport.reportForChart.isLoading,
    reportForChart: state.child.childReport.reportForChart && state.child.childReport.reportForChart.item,
    gamesForClassroom: finalGamesForClassroom,
    isLoadingGameDetails: state.games.gameDetailsForMultipleGames && state.games.gameDetailsForMultipleGames.isLoading,
    isLoadingSelectedVariationsForChild: state.child.childGameVariations.isLoading,
    reportButtonsStatus: state.child.childReport.reportStatus && state.child.childReport.reportStatus.item,
    isSavingChildGameVariation: state.child.childGameVariations.isSaving
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onTabValueChangeInChildViewPanel: tabValue => {
      dispatch(changeTabValueInChildViewPanel(tabValue));
    },
    onSetChild: child => {
      dispatch(setChild(child));
    },
    onEditChildClick: () => {
      dispatch(openEditChildDialogBox(true));
    },
    onTransferChildClick: () => {
      dispatch(openTransferChildDialog(true));
    },
    onDeativateChildClick: () => {
      dispatch(showDeactivateChildDialogStateChanged(true));
    },
    onCompleteChildReportButtonClick: reportTypeId => {
      dispatch(openChildReportDialog(true, reportTypeId));
      dispatch(clearAllReportStatuses());
    },
    onLoadChildReports: (childId, token) => {
      dispatch(loadChildReports(childId, token));
    },
    onGetGameDetailsForMultipleGames: (gameIds, token) => {
      dispatch(getGameDetailsForMultipleGames(gameIds, token));
    },
    onChildGameVariationChange: (payload, token) => {
      dispatch(changeChildGameVariationForChild(payload, token));
    },
    onGetReportButtonsStatus: (childId, token) => {
      dispatch(getReportButtonsStatus(childId, token));
    },
    onGetChildGameVariationsForChild: (childId, token) => {
      dispatch(getChildGameVariationsForChild(childId, token));
    },
    onClearGameDetailsForMultipleGames: () => {
      dispatch(clearGameDetailsForMultipleGames());
    },
    onClearChildGameVariationsForChild: () => {
      dispatch(clearVariationsForChild());
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChildrenViewPanel));
