import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: 'red'
  }
}));

const ErrorMessage = props => {
  const classes = useStyles();
  const { message } = props;

  return <span className={classes.errorMessage}>{message}</span>;
};

export default ErrorMessage;
