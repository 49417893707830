import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';

// import Cloud from '../../../res/Landing-page/Cloud.svg';
import DancingScarfs from '../../../res/Landing-page/DancingScarfs.png';
import CloudThree from '../../../res/Landing-page/Cloud03.png';
import Rainbow from '../../../res/Landing-page/Rainbow.svg';
import Cloud from '../../../res/Landing-page/Cloud.svg';
import TwoCloudsCombined from '../../../res/Landing-page/Cloud01.svg';
import Beading from '../../../res/Landing-page/Beading.png';
import RainDrop from '../RainDrop';

const useStyles = makeStyles(theme => ({
  bigContainer: {
    backgroundColor: theme.palette.backgroundGray.default,
    display: 'relative'
    // height: 'calc(100vh * 2)'
  },
  container: {
    width: '100%',
    fontFamily: 'Roboto-Regular',
    height: '100%',
    position: 'relative'
  },
  topCloud: {
    height: 800,
    width: '100%',
    position: 'absolute',
    top: 0,
    backgroundImage: `url(${CloudThree})`,
    backgroundSize: '130% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top -559px left -237px',
    zIndex: 1,
    '@media (min-width: 1540px)': {
      height: 1150
    }
  },
  content: {
    height: '100vh',
    width: 1200,
    margin: '0 auto'
  },
  gridContainer: {
    position: 'relative',
    zIndex: 2
  },
  gridItem: {
    width: '100%'
  },
  leftPanel: {
    height: 350,
    marginTop: 275,
    backgroundImage: `url(${DancingScarfs})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'left',
    position: 'relative',
    left: 0,
    zIndex: 3,
    '@media (min-width: 1540px)': {
      left: -90,
      top: 200
    }
  },
  rainbow: {
    height: 450,
    width: 300,
    backgroundImage: `url(${Rainbow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 230,
    left: 75,
    '@media (min-width: 1540px)': {
      left: '215px !important',
      top: '424px !important'
    }
  },
  bottomCloud: {
    height: 820,
    width: '100%',
    position: 'absolute',
    top: 'calc(100vh - 220px)',
    backgroundImage: `url(${Cloud})`,
    backgroundSize: '130% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top'
  },
  twoCloudsCombined: {
    height: 280,
    width: 600,
    backgroundImage: `url(${TwoCloudsCombined})`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 40,
    left: 35,
    zIndex: 1,
    '@media (min-width: 1540px)': {
      left: '110px !important',
      top: '20px !important'
    }
  },

  //RightPanel
  rightPanel: {
    marginTop: 100,
    '@media (min-width: 1540px)': {
      marginTop: 270
    }
  },
  title: {
    fontSize: 50,
    color: '#000',
    marginBottom: 20,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 30,
    marginBottom: 40,
    fontWeight: 600
  },
  text: {
    fontSize: 16,
    marginBottom: 20,
    fontWeight: 400
  },
  contactUsButton: {
    width: 300,
    fontSize: 12,
    marginTop: 20
  },
  registerButton: {
    width: 160,
    fontSize: 12,
    marginTop: 20
  },
  gettingStartedContainer: {
    width: '100%',
    position: 'relative',
    top: 220,
    height: 600
    // backgroundColor: '#B0D4FF',
    // border: '2px solid #B0D4FF'
  },
  gettingStartedContent: {
    height: '100%',
    width: 1200,
    margin: '0 auto'
  },
  gettingStartedGridContainer: {
    height: 600
  },
  gettingStartedRightPanel: {
    // height: 350,
    backgroundImage: `url(${Beading})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: '80%'
  },
  blueBlock: {
    height: 240,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#B0D4FF',
    zIndex: -1
  },
  gettingStartedGridItem: {},
  gettingStartedTitle: {
    color: theme.palette.primary.main,
    fontSize: 50,
    marginBottom: 10,
    fontWeight: 600
  },
  gettingStartedList: {
    fontSize: 16
  },

  rainDropsContainer: {
    height: 170,
    width: '100%',
    position: 'relative',
    top: -60,
    zIndex: -1,
    overflow: 'hidden',
    backgroundColor: '#B0D4FF'
  },

  whiteRainDrop: {
    position: 'absolute'
  },
  whiteRaindropOne: {
    left: '20%',
    top: 40
  },
  whiteRaindropTwo: {
    left: '25%'
  },
  whiteRaindropThree: {
    left: '50%',
    top: 40
  },
  whiteRaindropFour: {
    left: '90%',
    top: 40
  }
}));

const SectionResearch = props => {
  const classes = useStyles();
  const { navigateToRegisterSection } = props;
  return (
    <React.Fragment>
      <div className={classes.bigContainer}>
        <div className={classes.container}>
          <div className={classes.topCloud}></div>
          <div className={classes.content}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid className={`${classes.gridItem} ${classes.leftPanel}`} item xs={6}></Grid>
              <Grid className={`${classes.gridItem} ${classes.rightPanel}`} item xs={6}>
                <Typography variant="h1" className={classes.title}>
                  ENGAGE
                </Typography>
                <Typography variant="h2" className={classes.subTitle}>
                  is backed by research
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Delivering ENGAGE involves playing a selection of familiar children’s games for up to 30 minutes a
                  day, to develop the emotional, cognitive and behavioural skills that children need to thrive at school
                  and lead healthy, fulfilling lives.
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  ENGAGE was developed by Dr Dione Healey (University of Otago) and is delivered in ECE and school
                  environments in partnership with Methodist Mission Southern – with support from the Tindall
                  Foundation.
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  In research trials with a diverse range of whānau, ECE and primary school participants, ENGAGE has
                  been consistently demonstrated to support statistically significant improvements in children’s
                  self-regulation development, including reductions in hyperactivity, aggression and peer problems, and
                  improvements in attention, effortful control and emotional regulation.
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  ENGAGE is also being used in a large scale research partnership with Best Start Educare, The Wright
                  Family Foundation and Emotional Regulation Aotearoa New Zealand (ERANZ), focussed on supporting oral
                  language and self-regulation for New Zealand children.
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  The ENGAGE PlayTools website is designed for ECE kaiako and primary school teachers in New Zealand.
                  The website currently contains 36 games and over 200 game variations that can be played in large or
                  small groups by ECE and primary school aged children, plus additional learning support tools, video
                  guides, and resources to help achieve positive long-term self-regulation outcomes for tamariki.
                </Typography>

                <Button
                  id="contactUsButton"
                  className={classes.contactUsButton}
                  color="primary"
                  variant="contained"
                  onClick={e => navigateToRegisterSection(e)}
                >
                  Contact Us to learn more
                </Button>
              </Grid>
            </Grid>
            <div className={classes.rainbow}></div>
          </div>
          <div className={classes.bottomCloud}>
            <div className={classes.twoCloudsCombined}></div>
          </div>
        </div>
        <div className={classes.gettingStartedContainer}>
          <div className={classes.gettingStartedContent}>
            <Grid container spacing={0} className={classes.gettingStartedGridContainer}>
              <Grid item xs={6} className={`${classes.gettingStartedGridItem} ${classes.gettingStartedLeftPanel}`}>
                <Typography variant="h1" className={classes.gettingStartedTitle}>
                  ENGAGE...
                </Typography>
                <Typography variant="h2" className={classes.subTitle}>
                  getting started
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  ENGAGE is delivered by a team of trained facilitators, and includes:
                </Typography>
                <ul className={classes.gettingStartedList}>
                  <li>
                    Interactive training workshops for teachers to customise ENGAGE for each learning environment and
                    support self-regulation and classroom behaviour
                  </li>
                  <li>Access to ENGAGE games and resources (including website resources)</li>
                  <li>Classroom coaching visits and ongoing support from ENGAGE facilitators</li>
                  <li>
                    Ongoing professional development and train-the-trainer opportunities and networking with other ECEs
                    and schools delivering ENGAGE
                  </li>
                </ul>
                <Typography variant="body1" className={classes.text}>
                  To get started with ENGAGE at your early childhood centre or school, or to find out more information -
                  please enquire below
                </Typography>
                <Button
                  id="registerButton"
                  className={classes.registerButton}
                  color="primary"
                  variant="contained"
                  onClick={e => navigateToRegisterSection(e)}
                >
                  Enquire
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                className={`${classes.gettingStartedGridItem} ${classes.gettingStartedRightPanel}`}
              ></Grid>
            </Grid>
          </div>
          <div className={classes.blueBlock}></div>
          <div className={classes.rainDropsContainer}>
            <div className={`${classes.whiteRainDrop} ${classes.whiteRaindropOne}`}>
              <RainDrop color="white" size="medium" />
            </div>
            <div className={`${classes.whiteRainDrop} ${classes.whiteRaindropTwo}`}>
              <RainDrop color="white" size="small" />
            </div>
            <div className={`${classes.whiteRainDrop} ${classes.whiteRaindropThree}`}>
              <RainDrop color="white" size="large" />
            </div>
            <div className={`${classes.whiteRainDrop} ${classes.whiteRaindropFour}`}>
              <RainDrop color="white" size="small" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionResearch;
