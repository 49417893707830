import * as client from './baseClient';

export const getChild = (id, token) => client.get(`/api/children/${id}`, token);
export const getAllChildren = (classroomId, token) => {
  return client.get(`/api/children/${classroomId}`, token);
};

export const createChild = (child, token) => client.post(`/api/children`, child, token);
export const updateChild = (child, token) => client.put(`/api/children/${child.id}`, child, token);

export const getQuestionsForChild = (childReportTemplateType, token) => {
  return client.get(`/api/children/form-template/${childReportTemplateType}`, token); //TODO pass dynamic formTemplateId
};

export const postFormAnswer = (response, token) => client.post('/api/children/form-answer', response, token);

export const loadChildReport = (childId, token) => client.get(`/api/children/form-score/${childId}?top=12`, token);

export const transferChild = (child, token) => client.put(`/api/children/${child.id}`, child, token);

export const deactivateChild = (child, token) => client.put(`/api/children/${child.id}`, child, token);

export const getReportButtonsStatus = (childId, token) =>
  client.get(`/api/children/${childId}/report-complete-status`, token);

export const getChildGameVariationsForChild = (childId, token) =>
  client.get(`/api/children/${childId}/game-variation`, token);

export const changeChildGameVariationForChild = (payload, token) =>
  client.post(`/api/children/game-variation`, payload, token);

export const getReportStatusesForChildren = (classroomId, token) =>
  client.get(`/api/children/report-status/${classroomId}`, token);
