// GameDetails/index.js
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import { useAuth0 } from '../Auth';
import mapVariationsToDropDownFriendlyFormat from '../../filters/mapVariationsToDropDownFriendlyFormat';
import mapToGameClassroomVariationPayload from '../../filters/mapToChangeGameClassroomVariationPayload';
import { buttonNames } from '../../constants';
import LeftBar from './LeftBar';
import RightPane from './RightPane';
import './style.css';
import PrintingDialogBox from '../../containers/PrintingDialogBox';
// import FeedbackDialog from '../../containers/FeedbackDialog';

const styles = theme => {
  return {
    container: {
      height: '100vh'
    },

    rightPane: {
      zIndex: 100,
      background: theme.palette.backgroundGray.default,
      position: 'relative',
      '@media print': {
        background: 'none',
        size: 'auto',
        margin: '0mm'
      }
    },
    leftBar: {
      // '@media (max-width: 800px)': {
      '@media print': {
        display: 'none'
      }
    }
  };
};

const GameDetails = props => {
  const { getTokenSilently } = useAuth0();

  const {
    gameId,
    classes,
    onBackArrowClick,
    selectedVariationId,
    isLoadingVariationForTheGame,
    game,
    gameAttachments,
    isLoadingGame,
    isSavingVariationIdChange,
    open,
    showRemoveGameDialog,
    dbVersionOfVariationForTheGame,
    isSavingRemoveGame,
    errorMessage,
    gameImage,
    isLoadingGameImage,
    showOnlySelectStatus,
    gameIsChecked,
    handleSelectGameButtonClick,
    onOpenPrintingDialogBox,
    classroomId,
    gameRating,
    isLoadingOrSavingRateGame
  } = props;

  let mappedVariations;
  if (game.gameVariations && game.gameVariations.length > 0) {
    mappedVariations = mapVariationsToDropDownFriendlyFormat(game.gameVariations);
  }

  async function fetchToken() {
    return await getTokenSilently();
  }

  useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      props.onGetGameDetails(gameId, classroomId, token);
      props.onClearExistingGameRating();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGameVariationChange = async e => {
    const changedToVariationId = e.target.value;
    const { dbVersionOfVariationForTheGame, onGameVariationChange } = props;
    const payload = mapToGameClassroomVariationPayload(changedToVariationId, dbVersionOfVariationForTheGame);
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      onGameVariationChange(payload, token);
    });
  };

  const handleButtonClick = buttonType => {
    if (buttonType === buttonNames.REMOVE_GAME) {
      const { showRemoveGameDialog } = props;
      showRemoveGameDialog(true);
    } else if (buttonType === buttonNames.PRINT_GAME) {
      onOpenPrintingDialogBox(true);
    }
  };

  const handleConfirmRemove = () => {
    fetchToken().then(token => {
      props.onConfirmRemoveGame(dbVersionOfVariationForTheGame.id, token);
    });
  };

  const handleRatingClick = rating => {
    //Resolving promise
    fetchToken().then(token => {
      const payload = { classroomId, gameId, rating };

      //If gameRating already exists, then it would be an update request,
      //  else it would be a create request.
      if (!gameRating) {
        props.onSubmitRating(payload, token);
      } else {
        const existingRating = gameRating;
        existingRating.rating = rating;

        props.onUpdateGameRating(existingRating, token);
      }
    });
  };

  const getRatingForGame = () => {
    //Resolving promise
    fetchToken().then(token => {
      const payload = { gameId, classroomId };

      props.onGetGameRating(payload, token);
    });
  };

  return (
    <React.Fragment>
      <PrintingDialogBox mode="single-page" />
      {/* <FeedbackDialog /> */}

      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={3} id="leftBar" className={classes.leftBar}>
          <LeftBar
            handleButtonClick={handleButtonClick}
            handleConfirmRemove={handleConfirmRemove}
            onBackArrowClick={onBackArrowClick}
            game={game}
            gameAttachments={gameAttachments}
            isLoadingGame={isLoadingGame}
            isLoadingGameImage={isLoadingGameImage}
            isLoadingVariationForTheGame={isLoadingVariationForTheGame}
            selectedVariationId={selectedVariationId}
            isSavingVariationIdChange={isSavingVariationIdChange}
            open={open}
            showRemoveGameDialog={showRemoveGameDialog}
            isSavingRemoveGame={isSavingRemoveGame}
            errorMessage={errorMessage}
            mappedVariations={mappedVariations}
            handleGameVariationChange={handleGameVariationChange}
            showOnlySelectStatus={showOnlySelectStatus}
            gameIsChecked={gameIsChecked}
            handleSelectGameButtonClick={handleSelectGameButtonClick}
            handleRatingClick={handleRatingClick}
            gameRating={gameRating}
            getRatingForGame={getRatingForGame}
            isLoadingOrSavingRateGame={isLoadingOrSavingRateGame}
          />
        </Grid>

        <Grid item xs={9} className={classes.rightPane} id="rightPane">
          <RightPane
            gameId={gameId}
            game={game}
            gameImage={gameImage}
            isLoadingGame={isLoadingGame}
            isLoadingGameImage={isLoadingGameImage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

GameDetails.propTypes = {
  // describe the component's prop types here
};

GameDetails.defaultProps = {
  // assign default props to the component here
};

export default withStyles(styles)(GameDetails);
