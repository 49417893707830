import { handleActions } from 'redux-actions';

import { types, childStatus } from '../constants';
import { inputFieldNames } from '../constants';

const INITIAL_STATE = {
  showEditChildDialog: false,
  child: {
    item: null,
    isLoading: false,
    errorMessage: '',
    searchTerm: ''
  },
  visibleTab: 0,
  newChild: {
    showDialog: false,
    item: {
      firstName: '',
      lastName: '',
      preferredName: null,
      dob: null,
      gender: '',
      ethnicity: '',
      childStatus: childStatus.ACTIVE
    },
    isLoading: false,
    errorMessage: null
  },
  childReport: {
    currentReportTypeId: null,
    showChildReportDialog: false,
    report: {
      item: null,
      isLoading: false,
      errorMessage: null
    },
    response: {
      isSaving: false,
      errorMessage: null
    },
    reportForChart: {
      item: null,
      isLoading: false,
      errorMessage: null
    },
    reportStatus: {
      isLoading: false,
      item: null,
      errorMessage: ''
    }
  },
  stepper: {
    activeStep: 0,
    completedSteps: []
  },
  childTransfer: {
    showTransferChildDialog: false,
    transferChild: {
      item: {
        transferEmail: ''
      },
      isLoading: false,
      errorMessage: null
    }
  },
  childGameVariations: {
    items: [],
    isLoading: false,
    isSaving: false,
    errorMessage: null
  },
  deactiveChild: {
    open: false,
    isSaving: false,
    item: {},
    isLoading: false,
    errorMessage: ''
  }
};

const startLoadChildReport = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportForChart: {
        ...state.childReport.reportForChart,
        isLoading: true
      }
    }
  };
};
const loadChildReportSuccess = (state, { reportForChart }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportForChart: {
        ...state.childReport.reportForChart,
        item: reportForChart
      }
    }
  };
};
const loadChildReportFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportForChart: {
        ...state.childReport.reportForChart,
        errorMessage
      }
    }
  };
};
const loadChildReportFinished = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportForChart: {
        ...state.childReport.reportForChart,
        isLoading: false
      }
    }
  };
};

const changeTabValueInChildViewPanel = (state, { tabValue }) => {
  return {
    ...state,
    visibleTab: tabValue
  };
};

const openAddNewChildDialog = (state, { open }) => {
  return {
    ...state,
    newChild: {
      ...state.newChild,
      showDialog: open,
      item: {
        firstName: open === true ? '' : state.newChild.item.firstName,
        lastName: open === true ? '' : state.newChild.item.lastName,
        preferredName: open === true ? null : state.newChild.item.preferredName,
        dob: open === true ? null : state.newChild.item.dob,
        gender: open === true ? '' : state.newChild.item.gender,
        ethnicity: open === true ? '' : state.newChild.item.ethnicity,
        childStatus: open === true ? childStatus.ACTIVE : state.newChild.item.childStatus
      }
    }
  };
};

const changeAddNewChildInputField = (state, { value, fieldName }) => {
  let child = {};

  if (fieldName === inputFieldNames.FIRST_NAME) {
    child = { ...state.newChild.item, firstName: value };
  } else if (fieldName === inputFieldNames.LAST_NAME) {
    child = { ...state.newChild.item, lastName: value };
  } else if (fieldName === inputFieldNames.PREFERRED_NAME) {
    child = { ...state.newChild.item, preferredName: value };
  } else if (fieldName === inputFieldNames.DOB) {
    child = { ...state.newChild.item, dob: value };
  } else if (fieldName === inputFieldNames.GENDER) {
    child = { ...state.newChild.item, gender: value };
  } else if (fieldName === inputFieldNames.ETHNICITY) {
    child = { ...state.newChild.item, ethnicity: value };
  }

  return {
    ...state,
    newChild: {
      ...state.newChild,
      item: child
    }
  };
};

const changeUpdateChildInputField = (state, { value, fieldName }) => {
  let child = {};

  if (fieldName === inputFieldNames.PREFERRED_NAME) {
    child = { ...state.child.item, preferredName: value };
  } else if (fieldName === inputFieldNames.DOB) {
    child = { ...state.child.item, dob: value };
  } else if (fieldName === inputFieldNames.GENDER) {
    child = { ...state.child.item, gender: value };
  } else if (fieldName === inputFieldNames.ETHNICITY) {
    child = { ...state.child.item, ethnicity: value };
  }

  return {
    ...state,
    child: {
      ...state.child,
      item: child
    }
  };
};

const startCreateNewChild = state => {
  return {
    ...state,
    newChild: {
      ...state.newChild,
      isLoading: true
    }
  };
};

const createNewChildSuccess = (state, { newlyCreatedChild }) => {
  return {
    ...state,
    newChild: {
      ...state.newChild,
      item: newlyCreatedChild
    }
  };
};

const createNewChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    newChild: {
      ...state.newChild,
      errorMessage
    }
  };
};

const createNewChildFinished = state => {
  return {
    ...state,
    newChild: {
      ...state.newChild,
      isLoading: false
    }
  };
};

const setChild = (state, { child }) => {
  return {
    ...state,
    child: {
      ...state.child,
      item: child
    }
  };
};

const openEditChildDialog = (state, { open }) => {
  return {
    ...state,
    showEditChildDialog: open
  };
};

const startUpdateChild = state => {
  return {
    ...state,
    child: {
      ...state.child,
      isLoading: true
    }
  };
};

const updateChildSuccess = (state, { editedChild }) => {
  return {
    ...state,
    child: {
      ...state.child,
      item: editedChild
    }
  };
};

const updateChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    child: {
      ...state.child,
      errorMessage
    }
  };
};

const updateChildFinished = state => {
  return {
    ...state,
    child: {
      ...state.child,
      isLoading: false
    }
  };
};

const openChildReportDialog = (state, { open, reportTypeId }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      showChildReportDialog: open,
      report: {
        ...state.childReport.report,
        item: null
      },
      currentReportTypeId: reportTypeId
    },
    stepper: {
      ...state.stepper,
      activeStep: 0,
      completedSteps: []
    }
  };
};

const startGetQuestionsForChild = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      report: {
        ...state.childReport.report,
        isLoading: true
      }
    }
  };
};

const getQuestionsForChildSuccess = (state, { report }) => {
  const questionsWithSelectedId = report.questions.map(question => {
    question.selectedId = -1;
    return question;
  });
  report.questions = questionsWithSelectedId;

  return {
    ...state,
    childReport: {
      ...state.childReport,
      report: {
        ...state.childReport.report,
        item: report
      }
    }
  };
};

const getQuestionsForChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      report: {
        ...state.childReport.report,
        errorMessage
      }
    }
  };
};
const getQuestionsForChildFinished = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      report: {
        ...state.childReport.report,
        isLoading: false
      }
    }
  };
};

const changeSelectedAnswerForQuestion = (state, { selectedAnswerId, questionId }) => {
  const updatedQuestions = state.childReport.report.item.questions.map(question => {
    if (question.questionId === questionId) {
      question.selectedId = selectedAnswerId;
    }
    return question;
  });

  return {
    ...state,
    childReport: {
      ...state.childReport,
      report: {
        ...state.childReport.report,
        item: {
          ...state.childReport.report.item,
          questions: updatedQuestions
        }
      }
    }
  };
};

const startSubmitChildReportAnswers = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      response: {
        ...state.childReport.response,
        isSaving: true
      }
    }
  };
};

const submitChildReportAnswersSuccess = state => {
  return {
    ...state
  };
};

const submitChildReportAnswersFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      response: {
        ...state.childReport.response,
        errorMessage
      }
    }
  };
};

const submitChildReportAnswersFinished = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      response: {
        ...state.childReport.response,
        isSaving: false
      }
    }
  };
};

const openTransferChildDialog = (state, { open }) => {
  return {
    ...state,
    childTransfer: {
      ...state.childTransfer,
      showTransferChildDialog: open,
      transferChild: {
        ...state.childTransfer.transferChild,
        item:
          open === true
            ? { ...state.child.item, transferEmail: state.childTransfer.transferChild.item.transferEmail }
            : { ...state.child.item, transferEmail: '' } //once dialog close clearing transferEmail value
      }
    }
  };
};

const changeTransferEmailInputField = (state, { value }) => {
  let child = { ...state.childTransfer.transferChild.item, transferEmail: value };
  return {
    ...state,
    childTransfer: {
      ...state.childTransfer,
      transferChild: {
        ...state.childTransfer.transferChild,
        item: child
      }
    }
  };
};

const startTransferChild = state => {
  return {
    ...state,
    childTransfer: {
      ...state.childTransfer,
      transferChild: {
        ...state.childTransfer.transferChild,
        isLoading: true
      }
    }
  };
};

const transferChildSuccess = (state, { transferedChild }) => {
  return {
    ...state,
    child: {
      ...state.child,
      item: null
    },
    childTransfer: {
      ...state.childTransfer,
      transferChild: {
        ...state.childTransfer.transferChild,
        item: transferedChild
      }
    }
  };
};

const transferChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childTransfer: {
      ...state.childTransfer,
      transferChild: {
        ...state.childTransfer.transferChild,
        errorMessage
      }
    }
  };
};

const transferChildFinished = state => {
  return {
    ...state,
    child: {
      ...state.child,
      isLoading: false
    }
  };
};

const startGetReportButtonsStatus = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportStatus: {
        ...state.childReport.reportStatus,
        isLoading: true
      }
    }
  };
};

const getReportButtonsStatusSuccess = (state, { status }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportStatus: {
        ...state.childReport.reportStatus,
        item: status
      }
    }
  };
};

const getReportButtonsStatusFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportStatus: {
        ...state.childReport.reportStatus,
        errorMessage
      }
    }
  };
};

const getReportButtonsStatusFinished = state => {
  return {
    ...state,
    childReport: {
      ...state.childReport,
      reportStatus: {
        ...state.childReport.reportStatus,
        isLoading: false
      }
    }
  };
};

const startGetChildGameVariationsForChild = state => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      isLoading: true
    }
  };
};

const getChildGameVariationsForChildSuccess = (state, { gameVariations }) => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      items: gameVariations
    }
  };
};

const getChildGameVariationsForChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      errorMessage
    }
  };
};

const getChildGameVariationsForChildFinished = state => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      isLoading: false
    }
  };
};

const clearChildGameVariationsForChild = state => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      items: []
    }
  };
};

function showDeactiveChildDialog(state, { open }) {
  return {
    ...state,
    deactiveChild: {
      ...state.deactiveChild,
      open,
      errorMessage: '',
      item: { ...state.child.item },
      isSaving: false
    }
  };
}

function startDeactiveChild(state) {
  return {
    ...state,
    deactiveChild: {
      ...state.deactiveChild,
      isSaving: true
    }
  };
}

function deactiveChildSuccess(state, { deactivatedChild }) {
  return {
    ...state,
    child: {
      ...state.child,
      item: null
    },
    deactiveChild: {
      ...state.deactiveChild,
      item: deactivatedChild
    }
  };
}

function deactiveChildFailure(state, { errorMessage }) {
  return {
    ...state,
    deactiveChild: {
      ...state.deactiveChild,
      errorMessage
    }
  };
}

function deactiveChildFinished(state) {
  return {
    ...state,
    deactiveChild: {
      ...state.deactiveChild,
      isSaving: false
    }
  };
}
const startChangeDefaultVariationForChild = state => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      isSaving: true
    }
  };
};

const changeDefaultVariationForChildSuccess = (state, { updatedGameVariation, mainGroupSelected }) => {
  let childGameVariations = state.childGameVariations.items;

  //Finding if there is a game childGameVariation already existing for child-game duo.
  const indexOfFoundVariation = childGameVariations.findIndex(
    v => v.childId === updatedGameVariation.childId && v.gameId === updatedGameVariation.gameId
  );

  if (mainGroupSelected === true) {
    if (indexOfFoundVariation !== -1) {
      childGameVariations.splice(indexOfFoundVariation, 1);
    }
  } else {
    //If it does not exist, then insert updatedGameVariation in the list, else update the existing item.
    if (indexOfFoundVariation === -1) {
      childGameVariations = [...childGameVariations, updatedGameVariation];
    } else {
      childGameVariations[indexOfFoundVariation] = updatedGameVariation;
    }
  }

  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      items: childGameVariations
    }
  };
};

const changeDefaultVariationForChildFailure = (state, { errorMessage }) => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      errorMessage
    }
  };
};

const changeDefaultVariationForChildFinished = state => {
  return {
    ...state,
    childGameVariations: {
      ...state.childGameVariations,
      isSaving: false
    }
  };
};

const setActiveStep = (state, { activeStep }) => {
  return {
    ...state,
    stepper: {
      ...state.stepper,
      activeStep
    }
  };
};

const setCompletedForIndex = (state, { index }) => {
  return {
    ...state,
    stepper: {
      ...state.stepper,
      completedSteps: [...state.stepper.completedSteps, index]
    }
  };
};

const clearFieldsRelatedToStepper = state => {
  return {
    ...state,
    stepper: {
      ...state.stepper,
      activeStep: 0,
      completedSteps: []
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.CHANGE_TAB_VALUE_IN_CHILDVIEWPANEL]: changeTabValueInChildViewPanel,
    [types.OPEN_ADD_NEW_CHILD_DIALOG]: openAddNewChildDialog,
    [types.CHANGE_ADD_NEW_CHILD_INPUT_FIELD]: changeAddNewChildInputField,
    [types.CHANGE_UPDATE_CHILD_INPUT_FIELD]: changeUpdateChildInputField,

    [types.START_CREATE_NEW_CHILD]: startCreateNewChild,
    [types.CREATE_NEW_CHILD_SUCCESS]: createNewChildSuccess,
    [types.CREATE_NEW_CHILD_FAILURE]: createNewChildFailure,
    [types.CREATE_NEW_CHILD_FINISHED]: createNewChildFinished,

    [types.SET_CHILD]: setChild,

    [types.OPEN_EDIT_CHILD_DIALOG]: openEditChildDialog,

    [types.START_UPDATE_CHILD]: startUpdateChild,
    [types.UPDATE_CHILD_SUCCESS]: updateChildSuccess,
    [types.UPDATE_CHILD_FAILURE]: updateChildFailure,
    [types.UPDATE_CHILD_FINISHED]: updateChildFinished,

    [types.OPEN_CHILD_REPORT_DIALOG]: openChildReportDialog,

    [types.START_GET_QUESTIONS_FOR_CHILD]: startGetQuestionsForChild,
    [types.GET_QUESTIONS_FOR_CHILD_SUCCESS]: getQuestionsForChildSuccess,
    [types.GET_QUESTIONS_FOR_CHILD_FAILURE]: getQuestionsForChildFailure,
    [types.GET_QUESTIONS_FOR_CHILD_FINISHED]: getQuestionsForChildFinished,

    [types.CHANGE_SELECTED_ANSWER_FOR_ANSWER]: changeSelectedAnswerForQuestion,

    [types.START_SUBMIT_CHILD_REPORT_ANSWERS]: startSubmitChildReportAnswers,
    [types.SUBMIT_CHILD_REPORT_ANSWERS_SUCCESS]: submitChildReportAnswersSuccess,
    [types.SUBMIT_CHILD_REPORT_ANSWERS_FAILURE]: submitChildReportAnswersFailure,
    [types.SUBMIT_CHILD_REPORT_ANSWERS_FINISHED]: submitChildReportAnswersFinished,

    [types.START_LOAD_CHILD_REPORT]: startLoadChildReport,
    [types.LOAD_CHILD_REPORT_SUCCESS]: loadChildReportSuccess,
    [types.LOAD_CHILD_REPORT_FAILURE]: loadChildReportFailure,
    [types.LOAD_CHILD_REPORT_FINISHED]: loadChildReportFinished,

    [types.OPEN_TRANSFER_CHILD_DIALOG]: openTransferChildDialog,
    [types.CHANGE_TRANSFER_CHILD_EMAIL_INPUT_FIELD]: changeTransferEmailInputField,

    [types.START_TRANSFER_CHILD]: startTransferChild,
    [types.TRANSFER_CHILD_SUCCESS]: transferChildSuccess,
    [types.TRANSFER_CHILD_FAILURE]: transferChildFailure,
    [types.TRANSFER_CHILD_FINISHED]: transferChildFinished,

    [types.START_GET_REPORT_BUTTONS_STATUS]: startGetReportButtonsStatus,
    [types.GET_REPORT_BUTTONS_STATUS_SUCCESS]: getReportButtonsStatusSuccess,
    [types.GET_REPORT_BUTTONS_STATUS_FAILURE]: getReportButtonsStatusFailure,
    [types.GET_REPORT_BUTTONS_STATUS_FINISHED]: getReportButtonsStatusFinished,

    [types.SHOW_DEACTIVATE_CHILD_DIALOG]: showDeactiveChildDialog,

    [types.START_DEACTIVATE_CHILD]: startDeactiveChild,
    [types.DEACTIVATE_CHILD_SUCCESS]: deactiveChildSuccess,
    [types.DEACTIVATE_CHILD_FAILURE]: deactiveChildFailure,
    [types.DEACTIVATE_CHILD_FINISHED]: deactiveChildFinished,
    [types.START_GET_CHILD_GAME_VARIATIONS_FOR_CHILD]: startGetChildGameVariationsForChild,
    [types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_SUCCESS]: getChildGameVariationsForChildSuccess,
    [types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FAILURE]: getChildGameVariationsForChildFailure,
    [types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FINISHED]: getChildGameVariationsForChildFinished,
    [types.CLEAR_CHILD_GAME_VARIATIONS_FOR_CHILD]: clearChildGameVariationsForChild,

    [types.START_CHANGE_CHILD_GAME_VARIATION_FOR_CHILD]: startChangeDefaultVariationForChild,
    [types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_SUCCESS]: changeDefaultVariationForChildSuccess,
    [types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FAILURE]: changeDefaultVariationForChildFailure,
    [types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FINISHED]: changeDefaultVariationForChildFinished,

    [types.SET_ACTIVE_STEP]: setActiveStep,
    [types.SET_COMPLETED_FOR_INDEX]: setCompletedForIndex,
    [types.CLEAR_FIELDS_RELATED_TO_STEPPER]: clearFieldsRelatedToStepper
  },
  INITIAL_STATE
);
