import React from 'react';
import { Typography, Grid, Paper, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import DropDown from '../../DropDown';
import mapDevelopmentTypeIdToVariationTypeAndColor from '../../../filters/mapDevelopmentTypeIdToVariationTypeAndColor';
import mapVariationsToDropDownFriendlyFormat from '../../../filters/mapVariationsToDropDownFriendlyFormat';
import AddMainGroupPrefixInAppropriateGameVariation from '../../../filters/AddMainGroupPrefixInAppropriateGameVariation';

const useStyles = makeStyles(theme => ({
  singleGameContainer: {
    padding: 16
  },
  gridRoot: {
    marginBottom: 10,
    marginTop: 20
  },
  gameImage: {
    height: 135,
    width: 240,
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !important'
  },
  textContainer: {
    display: 'inline-block',
    marginLeft: 15
  },
  content: {},
  variationName: {
    fontSize: 20,
    fontWeight: 600
  },
  variationContainer: {},
  variationLabel: {
    display: 'inline-block',
    marginBottom: 8
  },
  variationDescription: {
    marginTop: 10
  },
  chip: {
    color: 'white !important',
    paddingTop: 2,
    fontWeight: 600,
    fontSize: 10,
    height: 22,
    marginTop: 4
  },

  highlight: {
    background: '#E6F5EC',
    borderLeft: `2px solid ${theme.palette.primary.main}`
  }
}));

const SingleVariationDetailInChildViewPanel = props => {
  const classes = useStyles();
  const {
    game,
    handleChildGameVariationChange,
    isSavingChildGameVariation,
    isLoadingSelectedVariationsForChild,
    classroomId
  } = props;

  const gameType = mapDevelopmentTypeIdToVariationTypeAndColor(game.developmentTypeId);
  let gameVariations;

  if (game.gameVariations) {
    gameVariations = mapVariationsToDropDownFriendlyFormat(game.gameVariations);
    gameVariations = AddMainGroupPrefixInAppropriateGameVariation(gameVariations, game.gameVariation);
    gameVariations.sort((a, b) => (a.prefix > b.prefix ? 1 : -1));
  }

  return (
    <React.Fragment>
      <div className={`${classes.singleGameContainer} ${game.hasCustomChildVariation ? classes.highlight : ''}`}>
        <Grid container className={`${classes.gridRoot}`}>
          <Grid item xs={4} className={classes.gridItem}>
            <Paper className={classes.gameImage} style={{ background: `url(${game.image})` }}></Paper>
          </Grid>

          <Grid item xs={8} className={classes.gridItem}>
            <div className={classes.textContainer}>
              <div className={classes.content}>
                <Typography variant="h4" className={classes.variationName}>
                  {game.name}
                </Typography>
                <Chip label={gameType.type} className={classes.chip} style={{ background: gameType.color }} />
                <Typography variant="body1" className={classes.variationDescription}>
                  {game.description}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.variationContainer}>
          <Typography className={classes.variationLabel} variant="caption">
            Personal variations
          </Typography>
          {gameVariations && (
            <DropDown
              handleChange={e => {
                const mainGroupVariationId = game.gameVariation.id;
                handleChildGameVariationChange(e, game.id, classroomId, mainGroupVariationId);
              }}
              items={gameVariations}
              //If there is a childGameVariation for a child/game saved int he database, then show it,
              //  otherwise show the default chosen game variation id for the game (coming from home page).
              value={game.childGameVariationIdFromDb ? game.childGameVariationIdFromDb : game.gameVariation.id}
              htmlId="childViewPanelVariationDropDown"
              dropDownWidth="100%"
              disabled={isSavingChildGameVariation || isLoadingSelectedVariationsForChild}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleVariationDetailInChildViewPanel;
