/**
 * Adds images to every game object.
 */

const consolidateGamesWithGameMedia = (games, gameMedia) => {
  for (let i = 0; i < games.length; i++) {
    const correctGameMedia = gameMedia.filter(gm => gm.gameId === games[i].id)[0];
    games[i].image = correctGameMedia && correctGameMedia.image;
  }

  return games;
};

export default consolidateGamesWithGameMedia;
