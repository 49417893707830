import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import engage_logo from '../../../res/Engage Play Tools_green.svg';

const useStyles = makeStyles(theme => ({
  header: {},
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.separator}`
  },
  headerText: {},
  headerDate: {
    color: '#AEAEAE'
  },
  engageLogo: {
    height: 40
    // width: 60
  }
}));

const Header = props => {
  const classes = useStyles();

  const date = moment(new Date()).format('DD MMMM YYYY');

  return (
    <div className={classes.header}>
      <div className={classes.content}>
        <div className={classes.leftTextContainer}>
          <Typography variant="h4" gutterBottom className={classes.headerText}>
            Variations
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.headerDate}>
            {date}
          </Typography>
        </div>
        <img src={engage_logo} alt="engage logo" className={classes.engageLogo} />
      </div>
    </div>
  );
};

export default Header;
