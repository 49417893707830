import React, { Component } from 'react';
import { Chip, withStyles, Paper, Button } from '@material-ui/core';

import Triangle from './Triangle';
import Highlight from '../../../utils/highlight';

const styles = theme => ({
  cardContainer: {
    width: '380px',
    textAlign: 'left',
    padding: 0,
    margin: 0,
    position: 'relative'
  },
  content: {
    margin: '10px',
    background: 'white'
  },

  noMargin: {
    margin: '0px',
    padding: '0px'
  },
  heading: {
    fontSize: '16px'
  },
  description: {
    textAlign: 'left',
    maxWidth: '100%'
  },
  viewDetails: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600
  },
  chip: {
    color: 'white',
    height: 20,
    margin: '5px 0',
    fontWeight: 600,
    fontSize: 12,
    display: 'inline-block',
    borderRadius: '10px',
    padding: '1px 0 0 0'
  },
  imageContainer: {
    marginTop: 10,
    height: '145px',
    width: '356px',
    backgroundPosition: 'center !important',
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat'
  }
});

class Card extends Component {
  render() {
    const { classes, index, handleCardClick, searchTerm, isLoadingImages, handleViewDetailsClick } = this.props;

    const { id, name, gameVariations, description, checked, color, image } = this.props.data;

    const onCardClick = e => {
      handleCardClick(id);
    };

    const onViewDetailsClick = event => {
      event.stopPropagation();
      handleViewDetailsClick(id, checked === true);
    };

    let border;
    if (checked) {
      border = `2px solid ${color}`;
    } else {
      border = '2px solid white';
    }

    const levelCountString = `${gameVariations && gameVariations.length} VARIATION${
      gameVariations && gameVariations.length > 1 ? 'S' : ''
    }`;

    return (
      <React.Fragment>
        <Paper id={`card${id}`} className={classes.cardContainer} style={{ border: border }} onClick={onCardClick}>
          {checked ? <Triangle color={color} /> : ''}
          <div className={classes.content}>
            <h2 className={`${classes.noMargin} ${classes.heading}`}>
              <Highlight text={name} highlight={searchTerm} />
            </h2>
            <Chip
              className={`${classes.noMargin} ${classes.chip}`}
              label={levelCountString}
              style={{ background: color }}
            />
            {!isLoadingImages && image && (
              <div
                className={classes.imageContainer}
                style={{
                  backgroundImage: `url(${image})`
                }}
              ></div>
            )}

            {!image && (
              <div
                className={classes.imageContainer}
                style={{
                  background: '#eeeeee'
                }}
              ></div>
            )}

            <p className={classes.description}>{description}</p>
            <Button
              id={`viewDetailsButton${index}`}
              size="small"
              className={classes.viewDetails}
              style={{ color: color }}
              onClick={onViewDetailsClick}
            >
              View Details
            </Button>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Card);
