import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, ListItemText, List, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import Moment from 'moment';

import { useAuth0 } from '../../Auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3)
  },
  completeButton: {
    height: 36,
    width: 180,
    fontSize: 14
  }
}));

const ChildReportActionButtons = props => {
  const classes = useStyles();

  const { handleCompleteChildReportButtonClick, child, onGetReportButtonsStatus, reportButtonsStatus } = props;

  const { getTokenSilently } = useAuth0();
  async function fetchToken() {
    return await getTokenSilently();
  }
  React.useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      if (child) {
        onGetReportButtonsStatus(child.id, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child]);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.content}>
          <List dense={true}>
            <ListItem>
              <ListItemText primary={<p style={{ fontWeight: 600 }}> {Moment().format('MMMM YYYY')} Reports </p>} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary={<p style={{ fontWeight: 600 }}>Strengths and Difficulties (SDQ)</p>} />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.completeButton}
                  onClick={() => handleCompleteChildReportButtonClick(2)}
                  disabled={reportButtonsStatus && reportButtonsStatus.sdqDone}
                >
                  Complete
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary={<p style={{ fontWeight: 600 }}>Behaviour Assessment (BASC)</p>} />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.completeButton}
                  onClick={() => handleCompleteChildReportButtonClick(1)}
                  disabled={reportButtonsStatus && reportButtonsStatus.bascDone}
                >
                  Complete
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>

      {/* <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Form Template Type</FormLabel>
          <RadioGroup
            aria-label="formTemplate"
            name="formTemplate"
            onChange={handleChange}
            value={childReportTemplateType}
          >
            <FormControlLabel value={2} control={<Radio />} label="BASC" />
            <FormControlLabel value={3} control={<Radio />} label="SDQ" />
          </RadioGroup>
        </FormControl>
      </div> */}
    </React.Fragment>
  );
};

export default ChildReportActionButtons;
