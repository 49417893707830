import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import PrivateRoute from './components/PrivateRoute';
import { urls } from './constants';
import LandingPage from './containers/LandingPage';
import AllGamesList from './containers/AllGamesList';
import GameDetails from './containers/GameDetails';
import HomePage from './containers/HomePage';
import AdminPage from './containers/AdminPage';
import EditGamePage from './containers/EditGamePage';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Switch>
        <Route path={urls.LANDING} component={LandingPage} />
        <PrivateRoute path={urls.HOME_PAGE} component={HomePage} />
        <PrivateRoute path={urls.GAME_DETAIL} component={GameDetails} />
        <PrivateRoute path={urls.ALL_GAMES} component={AllGamesList} />

        <PrivateRoute path="/children/:childId" component={HomePage} />
        <PrivateRoute path="/children" component={HomePage} />

        <PrivateRoute path="/admin/game/:gameId/edit" component={EditGamePage} />
        <PrivateRoute path="/admin" component={AdminPage} />

        <Redirect from="/" to={urls.LANDING} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
