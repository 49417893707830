import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EditGamePage from '../../components/EditGamePage';

import {
  getGame,
  clearSingleImage,
  clearSingleGame,
  updateTextFieldForGame,
  updateInstructionForGame,
  updateVariationForGame,
  updateGame,
  openPrintingDialogBox,
  addNewGameVariation,
  getVariationForGame,
  defaultVariationChange,
  removeAttachment
} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { gameId } = ownProps.match.params;
  const allGameTypes = state.games.gameTypes.items;
  const dbVersionOfVariationForTheGame = state.game.dbVersionOfVariationForTheGame.item;
  const existingAttachments = state.media.attachmentFiles.items.filter(i => i.container === 2);

  return {
    gameId,
    gameTypes: allGameTypes.slice(1),
    game: state.game.game.item,
    gameImage: state.media.actualImage && state.media.actualImage.item,
    isLoadingGameImage: state.media.actualImage.isLoading,
    updatedGame: state.game.game.updatedItem,
    isLoadingGame: state.game.game.isLoading,
    isUpdatingGame: state.game.game.isUpdating,
    validationErrors: state.game.game.validationErrors,
    formIsValid: state.game.game.formIsValid,
    createdGameVariations: state.game.game.updatedItem.createdGameVariations,
    gameAttachments: state.game.game.updatedItem.attachmentFiles,
    selectedVariationId: dbVersionOfVariationForTheGame && dbVersionOfVariationForTheGame.gameVariationId,
    dbVersionOfVariationForTheGame,
    existingAttachments: existingAttachments,
    isLoadingDefaultVariationForGame: dbVersionOfVariationForTheGame && dbVersionOfVariationForTheGame.isLoading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;

  return {
    onGetGame: (gameId, token) => {
      dispatch(clearSingleImage());
      dispatch(clearSingleGame());
      const id = gameId;
      dispatch(getGame(id, token));
    },
    backArrowClicked: () => {
      history.push(`/admin`);
    },
    onUpdateTextField: (value, inputFieldName) => {
      dispatch(updateTextFieldForGame(value, inputFieldName));
    },
    onUpdateAttachment: (value, inputFieldName) => {
      dispatch(updateTextFieldForGame(value, inputFieldName));
    },
    onUpdateInstruction: (value, inputFieldName, index) => {
      dispatch(updateInstructionForGame(value, inputFieldName, index));
    },
    onUpdateVariation: (value, variationName, id) => {
      dispatch(updateVariationForGame(value, variationName, id));
    },
    onAddingNewGameVariation: newGameVariation => {
      dispatch(addNewGameVariation(newGameVariation));
    },
    onUpdateGame: (game, token) => {
      dispatch(updateGame(game, token));
    },
    onOpenPrintingDialogBox: open => {
      dispatch(openPrintingDialogBox(open));
    },
    onFetchDefaultVariationForGame: (gameId, token) => {
      dispatch(getVariationForGame(gameId, token));
    },
    onDefaultVariationChange: defaultVariationId => {
      dispatch(defaultVariationChange(defaultVariationId));
    },
    onRemoveAttachment: attachment => {
      dispatch(removeAttachment(attachment));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditGamePage));
