import React from 'react';
import { Dialog as ParentDialog } from '@material-ui/core';

// import DialogTransition from '../DialogTransition'

export default function Dialog(props) {
  return (
    <ParentDialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      //   TransitionComponent={DialogTransition}
      {...props}
    >
      {props.children}
    </ParentDialog>
  );
}
