const AddMainGroupPrefixInAppropriateGameVariation = (allGameVariations, defaultGameVariation) => {
  const gameVariations = allGameVariations.map(gameVariation => {
    if (gameVariation.value === defaultGameVariation.id) {
      gameVariation.mainGroup = true;
    }

    return gameVariation;
  });

  return gameVariations;
};

export default AddMainGroupPrefixInAppropriateGameVariation;
