import React from 'react';

import { Fab, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import SearchBox from '../SearchBox';
import ActionBarSingleRow from '../ActionBarSingleRow';

const useStyles = makeStyles(theme => ({
  actionBarContainer: {
    '@media print': {
      display: 'none'
    }
  },
  fab: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    width: 180,
    height: '36px !important',
    borderWidth: 1.3,
    flex: 1
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  addGamesFab: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  allGamesButton: {
    marginLeft: 0
  },

  searchBoxContainer: {
    paddingTop: theme.spacing(1)
  }
}));

const AdminDashboardActionBar = props => {
  const classes = useStyles();

  const { onOpenCreateGameDialogBox, searchTerm, onSearchTermChange } = props;

  const handleCreateGameClick = () => {
    onOpenCreateGameDialogBox();
  };

  return (
    <React.Fragment>
      <ActionBarSingleRow>
        <div className={classes.actionBarContainer}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Fab
                id="addGamesButton"
                onClick={handleCreateGameClick}
                color="primary"
                variant="extended"
                aria-label="add"
                className={`${classes.addGamesFab} ${classes.button}`}
              >
                <AddIcon className={classes.extendedIcon} />
                Create Game
              </Fab>
            </Grid>

            <Grid item xs={10} className={`${classes.borderRed} ${classes.searchBoxContainer}`}>
              <SearchBox
                className={classes.searchBox}
                handleSearchTermChange={onSearchTermChange}
                searchTerm={searchTerm}
                placeholder="Search Games"
              />
            </Grid>
          </Grid>
        </div>
      </ActionBarSingleRow>
    </React.Fragment>
  );
};

export default AdminDashboardActionBar;
