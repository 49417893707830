import React from 'react';
import { withStyles } from '@material-ui/core';

import tickImage from '../../../../res/tick.png';
import { colors } from '../../../../constants';

const styles = theme => ({
  arrow: {
    width: '0px !important',
    height: '0px !important',
    borderLeft: '25px solid transparent !important',
    borderRight: '25px solid transparent !important',

    transform: 'rotate(45deg)',
    position: 'absolute',
    right: '-16px',
    top: '-4px'
  },
  arrowGreen: {
    borderBottom: `25px solid ${theme.palette.gameColor.green} !important`
  },
  arrowBlue: {
    borderBottom: `25px solid ${theme.palette.gameColor.blue} !important`
  },
  arrowOrange: {
    borderBottom: `25px solid ${theme.palette.gameColor.orange} !important`
  },
  arrowRed: {
    borderBottom: `25px solid ${theme.palette.gameColor.red} !important`
  },
  arrowWhite: {
    borderBottom: '25px solid white !important'
  },
  tick: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 1,
    height: '20px',
    width: '20px'
  }
});

const Triangle = ({ classes, color }) => {
  let colorClass;
  switch (color) {
    case colors.GREEN:
      colorClass = classes.arrowGreen;
      break;
    case colors.BLUE:
      colorClass = classes.arrowBlue;
      break;
    case colors.ORANGE:
      colorClass = classes.arrowOrange;
      break;
    case colors.RED:
      colorClass = classes.arrowRed;
      break;
    default:
      colorClass = classes.arrowWhite;
  }
  return (
    <React.Fragment>
      <img src={tickImage} alt="tick" className={classes.tick} />
      <div className={`${classes.arrow} ${colorClass}`}></div>
    </React.Fragment>
  );
};

export default withStyles(styles)(Triangle);
