import { handleActions } from 'redux-actions';

import { types } from '../constants';

const INITIAL_STATE = {
  showNotificationDrawer: false,
  // allNotification: {
  //   items: noties,
  //   isLoading: false,
  //   errorMessage: ''
  // },
  notification: {
    show: false,
    isLoading: false,
    errorMessage: ''
  }
};

const openNoitificationDrawer = (state, { open }) => {
  return {
    ...state,
    showNotificationDrawer: open
  };
};

const startLoadNotifications = state => {
  return {
    ...state,
    notification: {
      ...state.notification,
      isLoading: true
    }
  };
};

const loadNotificationsSuccess = (state, { showNotification }) => {
  return {
    ...state,
    notification: {
      ...state.notification,
      show: showNotification
    }
  };
};

const loadNotificationsFailure = (state, { errorMessage }) => {
  return {
    ...state,
    notification: {
      ...state.notification,
      errorMessage
    }
  };
};

const loadNotificationsFinished = state => {
  return {
    ...state,
    notification: {
      ...state.notification,
      isLoading: false
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.OPEN_NOTIFICATION_DRAWER]: openNoitificationDrawer,

    [types.START_LOAD_NITIFICATIONS]: startLoadNotifications,
    [types.LOAD_NITIFICATIONS_SUCCESS]: loadNotificationsSuccess,
    [types.LOAD_NITIFICATIONS_FAILURE]: loadNotificationsFailure,
    [types.LOAD_NITIFICATIONS_FINISHED]: loadNotificationsFinished
  },
  INITIAL_STATE
);
