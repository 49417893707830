import { handleActions } from 'redux-actions';

import { types } from '../constants';

const INITIAL_STATE = {
  actualImage: {
    item: null,
    isLoading: false,
    errorMessage: ''
  },
  attachmentFiles: {
    items: [],
    isLoading: false,
    errorMessage: ''
  }
};

const clearSingleImage = state => {
  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      item: null
    }
  };
};

const clearAttachmentFiles = state => {
  return {
    ...state,
    attachmentFiles: {
      ...state.attachmentFiles,
      items: []
    }
  };
};

const startLoadSingleActualImage = state => {
  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      isLoading: true
    }
  };
};

const startLoadingAttachmentFiles = state => {
  return {
    ...state,
    attachmentFiles: {
      ...state.attachmentFiles,
      isLoading: true
    }
  };
};

const loadSingleActualImageSuccess = (state, { image, mediaId }) => {
  var currentAttachments = state.attachmentFiles.items;
  var index = currentAttachments.findIndex(j => j.mediaId === mediaId);

  if (index > -1) {
    var attachment = currentAttachments[index];
    attachment.mediaURL = image;
    currentAttachments[index] = attachment;
  }
  var gameImageIndex = currentAttachments.findIndex(a => a.container == 1);
  var displayImage = currentAttachments[gameImageIndex].mediaURL;

  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      item: displayImage
    },
    attachmentFiles: {
      ...state.attachmentFiles,
      items: currentAttachments
    }
  };
};

const loadAttachmentFilesSuccess = (state, { images }) => {
  return {
    ...state,
    attachmentFiles: {
      ...state.attachmentFiles,
      items: images
    }
  };
};

const loadSingleActualImageFailure = (state, { errorMessage }) => {
  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      errorMessage
    }
  };
};

const loadAttachmentFilesFailure = (state, { errorMessage }) => {
  return {
    ...state,
    attachmentFiles: {
      ...state.attachmentFiles,
      errorMessage
    }
  };
};

const loadSingleActualImageFinished = state => {
  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      isLoading: false
    }
  };
};

const loadAttachmentFilesFinished = state => {
  return {
    ...state,
    attachmentFiles: {
      ...state.attachmentFiles,
      isLoading: false
    }
  };
};

function calculateAspectRatioFit(srcWidth, srcHeight) {
  var ratio = Math.min(361 / srcWidth, 235 / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

//Used for setting dimensions for single image.
const setGameImageDimensions = (state, { imageDimensions }) => {
  return {
    ...state,
    actualImage: {
      ...state.actualImage,
      dimensions: imageDimensions,
      dimensionRatio: calculateAspectRatioFit(imageDimensions.width, imageDimensions.height)
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.CLEAR_SINGLE_IMAGE]: clearSingleImage,
    [types.START_LOAD_SINGLE_ACTUAL_IMAGE]: startLoadSingleActualImage,
    [types.LOAD_SINGLE_ACTUAL_IMAGE_SUCCESS]: loadSingleActualImageSuccess,
    [types.LOAD_SINGLE_ACTUAL_IMAGE_FAILURE]: loadSingleActualImageFailure,
    [types.LOAD_SINGLE_ACTUAL_IMAGE_FINISHED]: loadSingleActualImageFinished,
    [types.SET_GAME_IMAGE_DIMENSIONS]: setGameImageDimensions,

    [types.CLEAR_ATTACHMENT_FILES_FOR_SINGLE_GAME]: clearAttachmentFiles,
    [types.START_LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME]: startLoadingAttachmentFiles,
    [types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_SUCCESS]: loadAttachmentFilesSuccess,
    [types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FAILURE]: loadAttachmentFilesFailure,
    [types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FINISHED]: loadAttachmentFilesFinished
  },
  INITIAL_STATE
);
