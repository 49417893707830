import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import PaperTile from '../../PaperTile';
import mapDevelopmentTypeIdToGameType from '../../../filters/mapDevelopmentTypeIdToGameType';
import EngageLogo from '../../../res/Engage Play Tools_green.svg';
import CustomChip from '../../CustomChip';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: 5,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    padding: '20px',
    '@media print': {
      padding: 40,
      boxShadow: 'none'
    }
  },
  title: {
    margin: 0,
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 'bold'
  },
  image: {
    marginTop: 10,
    width: 361,
    height: 235,
    backgroundPosition: 'center !important',
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important',
    '@media print': {
      display: 'none'
    }
  },
  imageTag: {
    width: '100%',
    height: 175,
    display: 'none',
    '@media print': {
      display: 'block',
      height: 250
    }
  },
  descriptionContainer: {
    marginTop: 10,
    height: 180,
    '@media print': {
      height: 340,
      overflow: 'auto',
      boxShadow: 'none',
      padding: 0
    }
  },
  description: {
    marginTop: 10,
    paddingRight: 40,
    paddingLeft: 40,
    height: '80%',
    fontSize: 14,
    textAlign: 'left',

    '@media print': {
      overflowY: 'hidden',
      paddingRight: 10,
      paddingLeft: 10
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px'
  },
  engageLogo: {
    float: 'right',
    height: 23,
    width: 58,
    background: `url('${EngageLogo}')`,
    backgroundSize: 'cover !important'
  }
}));

const TitlePaperTile = props => {
  const { game, gameImage, gameColor } = props;
  const classes = useStyles();

  return (
    <PaperTile>
      <div className={classes.container} style={{ border: `3px solid ${gameColor}` }}>
        <div className={classes.titleContainer}>
          <Typography variant="h2" gutterBottom className={`title ${classes.title}`} style={{ color: gameColor }}>
            {game.name}
          </Typography>
        </div>

        {gameImage && <div id="gameImage" className={classes.image} style={{ background: `url(${gameImage})` }}></div>}
        {!gameImage && (
          <div
            className={classes.image}
            style={{
              background: '#eeeeee'
            }}
          ></div>
        )}

        {/* Image tag is used for printing only */}
        <img src={gameImage} alt="Game" className={classes.imageTag} />

        <div className={classes.descriptionContainer}>
          <Typography className={`description ${classes.description}`} variant="body2" gutterBottom>
            {game.description}
          </Typography>
        </div>

        <div className={classes.footer}>
          {game.developmentTypeId && (
            <CustomChip chipLabel={mapDevelopmentTypeIdToGameType(game.developmentTypeId)} color={gameColor} />
          )}
          <div className={classes.engageLogo}>{/* <img src={EngageLogo} alt="engage logo" /> */}</div>
        </div>
      </div>
    </PaperTile>
  );
};

export default TitlePaperTile;
