import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClassroomDashboardActionBar from '../../components/ClassroomDashboardActionBar';
import { urls, pageNames } from '../../constants';
import {
  searchTermChangeForGamesOfClassroom,
  setLastVisitedPage,
  fireOpenPrintingDialogBoxInstruction,
  openPrintingDialogBox,
  clearAllGames,
  getGameDetailsForMultipleGames,
  setGameImageDimensions
} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    searchTerm: state.games.gamesForClassroom.searchTerm,
    isLoadingClassroom: state.games.gamesForClassroom.isLoading,
    isLoadingMedia: state.medias.gameMedias.isLoading,
    isLoadingImages: state.medias.actualImages.isLoading,
    gamesForClassroom: state.games.gamesForClassroom.items,
    gameDetailsForMultipleGames:
      state.games.gameDetailsForMultipleGames && state.games.gameDetailsForMultipleGames.items,
    openPrintingDialogBoxInstructionFired: state.game.openPrintingDialogBoxInstructionFired //To fix the bug of printing all games dialog opening when coming back from Children page
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;
  return {
    onAddGamesClick: () => {
      dispatch(clearAllGames());
      dispatch(setLastVisitedPage(pageNames.HOME_PAGE));
      history.push(urls.ALL_GAMES);
    },
    handleSearchTermChange: searchTerm => {
      dispatch(searchTermChangeForGamesOfClassroom(searchTerm));
    },
    //To fix the bug of printing all games dialog opening when coming back from Children page
    onFireOpenPrintingDialogBoxInstruction: status => {
      dispatch(fireOpenPrintingDialogBoxInstruction(status));
    },
    onOpenPrintingDialogBox: open => {
      dispatch(openPrintingDialogBox(open));
    },
    onGetGameDetailsForMultipleGames: (gameIds, token) => {
      dispatch(getGameDetailsForMultipleGames(gameIds, token));
    },
    onSetGameImageDimensions: (dimensions, gameId) => {
      dispatch(setGameImageDimensions(dimensions, gameId));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassroomDashboardActionBar));
