// EditGamePage/index.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Delete as DeleteIcon, Search as PreviewIcon, Save as SaveIcon } from '@material-ui/icons';

import HeaderWithBackButton from '../../HeaderWithBackButton';
import { buttonNames } from '../../../constants';

const useStyles = makeStyles(theme => ({
  headerClass: {
    background: '#fff'
  },
  colorBlack: {
    color: '#000000',
    fontSize: 14
  },
  buttonsContainer: {},
  deleteButton: {
    color: theme.palette.danger.default
  },
  previewButton: {},
  saveButton: {},
  button: {
    marginLeft: 15
  }
}));

const Header = props => {
  const classes = useStyles();
  const { onBackArrowClick, onButtonClick, formIsValid } = props;

  return (
    <React.Fragment>
      <HeaderWithBackButton
        className={`${classes.headerClass} ${classes.colorBlack} `}
        title="Edit Game"
        onBackArrowClick={onBackArrowClick}
        position="fixed"
      >
        <div className={classes.buttonsContainer}>
          <Button
            className={`${classes.deleteButton} ${classes.button}`}
            onClick={() => onButtonClick(buttonNames.DELETE)}
            id="deleteButton"
          >
            <DeleteIcon /> Delete
          </Button>
          <Button
            color="primary"
            className={`${classes.previewButton} ${classes.button}`}
            onClick={() => onButtonClick(buttonNames.PREVIEW)}
            id="previewButton"
          >
            <PreviewIcon /> Preview
          </Button>
          <Button
            color="primary"
            className={`${classes.saveButton} ${classes.button}`}
            onClick={() => onButtonClick(buttonNames.SAVE)}
            id="saveButton"
            disabled={!formIsValid}
          >
            <SaveIcon /> Save
          </Button>
        </div>
      </HeaderWithBackButton>
    </React.Fragment>
  );
};

export default Header;
