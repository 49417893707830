import React, { useState } from 'react';
import { makeStyles, Grid, LinearProgress, IconButton } from '@material-ui/core';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@material-ui/icons';

import TitlePaperTile from '../TitlePaperTile';
import VariationsPaperTile from '../VariationsPaperTile';
import HowToPlayTile from '../HowToPlayTile';
import TipsAndNotesPaperTile from '../TipsAndNotesPaperTile';
import mapDevelopmentTypeIdToVariationTypeAndColor from '../../../filters/mapDevelopmentTypeIdToVariationTypeAndColor';

const useStyles = makeStyles(theme => ({
  pagesContainer: {
    height: '100vh',
    width: '100%',
    overflowY: 'scroll',
    '@media print': {
      pageBreakAfter: 'auto'
    }
  },
  content: {
    width: 860,
    margin: '20px auto'
  },
  titleGridItem: {
    // '@media (max-width: 800px)': {
    '@media print': {
      // transform: 'rotate(90deg)',
      position: 'absolute',
      top: 0,
      right: 0
    }
  },
  howToPlayGridItem: {
    // '@media (max-width: 800px)': {
    '@media print': {
      // transform: 'rotate(90deg)',
      position: 'absolute',
      top: 'calc(20cm + 20px)',
      left: 0
    }
  },
  variationsGridItem: {
    // '@media (max-width: 800px)': {
    '@media print': {
      // transform: 'rotate(90deg)',
      position: 'absolute',
      top: 'calc(20cm + 50px)',
      right: 0
    }
  },
  tipsAndNotesGridItem: {
    // '@media (max-width: 800px)': {
    '@media print': {
      // transform: 'rotate(90deg)',
      position: 'absolute',
      // top: 'calc(20cm + 50px)',
      top: -20,
      left: 0
    }
  },
  zoom_buttons_container: {
    width: 100,
    position: 'absolute',
    left: 20,
    bottom: 20,
    borderRadius: 30,
    background: '#fff'
  }
}));

const RightPane = props => {
  const classes = useStyles();
  const [zoomLevel, setZoomLevel] = useState(1);
  const { game, gameImage, isLoadingGame, isLoadingGameImage } = props;

  const gameVariationType = mapDevelopmentTypeIdToVariationTypeAndColor(game.developmentTypeId);
  const gameColor = gameVariationType && gameVariationType.color;

  const zoomClicked = zoomMode => {
    const gameDetailsContent = document.getElementById('game-details-content');
    let newZoom = zoomLevel;
    if (zoomMode === '+') {
      //If zoom level is above 1.5, then no more zoom in.
      if (zoomLevel <= 2.0) {
        newZoom = newZoom + 0.25;
        setZoomLevel(newZoom);
      }
    } else {
      if (zoomLevel !== 1) {
        //If zoom level is below 1, then no more zoom out.
        newZoom = newZoom - 0.25;
        setZoomLevel(newZoom);
      }
    }

    gameDetailsContent.style.transform = `scale(${newZoom}, ${newZoom})`;
    gameDetailsContent.style.transformOrigin = 'top left';
  };

  return (
    <React.Fragment>
      <div className={classes.pagesContainer}>
        {isLoadingGame || isLoadingGameImage ? <LinearProgress /> : null}
        {!isLoadingGame ? (
          <div className={classes.content} id="game-details-content">
            <Grid container spacing={3}>
              {/* id added for print styling purposes */}
              <Grid item xs={6} className={classes.titleGridItem} id="titleGridItem">
                <div id="titlePaperTile">
                  <TitlePaperTile game={game} gameImage={gameImage} gameColor={gameColor} />
                </div>
              </Grid>
              {/* id added for print styling purposes */}
              <Grid item xs={6} className={classes.howToPlayGridItem} id="instructionsGridItem">
                <div id="HowToPlayTile">
                  <HowToPlayTile gameInstructions={game} gameColor={gameColor} />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {/* id added for print styling purposes */}
              <Grid item xs={6} className={classes.variationsGridItem} id="variationsGridItem">
                <div id="variationsPaperTile">
                  <VariationsPaperTile game={game} gameColor={gameColor} />
                </div>
              </Grid>
              {/* id added for print styling purposes */}
              <Grid item xs={6} className={classes.tipsAndNotesGridItem} id="ideasGridItem">
                <div id="TipsAndNotesPaperTile">
                  <TipsAndNotesPaperTile gameInstructions={game} gameColor={gameColor} />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}
        <div className={classes.zoom_buttons_container}>
          <IconButton onClick={e => zoomClicked('+')} disabled={zoomLevel > 1.75}>
            <ZoomInIcon />
          </IconButton>{' '}
          <IconButton onClick={e => zoomClicked('-')} disabled={zoomLevel < 1.25}>
            <ZoomOutIcon />
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightPane;
