// AdminPage/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AdminPage from '../../components/AdminPage';

function mapStateToProps(state, ownProps) {
  return {
    userRoles: state.classroom.userRoles.items
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { history } = ownProps;
  return {
    redirectToHomePage: () => {
      history.replace('/home');
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminPage)
);
