// HeaderAdmin/index.js
import { connect } from 'react-redux';

import HeaderAdmin from '../../components/HeaderAdmin';
import { openLogOutDialog } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    isLoadingGames: state.games.allGames.isLoading,
    isLoadingImages: state.medias.actualImages.isLoading
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onShowLogoutDialog: open => {
      dispatch(openLogOutDialog(open));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
