import React from 'react';
import { withStyles, Typography, LinearProgress } from '@material-ui/core';

import DropDown from './../../DropDown';

const styles = theme => {
  return {
    leftBarContainer: {
      padding: theme.spacing(2)
      // position: 'fixed'
    },
    divider: {
      margin: '40px 0'
    },
    arrowButton: {
      marginLeft: -20
    },
    gameNameContainer: {
      minWidth: 100
    }
  };
};

const GameVariationDropDown = props => {
  const {
    mappedVariations,
    isSavingVariationIdChange,
    selectedVariationId,
    handleGameVariationChange,
    classes
  } = props;

  return (
    <div className={classes.variationDropDownContainer}>
      <Typography variant="caption" display="block" gutterBottom>
        Main group variation
      </Typography>
      {mappedVariations && mappedVariations.length === 0 ? <p>No game variations</p> : ''}
      {mappedVariations && (
        <React.Fragment>
          {isSavingVariationIdChange && <LinearProgress />}
          <DropDown
            items={mappedVariations.sort((a, b) => (a.prefix > b.prefix ? 1 : -1))}
            dropDownWidth={'100%'}
            value={selectedVariationId}
            handleChange={handleGameVariationChange}
            disabled={isSavingVariationIdChange}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(GameVariationDropDown);
