export default {
  CHANGE_TAB_VALUE_IN_HEADER: 'CHANGE_TAB_VALUE_IN_HEADER',

  HANDLE_LOGOUT: 'HANDLE_LOGOUT',
  LOGOUT_DIALOG: 'LOGOUT_DIALOG',

  START_LOAD_GAME: 'START_LOAD_GAME',
  LOAD_GAME: 'LOAD_GAME',
  LOAD_GAME_SUCCESS: 'LOAD_GAME_SUCCESS',
  LOAD_GAME_FAILURE: 'LOAD_GAME_FAILURE',
  LOAD_GAME_FINISHED: 'LOAD_GAME_FINISHED',

  START_LOAD_ALL_GAMES: 'START_LOAD_ALL_GAMES',
  LOAD_ALL_GAMES: 'LOAD_ALL_GAMES',
  LOAD_ALL_GAMES_SUCCESS: 'LOAD_ALL_GAMES_SUCCESS',
  LOAD_ALL_GAMES_FAILURE: 'LOAD_ALL_GAMES_FAILURE',
  LOAD_ALL_GAMES_FINISHED: 'LOAD_ALL_GAMES_FINISHED',

  CLEAR_ALL_DROP_DOWN_FILTERS: 'CLEAR_ALL_DROP_DOWN_FILTERS',

  //Used in AddNewGames page
  CHANGE_SELECTED_GAME_TYPE_FILTER: 'CHANGE_SELECTED_GAME_TYPE_FILTER',
  CHANGE_POPULARITY_FILTER: 'CHANGE_POPULARITY_FILTER',

  CHANGE_GAME_CHECKED_STATUS: 'CHANGE_GAME_CHECKED_STATUS',
  CHANGE_GAME_CHECKED_STATUS_FROM_GAME_DETAILS_PAGE: 'CHANGE_GAME_CHECKED_STATUS_FROM_GAME_DETAILS_PAGE',
  CLEAR_ALL_GAMES: 'CLEAR_ALL_GAMES',
  CLEAR_GAMES_FOR_CLASSROOM: 'CLEAR_GAMES_FOR_CLASSROOM', //Clearning games on home page.

  LOAD_GAMES_FOR_CLASSROOM: 'LOAD_GAMES_FOR_CLASSROOM',
  START_LOAD_GAMES_FOR_CLASSROOM: 'START_LOAD_GAMES_FOR_CLASSROOM',
  LOAD_GAMES_FOR_CLASSROOM_SUCCESS: 'LOAD_GAMES_FOR_CLASSROOM_SUCCESS',
  LOAD_GAMES_FOR_CLASSROOM_FAILURE: 'LOAD_GAMES_FOR_CLASSROOM_FAILURE',
  LOAD_GAMES_FOR_CLASSROOM_FINISHED: 'LOAD_GAMES_FOR_CLASSROOM_FINISHED',

  LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB: 'LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB',
  START_LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB: 'START_LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB',
  LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_SUCCESS: 'LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_SUCCESS',
  LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FAILURE: 'LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FAILURE',
  LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FINISHED: 'LOAD_CLASSROOM_DETAILS_BY_EMAIL_FROM_DB_FINISHED',

  LOAD_VARIATIONS_FOR_GAME: 'LOAD_VARIATIONS_FOR_GAME',
  START_LOAD_VARIATIONS_FOR_GAME: 'START_LOAD_VARIATIONS_FOR_GAME',
  LOAD_VARIATIONS_FOR_GAME_SUCCESS: 'LOAD_VARIATIONS_FOR_GAME_SUCCESS',
  LOAD_VARIATIONS_FOR_GAME_FAILURE: 'LOAD_VARIATIONS_FOR_GAME_FAILURE',
  LOAD_VARIATIONS_FOR_GAME_FINISHED: 'LOAD_VARIATIONS_FOR_GAME_FINISHED',

  UPDATE_GAME_VARIATION_ID: 'UPDATE_GAME_VARIATION_ID',
  START_UPDATE_GAME_VARIATION_ID: 'START_UPDATE_GAME_VARIATION_ID',
  UPDATE_GAME_VARIATION_ID_SUCCESS: 'UPDATE_GAME_VARIATION_ID_SUCCESS',
  UPDATE_GAME_VARIATION_ID_FAILURE: 'UPDATE_GAME_VARIATION_ID_FAILURE',
  UPDATE_GAME_VARIATION_ID_FINISHED: 'UPDATE_GAME_VARIATION_ID_FINISHED',

  MESSAGE_SHOW: 'MESSAGE_SHOW',
  MESSAGE_HIDE: 'MESSAGE_HIDE',
  MESSAGE_NEXT: 'MESSAGE_NEXT',

  ADD_GAMES: 'ADD_GAMES',
  START_ADD_GAMES: 'START_ADD_GAMES',
  ADD_GAMES_SUCCESS: 'ADD_GAMES_SUCCESS',
  ADD_GAMES_FAILURE: 'ADD_GAMES_FAILURE',
  ADD_GAMES_FINISHED: 'ADD_GAMES_FINISHED',

  REMOVE_GAME: 'REMOVE_GAME',
  START_REMOVE_GAME: 'START_REMOVE_GAME',
  REMOVE_GAME_SUCCESS: 'REMOVE_GAME_SUCCESS',
  REMOVE_GAME_FAILURE: 'REMOVE_GAME_FAILURE',
  REMOVE_GAME_FINISHED: 'REMOVE_GAME_FINISHED',

  SHOW_REMOVE_GAME_DIALOG: 'SHOW_REMOVE_GAME_DIALOG',

  REMOVE_GAME_ATTACHMENT: 'REMOVE_GAME_ATTACHMENT',

  LOAD_MEDIA: 'LOAD_MEDIA',
  START_LOAD_MEDIA: 'START_LOAD_MEDIA',
  LOAD_MEDIA_SUCCESS: 'LOAD_MEDIA_SUCCESS',
  LOAD_MEDIA_FAILURE: 'LOAD_MEDIA_FAILURE',
  LOAD_MEDIA_FINISHED: 'LOAD_MEDIA_FINISHED',

  LOAD_ACTUAL_IMAGES: 'LOAD_ACTUAL_IMAGES',
  START_LOAD_ACTUAL_IMAGES: 'START_LOAD_ACTUAL_IMAGES',
  LOAD_ACTUAL_IMAGES_SUCCESS: 'LOAD_ACTUAL_IMAGES_SUCCESS',
  LOAD_ACTUAL_IMAGES_FAILURE: 'LOAD_ACTUAL_IMAGES_FAILURE',
  LOAD_ACTUAL_IMAGES_FINISHED: 'LOAD_ACTUAL_IMAGES_FINISHED',

  CLEAR_SINGLE_IMAGE: 'CLEAR_SINGLE_IMAGE',

  LOAD_SINGLE_ACTUAL_IMAGE: 'LOAD_SINGLE_ACTUAL_IMAGE',
  START_LOAD_SINGLE_ACTUAL_IMAGE: 'START_LOAD_SINGLE_ACTUAL_IMAGE',
  LOAD_SINGLE_ACTUAL_IMAGE_SUCCESS: 'LOAD_SINGLE_ACTUAL_IMAGE_SUCCESS',
  LOAD_SINGLE_ACTUAL_IMAGE_FAILURE: 'LOAD_SINGLE_ACTUAL_IMAGE_FAILURE',
  LOAD_SINGLE_ACTUAL_IMAGE_FINISHED: 'LOAD_SINGLE_ACTUAL_IMAGE_FINISHED',

  CLEAR_ATTACHMENT_FILES_FOR_SINGLE_GAME: 'CLEAR_ATTACHMENT_FILES_FOR_SINGLE_GAME',

  LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME: 'LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME',
  START_LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME: 'START_LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME',
  LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_SUCCESS: 'LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_SUCCESS',
  LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FAILURE: 'LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FAILURE',
  LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FINISHED: 'LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME_FINISHED',

  LOAD_ATTACHMENTS_FOR_GAME: 'LOAD_ATTACHMENTS_FOR_GAME',

  LOAD_ATTACHMENTS_ACTUAL_BLOB_FOR_GAME: 'LOAD_ATTACHMENTS_ACTUAL_BLOB_FOR_GAME',

  DELETE_ATTACHMENTS_FOR_GAME: 'DELETE_ATTACHMENTS_FOR_GAME',

  UPLOAD_ATTACHMENTS_FOR_GAME: 'UPLOAD_ATTACHMENTS_FOR_GAME',

  CLEAR_ALL_EXISTING_MEDIA_AND_IMAGES: 'CLEAR_ALL_EXISTING_MEDIA_AND_IMAGES',

  CHANGE_SEARCH_TERM_ALL_GAMES: 'CHANGE_SEARCH_TERM_ALL_GAMES',
  CHANGE_SEARCH_TERM_GAMES_FOR_CLASSROOM: 'CHANGE_SEARCH_TERM_GAMES_FOR_CLASSROOM',

  SPLASH_SCREEN_SHOW_FINISHED: 'SPLASH_SCREEN_SHOW_FINISHED',

  SET_LAST_VISITED_URL: 'SET_LAST_VISITED_URL',

  //To fix the bug of printing all games dialog opening when coming back from Children page
  FIRE_OPEN_PRITING_DIALOG_BOX_INSTRUCTION: 'FIRE_OPEN_PRITING_DIALOG_BOX_INSTRUCTION',
  OPEN_PRINTING_DIALOG: 'OPEN_PRINTING_DIALOG',

  LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES: 'LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES',
  START_LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES: 'START_LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES',
  LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_SUCCESS: 'LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_SUCCESS',
  LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FAILURE: 'LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FAILURE',
  LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FINISHED: 'LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FINISHED',

  CLEAR_GAMES_DETAILS_FOR_MULTIPLE_PRINTING: 'CLEAR_GAMES_DETAILS_FOR_MULTIPLE_PRINTING',
  CLEAR_CHILD_GAME_VARIATIONS_FOR_CHILD: 'CLEAR_CHILD_GAME_VARIATIONS_FOR_CHILD',

  LOAD_ALL_CHILDREN: 'LOAD_ALL_CHILDREN',
  START_LOAD_ALL_CHILDREN: 'START_LOAD_ALL_CHILDREN',
  LOAD_ALL_CHILDREN_SUCCESS: 'LOAD_ALL_CHILDREN_SUCCESS',
  LOAD_ALL_CHILDREN_FAILURE: 'LOAD_ALL_CHILDREN_FAILURE',
  LOAD_ALL_CHILDREN_FINISHED: 'LOAD_ALL_CHILDREN_FINISHED',

  CHANGE_SEARCH_TERM_ALL_CHILDREN: 'CHANGE_SEARCH_TERM_ALL_CHILDREN',

  SET_CHILD: 'SET_CHILD',

  LOAD_CHILD_DETAILS: 'LOAD_CHILD_DETAILS',
  START_LOAD_CHILD_DETAILS: 'START_LOAD_CHILD_DETAILS',
  LOAD_CHILD_DETAILS_SUCCESS: 'LOAD_CHILD_DETAILS_SUCCESS',
  LOAD_CHILD_DETAILS_FAILURE: 'LOAD_CHILD_DETAILS_FAILURE',
  LOAD_CHILD_DETAILS_FINISHED: 'LOAD_CHILD_DETAILS_FINISHED',

  CHANGE_TAB_VALUE_IN_CHILDVIEWPANEL: 'CHANGE_TAB_VALUE_IN_CHILDVIEWPANEL',

  OPEN_ADD_NEW_CHILD_DIALOG: 'OPEN_ADD_NEW_CHILD_DIALOG',

  CHANGE_ADD_NEW_CHILD_INPUT_FIELD: 'CHANGE_ADD_NEW_CHILD_INPUT_FIELD',
  CHANGE_UPDATE_CHILD_INPUT_FIELD: 'CHANGE_UPDATE_CHILD_INPUT_FIELD',

  CREATE_NEW_CHILD: 'CREATE_NEW_CHILD',
  START_CREATE_NEW_CHILD: 'START_CREATE_NEW_CHILD',
  CREATE_NEW_CHILD_SUCCESS: 'CREATE_NEW_CHILD_SUCCESS',
  CREATE_NEW_CHILD_FAILURE: 'CREATE_NEW_CHILD_FAILURE',
  CREATE_NEW_CHILD_FINISHED: 'CREATE_NEW_CHILD_FINISHED',

  UPDATE_CHILD: 'UPDATE_CHILD',
  START_UPDATE_CHILD: 'START_UPDATE_CHILD',
  UPDATE_CHILD_SUCCESS: 'UPDATE_CHILD_SUCCESS',
  UPDATE_CHILD_FAILURE: 'UPDATE_CHILD_FAILURE',
  UPDATE_CHILD_FINISHED: 'UPDATE_CHILD_FINISHED',

  ADD_NEWLY_CREATED_CHILD_TO_CHILDREN_LIST: 'ADD_NEWLY_CREATED_CHILD_TO_CHILDREN_LIST',
  UPDATE_EDITED_CHILD_IN_CHILDREN_LIST: 'UPDATE_EDITED_CHILD_IN_CHILDREN_LIST',

  OPEN_EDIT_CHILD_DIALOG: 'OPEN_EDIT_CHILD_DIALOG',

  OPEN_CHILD_REPORT_DIALOG: 'OPEN_CHILD_REPORT_DIALOG',

  GET_QUESTIONS_FOR_CHILD: 'GET_QUESTIONS_FOR_CHILD',
  START_GET_QUESTIONS_FOR_CHILD: 'START_GET_QUESTIONS_FOR_CHILD',
  GET_QUESTIONS_FOR_CHILD_SUCCESS: 'GET_QUESTIONS_FOR_CHILD_SUCCESS',
  GET_QUESTIONS_FOR_CHILD_FAILURE: 'GET_QUESTIONS_FOR_CHILD_FAILURE',
  GET_QUESTIONS_FOR_CHILD_FINISHED: 'GET_QUESTIONS_FOR_CHILD_FINISHED',

  CHANGE_SELECTED_ANSWER_FOR_ANSWER: 'CHANGE_SELECTED_ANSWER_FOR_ANSWER',

  SUBMIT_CHILD_REPORT_ANSWERS: 'SUBMIT_CHILD_REPORT_ANSWERS',
  START_SUBMIT_CHILD_REPORT_ANSWERS: 'START_SUBMIT_CHILD_REPORT_ANSWERS',
  SUBMIT_CHILD_REPORT_ANSWERS_SUCCESS: 'SUBMIT_CHILD_REPORT_ANSWERS_SUCCESS',
  SUBMIT_CHILD_REPORT_ANSWERS_FAILURE: 'SUBMIT_CHILD_REPORT_ANSWERS_FAILURE',
  SUBMIT_CHILD_REPORT_ANSWERS_FINISHED: 'SUBMIT_CHILD_REPORT_ANSWERS_FINISHED',

  CHANGE_REPORT_TYPE: 'CHANGE_REPORT_TYPE',

  LOAD_CHILD_REPORT: 'LOAD_CHILD_REPORT',
  START_LOAD_CHILD_REPORT: 'START_LOAD_CHILD_REPORT',
  LOAD_CHILD_REPORT_SUCCESS: 'LOAD_CHILD_REPORT_SUCCESS',
  LOAD_CHILD_REPORT_FAILURE: 'LOAD_CHILD_REPORT_FAILURE',
  LOAD_CHILD_REPORT_FINISHED: 'LOAD_CHILD_REPORT_FINISHED',

  OPEN_NOTIFICATION_DRAWER: 'OPEN_NOTIFICATION_DRAWER',
  CHANGE_TRANSFER_CHILD_EMAIL_INPUT_FIELD: 'CHANGE_TRANSFER_CHILD_EMAIL_INPUT_FIELD',

  OPEN_TRANSFER_CHILD_DIALOG: 'OPEN_TRANSFER_CHILD_DIALOG',
  TRANSFER_CHILD: 'TRANSFER_CHILD',
  START_TRANSFER_CHILD: 'START_TRANSFER_CHILD',
  TRANSFER_CHILD_SUCCESS: 'TRANSFER_CHILD_SUCCESS',
  TRANSFER_CHILD_FAILURE: 'TRANSFER_CHILD_FAILURE',
  TRANSFER_CHILD_FINISHED: 'TRANSFER_CHILD_FINISHED',

  GET_REPORT_BUTTONS_STATUS: 'GET_REPORT_BUTTONS_STATUS',
  START_GET_REPORT_BUTTONS_STATUS: 'START_GET_REPORT_BUTTONS_STATUS',
  GET_REPORT_BUTTONS_STATUS_SUCCESS: 'GET_REPORT_BUTTONS_STATUS_SUCCESS',
  GET_REPORT_BUTTONS_STATUS_FAILURE: 'GET_REPORT_BUTTONS_STATUS_FAILURE',
  GET_REPORT_BUTTONS_STATUS_FINISHED: 'GET_REPORT_BUTTONS_STATUS_FINISHED',

  CHANGE_DEFAULT_VARIATION_FOR_CHILD: 'CHANGE_DEFAULT_VARIATION_FOR_CHILD',
  START_CHANGE_DEFAULT_VARIATION_FOR_CHILD: 'START_CHANGE_DEFAULT_VARIATION_FOR_CHILD',
  CHANGE_DEFAULT_VARIATION_FOR_CHILD_SUCCESS: 'CHANGE_DEFAULT_VARIATION_FOR_CHILD_SUCCESS',
  CHANGE_DEFAULT_VARIATION_FOR_CHILD_FAILURE: 'CHANGE_DEFAULT_VARIATION_FOR_CHILD_FAILURE',
  CHANGE_DEFAULT_VARIATION_FOR_CHILD_FINISHED: 'CHANGE_DEFAULT_VARIATION_FOR_CHILD_FINISHED',

  GET_VARIATIONS_FOR_CHILD: 'GET_VARIATIONS_FOR_CHILD',
  START_GET_VARIATIONS_FOR_CHILD: 'START_GET_VARIATIONS_FOR_CHILD',
  GET_VARIATIONS_FOR_CHILD_SUCCESS: 'GET_VARIATIONS_FOR_CHILD_SUCCESS',
  GET_VARIATIONS_FOR_CHILD_FAILURE: 'GET_VARIATIONS_FOR_CHILD_FAILURE',
  GET_VARIATIONS_FOR_CHILD_FINISHED: 'GET_VARIATIONS_FOR_CHILD_FINISHED',

  SHOW_DEACTIVATE_CHILD_DIALOG: 'SHOW_DEACTIVATE_CHILD_DIALOG',
  DEACTIVATE_CHILD: 'DEACTIVATE_CHILD',

  START_DEACTIVATE_CHILD: 'START_DEACTIVATE_CHILD',
  DEACTIVATE_CHILD_SUCCESS: 'DEACTIVATE_CHILD_SUCCESS',
  DEACTIVATE_CHILD_FAILURE: 'DEACTIVATE_CHILD_FAILURE',
  DEACTIVATE_CHILD_FINISHED: 'DEACTIVATE_CHILD_FINISHED',
  CHANGE_CHILD_GAME_VARIATION_FOR_CHILD: 'CHANGE_CHILD_GAME_VARIATION_FOR_CHILD',
  START_CHANGE_CHILD_GAME_VARIATION_FOR_CHILD: 'START_CHANGE_CHILD_GAME_VARIATION_FOR_CHILD',
  CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_SUCCESS: 'CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_SUCCESS',
  CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FAILURE: 'CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FAILURE',
  CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FINISHED: 'CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FINISHED',

  GET_CHILD_GAME_VARIATIONS_FOR_CHILD: 'GET_CHILD_GAME_VARIATIONS_FOR_CHILD',
  START_GET_CHILD_GAME_VARIATIONS_FOR_CHILD: 'START_GET_CHILD_GAME_VARIATIONS_FOR_CHILD',
  GET_CHILD_GAME_VARIATIONS_FOR_CHILD_SUCCESS: 'GET_CHILD_GAME_VARIATIONS_FOR_CHILD_SUCCESS',
  GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FAILURE: 'GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FAILURE',
  GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FINISHED: 'GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FINISHED',

  OPEN_CHANGE_PASSWORD_DIALOG: 'OPEN_CHANGE_PASSWORD_DIALOG',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  START_CHANGE_PASSWORD: 'START_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_FINISHED: 'CHANGE_PASSWORD_FINISHED',

  LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING:
    'LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING',
  START_LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING:
    'START_LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING',
  LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_SUCCESS:
    'LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_SUCCESS',
  LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FAILURE:
    'LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FAILURE',
  LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FINISHED:
    'LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING_FINISHED',

  CLEAR_EXISTING_GAME_RATING: 'CLEAR_EXISTING_GAME_RATING',

  START_SUBMIT_RATING: 'START_SUBMIT_RATING',
  SUBMIT_RATING: 'SUBMIT_RATING',
  SUBMIT_RATING_SUCCESS: 'SUBMIT_RATING_SUCCESS',
  SUBMIT_RATING_FAILURE: 'SUBMIT_RATING_FAILURE',
  SUBMIT_RATING_FINISHED: 'SUBMIT_RATING_FINISHED',

  START_UPDATE_GAME_RATING: 'START_UPDATE_GAME_RATING',
  UPDATE_GAME_RATING: 'UPDATE_GAME_RATING',
  UPDATE_GAME_RATING_SUCCESS: 'UPDATE_GAME_RATING_SUCCESS',
  UPDATE_GAME_RATING_FAILURE: 'UPDATE_GAME_RATING_FAILURE',
  UPDATE_GAME_RATING_FINISHED: 'UPDATE_GAME_RATING_FINISHED',

  START_GET_GAME_RATING: 'START_GET_GAME_RATING',
  GET_GAME_RATING: 'GET_GAME_RATING',
  GET_GAME_RATING_SUCCESS: 'GET_GAME_RATING_SUCCESS',
  GET_GAME_RATING_FAILURE: 'GET_GAME_RATING_FAILURE',
  GET_GAME_RATING_FINISHED: 'GET_GAME_RATING_FINISHED',

  LOAD_NITIFICATIONS: 'LOAD_NITIFICATIONS',
  START_LOAD_NITIFICATIONS: 'START_LOAD_NITIFICATIONS',
  LOAD_NITIFICATIONS_SUCCESS: 'LOAD_NITIFICATIONS_SUCCESS',
  LOAD_NITIFICATIONS_FAILURE: 'LOAD_NITIFICATIONS_FAILURE',
  LOAD_NITIFICATIONS_FINISHED: 'LOAD_NITIFICATIONS_FINISHED',

  GET_REPORT_STATUSES_FOR_CHILDREN: 'GET_REPORT_STATUSES_FOR_CHILDREN',
  START_GET_REPORT_STATUSES_FOR_CHILDREN: 'START_GET_REPORT_STATUSES_FOR_CHILDREN',
  GET_REPORT_STATUSES_FOR_CHILDREN_SUCCESS: 'GET_REPORT_STATUSES_FOR_CHILDREN_SUCCESS',
  GET_REPORT_STATUSES_FOR_CHILDREN_FAILURE: 'GET_REPORT_STATUSES_FOR_CHILDREN_FAILURE',
  GET_REPORT_STATUSES_FOR_CHILDREN_FINISHED: 'GET_REPORT_STATUSES_FOR_CHILDREN_FINISHED',

  CLEAR_ALL_REPORT_STATUSES: 'CLEAR_ALL_REPORT_STATUSES',

  OPEN_CREATE_GAME_DIALOG: 'OPEN_CREATE_GAME_DIALOG',

  UPDATE_TEXTFIELD_FOR_GAME: 'UPDATE_TEXTFIELD_FOR_GAME',
  UPDATE_VARIATION_FOR_GAME: 'UPDATE_VARIATION_FOR_GAME',
  UPDATE_INSTRUCTION_FOR_GAME: 'UPDATE_INSTRUCTION_FOR_GAME',

  START_UPDATE_GAME: 'START_UPDATE_GAME',
  UPDATE_GAME: 'UPDATE_GAME',
  UPDATE_GAME_SUCCESS: 'UPDATE_GAME_SUCCESS',
  UPDATE_GAME_FAILURE: 'UPDATE_GAME_FAILURE',
  UPDATE_GAME_FINISHED: 'UPDATE_GAME_FINISHED',

  CLEAR_SINGLE_GAME: 'CLEAR_SINGLE_GAME',

  ADD_NEW_GAME_VARIATION: 'ADD_NEW_GAME_VARIATION',

  SET_GAME_IMAGE_DIMENSIONS: 'SET_GAME_IMAGE_DIMENSIONS',

  CLEAR_UPDATED_ITEM: 'CLEAR_UPDATED_ITEM',

  CHANGE_DEFAULT_VARIATION_ID_FOR_GAME: 'CHANGE_DEFAULT_VARIATION_ID_FOR_GAME',

  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_COMPLETED_FOR_INDEX: 'SET_COMPLETED_FOR_INDEX',
  CLEAR_FIELDS_RELATED_TO_STEPPER: 'CLEAR_FIELDS_RELATED_TO_STEPPER',

  LOAD_ROLES_FOR_USER: 'LOAD_ROLES_FOR_USER',
  START_LOAD_ROLES_FOR_USER: 'START_LOAD_ROLES_FOR_USER',
  LOAD_ROLES_FOR_USER_SUCCESS: 'LOAD_ROLES_FOR_USER_SUCCESS',
  LOAD_ROLES_FOR_USER_FAILURE: 'LOAD_ROLES_FOR_USER_FAILURE',
  LOAD_ROLES_FOR_USER_FINISHED: 'LOAD_ROLES_FOR_USER_FINISHED'
};
