import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import mapDevelopmentTypeIdToGameType from '../../../filters/mapDevelopmentTypeIdToGameType';
import styles from './styles';
import EngageLogo from '../../../res/Engage_Play_Tools_Logo.png';
import GameDescription from './GameDescription';

const TitlePdfTile = props => {
  const { game, gameImage, gameColor, gameImageDimensionRatio } = props;

  const imageParam = {
    uri: gameImage,
    method: 'GET'
  };

  const imageWidth = gameImageDimensionRatio && gameImageDimensionRatio.width;

  const imageHeight = gameImageDimensionRatio && gameImageDimensionRatio.height;

  return (
    <React.Fragment>
      <View
        style={[
          styles.sheetOne,
          {
            border: `5pt solid ${gameColor}`
          }
        ]}
      >
        <View style={styles.sheetOneContent}>
          <View style={styles.sheetOneTitleContainer}>
            <Text
              style={[
                styles.gameTitle,
                {
                  color: gameColor
                }
              ]}
            >
              {game.name}
            </Text>
          </View>
          <View
            style={[styles.sheetOneImageContainer, { display: 'flex', flexDirection: 'row', justifyContent: 'center' }]}
          >
            <Image
              source={imageParam}
              style={[
                styles.gameImage,
                {
                  width: `${imageWidth}pt`,
                  height: `${imageHeight}pt`,
                  backgroundPosition: 'center !important',
                  backgroundSize: 'contain !important',
                  backgroundRepeat: 'no-repeat !important'
                }
              ]}
            />
          </View>
          <View style={[styles.sheetOneDescriptionContainer, { paddingLeft: 35, paddingRight: 35 }]}>
            <GameDescription description={game.description} />
          </View>
          <View style={styles.footerContainer}>
            <View style={styles.footerContent}>
              <View style={styles.gameTypeContainer}>
                <Text
                  style={[
                    styles.gameType,
                    {
                      backgroundColor: gameColor
                    }
                  ]}
                >
                  {mapDevelopmentTypeIdToGameType(game.developmentTypeId)}
                </Text>
              </View>
              <View style={styles.logoContainer}>
                <Image source={EngageLogo} style={styles.appLogo} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default TitlePdfTile;
