import React, { useEffect } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { IconButton, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));
const NotificationIcon = props => {
  const classes = useStyles();
  const { handleNotificationClicked, fetchNotifications, showNotificationDot } = props;

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconButton
      aria-label="show 5 new notifications"
      color="inherit"
      onClick={handleNotificationClicked}
      id="notifications-button"
    >
      {showNotificationDot ? (
        <Badge color="error" className={classes.margin} variant="dot">
          <NotificationsIcon color="primary" />
        </Badge>
      ) : (
        <NotificationsIcon color="primary" />
      )}
    </IconButton>
  );
};

export default NotificationIcon;
