// EditChildDialog/index.js
import { connect } from 'react-redux';

import TransferChildDialog from '../../components/TransferChildDialog';
import { openTransferChildDialog, transferChild, changeTransferChildEmailInputField } from '../../actions';

import emailValidator from '../../utils/emailValidator';

function mapStateToProps(state, ownProps) {
  const email = state.child.childTransfer.transferChild.item.transferEmail;
  return {
    openDialog: state.child.childTransfer.showTransferChildDialog,
    child: state.child.childTransfer.transferChild.item,
    isTransfering: state.child.child.isTransfering,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    enableTransferButton: email !== '' && emailValidator.test(email)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseDialog: () => {
      dispatch(openTransferChildDialog(false));
    },
    onInputFieldTextChange: value => {
      dispatch(changeTransferChildEmailInputField(value));
    },
    onConfirmDeactiveChild: (payload, token) => {
      dispatch(transferChild(payload, token));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferChildDialog);
