import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChildrenDashboardActionBar from '../../components/ChildrenDashboardActionBar';
import { searchTermChangeForChildren, openAddNewChildDialog } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    searchTerm: state.children.allChildren.searchTerm
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddNewChildButtonClick: () => {
      dispatch(openAddNewChildDialog(true));
    },
    handleSearchTermChange: searchTerm => {
      dispatch(searchTermChangeForChildren(searchTerm));
    },
    onSearchTermChange: searchTerm => {
      dispatch(searchTermChangeForChildren(searchTerm));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChildrenDashboardActionBar)
);
