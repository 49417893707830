import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import ActionBarDoubleRow from '../../ActionBarDoubleRow';
import DropDown from '../../DropDown';
import SearchBox from '../../SearchBox';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: '0 8px 0 10px'
  },
  topMargin: {
    marginTop: '8px !important'
  },
  searchBoxContainer: {
    paddingLeft: '3px !important',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const Header = props => {
  const {
    gameTypes,
    popularityFilterTypes,
    handleGameTypeFilterChange,
    handlePopularityFilterChange,
    enableAddGamesButton,
    handleCrossClick,
    isLoading,
    isLoadingImages,
    isSavingGames,
    handleActionButtonClick,
    searchTerm,
    handleSearchTermChange,
    gameTypeFilter,
    popularityFilter,
    selectedGamesCount
  } = props;

  const classes = useStyles();

  let actionButtonText;
  if (selectedGamesCount) {
    if (selectedGamesCount === 1) {
      actionButtonText = `Add ${selectedGamesCount} Game`;
    } else {
      actionButtonText = `Add ${selectedGamesCount} Games`;
    }
  } else {
    actionButtonText = 'Add Games';
  }

  return (
    <React.Fragment>
      <ActionBarDoubleRow
        title="Add New Games"
        actionButtonText={actionButtonText}
        enableActionButton={enableAddGamesButton}
        handleCrossClick={handleCrossClick}
        isLoading={isLoading}
        isLoadingImages={isLoadingImages}
        isSavingGames={isSavingGames}
        handleActionButtonClick={handleActionButtonClick}
      >
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={2} className={classes.topMargin}>
            {/* Game type filter drop down */}
            <DropDown
              value={gameTypeFilter}
              handleChange={handleGameTypeFilterChange}
              items={gameTypes}
              htmlId="gameTypeFilterSelect"
            />
          </Grid>

          {/* Popularity filter drop down */}
          <Grid item xs={2} className={classes.topMargin}>
            <DropDown
              value={popularityFilter}
              handleChange={handlePopularityFilterChange}
              items={popularityFilterTypes}
              htmlId="popularityFilterSelect"
            />
          </Grid>
          <Grid item xs={8} className={classes.searchBoxContainer}>
            <SearchBox
              htmlId="gamesSearchBox"
              handleSearchTermChange={handleSearchTermChange}
              searchTerm={searchTerm}
            />
          </Grid>
        </Grid>
      </ActionBarDoubleRow>
    </React.Fragment>
  );
};

export default Header;
