// EditGamePage/index.js
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

import { useAuth0 } from '../Auth';
import Header from './Header';
import Body from './Body';
import { buttonNames } from '../../constants';
import MapGameDataForEditing from '../../filters/MapGameDataForEditing';
import MessageDisplay from '../../containers/MessageDisplay';
import PrintingDialogBox from '../../containers/PrintingDialogBox';

const useStyles = makeStyles(theme => ({
  body: {
    background: theme.palette.backgroundGray.default,
    height: '100%',
    marginTop: '65px'
  }
}));

const EditGamePage = props => {
  const classes = useStyles();

  const { getTokenSilently } = useAuth0();
  const {
    gameId,
    onGetGame,
    isLoadingGameImage,
    validationErrors,
    formIsValid,
    gameTypes,
    game,
    updatedGame,
    gameImage,
    onUpdateGame,
    isLoadingGame,
    isUpdatingGame,
    onOpenPrintingDialogBox,
    createdGameVariations,
    onSetGameImageDimensions,
    onFetchDefaultVariationForGame,
    selectedVariationId,
    isLoadingDefaultVariationForGame,
    onDefaultVariationChange,
    gameAttachments,
    existingAttachments,
    onRemoveAttachment
  } = props;

  async function fetchToken() {
    return await getTokenSilently();
  }

  useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      onGetGame(gameId, token);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackArrowClick = () => {
    props.backArrowClicked();
  };

  const onButtonClick = buttonName => {
    if (buttonName === buttonNames.SAVE) {
      const mappedGame = MapGameDataForEditing(game, updatedGame.updatedVariations, createdGameVariations);

      const payload = {
        ...mappedGame,
        existingAttachments: existingAttachments
      };

      fetchToken().then(token => {
        onUpdateGame(payload, token);
      });
    } else if (buttonName === buttonNames.PREVIEW) {
      onOpenPrintingDialogBox(true);
    }
  };

  const handleAttahmentChange = (value, inputFieldName) => {
    const { onUpdateAttachment } = props;
    onUpdateAttachment(value, inputFieldName);
  };

  const handleInputChange = (e, inputFieldName, index) => {
    let value = e.target.value;
    if (typeof value === 'string') {
      value = value.trim();
    }

    const { onUpdateInstruction, onUpdateTextField } = props;

    //If index exists, means its either HowToPlay, or WhatYouNeed or ThingsToSay or Tips
    if (typeof index !== 'undefined') {
      onUpdateInstruction(value, inputFieldName, index);
    } else {
      onUpdateTextField(value, inputFieldName);
    }
  };

  const handleVariationChange = (e, variationName, id) => {
    const value = e.target.value;
    const { onUpdateVariation } = props;
    onUpdateVariation(value, variationName, id);
  };

  const handleAddingNewGameVariation = newGameVariation => {
    const { onAddingNewGameVariation } = props;
    onAddingNewGameVariation(newGameVariation);
  };

  const handleDefaultVariationChange = e => {
    var value = e.target.value;
    onDefaultVariationChange(value);
  };

  const handleRemoveAttachment = attachment => {
    onRemoveAttachment(attachment);
  };

  if (game && !game.id) {
    return '';
  }

  return (
    <React.Fragment>
      <Header onBackArrowClick={onBackArrowClick} onButtonClick={onButtonClick} formIsValid={formIsValid} />

      <PrintingDialogBox mode="single-page" />
      <MessageDisplay />

      <div className={classes.body}>
        {isLoadingGame || isLoadingGameImage || isUpdatingGame || isLoadingDefaultVariationForGame ? (
          <LinearProgress />
        ) : (
          ''
        )}
        <Body
          game={game}
          gameTypes={gameTypes}
          handleInputChange={handleInputChange}
          handleAttahmentChange={handleAttahmentChange}
          handleVariationChange={handleVariationChange}
          gameImage={gameImage}
          isLoadingGameImage={isLoadingGameImage}
          validationErrors={validationErrors}
          handleAddingNewGameVariation={handleAddingNewGameVariation}
          onSetGameImageDimensions={onSetGameImageDimensions}
          onFetchDefaultVariationForGame={onFetchDefaultVariationForGame}
          selectedVariationId={selectedVariationId}
          handleDefaultVariationChange={handleDefaultVariationChange}
          handleRemoveAttachment={handleRemoveAttachment}
          gameAttachments={gameAttachments}
        />
      </div>
    </React.Fragment>
  );
};

export default EditGamePage;
