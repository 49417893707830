import { call, put, takeLatest, all } from 'redux-saga/effects';

import { types } from '../constants';
import * as gamesApi from '../api/games';
import { showMessage } from '../actions';
import { urls, pageNames } from '../constants';

function* watchLoadGame({ id, token }) {
  try {
    yield put({ type: types.START_LOAD_GAME });
    const { jsonData } = yield call(gamesApi.getGame, id, token);
    yield put({ type: types.LOAD_GAME_SUCCESS, game: jsonData });
    yield put({ type: types.CLEAR_SINGLE_IMAGE });
    yield put({ type: types.CLEAR_ATTACHMENT_FILES_FOR_SINGLE_GAME });

    yield put({ type: types.LOAD_ATTACHMENT_FILES_FOR_SINGLE_GAME, id, token });

    //Loading the actual blob for the game main image and attachments
    var gameMedia = jsonData.gameMedia;

    yield put({ type: types.LOAD_SINGLE_ACTUAL_IMAGE, mediaIds: gameMedia, token });
  } catch (e) {
    yield resolveError(types.LOAD_GAME_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_GAME_FINISHED });
  }
}

function* watchLoadAllGame({ token, minusAlreadyAddedGames }) {
  try {
    yield put({ type: types.START_LOAD_ALL_GAMES });
    const { jsonData } = yield call(gamesApi.getAllGames, token);
    yield put({ type: types.LOAD_ALL_GAMES_SUCCESS, games: jsonData, minusAlreadyAddedGames });
    let gameIdsArray = jsonData.map(g => g.id);
    const gameIdsString = gameIdsArray.join(';');
    yield put({ type: types.LOAD_MEDIA, gameIdsString, token });
  } catch (e) {
    yield resolveError(types.LOAD_ALL_GAMES_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_ALL_GAMES_FINISHED });
  }
}

function* watchLoadGamesForClassroom({ classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_GAMES_FOR_CLASSROOM });
    const { jsonData } = yield call(gamesApi.getGamesForClassroom, classroomId, token);
    yield put({ type: types.LOAD_GAMES_FOR_CLASSROOM_SUCCESS, games: jsonData });

    let gameIdsArray = jsonData.map(g => g.id);
    const gameIdsString = gameIdsArray.join(';');

    yield put({ type: types.LOAD_MEDIA, gameIdsString, token });
  } catch (e) {
    yield resolveError(types.LOAD_GAMES_FOR_CLASSROOM_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_GAMES_FOR_CLASSROOM_FINISHED });
  }
}

function* watchLoadDefaultVariationForGame({ gameId, classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_VARIATIONS_FOR_GAME });
    const { jsonData } = yield call(gamesApi.getVariationForGame, gameId, classroomId, token);
    yield put({ type: types.LOAD_VARIATIONS_FOR_GAME_SUCCESS, variations: jsonData });
  } catch (e) {
    yield resolveError(types.LOAD_VARIATIONS_FOR_GAME_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_VARIATIONS_FOR_GAME_FINISHED });
  }
}

function* watchChangeGameVariationId({ payload, token }) {
  try {
    yield put({ type: types.START_UPDATE_GAME_VARIATION_ID });
    const classroomVariationId = payload.toBeUpdatedGameClassroomVariationId;
    //Creating a new record
    const { jsonData } = yield call(gamesApi.createGameClassroomVariationRecord, payload, token);
    //Updating the old record
    yield call(gamesApi.updateGameVariationId, classroomVariationId, token);
    yield put({ type: types.UPDATE_GAME_VARIATION_ID_SUCCESS, updatedDbVersionOfVariationForTheGame: jsonData });

    yield put(showMessage('Game variation has been updated.'));
  } catch (e) {
    yield resolveError(types.UPDATE_GAME_VARIATION_ID_FAILURE, e);
    yield put(showMessage('An error occured.'));
  } finally {
    yield put({ type: types.UPDATE_GAME_VARIATION_ID_FINISHED });
  }
}

function* watchAddGames({ games, token, history }) {
  try {
    yield put({ type: types.START_ADD_GAMES });
    let responseData = yield all(
      games.map(game => {
        return call(gamesApi.createGameClassroomVariationRecord, game, token);
      })
    );
    const createdGames = responseData.map(g => g.jsonData);
    yield put({ type: types.ADD_GAMES_SUCCESS, createdGames });

    yield put({ type: types.SET_LAST_VISITED_URL, pageName: pageNames.ADD_NEW_GAMES_PAGE });
    history.push(urls.HOME_PAGE);
  } catch (error) {
    yield resolveError(types.ADD_GAMES_FAILURE, error);
  } finally {
    yield put({ type: types.ADD_GAMES_FINISHED });
  }
}

function* watchRemoveGame({ gameClassroomVariationId, token, history }) {
  try {
    yield put({ type: types.START_REMOVE_GAME });
    const { jsonData } = yield call(gamesApi.updateGameVariationId, gameClassroomVariationId, token);
    yield put({ type: types.REMOVE_GAME_SUCCESS, gameClassroomVariationId: jsonData.id });
    yield put({ type: types.SHOW_REMOVE_GAME_DIALOG, open: false });

    yield put({ type: types.SET_LAST_VISITED_URL, pageName: pageNames.GAME_DETAIL_PAGE });
    history.push(urls.HOME_PAGE);
    yield put(showMessage('Game has been removed.'));
  } catch (error) {
    yield resolveError(types.REMOVE_GAME_FAILURE, error);
  } finally {
    yield put({ type: types.REMOVE_GAME_FINISHED });
  }
}

function* watchLoadGameDetailsForMultipleGames({ gameIds, token }) {
  try {
    yield put({ type: types.START_LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES });
    let fetchedGames = yield all(
      gameIds.map(id => {
        return call(gamesApi.getGame, id, token);
      })
    );
    fetchedGames = fetchedGames.map(game => game.jsonData);
    yield put({ type: types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_SUCCESS, fetchedGames: fetchedGames });
  } catch (error) {
    yield resolveError(types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FAILURE, error);
  } finally {
    yield put({ type: types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES_FINISHED });
  }
}

function* watchSubmitRating({ payload, token }) {
  try {
    yield put({ type: types.START_SUBMIT_RATING });
    const { jsonData } = yield call(gamesApi.submitRating, payload, token);
    yield put({ type: types.SUBMIT_RATING_SUCCESS, createdRating: jsonData });
  } catch (error) {
    yield resolveError(types.SUBMIT_RATING_FAILURE, error);
  } finally {
    yield put({ type: types.SUBMIT_RATING_FINISHED });
  }
}

function* watchUpdateGameRating({ payload, token }) {
  try {
    yield put({ type: types.START_UPDATE_GAME_RATING });
    const { jsonData } = yield call(gamesApi.updateRating, payload, token);
    yield put({ type: types.UPDATE_GAME_RATING_SUCCESS, updatedRating: jsonData });
  } catch (error) {
    yield resolveError(types.UPDATE_GAME_RATING_FAILURE, error);
  } finally {
    yield put({ type: types.UPDATE_GAME_RATING_FINISHED });
  }
}

function* watchGetGameRating({ payload, token }) {
  try {
    yield put({ type: types.START_GET_GAME_RATING });
    const { jsonData } = yield call(gamesApi.getGameRating, payload, token);
    yield put({ type: types.GET_GAME_RATING_SUCCESS, createdRating: jsonData });
  } catch (error) {
    yield resolveError(types.GET_GAME_RATING_FAILURE, error);
  } finally {
    yield put({ type: types.GET_GAME_RATING_FINISHED });
  }
}

function* watchUpdateGame({ game, token }) {
  try {
    yield put({ type: types.START_UPDATE_GAME });

    const { jsonData } = yield call(gamesApi.updateGame, game, token);

    //upload attachments if there is any
    const attachments = attachmentsToBeAdded(game);

    if (typeof attachments !== 'undefined' && attachments.length > 0) {
      let gameId = game.id;
      yield put({ type: types.UPLOAD_ATTACHMENTS_FOR_GAME, gameId, attachments, token });
    }

    const attachmentToDelete = attachmentsToBeDeleted(game);
    let mediaIdsArray = attachmentToDelete.map(g => g.mediaId);
    yield put({ type: types.DELETE_ATTACHMENTS_FOR_GAME, mediaIdsArray, token });

    yield put({ type: types.UPDATE_GAME_SUCCESS, game: jsonData });
    yield put(showMessage('Game has been updated.'));
    yield put({ type: types.CLEAR_UPDATED_ITEM });
    yield put({ type: types.CLEAR_SINGLE_GAME });
    yield put({ type: types.LOAD_GAME, id: game.id, token });
  } catch (error) {
    yield resolveError(types.UPDATE_GAME_FAILURE, error);
  } finally {
    yield put({ type: types.UPDATE_GAME_FAILURE });
  }
}

const attachmentsToBeAdded = game => {
  function comparer(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          // eslint-disable-next-line
          return other.mediaFileName == current.name;
          // eslint-disable-next-line
        }).length == 0
      );
    };
  }
  var result = game.attachmentFiles.filter(comparer(game.existingAttachments));
  return result;
};

const attachmentsToBeDeleted = game => {
  function comparer(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          // eslint-disable-next-line
          return other.name == current.mediaFileName;
          // eslint-disable-next-line
        }).length == 0
      );
    };
  }
  var result = game.existingAttachments.filter(comparer(game.attachmentFiles));
  return result;
};

//This is used to catch all the errors in case of request unsuccessful, and will extract the actual error message.
function* resolveError(type, error) {
  let errorMessageCaptured = false;
  let completeErrorMessage = {};
  if (!error.ok) {
    try {
      const clone = error.clone();
      yield clone.text().then(text => {
        completeErrorMessage = JSON.parse(text);
        errorMessageCaptured = true;
      });
    } catch (err) {
      errorMessageCaptured = true;
      completeErrorMessage = err;
    }

    if (errorMessageCaptured) {
      const errorMessage = extractErrorMessage(completeErrorMessage);
      yield put({ type, errorMessage });
    }
  }
}

function extractErrorMessage(completeErrorMessage) {
  let errorMessage = '';

  try {
    const message = completeErrorMessage.exceptionMessage
      ? completeErrorMessage.exceptionMessage.split('Content:')[1]
      : completeErrorMessage.messageDetail;

    errorMessage = JSON.parse(message).errorMessage;
    if (!errorMessage) {
      errorMessage = 'An error occured';
    }
  } catch (error) {
    errorMessage = 'An error occured';
  }

  return errorMessage;
}

export default function* gamesWatcher() {
  yield takeLatest(types.LOAD_GAME, watchLoadGame);
  yield takeLatest(types.LOAD_ALL_GAMES, watchLoadAllGame);
  yield takeLatest(types.LOAD_GAMES_FOR_CLASSROOM, watchLoadGamesForClassroom);
  yield takeLatest(types.LOAD_VARIATIONS_FOR_GAME, watchLoadDefaultVariationForGame);
  yield takeLatest(types.UPDATE_GAME_VARIATION_ID, watchChangeGameVariationId);
  yield takeLatest(types.ADD_GAMES, watchAddGames);
  yield takeLatest(types.REMOVE_GAME, watchRemoveGame);
  yield takeLatest(types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES, watchLoadGameDetailsForMultipleGames);
  yield takeLatest(types.SUBMIT_RATING, watchSubmitRating);
  yield takeLatest(types.GET_GAME_RATING, watchGetGameRating);
  yield takeLatest(types.UPDATE_GAME_RATING, watchUpdateGameRating);
  yield takeLatest(types.UPDATE_GAME, watchUpdateGame);
}
