import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperSheetContainerC: {},
  paperSheetContainer: {
    height: 595,
    width: 421,
    padding: 20,
    // '@media (max-width: 800px)': {
    '@media print': {
      width: '14cm', //Based on true A4 size dimensions
      height: '20cm',
      boxShadow: 'none'
    }
  }
}));

const PaperTile = props => {
  const classes = useStyles();

  return (
    <div className={classes.paperSheetContainerC}>
      <Paper className={classes.paperSheetContainer}>{props.children}</Paper>
    </div>
  );
};

export default PaperTile;
