import { connect } from 'react-redux';
import DeactiveChildDialog from '../../components/DeactiveChildDialog';

import { showDeactivateChildDialogStateChanged, deactiveChild } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    child: state.child.deactiveChild.item,
    open: state.child.deactiveChild.open,
    isDeactivatingChild: state.child.deactiveChild.isSaving,
    errorMessage: state.child.deactiveChild.errorMessage,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showDeactiveChildDialog: open => {
      dispatch(showDeactivateChildDialogStateChanged(open));
    },
    onConfirmDeactiveChild: (payload, token) => {
      dispatch(deactiveChild(payload, token));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeactiveChildDialog);
