import React from 'react';
import {
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
  Divider,
  Radio,
  FormControlLabel,
  Grid,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '../Dialog';
import { useAuth0 } from '../Auth';
import MapToAnswersObject from '../../filters/MapToAnswersObject';

const useStyles = makeStyles(theme => ({
  dialog: {},
  containerDiv: {
    overflow: 'hidden !important'
  },
  dialogTitle: {
    background: '#f0f0f0'
  },
  dialogContent: {
    width: 580,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: 'calc(100vh - 260px)',
    overflowY: 'scroll'
  },
  textField: {
    margin: 0,
    padding: 0
  },
  preferredNameTextField: {
    margin: '20px 0'
  },
  actionButton: {
    width: 180,
    float: 'right',
    margin: theme.spacing(2)
  },
  picker: {
    margin: 0,
    padding: 0
  },
  dropDown: {
    margin: 0,
    padding: 0
  },
  footer: {
    background: '#f0f0f0'
  },
  gridItem: {
    marginBottom: theme.spacing(1),
    display: 'inline-block'
  },
  subTitle: {
    display: 'block',
    color: theme.palette.subTitle.main
  },
  formDescription: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  questionContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  questionTitle: {
    fontSize: 16,
    fontWeight: 600
  },

  answersContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  horizontalAnswer: {},
  verticalAnswer: {},
  radioButton: {
    margin: '0 auto'
  },
  answeredProgress: {
    float: 'right',
    marginTop: -20,
    fontSize: 12,
    color: theme.palette.subTitle.main
  }
}));

const VerticalAnswerRadioButtons = props => {
  const classes = useStyles();
  const { question, questionId, handleChange, index } = props;
  return (
    <React.Fragment>
      <div className={classes.answersContainer}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={question.selectedId}
          onChange={e => handleChange(e, questionId, index)}
          row
        >
          <Grid container direction="row" justify="space-evenly" alignItems="stretch">
            {question &&
              question.options.map(option => {
                return (
                  <Grid item className={classes.horizontalAnswer} key={option.optionId}>
                    <FormControlLabel
                      value={option.optionId}
                      control={<Radio color="primary" />}
                      label={option.optionName}
                      labelPlacement="bottom"
                      className={classes.radioButton}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </RadioGroup>
      </div>
    </React.Fragment>
  );
};

const HorizontalAnswerRadioButtons = props => {
  const classes = useStyles();
  const { question, questionId, handleChange, index } = props;
  return (
    <div className={classes.answersContainer}>
      <RadioGroup
        aria-label="position"
        name="position"
        value={question.selectedId}
        onChange={e => handleChange(e, questionId, index)}
      >
        {question &&
          question.options.map(option => {
            return (
              <div className={classes.verticalAnswer} key={option.optionId}>
                <FormControlLabel
                  value={option.optionId}
                  control={<Radio color="primary" />}
                  label={option.optionName}
                  labelPlacement="end"
                  className={classes.radioButton}
                />
              </div>
            );
          })}
      </RadioGroup>
    </div>
  );
};

const ChildFeedbackDialog = props => {
  //Child is coming from ReportsTab component
  const {
    openDialog,
    onCloseDialog,
    isLoading,
    onSubmitClicked,
    child,
    report,
    onGetQuestionsForTheChild,
    enableCompleteButton,
    isSaving,
    activeStep,
    setActiveStep,
    completedSteps,
    setCompletedForIndex
  } = props;
  const { getTokenSilently } = useAuth0();
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      const { openDialog, currentChildReportTemplateId } = props;
      if (openDialog === true && child && currentChildReportTemplateId) {
        onGetQuestionsForTheChild(currentChildReportTemplateId, token);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  React.useEffect(() => {
    if (report && report.questions) {
      let percentage = completedSteps.length / report.questions.length;
      setCompleted(Math.ceil(percentage * 100));
    } else {
      setCompleted(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps.length]);

  const handleSubmit = e => {
    e.preventDefault();

    const { report, child, classroomId } = props;
    const { currentChildReportTemplateId } = props;
    const payload = MapToAnswersObject(report, child.id, classroomId, currentChildReportTemplateId);

    async function fetchToken() {
      return await getTokenSilently();
    }
    //Resolving promise
    fetchToken().then(token => {
      onSubmitClicked(payload, token);
    });
  };

  const handleAnswerChange = (e, questionId, index) => {
    props.onAnswerChange(e.target.value, questionId);

    setActiveStep(activeStep + 1);

    //If index already does not exist in completedSteps array,
    //  then add it to completedSteps array
    if (!completedSteps.includes(index)) {
      setCompletedForIndex(index);
    }

    //Scrolling the next element to top.
    var nextStep = document.getElementById(`step${index + 1}`);
    nextStep.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const onQuestionClick = index => {
    setActiveStep(index);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
      scroll="paper"
    >
      {isLoading || isSaving ? <LinearProgress /> : ''}

      <div id="abcd" className={classes.containerDiv}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {child ? `${child.firstName} ${child.lastName}` : ''}
          <Typography variant="caption" className={classes.subTitle}>
            {report && report.formTitle}
          </Typography>

          {/* Total questions answered count */}
          {report && report.questions ? (
            <div className={classes.answeredProgress}>{`${completedSteps.length} / ${report.questions.length}`}</div>
          ) : (
            ''
          )}
        </DialogTitle>
        {completed > 0 ? <LinearProgress variant="determinate" value={completed} /> : ''}
        <form onSubmit={handleSubmit}>
          <DialogContent className={classes.dialogContent} id="feedbackDialogContent">
            <Typography className={classes.formDescription}>{report && report.description}</Typography>
            <Divider />

            {report ? ( //This is being added to fix console error.
              <Stepper activeStep={activeStep} orientation="vertical">
                {report.questions &&
                  report.questions.map((question, i) => {
                    const stepProps = {};

                    if (completedSteps && completedSteps.includes(i)) {
                      stepProps.completed = true;
                    } else {
                      stepProps.completed = false;
                    }

                    return (
                      <Step key={question.questionId} {...stepProps} id={`step${question.questionId}`}>
                        <StepLabel className={classes.questionTitle} onClick={() => onQuestionClick(i)}>
                          {question.questionTitle}
                        </StepLabel>
                        <StepContent>
                          <React.Fragment>
                            {question && question.options && question.options.length <= 4 ? (
                              <VerticalAnswerRadioButtons
                                question={question}
                                questionId={question.questionId}
                                handleChange={handleAnswerChange}
                                index={i}
                              />
                            ) : (
                              <HorizontalAnswerRadioButtons
                                question={question}
                                questionId={question.questionId}
                                handleChange={handleAnswerChange}
                                index={i}
                              />
                            )}
                          </React.Fragment>
                        </StepContent>
                      </Step>
                    );
                  })}
              </Stepper>
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions className={classes.footer}>
            <Button
              onClick={onCloseDialog}
              color="primary"
              variant="outlined"
              className={classes.actionButton}
              id="cancelButton"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.actionButton}
              id="addChildButton"
              disabled={!enableCompleteButton}
            >
              Complete
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default ChildFeedbackDialog;
