import { combineReducers } from 'redux';

import game from './game';
import games from './games';
import classroom from './classroom';
import general from './general';
import medias from './medias'; //crappy naming convention. I know.
import media from './media';
import children from './children';
import child from './child';
import notification from './notification';
import notifications from './notifications';

export default combineReducers({
  game,
  games,
  classroom,
  general,
  medias,
  media,
  children,
  child,
  notification,
  notifications
});
