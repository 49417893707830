// HomePage/index.js
import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Page from '../Page';
import ClassroomDashboard from '../../containers/ClassroomDashboard';
import ChildrenDashboard from '../../containers/ChildrenDashboard';
import Header from '../../containers/Header';
import ClassroomDashboardActionBar from '../../containers/ClassroomDashboardActionBar';
import ChildrenDashboardActionBar from '../../containers/ChildrenDashboardActionBar';
import MessageDisplay from '../../containers/MessageDisplay';
// import SplashScreen from '../SplashScreen';
import NotificationDrawer from '../../containers/NotificationDrawer';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#EEEEEE !important',
    height: '100vh',
    '@media print': {
      background: 'white !important'
    }
  }
}));

function HomePage(props) {
  const classes = useStyles();

  const {
    // isLoading,
    // isLoadingClassroom,
    // isLoadingMedia,
    // isLoadingImages,
    // onSplashScreenShowFinished,
    // splashScreenShownOnce,
    visibleTab
  } = props;

  // if (isLoading || isLoadingClassroom || isLoadingMedia || isLoadingImages) {
  //   if (!splashScreenShownOnce) {
  //     return (
  //       <SplashScreen
  //         onSplashScreenShowFinished={onSplashScreenShowFinished}
  //         isLoading={isLoading}
  //         isLoadingClassroom={isLoadingClassroom}
  //         isLoadingMedia={isLoadingMedia}
  //         isLoadingImages={isLoadingImages}
  //       />
  //     );
  //   }
  // }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Header />

        <MessageDisplay />

        <NotificationDrawer />

        <Page>
          {visibleTab === 0 ? (
            <React.Fragment>
              <ClassroomDashboardActionBar />
              <ClassroomDashboard />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ChildrenDashboardActionBar />
              <ChildrenDashboard />
            </React.Fragment>
          )}
        </Page>
      </div>
    </React.Fragment>
  );
}

HomePage.propTypes = {
  // describe the component's prop types here
};

HomePage.defaultProps = {
  // assign default props to the component here
};

export default HomePage;
