import colors from '../constants/colors';
import { gameTypes } from '../constants';

const mapDevelopmentTypeIdToVariationTypeAndColor = developmentTypeId => {
  switch (developmentTypeId) {
    case 1: //For Behavioural
      return { typePrefix: 'B', color: colors.GREEN, type: gameTypes.BEHAVIOURAL };
    case 2:
      return { typePrefix: 'C', color: colors.ORANGE, type: gameTypes.COGNITIVE };
    case 3:
      return { typePrefix: 'E', color: colors.RED, type: gameTypes.EMOTIONAL };
    default:
      return;
  }
};

export default mapDevelopmentTypeIdToVariationTypeAndColor;
