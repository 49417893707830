// AdminPage/index.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HeaderAdmin from '../../containers/HeaderAdmin';
import AdminDashboardActionBar from '../../containers/AdminDashboardActionBar';
import AdminDashboard from '../../containers/AdminDashboard';
import Page from '../Page';
// eslint-disable-next-line
import { userRolesNames } from '../../constants';
// eslint-disable-next-line
import NotAllowedToVisitThePage from '../NotAllowedToVisitThePage';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#EEEEEE !important',
    height: '100vh',
    '@media print': {
      background: 'white !important'
    }
  }
}));

const AdminPage = props => {
  const { userRoles, redirectToHomePage } = props;
  const classes = useStyles();

  if (userRoles && userRoles.length === 0) {
    return '';
  }

  // const isUserAllowed = true;
  const isUserAllowed = userRoles.some(ur => ur.name === userRolesNames.ADMIN);
  if (!isUserAllowed) {
    redirectToHomePage();
    // return <NotAllowedToVisitThePage />;
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <HeaderAdmin />

        <Page>
          <AdminDashboardActionBar />
          <AdminDashboard />
        </Page>
      </div>
    </React.Fragment>
  );
};

AdminPage.propTypes = {};

AdminPage.defaultProps = {};

export default AdminPage;
