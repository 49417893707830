import React from 'react';
import {
  makeStyles,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Checkbox,
  ListItemText
} from '@material-ui/core';

const styles = makeStyles(theme => {
  return {
    formControl: {
      minWidth: 120
    }
  };
});

/**
 * MultipleSelectDropDown has nice looking background and closer to
 *   material ui select
 */

const MultipleSelectDropDown = props => {
  const classes = styles();

  const { handleChange, label, items, value, dropDownWidth = '180px', htmlId, disabled, fullWidth } = props;

  const MapValueToName = selected => {
    var selectedItems = items.filter(item => {
      return selected.indexOf(item.value) !== -1;
    });

    const selectedNames = selectedItems.map(e => e.name).join(', ');

    return selectedNames;
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl} style={{ width: dropDownWidth }} variant="filled" id={htmlId}>
        <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
        <Select
          labelid="demo-mutiple-checkbox-label"
          id={htmlId}
          multiple
          value={value}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected => MapValueToName(selected)}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          {items.map(item => (
            <MenuItem key={item.value} value={item.value} id={`option${item.value}`}>
              <Checkbox color="primary" checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default MultipleSelectDropDown;
