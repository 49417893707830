// ChildrenList/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChildrenList from '../../components/ChildrenList';
import { loadAllChildren } from '../../actions';
import { getChildren } from '../../selectors';
import AddReportStatusToChildren from '../../filters/AddReportStatusToChildren';

const ConsolidateChildrenWithReportFilledStatues = (children, reportFilledStatuses) => {
  return AddReportStatusToChildren(children, reportFilledStatuses);
};

function mapStateToProps(state, ownProps) {
  const childId = ownProps.match.params.childId ? Number(ownProps.match.params.childId) : -1;

  const children = state.children.allChildren.items;

  const reportFilledStatuses = state.children.reportStatuses && state.children.reportStatuses.items;

  let updatedChildren;
  if (reportFilledStatuses) {
    updatedChildren = ConsolidateChildrenWithReportFilledStatues(children, reportFilledStatuses);
  }

  return {
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    children: getChildren(updatedChildren ? updatedChildren : children, childId),
    isLoadingChildren: state.children.allChildren.isLoading,
    searchTerm: state.children.allChildren.searchTerm
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { history } = ownProps;
  return {
    getAllChildrenForClassroom: (classroomId, token) => {
      dispatch(loadAllChildren(classroomId, token));
    },
    onChildClick: childId => {
      history.push(`/children/${childId}`);
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChildrenList)
);
