import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../components/Header';
import {
  getClassroomDetailsByEmailFromDb,
  changeTabValueInHeader,
  setLastVisitedPage,
  openNotificationDrawer,
  openConfirmChangePasswordDialog,
  loadNotifications,
  openLogOutDialog,
  loadUserRoles
} from '../../actions';
import { urls, pageNames } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  const { classroom } = state.classroom;
  return {
    classroomName: classroom && classroom.item && classroom.item.name,
    classroomId: classroom && classroom.item && classroom.item.id,
    isLoading: classroom && classroom.isLoading,
    isLoadingClassroom: state.games.gamesForClassroom.isLoading,
    isLoadingMedia: state.medias.gameMedias.isLoading,
    isLoadingImages: state.medias.actualImages.isLoading,
    visibleTab: state.general.visibleTab,
    isLoadingClassroomVariationDetailsForPrinting:
      state.games.classroomVariationDetailsForPrinting && state.games.classroomVariationDetailsForPrinting.isLoading,
    showNotificationDot: state.notifications.notification.show,
    isLoadingNotification: state.notifications.notification.isLoading,
    isLoadingGameDetailsForMultipleGames:
      state.games.gameDetailsForMultipleGames && state.games.gameDetailsForMultipleGames.isLoading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;

  return {
    onLogin: (email, token) => {
      dispatch(getClassroomDetailsByEmailFromDb(email, token));
    },
    onNotificationClicked: open => {
      dispatch(openNotificationDrawer(open));
    },
    onChangeTabValueInHeader: tabValue => {
      if (tabValue === 0) {
        history.push(urls.HOME_PAGE);
      } else if (tabValue === 1) {
        history.push(urls.CHILDREN);
      }
      dispatch(setLastVisitedPage(pageNames.ALL_GAMES));
      dispatch(changeTabValueInHeader(tabValue));
    },
    onChangePassword: () => {
      dispatch(openConfirmChangePasswordDialog(true));
    },
    onLoadNotifications: (classroomId, token) => {
      dispatch(loadNotifications(classroomId, token));
    },
    onShowLogoutDialog: open => {
      dispatch(openLogOutDialog(open));
    },
    onLoadUserRoles: (auth0UserId, token) => {
      dispatch(loadUserRoles(auth0UserId, token));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
