// EditChildDialog/index.js
import { connect } from 'react-redux';

import EditChildDialog from '../../components/EditChildDialog';
import { openEditChildDialogBox, changeUpdateChildInputField, updateChild } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    openDialog: state.child.showEditChildDialog,
    child: state.child.child.item,
    isSaving: state.child.child.isLoading
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseDialog: () => {
      dispatch(openEditChildDialogBox(false));
    },
    onInputFieldTextChange: (value, inputFieldName) => {
      dispatch(changeUpdateChildInputField(value, inputFieldName));
    },
    onUpdateChild: (child, token) => {
      dispatch(updateChild(child, token));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditChildDialog);
