// PrintingDialogBox/index.js
import { connect } from 'react-redux';
import PrintingDialogBox from '../../components/PrintingDialogBox';

import {
  fireOpenPrintingDialogBoxInstruction,
  openPrintingDialogBox,
  setGameImageDimensions,
  clearGameDetailsForMultipleGames
} from '../../actions';

function mapStateToProps(state, ownProps) {
  const { mode } = ownProps;
  const game = state.game.game.item;
  const games = state.games.gameDetailsForMultipleGames && state.games.gameDetailsForMultipleGames.items;

  return {
    openDialog: state.game.openPrintingDialogBox,
    gameImage: state.media.actualImage.item,
    gameImageDimensionRatio: state.media.actualImage.dimensionRatio,
    mode,
    data: mode === 'single-page' ? game : games
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseDialog: () => {
      dispatch(clearGameDetailsForMultipleGames());
      dispatch(openPrintingDialogBox(false));
      //To fix the bug of printing all games dialog opening when coming back from Children page
      dispatch(fireOpenPrintingDialogBoxInstruction(false));
    },
    onSetGameImageDimensions: (dimensions, gameId) => {
      dispatch(setGameImageDimensions(dimensions, gameId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintingDialogBox);
