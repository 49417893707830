// AdminDashboard/index.js
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemAvatar, ListItem, List, Divider, Typography, Chip } from '@material-ui/core';

import { useAuth0 } from '../Auth';
import mapDevelopmentTypeIdToVariationTypeAndColor from '../../filters/mapDevelopmentTypeIdToVariationTypeAndColor';
import Highlight from '../../utils/highlight';
import EmptySearchResultsMessage from '../EmptySearchResultsMessage';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    marginTop: 20
  },
  singleGame: {
    height: 175,
    cursor: 'pointer'
  },
  chip: {
    color: 'white !important',
    paddingTop: 2,
    fontWeight: 600,
    fontSize: 10,
    height: 22,
    marginTop: 4
  },
  imageContainer: {
    marginRight: 10,
    height: 135,
    width: 240,
    backgroundPosition: 'center !important',
    backgroundSize: 'cover !important'
  },
  gameName: {
    fontSize: 20,
    fontWeight: 600,
    color: 'black'
  },
  gameDescription: {
    fontSize: 16,
    color: 'black'
  },
  gameNameContainer: {},
  chipContainer: {
    marginBottom: 5
  },
  descriptionContainer: {},
  emptySearchMessageContainer: {
    height: 'calc(100vh - 180px)',
    width: '100%',
    position: 'relative'
  },
  emptySearchMessageContent: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const AdminDashboard = props => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();

  async function fetchToken() {
    return await getTokenSilently();
  }

  useEffect(() => {
    //Resolving promise
    fetchToken().then(token => {
      props.onGetAllGames(token);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Props
  const { allGames, isLoadingGames, onEditGameClick, isLoadingImages, searchTerm } = props;

  //Event handlers
  const handleEditGameClick = gameId => {
    onEditGameClick(gameId);
  };

  if (!allGames) {
    return '';
  }

  if (searchTerm !== '' && allGames && allGames.length === 0) {
    return (
      <div className={classes.emptySearchMessageContainer}>
        <div className={classes.emptySearchMessageContent}>
          <EmptySearchResultsMessage />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <List className={classes.root}>
        {!isLoadingGames && (
          <React.Fragment>
            <Divider />
            <Divider />
          </React.Fragment>
        )}
        {allGames &&
          allGames.map(game => {
            const mappedItem = mapDevelopmentTypeIdToVariationTypeAndColor(game.developmentTypeId);

            return (
              <React.Fragment key={game.id}>
                <ListItem
                  alignItems="flex-start"
                  key={game.id}
                  className={classes.singleGame}
                  onClick={() => handleEditGameClick(game.id)}
                >
                  <React.Fragment>
                    <ListItemAvatar>
                      <React.Fragment>
                        {!isLoadingImages && game.image && (
                          <div
                            className={classes.imageContainer}
                            style={{
                              background: `url(${game.image})`
                            }}
                          ></div>
                        )}

                        {!game.image && (
                          <div
                            className={classes.imageContainer}
                            style={{
                              background: '#eeeeee'
                            }}
                          ></div>
                        )}
                      </React.Fragment>
                    </ListItemAvatar>
                    <div>
                      <div className={classes.gameNameContainer}>
                        <Typography component="span" variant="h6" className={classes.gameName}>
                          <Highlight text={game.name} highlight={searchTerm} />
                          {/* {game.name} */}
                        </Typography>
                      </div>
                      <div className={classes.chipContainer}>
                        <Chip
                          label={mappedItem.type.charAt(0).toUpperCase() + mappedItem.type.slice(1)}
                          className={classes.chip}
                          style={{ background: mappedItem.color }}
                        />
                      </div>
                      <div className={classes.descriptionContainer}>
                        <Typography component="span" variant="body2" className={classes.gameDescription}>
                          {game.description}
                        </Typography>
                      </div>
                    </div>
                  </React.Fragment>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
      </List>
    </React.Fragment>
  );
};

AdminDashboard.propTypes = {
  // describe the component's prop types here
};

AdminDashboard.defaultProps = {
  // assign default props to the component here
};

export default AdminDashboard;
