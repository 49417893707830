import { ethnicities } from '../../constants';

export const MapEthnicityValueToName = ethnicityValue => {
  const allEthnicities = ethnicities();

  const splitedEthnicities = ethnicityValue.split(',');
  var selectedEthnicities = allEthnicities.filter(item => {
    return splitedEthnicities.indexOf(item.value) !== -1;
  });

  const ethnicity = selectedEthnicities.map(e => e.name).join(', ');

  return ethnicity;
};
