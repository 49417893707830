import React, { useEffect } from 'react';
import {
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Typography, LinearProgress } from '@material-ui/core';

import { ratings } from '../../constants';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12
  },

  container: {},
  smiley: {
    display: 'inline-block',
    padding: '7px 7px 1px 7px',
    margin: '7px 7px 7px 0',
    borderRadius: 50,
    cursor: 'pointer !important'
  },
  colorNegativeRating: {
    border: `1px solid ${theme.palette.danger.default}`,
    color: theme.palette.danger.default,
    cursor: 'pointer !important'
  },
  colorNeutralRating: {
    border: `1px solid ${theme.palette.gameColor.blue}`,
    color: theme.palette.gameColor.blue,
    cursor: 'pointer !important'
  },
  colorPositiveRating: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    cursor: 'pointer !important'
  },
  negativeFilled: {
    color: 'white',
    background: theme.palette.danger.default,
    pointerEvents: 'none'
  },
  neutralFilled: {
    color: 'white',
    background: theme.palette.gameColor.blue,
    pointerEvents: 'none'
  },
  positiveFilled: {
    color: 'white',
    background: theme.palette.secondary.main,
    pointerEvents: 'none'
  },
  disable: {
    pointerEvents: 'none',
    opacity: '0.4'
  }
}));

const Smileies = props => {
  const classes = useStyles();

  const { handleRatingClick, gameRating, getRatingForGame, isLoadingOrSavingRateGame } = props;
  useEffect(() => {
    if (!gameRating) {
      //ClassroomId and GameId is added on GameDetails component.
      getRatingForGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${classes.container} ${isLoadingOrSavingRateGame ? classes.disable : ''}`}>
      <div
        className={`${classes.smiley} ${classes.colorNegativeRating} 
          ${gameRating && gameRating.rating === ratings.NEGATIVE ? classes.negativeFilled : null}
        `}
        onClick={() => handleRatingClick(ratings.NEGATIVE)}
      >
        <SentimentVeryDissatisfiedIcon />
      </div>
      <div
        className={`${classes.smiley} ${classes.colorNeutralRating}
          ${gameRating && gameRating.rating === ratings.NEUTRAL ? classes.neutralFilled : null}        
        `}
        onClick={() => handleRatingClick(ratings.NEUTRAL)}
      >
        <SentimentDissatisfiedIcon />
      </div>
      <div
        className={`${classes.smiley} ${classes.colorPositiveRating}
          ${gameRating && gameRating.rating === ratings.POSITIVE ? classes.positiveFilled : null}        
        `}
        onClick={() => handleRatingClick(ratings.POSITIVE)}
      >
        <SentimentVerySatisfiedIcon />
      </div>
    </div>
  );
};

const GameRating = props => {
  const classes = useStyles();
  const { handleRatingClick, gameRating, getRatingForGame, isLoadingOrSavingRateGame } = props;

  return (
    <React.Fragment>
      <Typography variant="caption" className={classes.label}>
        Rate Game
      </Typography>
      {isLoadingOrSavingRateGame && <LinearProgress />}
      <Smileies
        handleRatingClick={handleRatingClick}
        gameRating={gameRating}
        getRatingForGame={getRatingForGame}
        isLoadingOrSavingRateGame={isLoadingOrSavingRateGame}
      />
    </React.Fragment>
  );
};

export default GameRating;
