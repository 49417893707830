export const PRINT_GAME = 'PRINT';
export const GAME_FEEDBACK = 'FEEDBACK';
export const REMOVE_GAME = 'REMOVE GAME';
export const SELECT_GAME = 'SELECT GAME';
export const SELECTED = 'SELECTED';
export const EDIT = 'EDIT';
export const TRANSFER = 'TRANSFER';
export const DEACTIVATE = 'DEACTIVATE';

export const DELETE = 'DELETE';
export const PREVIEW = 'PREVIEW';
export const SAVE = 'SAVE';

export default {
  PRINT_GAME,
  GAME_FEEDBACK,
  REMOVE_GAME,
  SELECT_GAME,
  SELECTED,
  EDIT,
  TRANSFER,
  DEACTIVATE,

  DELETE,
  PREVIEW,
  SAVE
};
