// ClassroomDashboard/index.js
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClassroomDashboard from '../../components/ClassroomDashboard';
import {
  getGamesForClassroom,
  clearGamesForHomePage,
  setLastVisitedPage,
  loadVariationDetailsForAllGames
} from '../../actions';
import colors from '../../constants/colors';
import { urls, pageNames } from '../../constants';
import consolidateGamesWithGameMedia from '../../filters/consolidateGamesWithGameMedia';

function mapStateToProps(state, ownProps) {
  const gamesForClassroom = state.games.gamesForClassroom.items;
  const gameMedia = state.medias.gameMedias.items;
  const actualImagesLoaded = gameMedia.some(g => g.image);

  const classroomVariationDetailsForPrinting =
    state.games.classroomVariationDetailsForPrinting &&
    state.games.classroomVariationDetailsForPrinting.item &&
    state.games.classroomVariationDetailsForPrinting.item.games;

  const behaviouralGamesForPrinting =
    classroomVariationDetailsForPrinting &&
    classroomVariationDetailsForPrinting.filter(game => game.developmentTypeId === 1);
  const cognitiveGamesForPrinting =
    classroomVariationDetailsForPrinting &&
    classroomVariationDetailsForPrinting.filter(game => game.developmentTypeId === 2);
  const emotionalGamesForPrinting =
    classroomVariationDetailsForPrinting &&
    classroomVariationDetailsForPrinting.filter(game => game.developmentTypeId === 3);

  if (behaviouralGamesForPrinting && cognitiveGamesForPrinting && emotionalGamesForPrinting) {
    behaviouralGamesForPrinting.title = 'Behavioural';
    behaviouralGamesForPrinting.color = colors.GREEN;

    cognitiveGamesForPrinting.title = 'Cognitive';
    cognitiveGamesForPrinting.color = colors.ORANGE;

    emotionalGamesForPrinting.title = 'Emotional';
    emotionalGamesForPrinting.color = colors.RED;
  }
  if (actualImagesLoaded) {
    consolidateGamesWithGameMedia(gamesForClassroom, gameMedia);
  }

  const behaviouralGames = gamesForClassroom.filter(game => game.developmentTypeId === 1);
  const cognitiveGames = gamesForClassroom.filter(game => game.developmentTypeId === 2);
  const emotionalGames = gamesForClassroom.filter(game => game.developmentTypeId === 3);

  behaviouralGames.title = 'Behavioural';
  behaviouralGames.color = colors.GREEN;

  cognitiveGames.title = 'Cognitive';
  cognitiveGames.color = colors.ORANGE;

  emotionalGames.title = 'Emotional';
  emotionalGames.color = colors.RED;

  const { classroom } = state.classroom;

  return {
    behaviouralGames,
    cognitiveGames,
    emotionalGames,
    behaviouralGamesForPrinting,
    cognitiveGamesForPrinting,
    emotionalGamesForPrinting,
    classroomId: classroom.item && classroom.item.id,
    isLoadingImages: state.medias.actualImages.isLoading,
    searchTerm: state.games.gamesForClassroom.searchTerm
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { history } = ownProps;
  return {
    onGetGamesForClassroom: (classroomId, token) => {
      //This is to clear the games on the home page before they are being fetched again
      dispatch(clearGamesForHomePage());
      dispatch(getGamesForClassroom(classroomId, token));
    },
    onGameClick: gameId => {
      dispatch(setLastVisitedPage(pageNames.HOME_PAGE));
      history.push(urls.ALL_GAMES + `/${gameId}`);
    },
    onLoadVariationDetailsForAllGames: (classroomId, token) => {
      dispatch(loadVariationDetailsForAllGames(classroomId, token));
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClassroomDashboard)
);
