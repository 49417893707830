import { handleActions } from 'redux-actions';

import { types } from '../constants';

const INITIAL_STATE = {
  gameMedias: {
    //crappy naming convention. I know.
    items: [],
    isLoading: false,
    errorMessage: null
  },
  actualImages: {
    // actualImages.items are not being used actively at the moment. But i ll keep them for future.
    items: [],
    isLoading: false,
    errorMessage: null
  }
};

const startLoadMedia = state => {
  return {
    ...state,
    gameMedias: {
      ...state.gameMedias,
      isLoading: true
    }
  };
};

const loadMediaSuccess = (state, { gameMedias }) => {
  return {
    ...state,
    gameMedias: {
      ...state.gameMedias,
      items: gameMedias
    }
  };
};

const loadMediaFailure = (state, { errorMessage }) => {
  return {
    ...state,
    gameMedias: {
      ...state.gameMedias,
      errorMessage
    }
  };
};

const loadMediaFinished = state => {
  return {
    ...state,
    gameMedias: {
      ...state.gameMedias,
      isLoading: false
    }
  };
};

const startLoadActualImages = state => {
  return {
    ...state,
    actualImages: {
      ...state.actualImages,
      isLoading: true
    }
  };
};

const loadActualImagesSuccess = (state, { images }) => {
  const consolidatedGameMedias = consolidateGameMediaAndActualImages(state.gameMedias.items, images);
  return {
    ...state,
    actualImages: {
      ...state.actualImages,
      items: images
    },
    gameMedias: {
      ...state.gameMedias,
      items: consolidatedGameMedias
    }
  };
};

const consolidateGameMediaAndActualImages = (gameMedias, actualImages) => {
  const consolidatedGameMedias = gameMedias.map(gameMedia => {
    var selectedImage = actualImages.filter(i => i.mediaId === gameMedia.mediaId);
    gameMedia.image = selectedImage[0].image; //[0] because .filter returns an array.

    return gameMedia;
  });
  return consolidatedGameMedias;
};

const loadActualImagesFailure = (state, { errorMessage }) => {
  return {
    ...state,
    actualImages: {
      ...state.actualImages,
      errorMessage
    }
  };
};

const loadActualImagesFinished = state => {
  return {
    ...state,
    actualImages: {
      ...state.actualImages,
      isLoading: false
    }
  };
};

const clearAllExistingMediaAndImages = state => {
  return {
    ...state,
    gameMedias: {
      ...state.gameMedias,
      items: []
    },
    actualImages: {
      ...state.actualImages,
      items: []
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.START_LOAD_MEDIA]: startLoadMedia,
    [types.LOAD_MEDIA_SUCCESS]: loadMediaSuccess,
    [types.LOAD_MEDIA_FAILURE]: loadMediaFailure,
    [types.LOAD_MEDIA_FINISHED]: loadMediaFinished,

    [types.START_LOAD_ACTUAL_IMAGES]: startLoadActualImages,
    [types.LOAD_ACTUAL_IMAGES_SUCCESS]: loadActualImagesSuccess,
    [types.LOAD_ACTUAL_IMAGES_FAILURE]: loadActualImagesFailure,
    [types.LOAD_ACTUAL_IMAGES_FINISHED]: loadActualImagesFinished,

    [types.CLEAR_ALL_EXISTING_MEDIA_AND_IMAGES]: clearAllExistingMediaAndImages
  },
  INITIAL_STATE
);
