// AdminDashboardActionBar/index.js
import { connect } from 'react-redux';
import AdminDashboardActionBar from '../../components/AdminDashboardActionBar';

import { openCreateGameDialogBox, searchTermChangeForAllGames } from '../../actions';

function mapStateToProps(state, ownProps) {
  return {
    searchTerm: state.games.allGames.searchTerm
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onOpenCreateGameDialogBox: () => {
      dispatch(openCreateGameDialogBox(true));
    },
    onSearchTermChange: searchTerm => {
      dispatch(searchTermChangeForAllGames(searchTerm));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardActionBar);
