// MessageQueue/index.js
import { connect } from 'react-redux';
import MessageDisplay from '../../components/MessageDisplay';
import { hideMessage, nextMessage } from '../../actions';

function mapStateToProps(state, ownProps) {
  const exists = state.general.message.queue.length > 0;
  const text = exists ? state.general.message.queue[0] : '';

  return {
    visible: state.general.message.visible,
    text
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    hideMessage: () => {
      dispatch(hideMessage());
    },
    nextMessage: () => {
      dispatch(nextMessage());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageDisplay);
