import React from 'react';

import StandardDialog from './../StandardDialog';
import { useAuth0 } from '../Auth';
import { childStatus } from '../../constants';

const DeactiveChildDialog = props => {
  const { child, open, showDeactiveChildDialog, errorMessage, isDeactivatingChild, classroomId } = props;

  const { getTokenSilently } = useAuth0();

  async function fetchToken() {
    return await getTokenSilently();
  }

  const handleConfirmDeactive = async event => {
    event.preventDefault();

    const { child, onConfirmDeactiveChild } = props;

    fetchToken().then(token => {
      const toDeactivateChild = { ...child, childStatus: childStatus.DEACTIVE };
      const payload = { toDeactivateChild, classroomId };
      onConfirmDeactiveChild(payload, token);
    });
  };

  return (
    <StandardDialog
      open={open}
      title={`Deactivate ${child.firstName} ${child.lastName}`}
      description="This child profile will be permanently deactivated. Currently, profiles can only be re-activated on request
        by Engage administrators."
      actionButtonText="Deactivate"
      cancelButtonText="Cancel"
      handleClose={() => showDeactiveChildDialog(false)}
      handleSubmit={handleConfirmDeactive}
      showProgressBar={isDeactivatingChild}
      errorMessage={errorMessage}
      isDanger={true}
    />
  );
};

export default DeactiveChildDialog;
