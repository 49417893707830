const accommodateForBulletPoints = (text, delimiter) => {
  let textArray = text.trim().split(delimiter);
  const filteredTextArray = textArray.filter(text => text !== '');
  return filteredTextArray;
};

export const convertArrayToString = instructionsArray => {
  //Removing empty string arrays
  instructionsArray = instructionsArray.filter(i => i !== '');

  const delimiter = '•';
  return `•${instructionsArray.join(delimiter)}`;
};

export default accommodateForBulletPoints;
