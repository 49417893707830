import mapDevelopmentTypeIdToVariationTypeAndColor from './mapDevelopmentTypeIdToVariationTypeAndColor';

const mapVariationsToDropDownFriendlyFormat = variations => {
  let items = [];

  variations.map(variation => {
    const typeAndColor = mapDevelopmentTypeIdToVariationTypeAndColor(variation.developmentTypeId);

    const item = {
      value: variation.id,
      name: ` ${variation.description}`,
      prefix: `${typeAndColor.typePrefix}${variation.levelNumber}`,
      color: typeAndColor.color
    };

    items = [...items, item];

    return items;
  });
  return items;
};

export default mapVariationsToDropDownFriendlyFormat;
