import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import EmptyDashboardImage from '../../../res/empty-dashboard-image.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  content: {
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  imageContainer: {
    position: 'relative'
  },
  emptyDashboardImage: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  textContainer: {
    marginTop: 90
  },
  text: {
    textAlign: 'center'
  },
  heading: {
    fontSize: 25,
    color: theme.palette.primary.main
  },
  subHeading: {
    fontSize: 20
  }
}));

const EmptyClassroomDashboard = props => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          <img src={EmptyDashboardImage} alt="Empty Dashboard" className={classes.emptyDashboardImage} />
        </div>
        <div className={classes.textContainer}>
          <Typography className={`${classes.text} ${classes.heading}`}>Add some games to get started!</Typography>
          <Typography className={`${classes.text} ${classes.subHeading}`}>
            Make sure to select a few from each colour
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EmptyClassroomDashboard;
