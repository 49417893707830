import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    height: '100%'
  },
  pageContainer: {
    padding: 20
  },
  pageContent: {
    border: '1px solid red',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    height: '92vh'
    // backgroundColor: 'yellow'
  },
  sheetOne: {
    // backgroundColor: 'red',
    flex: 1,
    border: '5pt solid #0070a2',
    borderRadius: 10,
    padding: 20
  },
  sheetOneContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sheetOneTitleContainer: {},

  gameTitle: {
    fontSize: 30,
    color: '#0070a2',
    textAlign: 'center'
  },
  sheetOneImageContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  gameImage: {
    height: 235
    // width: '350px'
  },
  sheetOneDescriptionContainer: {},
  gameDescription: {
    textAlign: 'left',
    fontSize: 20
  },
  footerContainer: {
    marginTop: 40
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    justifyContent: 'space-between'
  },
  gameTypeContainer: {},
  gameType: {
    backgroundColor: '#0070a2',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: 'white',
    borderRadius: 10
  },
  logoContainer: {},
  appLogo: {
    width: 100
  },

  /*Sheet 2*****************************************************/
  sheetTwo: {
    flex: 1
  },
  section: {
    margin: '20px 20px 20px 20px'
  },
  pg2Headings: {
    color: '#0070a2',
    textTransform: 'uppercase',
    fontSize: 14
  },
  blueNumbering: {
    color: '#0070a2'
  },
  orangeNumbering: {
    color: 'orange'
  },
  requirementsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  requirement: {
    marginTop: 10,
    border: '1pt solid gray',
    backgroundColor: 'gray',
    borderRadius: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 2,
    color: 'white',
    flex: '1 1 150pt'
  }

  /*Sheet 3*****************************************************/
});

export default styles;
