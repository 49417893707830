import { StyleSheet, Font } from '@react-pdf/renderer';

import Roboto_Bold from './../../../Font/Roboto_Bold.ttf';
import Roboto_Normal from './../../../Font/Roboto-Light.ttf';

Font.register({
  family: 'Roboto_Bold',
  src: Roboto_Bold
});

Font.register({
  family: 'Roboto_Regular',
  src: Roboto_Normal
});

const styles = StyleSheet.create({
  sheetTwo: {
    flex: 1
  },
  section: {
    margin: '0px 20px 20px 20px'
  },
  pg2Headings: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 800,
    fontFamily: 'Roboto_Bold',
    marginBottom: 5
  },
  singleDirection: {
    display: 'flex',
    padding: '3px 0',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'Roboto_Regular'
  },
  blueNumbering: {
    fontFamily: 'Roboto_Bold'
  },
  bulletPoints: {
    // display: 'inline-block',
    fontSize: 20,
    marginTop: -5
  },
  instruction: {
    fontSize: 10,
    fontFamily: 'Roboto_Regular',
    marginLeft: 5
  },
  orangeNumbering: {
    color: 'orange',
    ontFamily: 'Roboto_Bold'
  },
  requirementsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  requirement: {
    marginTop: 10,
    border: '1pt solid gray',
    backgroundColor: '#E0E0E0',
    borderRadius: 10,
    paddingLeft: 7,
    paddingTop: 3,
    paddingBottom: 2,
    paddingRight: 7,
    color: '#333333',
    flex: '1 1 150pt',
    fontSize: 12,
    fontFamily: 'Roboto_Regular',
    marginRight: 5
  }
});

export default styles;
