import React from 'react';
import { makeStyles } from '@material-ui/core';

import PaperTile from '../../PaperTile';
import SingleInstruction from '../SingleInstruction';
import accommodateForBulletPoints from '../../../filters/accommodateForBulletPoints';

const useStyles = makeStyles(theme => ({}));

const TipsAndNotesPaperTile = props => {
  const classes = useStyles();

  const { gameInstructions, gameColor } = props;

  if (!gameInstructions) {
    return '';
  }

  return (
    <PaperTile>
      <div className={`container ${classes.container}`}>
        <SingleInstruction
          title="Tips"
          body={gameInstructions.tips && accommodateForBulletPoints(gameInstructions.tips.trim(), '•')}
          color={gameColor}
        />
        <SingleInstruction title="Notes" body=" " color={gameColor} />
      </div>
    </PaperTile>
  );
};

export default TipsAndNotesPaperTile;
