import React from 'react';
import {
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../Dialog';

import { childStatus } from '../../constants';
import { useAuth0 } from '../Auth';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: 580,
    marginTop: -24,
    marginBottom: -10
  },
  textField: {
    margin: 0,
    padding: 0
  },
  transferCenterEmail: {
    margin: '20px 0'
  },
  actionButton: {
    width: 180,
    float: 'right',
    margin: theme.spacing(2)
  },
  gridItem: {
    marginBottom: theme.spacing(1),
    display: 'inline-block'
  },
  detail: {
    '& h6': {
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0.5
    }
  },
  dialogTitle: {
    marginTop: theme.spacing(1),
    '& h2': {
      fontSize: 20,
      fontWeight: 600
    }
  },
  transferEmailField: {
    marginTop: -10,
    marginBottom: -8
  }
}));

const TransferChildDialog = props => {
  const classes = useStyles();
  const { openDialog, onCloseDialog, child, isTransfering, classroomId, enableTransferButton } = props;

  const { getTokenSilently } = useAuth0();

  const handleInputChange = event => {
    props.onInputFieldTextChange(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { child, onTranferChild } = props;

    async function fetchToken() {
      return await getTokenSilently();
    }

    //Resolving promise
    fetchToken().then(token => {
      const toTransferChild = { ...child, childStatus: childStatus.TRANSFERRING };
      const payload = { toTransferChild, classroomId }; // passing classroom Id to call load children api once transfer success
      onTranferChild(payload, token);
    });
  };

  if (!child) {
    return '';
  }

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      {isTransfering ? <LinearProgress /> : ''}
      <DialogTitle
        id="form-dialog-title"
        className={classes.dialogTitle}
      >{`Request Transfer of ${child.firstName} ${child.lastName}`}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography className={classes.detail} gutterBottom>
                Please enter the email address for the centre {child.firstName} is transfering to.
              </Typography>
            </Grid>

            <Grid item xs={12} className={`${classes.gridItem} ${classes.transferEmailField}`}>
              <TextField
                id="transferEmail"
                label="Email"
                className={`${classes.textField} ${classes.transferCenterEmail}`}
                margin="normal"
                variant="filled"
                autoComplete="off"
                fullWidth
                onChange={e => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button
            onClick={onCloseDialog}
            color="primary"
            variant="outlined"
            className={classes.actionButton}
            id="cancelButton"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.actionButton}
            id="transferChildButton"
            disabled={!enableTransferButton}
          >
            Request
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferChildDialog;
