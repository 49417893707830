import { call, put, takeLatest } from 'redux-saga/effects';

import { types } from '../constants';
import * as childrenApi from '../api/children';
import MapFromPascelToCamelCase from '../filters/MapFromPascelToCamelCase';
import { showMessage } from '../actions';

function* watchLoadChildren({ classroomId, token }) {
  try {
    yield put({ type: types.START_LOAD_ALL_CHILDREN });
    const { jsonData } = yield call(childrenApi.getAllChildren, classroomId, token);

    yield put({ type: types.GET_REPORT_STATUSES_FOR_CHILDREN, classroomId, token });
    yield put({ type: types.LOAD_ALL_CHILDREN_SUCCESS, children: jsonData });
  } catch (e) {
    yield resolveError(types.LOAD_ALL_CHILDREN_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_ALL_CHILDREN_FINISHED });
  }
}

function* watchCreateNewChild({ child, token }) {
  try {
    yield put({ type: types.START_CREATE_NEW_CHILD });

    const { jsonData } = yield call(childrenApi.createChild, child, token);

    yield put({ type: types.CREATE_NEW_CHILD_SUCCESS, newlyCreatedChild: jsonData });
    yield put({ type: types.ADD_NEWLY_CREATED_CHILD_TO_CHILDREN_LIST, newlyCreatedChild: jsonData });
    yield put({ type: types.OPEN_ADD_NEW_CHILD_DIALOG, open: false });
    yield put({ type: types.LOAD_NITIFICATIONS, classroomId: child.classroomId, token });
    yield put({ type: types.GET_REPORT_STATUSES_FOR_CHILDREN, classroomId: child.classroomId, token });
  } catch (e) {
    yield resolveError(types.CREATE_NEW_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.CREATE_NEW_CHILD_FINISHED });
  }
}

function* watchUpdateChild({ child, token }) {
  try {
    yield put({ type: types.START_UPDATE_CHILD });

    const { jsonData } = yield call(childrenApi.updateChild, child, token);
    yield put({ type: types.UPDATE_CHILD_SUCCESS, editedChild: jsonData });
    yield put({ type: types.UPDATE_EDITED_CHILD_IN_CHILDREN_LIST, editedChild: jsonData });
    yield put({ type: types.OPEN_EDIT_CHILD_DIALOG, open: false });
  } catch (e) {
    yield resolveError(types.UPDATE_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.UPDATE_CHILD_FINISHED });
  }
}

function* watchGetQuestionsForChild({ childReportTemplateType, token }) {
  try {
    yield put({ type: types.START_GET_QUESTIONS_FOR_CHILD });
    const { jsonData } = yield call(childrenApi.getQuestionsForChild, childReportTemplateType, token);
    const questionObj = JSON.parse(jsonData.templateJson);
    let camelCaseQuestionObject = MapFromPascelToCamelCase(questionObj);
    yield put({ type: types.GET_QUESTIONS_FOR_CHILD_SUCCESS, report: camelCaseQuestionObject });
  } catch (e) {
    yield resolveError(types.GET_QUESTIONS_FOR_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.GET_QUESTIONS_FOR_CHILD_FINISHED });
  }
}

function* watchSubmitChildReportAnswers({ response, token }) {
  try {
    yield put({ type: types.START_SUBMIT_CHILD_REPORT_ANSWERS });
    yield call(childrenApi.postFormAnswer, response, token);
    yield put({ type: types.SUBMIT_CHILD_REPORT_ANSWERS_SUCCESS });
    yield put({ type: types.OPEN_CHILD_REPORT_DIALOG, open: false });
    yield put({ type: types.LOAD_CHILD_REPORT, childId: response.childId, token });
    yield put({ type: types.GET_REPORT_BUTTONS_STATUS, childId: response.childId, token });
    yield put({ type: types.LOAD_NITIFICATIONS, classroomId: response.classroomId, token });
    yield put({ type: types.GET_REPORT_STATUSES_FOR_CHILDREN, classroomId: response.classroomId, token });
    yield put({ type: types.CLEAR_FIELDS_RELATED_TO_STEPPER });

    yield put(showMessage('Report has been saved.'));
  } catch (e) {
    yield resolveError(types.SUBMIT_CHILD_REPORT_ANSWERS_FAILURE, e);
  } finally {
    yield put({ type: types.SUBMIT_CHILD_REPORT_ANSWERS_FINISHED });
  }
}

function* watchLoadChildReport({ childId, token }) {
  try {
    yield put({ type: types.START_LOAD_CHILD_REPORT });
    const { jsonData } = yield call(childrenApi.loadChildReport, childId, token);
    yield put({ type: types.LOAD_CHILD_REPORT_SUCCESS, reportForChart: jsonData });
  } catch (e) {
    yield resolveError(types.LOAD_CHILD_REPORT_FAILURE, e);
  } finally {
    yield put({ type: types.LOAD_CHILD_REPORT_FINISHED });
  }
}

function* watchTransferChild({ payload, token }) {
  //const email = child.transferEmail; //no api to use email at the moment. will be used later
  const classroomId = payload.classroomId;
  try {
    yield put({ type: types.START_TRANSFER_CHILD });
    const { jsonData } = yield call(childrenApi.transferChild, payload.toTransferChild, token);
    yield put({ type: types.TRANSFER_CHILD_SUCCESS, transferedChild: jsonData });
    yield put({ type: types.LOAD_ALL_CHILDREN, classroomId, token });
    yield put({ type: types.OPEN_TRANSFER_CHILD_DIALOG, open: false });
  } catch (e) {
    yield resolveError(types.TRANSFER_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.TRANSFER_CHILD_FINISHED });
  }
}

function* watchDeactiveChild({ payload, token }) {
  //const email = child.transferEmail; //no api to use email at the moment. will be used later
  const classroomId = payload.classroomId;
  try {
    yield put({ type: types.START_DEACTIVATE_CHILD });
    const { jsonData } = yield call(childrenApi.deactivateChild, payload.toDeactivateChild, token);
    yield put({ type: types.DEACTIVATE_CHILD_SUCCESS, deactivatedChild: jsonData });
    yield put({ type: types.LOAD_ALL_CHILDREN, classroomId, token });
    yield put({ type: types.SHOW_DEACTIVATE_CHILD_DIALOG, open: false });
  } catch (e) {
    yield resolveError(types.DEACTIVATE_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.DEACTIVATE_CHILD_FINISHED });
  }
}

function* watchGetReportButtonsStatus({ childId, token }) {
  try {
    yield put({ type: types.START_GET_REPORT_BUTTONS_STATUS });
    const { jsonData } = yield call(childrenApi.getReportButtonsStatus, childId, token);
    yield put({ type: types.GET_REPORT_BUTTONS_STATUS_SUCCESS, status: jsonData });
  } catch (e) {
    yield resolveError(types.GET_REPORT_BUTTONS_STATUS_FAILURE, e);
  } finally {
    yield put({ type: types.GET_REPORT_BUTTONS_STATUS_FINISHED });
  }
}

function* watchGetChildGameVariationsForChild({ childId, token }) {
  try {
    yield put({ type: types.START_GET_CHILD_GAME_VARIATIONS_FOR_CHILD });
    const { jsonData } = yield call(childrenApi.getChildGameVariationsForChild, childId, token);
    yield put({ type: types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_SUCCESS, gameVariations: jsonData });
  } catch (e) {
    yield resolveError(types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD_FINISHED });
  }
}

//To change variations of a child
function* watchChangeChildGameVariationForChild({ payload, token }) {
  try {
    yield put({ type: types.START_CHANGE_CHILD_GAME_VARIATION_FOR_CHILD });
    const { jsonData } = yield call(childrenApi.changeChildGameVariationForChild, payload, token);
    //True will be returned if the user selects the MainGroup variation again.
    yield put({
      type: types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_SUCCESS,
      updatedGameVariation: jsonData === true ? payload : jsonData,
      mainGroupSelected: jsonData === true ? true : false
    });
  } catch (e) {
    yield resolveError(types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FAILURE, e);
  } finally {
    yield put({ type: types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD_FINISHED });
  }
}

function* watchGetReportStatusesForChildren({ classroomId, token }) {
  try {
    yield put({ type: types.START_GET_REPORT_STATUSES_FOR_CHILDREN });
    const { jsonData } = yield call(childrenApi.getReportStatusesForChildren, classroomId, token);
    yield put({ type: types.GET_REPORT_STATUSES_FOR_CHILDREN_SUCCESS, reportStatuses: jsonData });
  } catch (e) {
    yield resolveError(types.GET_REPORT_STATUSES_FOR_CHILDREN_FAILURE, e);
  } finally {
    yield put({ type: types.GET_REPORT_STATUSES_FOR_CHILDREN_FINISHED });
  }
}

//This is used to catch all the errors in case of request unsuccessful, and will extract the actual error message.
function* resolveError(type, error) {
  let errorMessageCaptured = false;
  let completeErrorMessage = {};
  if (!error.ok) {
    try {
      const clone = error.clone();
      yield clone.text().then(text => {
        completeErrorMessage = JSON.parse(text);
        errorMessageCaptured = true;
      });
    } catch (err) {
      errorMessageCaptured = true;
      completeErrorMessage = err;
    }

    if (errorMessageCaptured) {
      const errorMessage = extractErrorMessage(completeErrorMessage);
      yield put({ type, errorMessage });
    }
  }
}

function extractErrorMessage(completeErrorMessage) {
  let errorMessage = '';

  try {
    const message = completeErrorMessage.exceptionMessage
      ? completeErrorMessage.exceptionMessage.split('Content:')[1]
      : completeErrorMessage.messageDetail;

    errorMessage = JSON.parse(message).errorMessage;
    if (!errorMessage) {
      errorMessage = 'An error occured';
    }
  } catch (error) {
    errorMessage = 'An error occured';
  }

  return errorMessage;
}

export default function* gamesWatcher() {
  yield takeLatest(types.LOAD_ALL_CHILDREN, watchLoadChildren);
  yield takeLatest(types.CREATE_NEW_CHILD, watchCreateNewChild);
  yield takeLatest(types.UPDATE_CHILD, watchUpdateChild);
  yield takeLatest(types.GET_QUESTIONS_FOR_CHILD, watchGetQuestionsForChild);
  yield takeLatest(types.SUBMIT_CHILD_REPORT_ANSWERS, watchSubmitChildReportAnswers);
  yield takeLatest(types.LOAD_CHILD_REPORT, watchLoadChildReport);

  yield takeLatest(types.TRANSFER_CHILD, watchTransferChild);

  yield takeLatest(types.DEACTIVATE_CHILD, watchDeactiveChild);

  yield takeLatest(types.GET_REPORT_BUTTONS_STATUS, watchGetReportButtonsStatus);

  yield takeLatest(types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD, watchGetChildGameVariationsForChild);
  yield takeLatest(types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD, watchChangeChildGameVariationForChild);

  yield takeLatest(types.GET_REPORT_STATUSES_FOR_CHILDREN, watchGetReportStatusesForChildren);
}
