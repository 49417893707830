import { types } from '../constants';

export const getGame = (id, token) => ({
  type: types.LOAD_GAME,
  id,
  token
});

export const getAllGames = (token, minusAlreadyAddedGames) => ({
  type: types.LOAD_ALL_GAMES,
  token,
  minusAlreadyAddedGames
});

export const clearAllDropDownFilters = () => ({
  type: types.CLEAR_ALL_DROP_DOWN_FILTERS
});

export const changeGameTypeFilter = gameTypeId => ({
  type: types.CHANGE_SELECTED_GAME_TYPE_FILTER,
  gameTypeId
});

export const changePopularityFilter = popularityFilterId => ({
  type: types.CHANGE_POPULARITY_FILTER,
  popularityFilterId
});

export const changeGameCheckedStatus = gameId => ({
  type: types.CHANGE_GAME_CHECKED_STATUS,
  gameId
});

export const changeGameCheckedStatusFromGameDetailsPage = gameId => ({
  type: types.CHANGE_GAME_CHECKED_STATUS_FROM_GAME_DETAILS_PAGE,
  gameId
});

export const clearAllGames = () => ({
  type: types.CLEAR_ALL_GAMES
});

export const getGamesForClassroom = (classroomId, token) => ({
  type: types.LOAD_GAMES_FOR_CLASSROOM,
  classroomId,
  token
});

export const getVariationForGame = (gameId, classroomId, token) => ({
  type: types.LOAD_VARIATIONS_FOR_GAME,
  gameId,
  classroomId,
  token
});

export const clearGamesForHomePage = () => ({
  type: types.CLEAR_GAMES_FOR_CLASSROOM
});

export const updateGameVariationId = (payload, token) => ({
  type: types.UPDATE_GAME_VARIATION_ID,
  payload,
  token
});

export const addMultipleGames = (games, token, history) => ({
  type: types.ADD_GAMES,
  games,
  token,
  history
});

export const showRemoveGameDialogStateChanged = open => ({
  type: types.SHOW_REMOVE_GAME_DIALOG,
  open
});

export const removeGame = (gameClassroomVariationId, token, history) => ({
  type: types.REMOVE_GAME,
  gameClassroomVariationId,
  token,
  history
});

export const searchTermChangeForAllGames = searchTerm => ({
  type: types.CHANGE_SEARCH_TERM_ALL_GAMES,
  searchTerm
});

export const searchTermChangeForGamesOfClassroom = searchTerm => ({
  type: types.CHANGE_SEARCH_TERM_GAMES_FOR_CLASSROOM,
  searchTerm
});

export const clearExistingMediaAndImages = () => ({
  type: types.CLEAR_ALL_EXISTING_MEDIA_AND_IMAGES
});

export const setLastVisitedPage = pageName => ({
  type: types.SET_LAST_VISITED_URL,
  pageName
});

//To fix the bug of printing all games dialog opening when coming back from Children page
export const fireOpenPrintingDialogBoxInstruction = status => ({
  type: types.FIRE_OPEN_PRITING_DIALOG_BOX_INSTRUCTION,
  status
});

export const openPrintingDialogBox = open => ({
  type: types.OPEN_PRINTING_DIALOG,
  open
});

export const getGameDetailsForMultipleGames = (gameIds, token) => ({
  type: types.LOAD_GAME_DETAILS_FOR_MULTIPLE_GAMES,
  gameIds,
  token
});

export const clearGameDetailsForMultipleGames = () => ({
  type: types.CLEAR_GAMES_DETAILS_FOR_MULTIPLE_PRINTING
});

export const clearVariationsForChild = () => ({
  type: types.CLEAR_CHILD_GAME_VARIATIONS_FOR_CHILD
});

export const getReportButtonsStatus = (childId, token) => ({
  type: types.GET_REPORT_BUTTONS_STATUS,
  childId,
  token
});

export const changeChildGameVariationForChild = (payload, token) => ({
  type: types.CHANGE_CHILD_GAME_VARIATION_FOR_CHILD,
  payload,
  token
});

export const getChildGameVariationsForChild = (childId, token) => ({
  type: types.GET_CHILD_GAME_VARIATIONS_FOR_CHILD,
  childId,
  token
});

export const loadVariationDetailsForAllGames = (classroomId, token) => ({
  type: types.LOAD_CLASSROOM_VARIATION_DETAILS_FOR_ALL_GAMES_FOR_PRINTING,
  classroomId,
  token
});

export const clearExistingGameRating = () => ({
  type: types.CLEAR_EXISTING_GAME_RATING
});

export const onSubmitRating = (payload, token) => ({
  type: types.SUBMIT_RATING,
  payload,
  token
});

export const updateGameRating = (payload, token) => ({
  type: types.UPDATE_GAME_RATING,
  payload,
  token
});

export const getGameRatings = (payload, token) => ({
  type: types.GET_GAME_RATING,
  payload,
  token
});

export const openCreateGameDialogBox = open => ({
  type: types.OPEN_CREATE_GAME_DIALOG,
  open
});

export const updateTextFieldForGame = (value, inputFieldName) => ({
  type: types.UPDATE_TEXTFIELD_FOR_GAME,
  value,
  inputFieldName
});

export const updateInstructionForGame = (value, inputFieldName, index) => ({
  type: types.UPDATE_INSTRUCTION_FOR_GAME,
  value,
  inputFieldName,
  index
});

export const updateVariationForGame = (value, variationName, id) => ({
  type: types.UPDATE_VARIATION_FOR_GAME,
  value,
  variationName,
  id
});

export const updateGame = (game, token) => ({
  type: types.UPDATE_GAME,
  game,
  token
});

export const clearSingleGame = () => ({
  type: types.CLEAR_SINGLE_GAME
});

export const addNewGameVariation = newGameVariation => ({
  type: types.ADD_NEW_GAME_VARIATION,
  newGameVariation
});

export const defaultVariationChange = defaultVariationId => ({
  type: types.CHANGE_DEFAULT_VARIATION_ID_FOR_GAME,
  defaultVariationId
});

export const removeAttachment = attachment => ({
  type: types.REMOVE_GAME_ATTACHMENT,
  attachment
});
