import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import GiantTreasure from '../../../res/Landing-page/GiantTreasure.png';
import GrassOne from '../../../res/Landing-page/Grass01.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    fontFamily: 'Roboto-Regular',
    backgroundColor: theme.palette.backgroundGray.default,
    height: '100%',
    position: 'relative'
  },
  content: {
    height: 'calc(100vh - 65px)',
    width: 1200,
    margin: '0 auto',
    position: 'relative'
  },
  gridContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100
  },
  gridItem: {},
  sectionTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 50
  },
  sectionBody: {
    fontSize: 30,
    fontWeight: 400
  },
  contactUsButton: {
    width: 300,
    fontSize: 12,
    marginTop: 20,
    position: 'relative',
    zIndex: 100
  },
  rightPanel: {
    height: 420,
    background: `url(${GiantTreasure})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    position: 'relative',
    zIndex: 100
  },
  grassContainer: {
    height: '100px',
    width: '100%',
    position: 'absolute'
    // top: -200
    // bottom: 0
  },
  greenBackground: {
    height: 500,
    position: 'relative',
    top: -50,
    background: theme.palette.primary.main,
    zIndex: 0
  },
  grass: {
    height: 100,
    width: '100%',
    background: `url(${GrassOne})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top -16px left 160px',
    position: 'relative',
    zIndex: 10
  },
  readMoreButton: {
    background: theme.palette.buttonWhite.default,
    position: 'absolute',
    bottom: 50,
    left: '50%',
    color: theme.palette.primary.main,
    padding: '3px 15px',
    marginLeft: -67,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.buttonWhite.default,
      border: `1px solid ${theme.palette.buttonWhite.default}`
    }
  }
}));

const SectionBanner = props => {
  const classes = useStyles();
  const { navigateToRegisterSection } = props;

  //Setting the top of GrassContainer. The top will always touch the bottom of the TreasureHunt kids picture.
  const rightPanel = document.getElementById('right-panel');
  const rightPanelHeight = rightPanel && rightPanel.offsetHeight;
  const rect = rightPanel && rightPanel.getBoundingClientRect();
  let rightPanelEndingPointFromTop;
  if (rect) {
    rightPanelEndingPointFromTop = rect.top + rightPanelHeight;
  }
  const grassContainer = document.getElementById('grass-container');

  const handleReadMoreClick = e => {
    document.getElementById('scroll-button').scrollIntoView({ behavior: 'smooth' });
  };

  if (grassContainer) {
    grassContainer.style.top = `${rightPanelEndingPointFromTop - 132}px`;
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.content} id="content">
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid className={`${classes.gridItem} ${classes.leftPanel}`} item xs={7}>
              <Typography variant="h1" className={classes.sectionTitle}>
                ENGAGE
              </Typography>
              <Typography variant="body1" className={classes.sectionBody}>
                is an evidence-based approach to developing children’s self-regulation skills through play
              </Typography>
              <Button
                id="contactUsButton"
                className={classes.contactUsButton}
                color="primary"
                variant="contained"
                onClick={e => navigateToRegisterSection(e)}
              >
                Contact Us to learn more
              </Button>
            </Grid>
            <Grid className={`${classes.gridItem} ${classes.rightPanel}`} id="right-panel" item xs={5}></Grid>
          </Grid>
        </div>
        <div className={classes.grassContainer} id="grass-container">
          <div className={classes.grass}></div>
          <div className={classes.greenBackground} id="green-background"></div>
        </div>
        <div>
          <Button className={classes.readMoreButton} onClick={handleReadMoreClick} id="scroll-button">
            Read More <KeyboardArrowDownIcon />
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionBanner;
