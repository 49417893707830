const calculatePreferredName = child => {
  let preferredName;
  if (child.preferredName || !child.preferredName === 'null') {
    preferredName = child.preferredName;
  } else {
    preferredName = `${child.firstName} ${child.lastName}`;
  }

  return preferredName;
};

export default calculatePreferredName;
