import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  videoContainer: {
    border: '1px solid red'
  }
}));

const Resources = props => {
  const classes = useStyles();

  const { URL } = props;

  return (
    <React.Fragment>
      <Typography>Resources</Typography>
      <div className={classes.videoContainer}>
        <ReactPlayer playing={false} playsInline={true} url={URL} width="100%" controls={true} />
      </div>
    </React.Fragment>
  );
};

export default Resources;
