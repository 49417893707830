import { handleActions } from 'redux-actions';

import { types, childStatus } from '../constants';

const INITIAL_STATE = {
  allChildren: {
    items: [],
    itemsCopy: [],
    isLoading: false,
    errorMessage: '',
    searchTerm: ''
  },
  reportStatuses: {
    items: null,
    isLoading: false,
    errorMessage: ''
  }
};

const startLoadAllChildren = state => {
  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      isLoading: true
    }
  };
};

const loadAllChildrenSuccess = (state, { children }) => {
  const activeChildren = children.filter(c => c.childStatus === childStatus.ACTIVE);
  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      items: activeChildren,
      itemsCopy: activeChildren
    }
  };
};

const loadAllChildrenFailure = (state, { errorMessage }) => {
  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      errorMessage
    }
  };
};

const loadAllChildrenFinished = state => {
  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      isLoading: false
    }
  };
};

const addNewlyCreatedChildToChildrenList = (state, { newlyCreatedChild }) => {
  return {
    ...state,
    allChildren: {
      items: [...state.allChildren.items, newlyCreatedChild],
      itemsCopy: [...state.allChildren.itemsCopy, newlyCreatedChild]
    }
  };
};

const updateEditedChildToChildrenList = (state, { editedChild }) => {
  const children = state.allChildren.items.map((item, i) => {
    if (item.id === editedChild.id) {
      item = editedChild;
    }

    return item;
  });
  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      items: children,
      itemsCopy: children
    }
  };
};

const changeSearchTermAllChildren = (state, { searchTerm }) => {
  let children = state.allChildren.itemsCopy;
  let filteredChildren = children.filter(c =>
    c.preferredName
      ? c.firstName.toLowerCase().includes(searchTerm) ||
        c.lastName.toLowerCase().includes(searchTerm) ||
        c.preferredName.toLowerCase().includes(searchTerm)
      : c.firstName.toLowerCase().includes(searchTerm) || c.lastName.toLowerCase().includes(searchTerm)
  );
  if (searchTerm === '') {
    filteredChildren = state.allChildren.itemsCopy;
  }

  return {
    ...state,
    allChildren: {
      ...state.allChildren,
      searchTerm,
      items: filteredChildren
    }
  };
};

const startGetReportStatusesForChildren = state => {
  return {
    ...state,
    reportStatuses: {
      ...state.reportStatuses,
      isLoading: true
    }
  };
};

const getReportStatusesForChildrenSuccess = (state, { reportStatuses }) => {
  return {
    ...state,
    reportStatuses: {
      ...state.reportStatuses,
      // items: reportStatuses.length === 0 ? null : reportStatuses
      items: reportStatuses
    }
  };
};

const getReportStatusesForChildrenFailure = (state, { errorMessage }) => {
  return {
    ...state,
    reportStatuses: {
      ...state.reportStatuses,
      errorMessage
    }
  };
};

const getReportStatusesForChildrenFinished = state => {
  return {
    ...state,
    reportStatuses: {
      ...state.reportStatuses,
      isLoading: true
    }
  };
};

const clearAllReportStatuses = state => {
  return {
    ...state,
    reportStatuses: {
      ...state.reportStatuses,
      items: null
    }
  };
};

//Handles the incoming actions.
export default handleActions(
  {
    [types.START_LOAD_ALL_CHILDREN]: startLoadAllChildren,
    [types.LOAD_ALL_CHILDREN_SUCCESS]: loadAllChildrenSuccess,
    [types.LOAD_ALL_CHILDREN_FAILURE]: loadAllChildrenFailure,
    [types.LOAD_ALL_CHILDREN_FINISHED]: loadAllChildrenFinished,

    [types.ADD_NEWLY_CREATED_CHILD_TO_CHILDREN_LIST]: addNewlyCreatedChildToChildrenList,
    [types.UPDATE_EDITED_CHILD_IN_CHILDREN_LIST]: updateEditedChildToChildrenList,

    [types.CHANGE_SEARCH_TERM_ALL_CHILDREN]: changeSearchTermAllChildren,

    [types.START_GET_REPORT_STATUSES_FOR_CHILDREN]: startGetReportStatusesForChildren,
    [types.GET_REPORT_STATUSES_FOR_CHILDREN_SUCCESS]: getReportStatusesForChildrenSuccess,
    [types.GET_REPORT_STATUSES_FOR_CHILDREN_FAILURE]: getReportStatusesForChildrenFailure,
    [types.GET_REPORT_STATUSES_FOR_CHILDREN_FINISHED]: getReportStatusesForChildrenFinished,

    [types.CLEAR_ALL_REPORT_STATUSES]: clearAllReportStatuses
  },
  INITIAL_STATE
);
