import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AllGamesList from '../../components/AllGamesList';
import {
  changeGameTypeFilter,
  changePopularityFilter,
  changeGameCheckedStatus,
  getAllGames,
  clearAllGames,
  addMultipleGames,
  searchTermChangeForAllGames,
  clearExistingMediaAndImages,
  setLastVisitedPage,
  clearAllDropDownFilters
} from '../../actions';
import { urls, pageNames } from '../../constants';
import consolidateGamesWithGameMedia from '../../filters/consolidateGamesWithGameMedia';
import calculateSelectedGames from '../../filters/calculateSelectedGames';

const mapStateToProps = (state, ownProps) => {
  const allGames = state.games.allGames.items;
  let checkedGames = allGames && allGames.filter(game => game.checked === true);

  const gameMedia = state.medias.gameMedias.items;
  const actualImagesLoaded = gameMedia.some(g => g.image);
  if (actualImagesLoaded) {
    consolidateGamesWithGameMedia(allGames, gameMedia);
  }

  return {
    gameTypes: state.games.gameTypes.items,
    gameTypeFilter: state.games.gameTypes.filter,

    popularityFilterTypes: state.games.popularityFilterTypes.items,
    popularityFilter: state.games.popularityFilterTypes.filter,

    allGames,
    selectedGamesCount: calculateSelectedGames(allGames),
    isLoading: state.games.allGames.isLoading,
    isSavingGames: state.games.submitMultipleGames.isSaving,
    enableAddGamesButton: checkedGames && checkedGames.length > 0,
    classroomId: state.classroom.classroom.item && state.classroom.classroom.item.id,
    searchTerm: state.games.allGames.searchTerm,
    isLoadingImages: state.medias.actualImages.isLoading,

    reloadAllGames: state.general.lastVisitedPage !== pageNames.GAME_DETAIL_PAGE || allGames.length === 0
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;
  return {
    onGetAllGames: token => {
      //True when loading all games for AddGamesPage.
      //False when loading all games on admin page
      const minusAlreadyAddedGames = true;
      dispatch(getAllGames(token, minusAlreadyAddedGames));
      dispatch(clearAllDropDownFilters());
      dispatch(clearExistingMediaAndImages());
    },
    onGameTypesFilterChange: gameTypeId => {
      dispatch(changeGameTypeFilter(gameTypeId));
    },
    onPopularityFilterChange: popularityFilterId => {
      dispatch(changePopularityFilter(popularityFilterId));
    },
    onGameCheckStatusChanged: gameId => {
      dispatch(changeGameCheckedStatus(gameId));
    },
    onHandleCrossClick: () => {
      dispatch(setLastVisitedPage(pageNames.ADD_NEW_GAMES_PAGE));
      history.push(urls.HOME_PAGE);
    },
    onComponentLoad: () => {
      dispatch(clearAllGames());
    },
    onAddMultipleGames: (games, token) => {
      dispatch(addMultipleGames(games, token, history));
    },
    onSearchTermChange: searchTerm => {
      dispatch(searchTermChangeForAllGames(searchTerm));
    },
    onHandleViewDetailsClick: (gameId, isChecked) => {
      dispatch(setLastVisitedPage(pageNames.ADD_NEW_GAMES_PAGE));
      history.push(urls.ALL_GAMES + `/${gameId}/view-details`);
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AllGamesList)
);
