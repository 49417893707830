import React from 'react';

import StandardDialog from './../StandardDialog';
import { useAuth0 } from '../Auth';

const LogoutDialog = props => {
  const { open, isSaving, onShowLogoutDialog, onSubmitLogoutRequest } = props;
  const { logout } = useAuth0();

  const handleSubmit = () => {
    onSubmitLogoutRequest();
    logout();
  };

  return (
    <StandardDialog
      open={open || false}
      title="Confirm Logout"
      description="Are you sure you want to Logout?"
      actionButtonText="Logout"
      cancelButtonText="Cancel"
      handleClose={() => onShowLogoutDialog(false)}
      handleSubmit={handleSubmit}
      showProgressBar={isSaving || false}
      errorMessage=""
      isDanger={true}
    />
  );
};

export default LogoutDialog;
