export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const PREFERRED_NAME = 'PREFERRED_NAME';
export const DOB = 'DOB';
export const GENDER = 'GENDER';
export const ETHNICITY = 'ETHNICITY';
export const GAME_TITLE = 'GAME_TITLE';
export const GAME_DESCRIPTION = 'GAME_DESCRIPTION';
export const GAME_TYPE = 'GAME_TYPE';
export const GAME_TIPS = 'GAME_TIPS';
export const GAME_VIDEO = 'GAME_VIDEO';
export const GAME_ATTACHMENT = 'GAME_ATTACHMENT';
export const HOW_TO_PLAY = 'HOW_TO_PLAY';
export const WHAT_YOU_NEED = 'WHAT_YOU_NEED';
export const THINGS_TO_SAY = 'THINGS_TO_SAY';
export const TIPS = 'TIPS';
export const BEHAVIOURAL = 'BEHAVIOURAL';
export const COGNITIVE = 'COGNITIVE';
export const EMOTIONAL = 'EMOTIONAL';
export const DEFAULT_VARIATION_FOR_GAME_DROPDOWN = 'DEFAULT_VARIATION_FOR_GAME_DROPDOWN';
export const EMAIL = 'EMAIL';
export const MESSAGE = 'MESSAGE';

export default {
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  DOB,
  GENDER,
  ETHNICITY,
  GAME_TITLE,
  GAME_DESCRIPTION,
  GAME_TYPE,
  GAME_TIPS,
  GAME_VIDEO,
  GAME_ATTACHMENT,
  HOW_TO_PLAY,
  WHAT_YOU_NEED,
  THINGS_TO_SAY,
  TIPS,
  BEHAVIOURAL,
  COGNITIVE,
  EMOTIONAL,
  DEFAULT_VARIATION_FOR_GAME_DROPDOWN,
  EMAIL,
  MESSAGE
};
